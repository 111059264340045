/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EmptySection } from '../../common/empty-section/EmptySection'
import { TeamInviteCode } from '../team-invite-code/TeamInviteCode'
import styles from './TeamsTabInformation.module.scss'
import aboutIcon from '../../assets/images/svg/about.svg'
import editIcon from '../../assets/images/svg/editIcon.svg'
import { useFetch } from '../../common/https'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { AboutForm } from '../user-profile-information/UserProfileInformation'
import { useLocation } from 'react-router'
import { Loader } from '../../common/Loader/Loader'
import { TeamMembersList } from '../member-list-team/TeamMembersList'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { Pagination } from '@material-ui/lab'
import { describeChannel, listChannelMessages } from '../chat-window/api/ChimeAPI'
import {
  useChatChannelState,
  useChatInitState,
  useChatMessagingState,
} from '../chat-window/providers/ChatMessagesProvider'
import { ChatContent } from '../chat-content/ChatContent'

const AboutSection: React.FC<{ readonly getInfo: any; readonly teamInfo: any }> = ({
  getInfo,
  teamInfo,
}) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const teamId = pathname.substring(pathname.lastIndexOf('/') + 1)

  const { fetchRest, sectionLoading } = useFetch({ loader: false })
  const [openAbout, setOpenAbout] = useState(false)
  return (
    <>
      {sectionLoading && sectionLoading.loader ? (
        <Loader />
      ) : (
        <div className={styles['list-card']}>
          <div className={styles.headingWrapper}>
            <div className={styles.label}>
              <img src={aboutIcon} alt="About icon" className={styles.divImg} />
              <div>{t('userDetails.about')}</div>
            </div>
            <div className={styles.pointer}>
              <img
                src={editIcon}
                alt="edit"
                onClick={() => {
                  setOpenAbout(true)
                }}
              />
            </div>
          </div>
          <div className={styles.heading}>{teamInfo?.teamDetails?.teamTitle}</div>
          <div className={styles.aboutContent}>
            <div className={styles.margin}>
              <>
                {teamInfo?.teamDetails?.teamDescription ? (
                  teamInfo?.teamDetails?.teamDescription
                ) : teamInfo?.teamDetails?.teamTitle ? (
                  ''
                ) : (
                  <EmptySection subText={'Tell people a little about your team'} />
                )}
              </>
            </div>
          </div>
        </div>
      )}
      <DialogWrapper
        onClose={() => {
          setOpenAbout(false)
        }}
        open={openAbout}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <AboutForm
          closePopup={() => {
            setOpenAbout(false)
            getInfo()
          }}
          isTeam={true}
          teamId={teamId}
        />
      </DialogWrapper>
    </>
  )
}

const TeamMembers: React.FC<{
  paginationState: any
  list: any
  changeHandler: any
  count: number
}> = ({ paginationState, list, changeHandler, count }) => {
  const { fetchRest, sectionLoading } = useFetch({ teamMember: false })
  const { pathname } = useLocation()
  const teamId = pathname.substring(pathname.lastIndexOf('/') + 1)

  return (
    <div className={styles['member-section']}>
      <div className={styles.title}>Team Members :</div>
      {sectionLoading && sectionLoading.teamMember ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : (
        <>
          {list ? (
            list.map((each: any, index: number) => {
              return <TeamMembersList teamInfo={each} key={index} />
            })
          ) : (
            <></>
          )}
        </>
      )}
      {count > 10 ? (
        <div className={styles.pagination_wrapper}>
          <Pagination
            page={paginationState.page}
            onChange={changeHandler}
            count={Math.ceil(count / paginationState.perPage)}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

const TeamChatPannel: React.FC<{
  readonly teamInfo: any
  readonly memberList: any
  readonly memberCount: number
}> = ({ teamInfo, memberList, memberCount }) => {
  const { setActiveChannel, activeChannel, setChannelMessageToken } = useChatChannelState()
  const { messages, setMessages } = useChatMessagingState()
  const { fetchRest } = useFetch({ teamChannelPannel: false })
  const { userDetails } = useUserDetailsContext()
  const { initState } = useChatInitState()
  const [member, setMember] = useState({
    userId: localStorage.getItem('identityId'),
    username: undefined,
  })
  // TODO:check it later
  useEffect(() => {
    if (userDetails.userId) {
      const memberObj = {
        userId: userDetails?.identityId
          ? userDetails?.identityId
          : localStorage.getItem('identityId'),
        username: userDetails.userId,
      }
      setMember(memberObj)
    }
  }, [userDetails])
  const setupTeamChatPannel = async (channelArn: string) => {
    const newMessages = await listChannelMessages(channelArn, member?.userId)
    let channel = await describeChannel(channelArn, member?.userId)
    setMessages(newMessages.Messages)
    setChannelMessageToken(newMessages.NextToken)
    setActiveChannel(channel)
  }

  useEffect(() => {
    if (teamInfo && teamInfo.hasOwnProperty('teamChannel') && initState) {
      setupTeamChatPannel(teamInfo.teamChannel)
    }
  }, [teamInfo, initState])

  return (
    <>
      <div className={`${styles['list-card']} ${styles['chat-pannel-box']}`}>
        <div className={`${styles.headingWrapper} ${styles['team-chat-wrapper']}`}>
          <div className={styles.label}>
            <img src={aboutIcon} alt="Team icon" className={styles.divImg} />
            <div>Team chat</div>
          </div>
          <div className={styles['team-chatting-box']}>
            <ChatContent
              memberList={memberList}
              memberCount={memberCount}
              initState={initState}
              member={member}
              messages={messages}
              activeChannel={activeChannel}
              isTeam={true}
              viewType={'team'}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export const InformationTab: React.FC<{
  readonly invitationKey: string
  readonly callBack?: any
  readonly teamData?: any
}> = ({ invitationKey, callBack = () => {}, teamData = {} }) => {
  const { fetchRest } = useFetch()
  const [admin, setAdmin] = useState<boolean>(false)
  const [paginationState, setPaginationState] = useState<{
    readonly perPage: number
    readonly page: number
  }>({
    perPage: 10,
    page: 1,
  })
  const [count, setCount] = useState<number>(0)
  const [list, setList] = useState<any>([])
  const { userDetails } = useUserDetailsContext()

  const { pathname } = useLocation()
  const teamId = pathname.substring(pathname.lastIndexOf('/') + 1)

  const fetchMembers = (obj: { readonly page?: number; readonly perPage?: number }) => {
    const { page = paginationState.page, perPage = paginationState.perPage } = obj
    fetchRest(
      {
        method: 'GET',
        url: `team/${teamId}/members`,
        queryParams: {
          page: page,
          perPage: perPage,
        },
      },
      'teamMember'
    )
      .then((res) => {
        setCount(res.data.count)
        const sortValues = ['SuperAdmin', 'Admin', 'Member']
        let arr1 = []
        for (let i = 0; i < sortValues.length; i++) {
          for (let j = 0; j < res.data.data.length; j++) {
            if (res.data.data[j] !== undefined && res.data.data[j].memberRole === sortValues[i]) {
              arr1.push(res.data.data[j])
              res.data.data[j] = undefined
            }
          }
        }
        setList(arr1)
        arr1.forEach((each: any) => {
          if (each.memberInfo.userId === userDetails.userId) {
            if (each.memberRole === 'SuperAdmin') {
              setAdmin(true)
            }
          }
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const changeHandler = (event: any, value: any) => {
    setPaginationState((prev) => ({ ...prev, page: value }))
    fetchMembers({ page: value, perPage: paginationState.perPage })
  }
  useEffect(() => {
    fetchMembers({})
  }, [])
  return (
    <>
      <div className={styles.contentWrapper}>
        <div className={styles.left}>
          <TeamChatPannel teamInfo={teamData} memberList={list} memberCount={count} />
          <AboutSection getInfo={callBack} teamInfo={teamData} />
        </div>
        <div className={styles.right}>
          <TeamInviteCode inviteCode={invitationKey} callBack={callBack} />
          <TeamMembers
            paginationState={paginationState}
            list={list}
            changeHandler={changeHandler}
            count={count}
          />
        </div>
      </div>
    </>
  )
}
