/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { debounce } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import deleteIcon from '../../assets/images/svg/delete-icon.svg'
import {
  default as deleteMenu,
  default as RepeatRequest,
} from '../../assets/images/svg/delete-request.svg'
import redCross from '../../assets/images/svg/red-cross.svg'
import Search from '../../assets/images/svg/search.svg'
import settings from '../../assets/images/svg/settings.svg'
import createTeam from '../../assets/images/svg/teamSuccessPopup.svg'
import userIcon from '../../assets/images/svg/userIcon.svg'
import whiteTick from '../../assets/images/svg/whiteTick.svg'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { Button } from '../../common/button/Button'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { EmptySection } from '../../common/empty-section/EmptySection'
import { useFetch } from '../../common/https'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import { Loader } from '../../common/Loader/Loader'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import { UsePopupContext } from '../../common/use-popup-context'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import {
  TeacherChatUserProfile,
  StudentChatUserProfile,
} from '../chat-user-profile/ChatUserProfile'
import { InviteUsers } from '../invite-users/InviteUsers'
import { NameInitials } from '../name-initials/NameInitials'
import { TeamInviteCode } from '../team-invite-code/TeamInviteCode'
import { UserRights } from '../user-rights/UserRights'
import styles from './TeamsTabMembers.module.scss'

const JoinRequestsAndInvites: React.FC<{
  readonly userData: any
  readonly callBackFunction?: any
}> = ({ userData, callBackFunction = () => {} }) => {
  const { fetchRest, sectionLoading } = useFetch({ actionForRequest: false })
  const { close, setData: setPopupData } = UsePopupContext()
  const { t } = useTranslation()
  const [profile, setProfile] = useState(false)
  const [profileDetails, setProfileDetails] = useState({
    isTeacher: false,
    userId: '',
  })

  const setProfileInfo = (userId: string, isTeacher: boolean) => {
    setProfileDetails({
      isTeacher: isTeacher,
      userId: userId,
    })
    setProfile(true)
  }

  const openPopup = (success: boolean, value?: string) => {
    let headerContent: string = ''
    let content: string = ''
    if (userData.actionType === 'Invitation') {
      if (success) {
        headerContent = t('teams.cancelInvitation')
        content = t('teams.cancelInvitationContent')
      } else {
        headerContent = t('teams.createTeam.error')
      }
    } else if (userData.actionType === 'Join_Request') {
      if (success) {
        if (value === 'Accepted') {
          headerContent = t('teams.accepted')
          content = t('teams.acceptedJoinRequest')
        } else if (value === 'Declined') {
          headerContent = t('teams.cancel')
          content = t('teams.cancelledJoinRequest')
        }
      } else {
        headerContent = t('teams.createTeam.error')
      }
    }

    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? createTeam : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.green}
                    text={'Got It'}
                    onClick={() => {
                      close()
                      callBackFunction({})
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button
                    text={'Go back'}
                    className={styles['cancel-btn']}
                    onClick={close}
                  ></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }
  const [loader, setLoader] = useState<'Declined' | 'Accepted' | 'Cancelled' | ''>('')
  const actionForRequestAndInvite = (type: 'Declined' | 'Accepted' | 'Cancelled' | '') => {
    setLoader(type)
    fetchRest(
      {
        method: 'GET',
        url: `team/${userData?.teamId}/action/${userData.actionId}/resolve/${type}`,
      },
      'actionForRequest'
    )
      .then((res) => {
        openPopup(true, type)
        setLoader('')
      })
      .catch((err: any) => {
        openPopup(false)
      })
  }
  return (
    <>
      <div className={styles.outerWrapper}>
        <div className={styles.wrapperForJoin}>
          <div
            className={styles.joinSection}
            onClick={() =>
              setProfileInfo(userData?.actionToInfo?.userId, userData?.actionToInfo?.isTeacher)
            }
          >
            <div className={styles.iconWrapper}>
              {userData?.actionToInfo?.imageUrl ? (
                <img src={userData?.actionToInfo?.imageUrl} alt="action" className={styles.icon} />
              ) : (
                <NameInitials
                  firstName={userData?.actionToInfo?.firstName}
                  lastName={userData?.actionToInfo?.lastName}
                  className={styles.margin}
                />
              )}
            </div>
            <div className={styles.userName}>
              {userData?.actionToInfo?.firstName} {userData?.actionToInfo?.lastName}
            </div>
          </div>
          <NoEventsLoader isLoading={sectionLoading && sectionLoading.actionForRequest}>
            <div className={styles.joinSection}>
              <div>
                {userData?.actionType === 'Join_Request' ? (
                  <Button
                    text={'Accept'}
                    className={styles.acceptButton}
                    ButtonImg={() => {
                      return (
                        <>
                          {sectionLoading &&
                          sectionLoading.actionForRequest &&
                          loader === 'Accepted' ? (
                            <>
                              <Loader size={20} thickness={2} className={styles.divImg} />
                            </>
                          ) : (
                            <img src={whiteTick} alt="tick icon" className={styles.divImg} />
                          )}
                        </>
                      )
                    }}
                    onClick={() => {
                      actionForRequestAndInvite('Accepted')
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>

              <div>
                {sectionLoading &&
                sectionLoading.actionForRequest &&
                (loader === 'Declined' || loader === 'Cancelled') ? (
                  <>
                    <Loader size={30} thickness={2} className={styles.divImg} />
                  </>
                ) : (
                  <img
                    src={redCross}
                    alt="cross"
                    className={styles.cross}
                    onClick={() => {
                      if (userData?.actionType === 'Join_Request') {
                        actionForRequestAndInvite('Declined')
                      } else {
                        actionForRequestAndInvite('Cancelled')
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </NoEventsLoader>
        </div>
      </div>
      <DialogWrapper
        onClose={() => {
          setProfile(false)
        }}
        open={profile}
        Title={DialogHeader}
      >
        {profileDetails.isTeacher ? (
          <TeacherChatUserProfile isChat={false} userId={profileDetails.userId} />
        ) : (
          <StudentChatUserProfile isChat={false} userId={profileDetails.userId} />
        )}
      </DialogWrapper>
    </>
  )
}

export const MembersList: React.FC<{
  readonly teamId: string
  callBackFunction?: any
  getMembers?: any
  membersList?: any
  count?: any
  isAdmin: boolean
}> = ({ teamId, callBackFunction = () => {}, getMembers, membersList, count, isAdmin }) => {
  const [inviteUsers, setInviteUsers] = useState(false)
  const [userRights, setUserRights] = useState(false)
  const { userDetails } = useUserDetailsContext()
  const [searchItems, setSearchItems] = useState<any>({
    value: '',
    isTouched: false,
    isValid: false,
    errorMsg: '',
  })
  const [paginationState, setPaginationState] = useState<{
    readonly perPage: number
    readonly page: number
  }>({
    perPage: 10,
    page: 1,
  })

  const { fetchRest, sectionLoading } = useFetch({ loader: false })
  const { close, setData: setPopupData } = UsePopupContext()
  const [teamData, setTeamData] = useState(null)
  // const [admin, setAdmin] = useState(false)

  useEffect(() => {
    getMembers(
      {
        page: paginationState.page,
        perPage: paginationState.perPage,
      },
      searchItems.value
    )
  }, [searchItems])

  const removeMember = (memberId: string) => {
    fetchRest({
      url: `team/remove`,
      method: 'POST',
      data: {
        userId: userDetails.userId,
        teamId: teamId,
        memberId: memberId,
      },
      isFullPageLoader: true,
    })
      .then((res) => {
        getMembers({
          page: 1,
          perPage: 10,
        })
      })
      .catch((err) => console.log(err))
  }
  // const onChangeSearch = (e: ) => {
  //   const { value: nextValue } = e.value
  //   debouncedSave(nextValue)
  // }

  const debouncedSave = useCallback(
    debounce((value: string) => {
      setSearchItems((prev: any) => {
        return {
          ...prev,
          value,
        }
      })
    }, 1000),
    []
  )
  const popUp = (memberId: string) => {
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={deleteMenu} alt="popup icon" />
        }}
        title={'Remove User'}
        Buttons={() => {
          return (
            <>
              <Button
                text={'Remove'}
                className={styles.red}
                onClick={() => {
                  removeMember(memberId)
                  close()
                }}
              ></Button>
              <Button text={'Cancel'} className={styles['cancel-btn']} onClick={close}></Button>
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>Are you sure want to remove user from the group</p>
            </div>
          )
        }}
      />
    )
  }

  const [profile, setProfile] = useState(false)
  const [profileDetails, setProfileDetails] = useState({
    isTeacher: false,
    userId: '',
  })

  const setProfileInfo = (userId: string, isTeacher: boolean) => {
    setProfileDetails({
      isTeacher: isTeacher,
      userId: userId,
    })
    setProfile(true)
  }

  return (
    <div className={styles['membersList-container']}>
      <div className={`${styles.section} ${styles.search_section}`}>
        <div className={styles.search_heading}>
          Team Members: <span className={styles.request_count}>{count}</span>
        </div>
        <div className="autocomplete-wrapper autocomplete_disable_icon_animation">
          <InputWrapper
            type="text"
            name="searchLearn"
            id={styles.searchLearn}
            inputIcon={Search}
            placeholder="Search username..."
            onChange={(e: any) => {
              debouncedSave(e.value)
            }}
            valueObj={searchItems}
          />
        </div>
        <Button
          text={'Invite users'}
          ButtonImg={() => {
            return <img src={userIcon} alt="user icon" className={styles.user_img} />
          }}
          onClick={() => setInviteUsers(true)}
          className={styles.invite_btn}
        />
        {sectionLoading && sectionLoading.loader ? (
          <Loader />
        ) : (
          <>
            {membersList && membersList.length > 0 ? (
              <>
                <div className={styles.memberTop}>
                  {membersList.map((each: any, index: any) => {
                    return (
                      <>
                        {each.memberStatus === 'Active' ? (
                          <div className={styles.members} key={index}>
                            <div
                              className={styles['membersList-profile']}
                              onClick={() =>
                                setProfileInfo(each.memberInfo.userId, each.memberInfo.isTeacher)
                              }
                            >
                              {each.memberInfo.imageUrl ? (
                                <img
                                  src={each.memberInfo.imageUrl}
                                  alt="owner"
                                  className={styles.user_Img}
                                />
                              ) : (
                                <NameInitials
                                  firstName={each.memberInfo.firstName}
                                  lastName={each.memberInfo.lastName}
                                  className={styles.user_Img}
                                />
                              )}
                              <div className={styles['membersList-userDetails']}>
                                <div className={styles['membersList-username']}>
                                  {each.memberInfo.firstName}
                                </div>
                                <div
                                  className={
                                    each.memberRole === 'Admin' || each.memberRole === 'SuperAdmin'
                                      ? `${styles['membersList-role']} ${styles.pink}`
                                      : styles['membersList-role']
                                  }
                                >
                                  {each.memberRole}
                                </div>
                              </div>
                            </div>
                            {each.memberRole === 'SuperAdmin' ? (
                              <></>
                            ) : (
                              <div className={styles['membersList-actions']}>
                                {isAdmin ? (
                                  <>
                                    <Button
                                      text={'User rights'}
                                      ButtonImg={() => {
                                        return (
                                          <img
                                            src={settings}
                                            alt="settings icon"
                                            className={styles.user_img}
                                          />
                                        )
                                      }}
                                      onClick={() => {
                                        setUserRights(true)
                                        setTeamData(each)
                                      }}
                                      className={styles.settings_btn}
                                    />
                                    <img
                                      src={deleteIcon}
                                      alt="delete icon"
                                      className={styles.delete_img}
                                      onClick={() => {
                                        popUp(each.memberId)
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    )
                  })}
                </div>
                <div className={styles.pagination_wrapper}>
                  <Pagination
                    page={paginationState.page}
                    onChange={(event, value) => {
                      setPaginationState((prev) => ({ ...prev, page: value }))
                    }}
                    count={Math.ceil(count / paginationState.perPage)}
                  />
                </div>
              </>
            ) : (
              <>
                <EmptySection subText="No members with given name" />
              </>
            )}
          </>
        )}
      </div>
      <DialogWrapper
        onClose={() => {
          setInviteUsers(false)
        }}
        open={inviteUsers}
        Title={DialogHeader}
      >
        <InviteUsers
          teamId={teamId}
          closePopup={() => {
            setInviteUsers(false)
            callBackFunction()
          }}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setUserRights(false)
        }}
        open={userRights}
        Title={DialogHeader}
      >
        <UserRights
          team={teamData}
          closePopup={() => {
            setUserRights(false)
            getMembers({
              page: 1,
              perPage: 10,
            })
          }}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setProfile(false)
        }}
        open={profile}
        Title={DialogHeader}
      >
        {profileDetails.isTeacher ? (
          <TeacherChatUserProfile isChat={false} userId={profileDetails.userId} />
        ) : (
          <StudentChatUserProfile isChat={false} userId={profileDetails.userId} />
        )}
      </DialogWrapper>
    </div>
  )
}

export const TeamMembers: React.FC<{
  readonly invitationKey: string
  readonly isAdmin?: boolean
  readonly callBack?: any
  readonly memberRole: string
}> = ({ invitationKey, isAdmin = false, callBack = () => {}, memberRole }) => {
  const { fetchRest, sectionLoading } = useFetch({ getAllRequestsAndInvites: false })
  const [count, setCount] = useState<number>(0)
  const [requestsAndInvites, setRequestsAndInvites] = useState([])
  const { pathname } = useLocation()
  const [paginationState, setPaginationState] = useState<{ page: number; perPage: number }>({
    page: 1,
    perPage: 3,
  })
  const teamId: string = pathname.substring(pathname.lastIndexOf('/') + 1)
  const changeHandler = (event: any, value: any) => {
    setPaginationState((prev) => ({ ...prev, page: value }))
    fetchAllInvitesAndRequests({ page: value, perPage: paginationState.perPage })
  }

  const [membersList, setMembersList] = useState<any>([])
  const [memberCount, setMemberCount] = useState<number>(0)
  const fetchMembers = (paginationMemberState: any, searchValue?: string) => {
    fetchRest(
      {
        method: 'GET',
        url: `team/${teamId}/members`,
        queryParams: {
          page: paginationMemberState.page,
          perPage: paginationMemberState.perPage,
          search: searchValue ? searchValue : '',
        },
      },
      'loader'
    )
      .then((res) => {
        setMemberCount(res.data.count)
        const sortValues = ['SuperAdmin', 'Admin', 'Member']
        let arr1 = []
        for (let i = 0; i < sortValues.length; i++) {
          for (let j = 0; j < res.data.data.length; j++) {
            if (res.data.data[j] !== undefined && res.data.data[j].memberRole === sortValues[i]) {
              arr1.push(res.data.data[j])
              res.data.data[j] = undefined
            }
          }
        }
        setMembersList(arr1)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const fetchAllInvitesAndRequests = (obj: {
    readonly page?: number
    readonly perPage?: number
  }) => {
    const { page = paginationState.page, perPage = paginationState.perPage } = obj
    fetchRest(
      {
        method: 'GET',
        url: `team/${teamId}/join-requests-and-invitations`,
        queryParams: {
          page: paginationState.page,
          perPage: paginationState.perPage,
        },
      },
      'getAllRequestsAndInvites'
    ).then((res) => {
      setRequestsAndInvites(res.data.data)
      setCount(res.data.count)
    })
  }

  useEffect(() => {
    fetchAllInvitesAndRequests({})
  }, [])
  const { t } = useTranslation()
  return (
    <>
      <div className={styles.contentWrapper}>
        <div className={styles.left}>
          <div className={styles.invites}>
            <div className={styles.joinRequestTitle}>
              {t('teams.joinRequestsAndInvites')}
              <span className={count === 0 ? styles.count : styles.countBlue}>{count}</span>
            </div>
            <div>
              {sectionLoading && sectionLoading.getAllRequestsAndInvites ? (
                <Loader className={styles.marginBtm} />
              ) : (
                <>
                  {count ? (
                    requestsAndInvites.map((each: any, key: number) => {
                      return (
                        <JoinRequestsAndInvites
                          userData={each}
                          key={key}
                          callBackFunction={() => {
                            fetchAllInvitesAndRequests({})
                            fetchMembers({ page: 1, perPage: 10 })
                          }}
                        />
                      )
                    })
                  ) : (
                    <></>
                  )}
                </>
              )}
              {count > 3 ? (
                <div className={styles.pagination}>
                  <Pagination
                    count={Math.ceil(count / paginationState.perPage)}
                    onChange={changeHandler}
                    page={paginationState.page}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <MembersList
            teamId={teamId}
            callBackFunction={() => {
              fetchAllInvitesAndRequests({})
            }}
            getMembers={(pagination: any, value: string | undefined) => {
              fetchMembers(pagination, value)
            }}
            membersList={membersList}
            count={memberCount}
            isAdmin={memberRole === 'SuperAdmin' ? true : false}
          />
        </div>
        <div className={styles.right}>
          <TeamInviteCode inviteCode={invitationKey} isAdmin={isAdmin} callBack={callBack} />
        </div>
      </div>
    </>
  )
}
