/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { Button } from '../../common/button/Button'
import { NameInitials } from '../name-initials/NameInitials'
import styles from './TeamDetails.module.scss'
import whiteTick from '../../assets/images/svg/whiteTick.svg'
import redCross from '../../assets/images/svg/red-cross.svg'
import { DropDown } from '../../common/drop-down/DropDown'
import dotMenu from '../../assets/images/svg/dots-menu.svg'
import deleteMenu from '../../assets/images/svg/delete-menu.svg'
import leave from '../../assets/images/svg/leaveTeam.svg'
import createTeam from '../../assets/images/svg/teamSuccessPopup.svg'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { UsePopupContext } from '../../common/use-popup-context'
import { useTranslation } from 'react-i18next'
import RepeatRequest from '../../assets/images/svg/delete-request.svg'
import { useFetch } from '../../common/https'
import { Loader } from '../../common/Loader/Loader'
import { useHistory } from 'react-router'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import deleteTeam from '../../assets/images/svg/deleteTeamPopup.svg'
import LeaveTeam from '../../assets/images/svg/LeaveTeamPopup.svg'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { DeleteTeam } from '../delete-team/DeleteTeam'

export const TeamDetails: React.FC<{
  readonly teamInfo: any
  readonly teamMembers?: any
  readonly callBackFunction?: any
  readonly fetchList?: any
  readonly join?: boolean
  readonly inPending?: boolean
}> = ({
  inPending = false,
  teamInfo,
  join = false,
  callBackFunction = () => {},
  fetchList = () => {},
  teamMembers = teamInfo.teamMembers,
}) => {
  const { push } = useHistory()
  const { t } = useTranslation()
  const { close, setData: setPopupData } = UsePopupContext()
  const { fetchRest, sectionLoading } = useFetch({
    getTeam: false,
    getTeamMembers: false,
    Action: false,
    leaveTeam: false,
  })
  const { userDetails } = useUserDetailsContext()
  const [openDeleteTeamDialog, setOpenDeleteTeamDialog] = useState<boolean>(false)
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [loader, setLoader] = useState<'Declined' | 'Accepted' | 'Cancelled' | ''>('')
  const listArrayIsAdmin = [
    {
      listContent: (
        <>
          <div className={styles.element}>
            <img src={leave} alt="person icon" className={styles.elementImage} />
            <div>Leave team</div>
          </div>
        </>
      ),
      value: 'Leave Team',
      onClick: (_e: any) => {
        openPopup(false, 'Leave')
      },
    },
    {
      listContent: (
        <>
          <div className={styles.element}>
            <img src={deleteMenu} alt="person icon" className={styles.elementImage} />
            <div>Delete team</div>
          </div>
        </>
      ),
      value: 'Delete team',
      onClick: (_e: any) => {
        setOpenDeleteTeamDialog(true)
      },
    },
  ]

  const listArray = [
    {
      listContent: (
        <>
          <div className={styles.element}>
            {sectionLoading && sectionLoading.leaveTeam ? (
              <Loader size={20} thickness={2} className={styles.marginRight} />
            ) : (
              <img src={leave} alt="person icon" className={styles.elementImage} />
            )}
            <div>Leave team</div>
          </div>
        </>
      ),
      value: 'Leave Team',
      onClick: (_e: any) => {
        leaveTeam()
      },
    },
  ]

  const leaveTeam = () => {
    fetchRest(
      {
        url: `/team/${teamInfo?.teamId}/leave-team`,
        method: 'GET',
      },
      'leaveTeam'
    )
      .then((res: any) => {
        openPopup(true, 'Leave')
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const openPopup = (success: boolean, type?: string) => {
    let headerContent: string = ''
    let content: string = ''
    if (type === 'Leave') {
      if (success) {
        headerContent = t('teams.createTeam.leaveTeam')
        content = t('teams.createTeam.leaveTeamContent')
      } else {
        headerContent = t('teams.createTeam.cannotLeave')
        content = t('teams.createTeam.cannotLeaveContent')
      }
    } else if (type === 'Declined') {
      if (success) {
        headerContent = t('teams.createTeam.declineRequest')
      } else {
        headerContent = t('teams.createTeam.couldNotCompleteRequest')
      }
    } else if (type === 'Accepted') {
      if (success) {
        headerContent = t('teams.createTeam.invitationAccepted')
      } else {
        headerContent = t('teams.createTeam.couldNotCompleteRequest')
      }
    } else if (type === 'Cancelled') {
      if (success) {
        headerContent = t('teams.cancelInvitation')
      } else {
        headerContent = t('teams.createTeam.couldNotCompleteRequest')
      }
    } else if (type === 'Delete') {
      headerContent = 'Work in progress'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return (
            <img
              src={
                success
                  ? createTeam
                  : type === 'delete'
                  ? deleteTeam
                  : type === 'Leave'
                  ? LeaveTeam
                  : RepeatRequest
              }
              alt="popup icon"
            />
          )
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.green}
                    text={'Got It'}
                    onClick={() => {
                      close()
                      fetchList({})
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button
                    text={'Close'}
                    onClick={(e) => {
                      close()
                    }}
                    className={styles.white}
                  ></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  const actionForJoinRequestAndInvite = (type: 'Declined' | 'Accepted' | 'Cancelled' | '') => {
    setLoader(type)
    fetchRest(
      {
        url: `team/${teamInfo.teamId}/action/${teamInfo.actionId}/resolve/${type}`,
        method: 'GET',
        isFullPageLoader: false,
      },
      'Action'
    )
      .then((res: any) => {
        openPopup(true, type)
        setLoader('')
      })
      .catch((err) => {
        openPopup(false)
      })
  }

  const userImgFieldUI = (memberList: any[], memberCount: number, memberLimit: number = 5) => {
    return (
      <>
        <div className={styles.members}>
          {memberList.map((member, index) => {
            if (index < memberLimit) {
              return (
                <div className={styles.icon} key={index}>
                  {member.memberInfo.imageUrl ? (
                    <img src={member.memberInfo.imageUrl} alt="user profile" />
                  ) : (
                    <NameInitials
                      firstName={member?.memberInfo.firstName}
                      lastName={member?.memberInfo.lastName}
                    />
                  )}
                </div>
              )
            } else {
              return null
            }
          })}
          {memberCount > memberLimit ? (
            <div className={`${styles.icon} ${styles.relative4}`}>
              <div className={`${styles.container}`}>+{memberCount - memberLimit}</div>
            </div>
          ) : null}
        </div>
      </>
    )
  }

  useEffect(() => {
    if (!inPending && !join) {
      teamMembers.forEach((each: any) => {
        if (each.memberInfo.userId === userDetails.userId && each.memberRole === 'SuperAdmin') {
          setIsAdmin(true)
        }
      })
    }
  }, [userDetails])

  return (
    <>
      <div
        className={styles.myTeamWrapper}
        onClick={() => {
          if (!inPending && !join) {
            callBackFunction('profile')
            push(`/teams/${teamInfo.teamId}?tab=Information`)
          }
        }}
      >
        {sectionLoading && sectionLoading.getTeam ? (
          <Loader />
        ) : (
          <div className={styles.dflex}>
            <div className={styles.heading}>
              {inPending
                ? teamInfo?.teamMetaInfo?.teamDetails?.teamName
                : teamInfo?.teamDetails?.teamName}
            </div>
            {inPending || join ? (
              <></>
            ) : (
              <div
                onClick={(events) => {
                  events.stopPropagation()
                }}
              >
                <DropDown
                  menuProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                    transformOrigin: { vertical: 'top', horizontal: 'right' },
                  }}
                  ListArray={isAdmin ? listArrayIsAdmin : listArray}
                >
                  <div className={styles['dropdown-menu']}>
                    <img src={dotMenu} alt="menu" className={styles['dotted-menu']} />
                  </div>
                </DropDown>
              </div>
            )}
          </div>
        )}
        <div className={styles.desc}>{/* {team.createdOn} and {team.createdAt} */}</div>
        {teamMembers && teamMembers.length
          ? userImgFieldUI(teamMembers, teamMembers.length, teamMembers?.totalTeamMembers)
          : null}
        <div>
          {inPending ? (
            teamInfo.actionType === 'Join_Request' ? (
              <div className={styles.flex}>
                <Button
                  text="Cancel request"
                  className={`${styles.grey} ${styles.widthCancelRequest}`}
                  ButtonImg={() => {
                    return (
                      <>
                        {sectionLoading && sectionLoading.Action ? (
                          <Loader size={20} thickness={2} className={styles.marginRight} />
                        ) : (
                          <img src={redCross} className={styles.divImg} alt="" />
                        )}
                      </>
                    )
                  }}
                  onClick={() => {
                    actionForJoinRequestAndInvite('Cancelled')
                  }}
                />
              </div>
            ) : (
              <div className={styles.flex}>
                <Button
                  text="Accept"
                  className={styles.button}
                  ButtonImg={() => {
                    return (
                      <>
                        {sectionLoading && sectionLoading.Action && loader === 'Accepted' ? (
                          <Loader size={20} thickness={2} className={styles.marginRight} />
                        ) : (
                          <img src={whiteTick} className={styles.divImg} alt="" />
                        )}
                      </>
                    )
                  }}
                  onClick={() => {
                    actionForJoinRequestAndInvite('Accepted')
                  }}
                />
                <Button
                  text="Decline"
                  className={styles.grey}
                  ButtonImg={() => {
                    return (
                      <>
                        {sectionLoading && sectionLoading.Action && loader === 'Declined' ? (
                          <Loader size={20} thickness={2} className={styles.marginRight} />
                        ) : (
                          <img src={redCross} className={styles.divImg} alt="" />
                        )}
                      </>
                    )
                  }}
                  onClick={() => {
                    actionForJoinRequestAndInvite('Declined')
                  }}
                />
              </div>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
      <DialogWrapper
        Title={DialogHeader}
        open={openDeleteTeamDialog}
        onClose={() => {
          setOpenDeleteTeamDialog(false)
        }}
        isFullScreen={false}
        fullHeightDialog={false}
      >
        <DeleteTeam
          teamInfo={teamInfo}
          closePopup={() => {
            setOpenDeleteTeamDialog(false)
            fetchList({})
          }}
        />
      </DialogWrapper>
    </>
  )
}
