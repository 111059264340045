/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import edit from '../../assets/images/svg/editButton.svg'
import { useFetch } from '../../common/https'
import { Loader } from '../../common/Loader/Loader'
import ProtectedRoute from '../../common/protected-route/ProtectedRoute'
import { useUploadContext } from '../../common/use-upload-context'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { Footer } from '../../components/footer/Footer'
import { Header } from '../../components/header/Header'
import { TabItem } from '../../components/tab-item/TabItem'
import { TabsWrapper, TabWrapperProps } from '../../components/tabs-wrapper/TabsWrapper'
import { UserProfileAccountSetting } from '../../components/user-profile-account-setting/UserProfileAccountSetting'
import { UserProfileFinancialSetting } from '../../components/user-profile-financial-setting/UserProfileFinancialSetting'
import { UserProfileInformation } from '../../components/user-profile-information/UserProfileInformation'
import { UserProfileReviews } from '../../components/user-profile-reviews/UserProfileReviews'
import styles from './UserProfile.module.scss'
interface TeachersFeedback {
  reviewRating: string
  reviewSummary: string
  reviewerName: string
  reviewerImage: string
  sessionName: string
}
const UserProfile: React.FC<{}> = () => {
  const { userDetails, setUser } = useUserDetailsContext()
  const { search } = useLocation()
  const { push } = useHistory()
  const [teachersReview, setTeachersReview] = useState<ReadonlyArray<TeachersFeedback>>([])
  const [teacherFeedbackCount, setTeacherFeedbackCount] = useState<number>(0)
  const [studentsReview, setStudentsReview] = useState<ReadonlyArray<TeachersFeedback>>([])
  const [studentFeedbackCount, setStudentFeedbackCount] = useState<number>(0)
  const [defaultState, setDefaultState] = useState<number>(-1)
  const [isActiveTab, setIsActiveTab] = useState<
    'Information' | 'Review' | 'AccountSetting' | 'FinancialSetting'
  >(userDetails.isTeacher ? 'Information' : 'Review')
  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const { fetchRest, sectionLoading } = useFetch({ userImage: false })
  const { uploadFile } = useUploadContext()
  const S3_BUCKET = process.env.REACT_APP_USER_ASSET_S3 || 'ontelo-dev-user-assets'

  const setValueForActiveTab = (tabName: string) => {
    if (tabName === 'Information') {
      setIsActiveTab('Information')
    } else if (tabName === 'Review') {
      setIsActiveTab('Review')
    } else if (tabName === 'AccountSetting') {
      setIsActiveTab('AccountSetting')
    } else if (tabName === 'FinancialSetting') {
      setIsActiveTab('FinancialSetting')
    }
  }

  const onTabChange = (activeTab: number) => {
    if (userDetails.isTeacher) {
      switch (activeTab) {
        case 1:
          push('/user-profile?tab=Reviews')
          setIsActiveTab('Review')

          break
        case 2:
          push('/user-profile?tab=AccountSetting')
          setIsActiveTab('AccountSetting')

          break
        case 3:
          push('/user-profile?tab=FinancialSetting')
          setIsActiveTab('FinancialSetting')

          break
        default:
          push('/user-profile?tab=Information')
          setIsActiveTab('Information')
      }
    } else {
      switch (activeTab) {
        case 1:
          push('/user-profile?tab=AccountSetting')
          setIsActiveTab('AccountSetting')

          break
        case 2:
          push('/user-profile?tab=FinancialSetting')
          setIsActiveTab('FinancialSetting')
          break
        default:
          push('/user-profile?tab=Reviews')
          setIsActiveTab('Review')
      }
    }
  }

  const commonTabs: ReadonlyArray<TabWrapperProps> = [
    {
      key: 'Reviews',
      TabItemWrapper: ({ isActive }) => {
        return (
          <TabItem
            isActive={isActive}
            text="Reviews"
            count={
              userDetails.isTeacher
                ? teacherFeedbackCount + studentFeedbackCount
                : teacherFeedbackCount
            }
            className={styles.tab}
          />
        )
      },
    },
    {
      key: 'Account Settings',
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Account Settings" className={styles.tab} />
      },
    },
    {
      key: 'Financial Settings',
      // disabled: true,
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Financial Settings" className={styles.tab} />
      },
    },
  ]

  const teachersAdditionalTab: ReadonlyArray<TabWrapperProps> = [
    {
      key: 'Information',
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Information" className={styles.tab} />
      },
    },
  ]

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const imageHandler = (e: any) => {
    if (
      e?.target?.files?.[0] &&
      e.target.files[0]?.type?.indexOf('image') !== -1 &&
      e.target.files[0]?.size / (1024 * 1024) <= 3
    ) {
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = async () => {
        if (reader.readyState === 2) {
          setSelectedImage(reader.result as string)
        }
        // const multipartFormData = new FormData()
        // // eslint-disable-next-line @typescript-eslint/no-explicit-any
        // multipartFormData.append('picture', reader.result as any)

        const file = reader.result as any
        const contentType = file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
        const fileBuffer = Buffer.from(
          file.replace(/^data:image\/[a-zA-Z+]+;base64,/, ""),
          "base64"
        )
        const uploadResult: any = await uploadFile({
          S3_BUCKET,
          file: fileBuffer,
          key: `${userDetails.userId}/profile_picture`,
          ACL: 'public-read',
          contentType 
        })
        if (uploadResult.status === 'success') {
          setUser({ ...userDetails, imageUrl: reader.result })
        }

        // fetchRest(
        //   {
        //     url: 'updateUserProfilePicture',
        //     method: 'PUT',
        //     data: multipartFormData,
        //     headers: {
        //       'content-type': 'multipart/form-data',
        //     },
        //   },
        //   'userImage'
        // )
        //   .then((_res) => {
        //     setUser({ ...userDetails, imageUrl: reader.result })
        //   })
        //   .catch((_err) => {
        //     setSelectedImage(null)
        //   })
      }
    }
  }

  useEffect(() => {
    let searchParams = new URLSearchParams(search)
    let defaultTab: number = -1
    let searchTab = searchParams.get('tab')
      ? searchParams.get('tab')
      : userDetails.isTeacher
        ? 'Information'
        : 'Reviews'
    if (searchTab) {
      setValueForActiveTab(searchTab)
      // setIsActiveTab(searchTab)
    }
    if (userDetails.isTeacher) {
      if (searchTab === 'Information') {
        defaultTab = 0
      } else if (searchTab === 'Reviews') {
        defaultTab = 1
      } else if (searchTab === 'AccountSetting') {
        defaultTab = 2
      } else if (searchTab === 'FinancialSetting') {
        defaultTab = 3
      }
    } else {
      if (searchTab === 'Reviews') {
        defaultTab = 0
      } else if (searchTab === 'AccountSetting') {
        defaultTab = 1
      } else if (searchTab === 'FinancialSetting') {
        defaultTab = 2
      }
    }

    setDefaultState(defaultTab)
  }, [search, userDetails])
  useEffect(() => {
    fetchRest(
      {
        url: 'userReview',
        method: 'GET',
      },
      'review'
    )
      .then((res) => {
        if (res && res.data) {
          if (userDetails.isTeacher) {
            setStudentsReview(res.data.studentsFeedback)
            setStudentFeedbackCount(res.data.studentsFeedbackCount)
          }
          setTeachersReview(res.data.teachersFeedback)
          setTeacherFeedbackCount(res.data.teacherFeedbackCount)
        }
      })
      .catch((err) => { })
  }, [])
  return (
    <>
      <Header />
      <div className={styles.background}>
        <div className={styles.center}>
          <div className={styles.wrapper}>
            <div className={styles.imgWrapper}>
              <input
                type="file"
                accept="image/*"
                name="image"
                id="image"
                style={{ display: 'none' }}
                onChange={imageHandler}
              />
              {selectedImage ? (
                <>
                  <img className={styles.userImg} src={selectedImage} alt="profile" />
                </>
              ) : (
                <>
                  {userDetails.imageUrl ? (
                    <img className={styles.userImg} src={userDetails.imageUrl} alt="profile" />
                  ) : (
                    <>
                      <div className={`${styles.imgWrapperIcon}`}>
                        <div className={styles.verticalAlignment}>
                          <div className={styles.align}>
                            {userDetails.firstName && userDetails.lastName
                              ? `${userDetails.firstName
                                .toUpperCase()
                                .charAt(0)}${userDetails.lastName.toUpperCase().charAt(0)}`
                              : ''}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              <label htmlFor="image">
                {sectionLoading && sectionLoading.userImage ? (
                  <div className={styles.loader}>
                    <Loader className="form_loader" size={20} thickness={2} />
                  </div>
                ) : (
                  <>
                    <img className={styles.editImg} src={edit} alt="edit icon" />
                  </>
                )}
              </label>
            </div>
            {/* <img className={styles.editImg} src={edit} alt="edit icon" /> */}
            <div className={styles.userInfoWrapper}>
              <div className={styles.name}>
                {userDetails.firstName} {userDetails.lastName}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.center}>
          <div className={styles.tabsWrapper}>
            <TabsWrapper
              tabHeaders={
                userDetails.isTeacher ? [...teachersAdditionalTab, ...commonTabs] : commonTabs
              }
              activeTabValue={defaultState}
              onTabChange={onTabChange}
            />
          </div>
        </div>
        <div className={styles.center}>
          {userDetails.isTeacher && isActiveTab === 'Information' ? (
            <UserProfileInformation />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={styles.background}>
        {isActiveTab === 'Review' ? (
          <UserProfileReviews
            isTeacher={userDetails.isTeacher}
            studentFeedbackCount={studentFeedbackCount}
            teacherFeedbackCount={teacherFeedbackCount}
            teachersReview={teachersReview}
            studentsReview={studentsReview}
          />
        ) : (
          <></>
        )}
        {isActiveTab === 'AccountSetting' ? (
          <div className={styles.tabContentWrapper}>
            <div className={styles.tabContent}>
              <UserProfileAccountSetting />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div>
          {isActiveTab === 'FinancialSetting' ? (
            <UserProfileFinancialSetting
              isStudent={userDetails.isTeacher ? false : true}
            // isStudent={true}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={styles.center}>
        <Footer />
      </div>
    </>
  )
}
export const ProtectedUserProfile: React.FC = () => {
  return (
    <ProtectedRoute>
      <UserProfile />
    </ProtectedRoute>
  )
}
