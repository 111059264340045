import React from 'react'
import { useTranslation } from 'react-i18next'
import { AccordionSection } from '../accordion-section/AccordionSection'
import { SubmitRequest } from '../submit-request/SubmitRequest'
import styles from './RequestLearning.module.scss'

export const RequestLearning: React.FC<{
  readonly teamId?: string
  readonly statsFunction?: () => void
}> = ({ teamId = '', statsFunction = () => {} }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.backgroundBlue}>
        <div className={styles.wrapper}>
          <div className={styles.divContainer}>
            <div className={styles.headerText}>
              {t('dashboard.ask-your')}
              <span className={styles.green}> {t('dashboard.question')}</span>!
            </div>
          </div>
        </div>
        <div className={styles.wrapperAccordion}>
          <SubmitRequest
            isDashboard={true}
            className={styles.backgroundBlueField}
            teamId={teamId ? teamId : ''}
            statsFunction={statsFunction}
          />
        </div>
      </div>
      <div className={styles.background}>
        <div className={`${styles.wrapperAccordion} ${styles.pTop}`}>
          <AccordionSection
            inDashboard={true}
            teamId={teamId ? teamId : ''}
            statsFunction={statsFunction}
          />
        </div>
      </div>
    </>
  )
}
