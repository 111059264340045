/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useFetch } from '../../common/https'
import ProtectedRoute from '../../common/protected-route/ProtectedRoute'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { Footer } from '../../components/footer/Footer'
import { Header } from '../../components/header/Header'
import { MyTeams } from '../../components/my-teams/MyTeams'
import { Pending } from '../../components/pending/Pending'
import { TabItem } from '../../components/tab-item/TabItem'
import { TabsWrapper, TabWrapperProps } from '../../components/tabs-wrapper/TabsWrapper'
import { TeamProfile } from '../../components/team-profile/TeamProfile'
import styles from './TeamsDashboard.module.scss'

const TeamsDashboard: React.FC = () => {
  const { push } = useHistory()
  const { search } = useLocation()

  const [tabCountValuePending, setTabCountValuePending] = useState<number>(0)
  const [tabHeaders, setTabHeaders] = useState<ReadonlyArray<TabWrapperProps>>([])
  const { fetchRest, sectionLoading } = useFetch({ allJoinRequests: false })
  const [pendingReq, setPendingReq] = useState([])
  const [count, setCount] = useState(pendingReq.length)
  const [paginationState, setPaginationState] = useState<{
    readonly perPage: number
    readonly page: number
  }>({
    perPage: 5,
    page: 1,
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getTabHeaders: (count: number) => ReadonlyArray<TabWrapperProps> = (countValues) => {
    return [
      {
        key: 'MyTeams',
        TabItemWrapper: ({ isActive }) => {
          return <TabItem isActive={isActive} text="My Teams" />
        },
      },
      {
        key: 'Pending',
        TabItemWrapper: ({ isActive }) => {
          return <TabItem count={countValues} isActive={isActive} text="Pending" />
        },
      },
    ]
  }

  const fetchStatus = () => {}
  const [selectedTeam, setSelectedTeam] = useState<string>('')
  const [initialTab, setInitialTab] = useState<number>(-1)
  const { pathname } = useLocation()
  const { userDetails } = useUserDetailsContext()

  const [inProfile, setInProfile] = useState<boolean>(false)
  const onTabChange = (activeTab: number) => {
    setInitialTab(activeTab)
    switch (activeTab) {
      case 1:
        push('/teams?tab=Pending')
        break
      default:
        push('/teams?tab=MyTeams')
        setSelectedTeam('myTeams')
    }
  }

  const setPath = (path: any) => {
    setSelectedTeam(path)
  }
  const changeHandler = (event: any, value: any) => {
    setPaginationState((prev) => ({ ...prev, page: value }))
    fetchPendingRequestList({ page: value, perPage: paginationState.perPage })
  }
  const [newJoinRequest, setNewJoinRequest] = useState<boolean>(false)
  const setNewRequest = () => {
    setNewJoinRequest(true)
  }
  const fetchPendingRequestList = (obj: { readonly page?: number; readonly perPage?: number }) => {
    const { page = paginationState.page, perPage = paginationState.perPage } = obj
    fetchRest(
      {
        url: `join-requests-and-invitations`,
        method: 'GET',
        isFullPageLoader: false,
        queryParams: {
          page,
          perPage,
        },
      },
      'allJoinRequests'
    )
      .then((res: any) => {
        setPendingReq(res.data.data)
        setTabCountValuePending(res.data.count)
      })
      .catch((err: any) => {
        // openPopup(false)
      })
  }
  useEffect(() => {
    if (tabHeaders.length) {
      fetchPendingRequestList({})
    }
    setNewJoinRequest(false)
  }, [tabHeaders, newJoinRequest])

  useEffect(() => {
    let searchParams = new URLSearchParams(search)
    let searchTab = searchParams.get('tab')
    if (searchTab === 'MyTeam') {
      setInitialTab(0)
    } else if (searchTab === 'Pending') {
      setInitialTab(1)
    } else {
      setInitialTab(0)
    }
    fetchStatus()
  }, [search])
  const [isMember, setIsMember] = useState<boolean>(false)
  const fetchMembers = (teamId: string) => {
    fetchRest(
      {
        method: 'GET',
        url: `team/${teamId}/members`,
      },
      'teamMember'
    )
      .then((res) => {
        setCount(res.data.count)
        const sortValues = ['SuperAdmin', 'Admin', 'Member']
        let arr1 = []
        for (let i = 0; i < sortValues.length; i++) {
          for (let j = 0; j < res.data.data.length; j++) {
            if (res.data.data[j] !== undefined && res.data.data[j].memberRole === sortValues[i]) {
              arr1.push(res.data.data[j])
              res.data.data[j] = undefined
            }
          }
        }
        arr1.forEach((each: any) => {
          if (each.memberInfo.userId === userDetails.userId) {
            setIsMember(true)
          }
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(() => {
    const href = window.location.href
    const path = href.substring(href.indexOf('?') + 1)
    const teamId = pathname.substring(pathname.lastIndexOf('/') + 1)
    // call current-user-info to confirm if the user is in that team or not and then add condition if path exists and the user is a team member
    if (teamId !== 'teams') {
      fetchMembers(teamId)
    }
    if (
      (path === 'tab=Information' ||
        path === 'tab=Activity' ||
        path === 'tab=Members' ||
        path === 'tab=RequestLearning' ||
        path === 'tab=Requests' ||
        path === 'tab=Proposals' ||
        path === 'tab=Approvals' ||
        path === 'tab=Sessions' ||
        path === 'tab=Archive') &&
      isMember
    ) {
      setInProfile(true)
    } else {
      setInProfile(false)
    }
  }, [pathname, userDetails, isMember])
  useEffect(() => {
    setTabHeaders(getTabHeaders(tabCountValuePending))
  }, [tabCountValuePending])
  useEffect(() => {
    setCount(pendingReq.length)
  }, [pendingReq])
  return (
    <>
      <Header />
      <div className={styles.tabHeaders}>
        <div className={styles.container}>
          <TabsWrapper
            tabHeaders={tabHeaders}
            activeTabValue={initialTab}
            onTabChange={onTabChange}
          />
        </div>
      </div>
      <div className={styles.wrapperForComponent}>
        {initialTab === 0 ? (
          selectedTeam === 'profile' || inProfile ? (
            <TeamProfile callback={setPath} />
          ) : (
            <MyTeams callback={setPath} joinRequest={setNewRequest} />
          )
        ) : (
          <></>
        )}
        {initialTab === 1 ? (
          <>
            <Pending
              pendingReq={pendingReq}
              count={count}
              totalCount={tabCountValuePending}
              isLoading={sectionLoading && sectionLoading.allJoinRequests ? true : false}
              changeHandler={changeHandler}
              paginationState={paginationState}
              fetchList={fetchPendingRequestList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      <div className={styles.wrapper}>
        <Footer />
      </div>
    </>
  )
}

export const ProtectedTeamsDashboard: React.FC = () => {
  return (
    <ProtectedRoute>
      <TeamsDashboard />
    </ProtectedRoute>
  )
}
