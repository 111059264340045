/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import { StripeCardNumberElement } from '@stripe/stripe-js'

const StripePaymentContext = createContext({
  confirm: (elements: any) => {
    return {} as any
  },
  createToken: (element: StripeCardNumberElement, name: string) => {
    return {} as any
  },
  confirmCard: (clientSecret: string) => {
    return {} as any
  },
})

export default StripePaymentContext

export const StripePaymentProvider: React.FC = ({ children }) => {
  const stripe: any = useStripe()

  const createToken = async (element: StripeCardNumberElement, name: string) => {
    const { token, error } = await stripe?.createToken(element, { name: name })
    return token ? { token } : { message: error.message }
  }

  const confirm = async (elements: any) => {
    const payment = await stripe?.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_BASE_URL}/ontelo/learn?tab=Proposals`,
      },
    })

    const { paymentIntent } = payment

    return paymentIntent as any
  }

  const confirmCard = async (clientSecret: string) => {
    const { paymentIntent } = await stripe?.confirmCardPayment(clientSecret)

    return paymentIntent as any
  }

  const setDataFunction = {
    confirm,
    createToken,
    confirmCard,
  }

  return (
    <StripePaymentContext.Provider value={setDataFunction}>
      {children}
    </StripePaymentContext.Provider>
  )
}
