/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { NameInitials } from '../name-initials/NameInitials'
import styles from './Chat.module.scss'
import timeIcon from '../../assets/images/svg/time-icon.svg'
import { Button } from '../../common/button/Button'
import Moment from 'react-moment'
import { Loader } from '../../common/Loader/Loader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { ChatAttachment } from './ChatAttachment'
import { ProposalDetails } from '../proposal-details/ProposalDetails'
import { useFetch } from '../../common/https'
import { UserInfo } from '../user-info/UserInfo'

// message.tsx rename
export const Chat: React.FC<{
  readonly message?: any
  readonly isLoading: boolean
  readonly channelMetaInfo?: any
  readonly userDetails?: any
  readonly member?: any
  readonly getFileUrl?: any
  readonly isTeam?: boolean
}> = ({
  message,
  isLoading,
  channelMetaInfo,
  userDetails = undefined,
  member = undefined,
  getFileUrl = () => {},
  isTeam = false,
}) => {
  const { fetchRest, sectionLoading } = useFetch({ proposalDetailsApi: false })
  const [openProposal, setOpenProposal] = useState(false)
  const { push } = useHistory()
  const [metadataInfo, setMetaDataInfo] = useState<any>(undefined)
  const [link, setLink] = useState('')
  const [fileName, setFileName] = useState('')
  const [fileSize1, setFileSize1] = useState('')
  const [proposalDetails, setProposalDetails] = useState<any>(undefined)
  const href = window.location.href
  const mentor = href.substring(href.indexOf('?') + 1)

  const showUserdetail = (recieverId: string) => {
    let userDetailLink = ''
    if (userDetails.userId !== recieverId) {
      if (mentor === 'type=learn') {
        userDetailLink = `/teacher/${recieverId}?tab=Information`
      } else {
        userDetailLink = `/student/${recieverId}`
      }
      push(userDetailLink)
    }
  }

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    setFileSize1(parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i])
  }

  const messageMetaDataHandler = async () => {
    const metadata = JSON.parse(message.Metadata)
    setMetaDataInfo(metadata)
    if (metadata?.attachments && metadata?.attachments[0]) {
      const userArn = message.Sender.Arn.split('/user/')[1]
      const fileKey = metadata?.attachments[0]?.fileKey
      const fileSize = metadata?.attachments[0]?.size
      const res = await getFileUrl(userArn, fileKey)
      if (res) {
        setLink(res)
        setFileName(fileKey)
        formatBytes(fileSize)
      }
    } else if (metadata?.proposal && metadata?.proposal.proposalId) {
      // -- will start the loader.
      // --api call for proposal detail.
      fetchRest(
        {
          method: 'GET',
          // data: { proposalId: metadata?.proposal.proposalId },
          url: `/proposalDetails/${metadata?.proposal.proposalId}`,
        },
        'proposalDetailsApi'
      )
        .then((res) => {
          setProposalDetails(res.data.data)
        })
        .catch((err) => {
          setProposalDetails({ error: true })
        })
      // --stop the loader
    }
  }

  useEffect(() => {
    if (message.Metadata && userDetails.userId && member.userId && member.username) {
      messageMetaDataHandler()
    }
  }, [message, userDetails, member])

  /**
   * Renderer coded
   */

  const creatorAttendeeInfo = (isTeacher: boolean = false) => {
    return (
      <div className={styles['review-card__profile']}>
        <div>
          {
            <>
              {(
                isTeacher
                  ? proposalDetails?.request.user.imageUrl !== ''
                  : proposalDetails?.owner.imageUrl !== ''
              ) ? (
                <img
                  src={
                    isTeacher
                      ? proposalDetails?.request.user.imageUrl
                      : proposalDetails?.owner.imageUrl
                  }
                  alt="owner"
                  className={styles.icon}
                />
              ) : (
                <NameInitials
                  firstName={
                    isTeacher
                      ? proposalDetails?.request.user.firstName
                      : proposalDetails?.owner.firstName
                  }
                  lastName={
                    isTeacher
                      ? proposalDetails?.request.user.lastName
                      : proposalDetails?.owner.lastName
                  }
                  className={styles.icon}
                />
              )}
            </>
          }
        </div>
        <div className={styles['review-card__star-rating']}>
          <div className={styles['review-card__username']}>
            {isTeacher ? proposalDetails?.request.user.firstName : proposalDetails?.owner.firstName}
            {(isTeacher
              ? proposalDetails?.request.user.lastName
              : proposalDetails?.owner.lastName
            ).charAt(0)}
          </div>
          <div className={styles['review-card__rating']}>{isTeacher ? 'Attendee' : 'Creator'}</div>
        </div>
      </div>
    )
  }

  const proposalView = () => {
    return (
      <>
        {sectionLoading && sectionLoading.proposalDetailsApi ? (
          <>
            <Loader size={20} thickness={3} />
          </>
        ) : proposalDetails && proposalDetails.error ? (
          <div className={styles['proposal-loading-issue']}>Failed to load proposal !</div>
        ) : proposalDetails && proposalDetails.proposalDetail ? (
          <div className={styles['proposal-full-size']}>
            <div className={styles['review-card__align-items']}>
              <img src={timeIcon} alt="time icon" className={styles['review-card__time-icon']} />
              <div className={styles['review-card__time-subject']}>
                posted&nbsp;{proposalDetails?.createdOn}
              </div>
            </div>
            <div className={styles['list-card-title']}>
              {proposalDetails?.proposalDetail?.title}
              <div className={styles['list-card-content']}>
                <Moment
                  date={proposalDetails?.proposalDetail?.sessionDate}
                  format="DD MMM"
                  interval={0}
                />
                ,
                <Moment
                  date={proposalDetails?.proposalDetail?.sessionDate}
                  format="HH:mm"
                  interval={0}
                />
                -
                <Moment
                  date={proposalDetails?.proposalDetail.sessionDate}
                  format="HH:mm"
                  interval={0}
                  add={{ hours: proposalDetails?.proposalDetail?.sessionTimeInHour }}
                />
              </div>
            </div>
            {creatorAttendeeInfo(member.username === proposalDetails?.ownerId)}
            <Button
              className={styles.buttonClass}
              text={'View Proposal'}
              onClick={() => {
                setOpenProposal(true)
              }}
              type="submit"
            ></Button>
          </div>
        ) : null}
      </>
    )
  }

  const senderView = () => {
    return (
      <div className={styles.dflex}>
        <div className={`${styles.chat} ${styles.sender}`}>
          {/* ----- view for showing message loader ----- */}
          {/* Message loader view start */}
          {/* {isLoading ? (
              // <div className={styles.loader}>
              //   <Loader size={20} thickness={1} />
              //  <img src={retry} className={styles.retry} alt="retry" />
              // </div>
            ) : (
              <></>
            )} */}
          {/* Message loader view end */}

          {/* ------ For proposal ------ */}
          {metadataInfo?.proposal ? (
            proposalView()
          ) : (
            <>
              {/* ------ For message ------ */}
              {Boolean(message.Content) ? (
                <div className={styles.text}>{message.Content}</div>
              ) : (
                <></>
              )}
              {/* ------ For file ------ */}
              {metadataInfo?.attachments && metadataInfo?.attachments[0] ? (
                <ChatAttachment fileSize={fileSize1} link={link} fileName={fileName} />
              ) : null}
              {/* ------ For timestamp ------ */}
              <div className={styles.timeStamp}>
                <Moment date={message.CreatedTimestamp} format="DD MMM" interval={0} /> ,
                <Moment date={message.CreatedTimestamp} format="HH:mm" interval={0} />
              </div>
            </>
          )}

          {/* ------ here also add condition for api section loading and the retry options ----- */}
          {/* {isLoading ? (
            <div>
              <Loader size={20} thickness={1} />
            </div>
          ) : (
            <></>
          )} */}
        </div>
      </div>
    )
  }

  const recieverView = () => {
    return (
      <div className={styles['d-flex']}>
        <div className={`${styles.row}`}>
          <div
            className={styles.icon}
            onClick={() => {
              showUserdetail(message.Sender.Name)
            }}
          >
            {channelMetaInfo.imageUrl ? (
              <img src={channelMetaInfo.imageUrl} className={styles.img} alt="channel icon" />
            ) : (
              <NameInitials
                firstName={channelMetaInfo?.firstName}
                lastName={channelMetaInfo?.lastName}
                className={styles.radius}
              />
            )}
          </div>
        </div>

        <div className={`${styles.chat} ${styles.receiver}`}>
          {/* ------ For proposal ------ */}
          {metadataInfo?.proposal ? (
            proposalView()
          ) : (
            <>
              {/* ------ For message ------ */}
              <div className={styles.text}>{message.Content}</div>
              {/* ------ For file ------ */}
              {metadataInfo?.attachments && metadataInfo?.attachments[0] ? (
                <ChatAttachment fileSize={fileSize1} link={link} fileName={fileName} />
              ) : null}
              {/* ------ For timestamp ------ */}
              <div className={styles.timeStamp}>
                <Moment date={message?.time} format="DD MMM" interval={0} /> ,
                <Moment date={message?.time} format="HH:mm" interval={0} />
              </div>
            </>
          )}
          {/* Message loader view start */}
          {/* {isLoading ? (
            <div>
              <Loader size={20} thickness={1} />
            </div>  
            ) : (
              <></>
            )} */}
          {/* Message loader view end */}
        </div>
      </div>
    )
  }

  const teamRecieverView = () => {
    return (
      <div className={styles['d-flex']}>
        <div className={`${styles.row}`}>
          <UserInfo userId={message?.Sender?.Name} />
        </div>

        <div className={`${styles.chat} ${styles.receiver}`}>
          {/* ------ For proposal ------ */}
          {metadataInfo?.proposal ? (
            proposalView()
          ) : (
            <>
              {/* ------ For message ------ */}
              <div className={styles.text}>{message.Content}</div>
              {/* ------ For file ------ */}
              {metadataInfo?.attachments && metadataInfo?.attachments[0] ? (
                <ChatAttachment fileSize={fileSize1} link={link} fileName={fileName} />
              ) : null}
              {/* ------ For timestamp ------ */}
              <div className={styles.timeStamp}>
                <Moment date={message?.time} format="DD MMM" interval={0} /> ,
                <Moment date={message?.time} format="HH:mm" interval={0} />
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      {message.Sender.Name === userDetails?.userId ? (
        senderView()
      ) : (
        <>{isTeam ? teamRecieverView() : recieverView()}</>
      )}

      {/* ----- For showing the proposal detail ------- */}
      {/* proposal detail view start */}
      {metadataInfo?.proposal ? (
        <DialogWrapper
          onClose={() => {
            setOpenProposal(false)
          }}
          open={openProposal}
          isFullScreen={true}
          Title={DialogHeader}
        >
          <ProposalDetails
            objectReceived={proposalDetails}
            isAttendee={member.username !== proposalDetails?.ownerId}
            onClose={() => setOpenProposal(false)}
            fromChat={true}
          />
        </DialogWrapper>
      ) : null}
      {/* proposal details view end */}
    </>
  )
}
