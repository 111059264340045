/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Switch, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import aboutIcon from '../../assets/images/svg/about.svg'
import cost from '../../assets/images/svg/HourRate.svg'
import interests from '../../assets/images/svg/interests.svg'
import PopUPIcon from '../../assets/images/svg/popup-icon.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import Search from '../../assets/images/svg/search.svg'
import tick from '../../assets/images/svg/whiteTick.svg'
import { Button } from '../../common/button/Button'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { AutocompleteOption } from '../../common/autocomplete-option/AutocompleteOption'
import { Loader } from '../../common/Loader/Loader'
import { useFetch } from '../../common/https'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import { MultipleSelect } from '../../common/multiple-select/MultipleSelect'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import { UsePopupContext } from '../../common/use-popup-context'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { getServiceFee, getUserFee, getUserTokens, onKeyDown } from '../../common/utils'
import { validateInput } from '../../common/ValidateFields'
import styles from './WelcomeWizard.module.scss'
import { onKeyDownCost } from '../../common/utils'
import {
  costSchema,
  schemaAreaOfExpertise,
  schemaInterests,
  schemaSkills,
} from '../../common/schema/Schema'

interface CategorySubCategoryType {
  id: string
  name: string
  categoryId?: string
  subCategoryId?: string
  isValid: boolean
  errorMsg: string
  isTouched: boolean
}
interface CategoryType {
  categoryId: string
  description?: string
  imageUrl?: string
  name: string
}

interface SubcategoryType {
  subCategoryId: string
  description?: string
  imageUrl?: string
  name: string
}
export const WelcomeWizard: React.FC<{ closePopup(): void; readonly isTeachersForm?: boolean }> = ({
  closePopup,
  isTeachersForm = false,
}) => {
  const { fetchUserDetails, userDetails, countries } = useUserDetailsContext()
  const [data, setData] = useState<{
    interest: { value: []; isValid: boolean; isTouched: boolean; errorMsg: string }
    title: { value: string; isValid: boolean; isTouched: boolean; errorMsg: string }
    moreInfo: { value: string; isValid: boolean; isTouched: boolean; errorMsg: string }
    skills: { value: []; isValid: boolean; isTouched: boolean; errorMsg: string }
    areaOfExpertise: { value: []; isValid: boolean; isTouched: boolean; errorMsg: string }
    cost: { value: any; isValid: boolean; isTouched: boolean; errorMsg: string }
    dollar: { value: string; isValid: boolean; isTouched: boolean; errorMsg: string }
  }>({
    interest: { value: [], isValid: true, isTouched: false, errorMsg: '' },
    title: { value: '', isValid: true, isTouched: false, errorMsg: '' },
    moreInfo: { value: '', isValid: true, isTouched: false, errorMsg: '' },
    skills: {
      value: [],
      isValid: true,
      isTouched: false,
      errorMsg: '',
    },
    areaOfExpertise: {
      value: [],
      isValid: true,
      isTouched: false,
      errorMsg: '',
    },
    cost: { value: 0, isValid: false, isTouched: false, errorMsg: 'Please enter the cost' },
    dollar: { value: '$', isValid: true, isTouched: false, errorMsg: '' },
  })
  const [optionData, setOptionData] = useState<Array<CategorySubCategoryType>>([])
  const [category, setCategory] = useState<Array<CategoryType>>([])
  const [subCategory, setSubCategory] = useState<Array<SubcategoryType>>([])
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const { fetchRest, sectionLoading } = useFetch({ editProfile: false })
  const { t } = useTranslation()
  const { close, setData: setPopupData } = UsePopupContext()
  const [legalEntity, setLegalEntity] = useState<boolean>(false)

  const onBoardingProcess = () => {
    fetchRest({
      url: '/accountOnBoardingCreateLink',
      method: 'POST',
      data: {
        accountId: userDetails.stripeAccountId,
        stripeAccount: 'UK',
      },
      isFullPageLoader: true,
    })
      .then((each: any) => {
        window.location.href = each.data.data.url
      })
      .catch((error) => {
        openPopup(false, error.message)
      })
  }

  const openPopup = (success: boolean, err?: string) => {
    let content: string = ''
    let headerContent: string = ''
    if (success && err === undefined) {
      headerContent = 'Successfully saved'
      content = 'Your Details have been saved Successfully!'
    } else if (!success && err) {
      headerContent = 'Onboarding process Failed'
      content = err
    } else {
      headerContent = 'Something went wrong'
      content =
        'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? PopUPIcon : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.white}
                    text={'Got It'}
                    onClick={() => {
                      closePopup()
                      close()
                      if (userDetails.stripeAccountId) {
                        onBoardingProcess()
                      }
                    }}
                  />
                </>
              ) : (
                <>
                  <Button
                    text={err ? 'Try again' : 'Go back!'}
                    onClick={(e) => {
                      if (err) {
                        onBoardingProcess()
                      } else {
                        close()
                      }
                    }}
                    className={styles.white}
                  ></Button>
                  <Button
                    text={'Skip'}
                    className={styles['cancel-btn']}
                    onClick={() => {
                      close()
                      closePopup()
                    }}
                  ></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  useEffect(() => {
    if (userDetails.stripeAccountId) {
      let supported = false
      if (countries.length > 0) {
        countries.forEach((each: any) => {
          if (each === userDetails.countryCode) {
            supported = true
          }
        })
      }
      if (supported && userDetails.account.capabilities.transfers === 'inactive') {
        onBoardingProcess()
      }
    }
  }, [userDetails, userDetails.stripeAccountId])

  const autoCompleteOnChange = (newValue: any, key: string) => {
    if (key === 'interest') {
      const valid = validateInput(schemaInterests, newValue)
      setData((prev: any) => {
        return {
          ...prev,
          [key]: {
            value: newValue,
            isValid: valid.isValid,
            isTouched: true,
            errorMsg: valid.errorMsg,
          },
        }
      })
    } else if (key === 'skills') {
      const valid = validateInput(schemaSkills, newValue)
      setData((prev: any) => {
        return {
          ...prev,
          [key]: {
            value: newValue,
            isValid: valid.isValid,
            isTouched: true,
            errorMsg: valid.errorMsg,
          },
        }
      })
    } else if (key === 'areaOfExpertise') {
      const valid = validateInput(schemaAreaOfExpertise, newValue)
      setData((prev: any) => {
        return {
          ...prev,
          [key]: {
            value: newValue,
            isValid: valid.isValid,
            isTouched: true,
            errorMsg: valid.errorMsg,
          },
        }
      })
    }
  }
  const changeToggle = () => {
    setIsChecked(!isChecked)
  }
  const handleChange = (e: any, key: string) => {
    const localData = e.value
    if (key === 'cost') {
      const costValue = Number(localData)
      if (isNaN(costValue) || costValue > 9999) {
        setData((prev: any) => {
          return {
            ...prev,
            cost: { ...prev.cost, isTouched: true },
          }
        })
      } else {
        setData((prev: any) => {
          return {
            ...prev,
            cost: { value: localData, ...e },
          }
        })
      }
    } else {
      setData((prev: any) => {
        return {
          ...prev,
          [key]: { value: localData, ...e },
        }
      })
    }
  }
  const closeIconFunc = (e: any, key: string) => {
    let propertyToAccess: string = ''
    if (key === 'interest') {
      propertyToAccess = 'id'
    }
    if (key === 'skills') {
      propertyToAccess = 'subCategoryId'
    }
    if (key === 'areaOfExpertise') {
      propertyToAccess = 'categoryId'
    }
    setData((prev: any) => {
      return {
        ...prev,
        [key]: {
          ...prev[key],
          value: prev[key].value.filter((each: any) => {
            return each[propertyToAccess] !== e[propertyToAccess]
          }),
        },
      }
    })
  }
  const submitForm = (e: any) => {
    e.preventDefault()
    setData((prev: any) => {
      let blockPrev = { ...prev }
      Object.keys(blockPrev).forEach((each) => {
        blockPrev = { ...blockPrev, [each]: { ...blockPrev[each], isTouched: true } }
      })
      return blockPrev
    })

    let url = ''
    if (isChecked || isTeachersForm) {
      url = 'welcomeWizard/teacher'
    } else {
      url = 'welcomeWizard/student'
    }
    if (data.cost.value === 0) {
      setData((prev: any) => {
        return {
          ...prev,
          cost: {
            ...prev.cost,
            errorMsg: 'Please enter a valid rate',
            isValid: false,
            isTouched: true,
          },
        }
      })
    }
    const isValid = isChecked || isTeachersForm ? (data.cost.isValid ? true : false) : true
    if (isValid) {
      fetchRest(
        {
          url,
          method: 'POST',
          data: {
            hourlyRate: data.cost.value,
            info: data.moreInfo.value ? data.moreInfo.value : undefined,
            title: data.title.value ? data.title.value : undefined,
            areaOfExpertise:
              data.areaOfExpertise.value && data.areaOfExpertise.value.length
                ? data.areaOfExpertise.value.map((each: any) => each.categoryId)
                : [],
            interests:
              data.interest.value && data.interest.value.length
                ? data.interest.value.map((each: any) => {
                    return {
                      categoryId: each.categoryId ? each.categoryId : undefined,
                      subCategoryId: each.subCategoryId ? each.subCategoryId : undefined,
                    }
                  })
                : [],
            skills:
              data.skills.value && data.skills.value.length
                ? data.skills.value.map((each: any) => each.subCategoryId)
                : [],
          },
        },
        'editProfile'
      )
        .then((res) => {
          localStorage.removeItem('welcomeWizard')
          const { accessToken, identityId, idToken } = getUserTokens()
          if (accessToken) {
            fetchUserDetails({ accessToken, identityId, idToken })
            openPopup(true)
          }
        })
        .catch((er) => {
          openPopup(false)
        })
    }
  }
  useEffect(() => {
    fetchRest({
      url: '/requestCategoryAndSubCategory',
      method: 'GET',
    }).then((res: any) => {
      if (res && res.data && res.data.data) {
        setOptionData(
          res.data.data.map((each: any) => {
            return { id: each.subCategoryId ? each.subCategoryId : each.categoryId, ...each }
          })
        )
      } else {
        setOptionData([])
      }
    })
    fetchRest({
      url: '/category',
      method: 'GET',
    }).then((res: any) => {
      if (res && res.data && res.data.data) {
        setCategory(
          res.data.data.map((each: any) => {
            return {
              categoryId: each.categoryId,
              name: each.name,
              description: each.description,
              imageUrl: each.imageUrl,
            }
          })
        )
      } else {
        setCategory([])
      }
    })
    fetchRest({
      url: '/subCategory',
      method: 'GET',
    }).then((res: any) => {
      if (res && res.data && res.data.data) {
        setSubCategory(
          res.data.data.map((each: any) => {
            return {
              subCategoryId: each.subCategoryId,
              name: each.name,
              description: each.description,
              imageUrl: each.imageUrl,
            }
          })
        )
      } else {
        setSubCategory([])
      }
    })
  }, [])

  return (
    <div>
      <NoEventsLoader isLoading={sectionLoading && sectionLoading.editProfile}>
        <form onSubmit={submitForm}>
          {/* for interest here conditional rendering */}
          {isTeachersForm ? (
            <></>
          ) : (
            <div className={styles.marginBtm}>
              <div className={styles.interestWrapper}>
                <div className={styles.heading2}>{t('userDetails.enter-interests')}</div>
                <div className={styles.subHeading}>{t('userDetails.remove')}</div>
                <div className={styles.autocompletePosition}>
                  <div className="autocomplete-wrapper search">
                    <Autocomplete
                      popupIcon={<img src={Search} alt="search" />}
                      multiple
                      value={data.interest.value}
                      onChange={(event, newValue) => {
                        autoCompleteOnChange(newValue, 'interest')
                      }}
                      disableCloseOnSelect={true}
                      handleHomeEndKeys
                      options={optionData}
                      getOptionLabel={(option) => {
                        return option.name
                      }}
                      renderOption={(option: any) => <AutocompleteOption option={option.name} />}
                      style={{ width: '100%', position: 'relative' }}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                  </div>
                  {data.interest.isTouched && !data.interest.isValid ? (
                    <div className={styles.error}>{data.interest.errorMsg}</div>
                  ) : (
                    ''
                  )}
                  {data.interest.value && data.interest.value.length ? (
                    <div className={styles.optionWrapper}>
                      {data.interest.value.map((each: any) => {
                        return (
                          <>
                            <MultipleSelect
                              textContent={each.name}
                              closeIconFunc={() => {
                                closeIconFunc(each, 'interest')
                              }}
                            />
                          </>
                        )
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          )}
          {isTeachersForm ? (
            <></>
          ) : (
            <div className={styles.switchWrapper}>
              <div className={styles.headingCheckBox}>{t('userDetails.areYouATeacher')}</div>
              <Switch checked={isChecked} onChange={changeToggle} color="primary" />
            </div>
          )}
          {isChecked || isTeachersForm ? (
            <>
              <div className={styles.marginBtm}>
                <div className={styles.label}>
                  <img src={aboutIcon} alt="About icon" className={styles.divImg} />
                  <div>{t('userDetails.about')}</div>
                </div>
                <div className={styles.fullField}>
                  <div className={styles.heading2}>{t('userDetails.your-title')}</div>
                  <div className={styles.subHeading}>{t('userDetails.title-desc')}</div>

                  <div>
                    <InputWrapper
                      type="text"
                      valueObj={data.title}
                      placeholder="For example, Professional UX teacher..."
                      onChange={(e: any) => {
                        handleChange(e, 'title')
                      }}
                    />
                  </div>
                </div>
                <div className={styles.fullField}>
                  <div className={styles.heading2}>{t('userDetails.edit')}</div>
                  <div className={styles.subHeading}>{t('userDetails.general-info')}</div>

                  <textarea
                    className={styles.textareaStyles}
                    onChange={(e) => {
                      handleChange({ value: e.target.value }, 'moreInfo')
                    }}
                    value={data.moreInfo.value}
                  ></textarea>
                </div>
                {/* <div className={`${styles.topborder} ${styles.switch}`}>
                  {userDetails.countryCode === 'AE' ? (
                    <div className={styles.switchWrapper}>
                      <div className={styles.headingCheckBox}>
                        {t('userDetails.areYouALegalEntity')}
                      </div>
                      <Switch
                        checked={legalEntity}
                        onChange={() => {
                          setLegalEntity(!legalEntity)
                        }}
                        color="primary"
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div> */}
              </div>
              <div className={styles.marginBtm}>
                <div className={styles.label}>
                  <img src={interests} alt="interests" className={styles.divImg} />
                  <div>{t('userDetails.skills')}</div>
                </div>
                <div className={styles.heading}>{t('userDetails.expertise')}</div>
                <div className={styles.autocompletePosition}>
                  <div className="autocomplete-wrapper search">
                    <Autocomplete
                      popupIcon={<img src={Search} alt="search" />}
                      multiple
                      value={data.areaOfExpertise.value}
                      onChange={(event, newValue) => {
                        autoCompleteOnChange(newValue, 'areaOfExpertise')
                      }}
                      disableCloseOnSelect={true}
                      handleHomeEndKeys
                      options={category}
                      getOptionLabel={(option) => {
                        return option.name
                      }}
                      renderOption={(option: any) => <AutocompleteOption option={option.name} />}
                      style={{ width: '100%', position: 'relative' }}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                  </div>
                  {data.areaOfExpertise.isTouched && !data.areaOfExpertise.isValid ? (
                    <div className={styles.error}>{data.areaOfExpertise.errorMsg}</div>
                  ) : (
                    ''
                  )}
                  {data.areaOfExpertise.value && data.areaOfExpertise.value.length ? (
                    <div className={styles.optionWrapper}>
                      {data.areaOfExpertise.value.map((each: any) => {
                        return (
                          <>
                            <MultipleSelect
                              textContent={each.name}
                              closeIconFunc={() => {
                                closeIconFunc(each, 'areaOfExpertise')
                              }}
                            />
                          </>
                        )
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className={styles.heading}>{t('userDetails.skills')}</div>
                <div className={styles.autocompletePosition}>
                  <div>
                    <div className="autocomplete-wrapper search">
                      <Autocomplete
                        popupIcon={<img src={Search} alt="search" />}
                        multiple
                        value={data.skills.value}
                        onChange={(event, newValue) => {
                          autoCompleteOnChange(newValue, 'skills')
                        }}
                        handleHomeEndKeys
                        disableCloseOnSelect={true}
                        options={subCategory}
                        getOptionLabel={(option) => {
                          return option.name
                        }}
                        renderOption={(option: any) => <AutocompleteOption option={option.name} />}
                        style={{ maxWidth: '715px', width: '100%', position: 'relative' }}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                      />
                    </div>
                  </div>
                  {data.skills.isTouched && !data.skills.isValid ? (
                    <div className={styles.error}>{data.skills.errorMsg}</div>
                  ) : (
                    ''
                  )}
                  {data.skills.value && data.skills.value.length ? (
                    <div className={styles.optionWrapper}>
                      {data.skills.value.map((each: any) => {
                        return (
                          <>
                            <MultipleSelect
                              textContent={each.name}
                              closeIconFunc={() => {
                                closeIconFunc(each, 'skills')
                              }}
                            />
                          </>
                        )
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className={styles.costPadding}>
                <div className={styles.labelCost}>
                  <img src={cost} alt="costs" className={styles.divImg} />
                  <div>{t('userDetails.cost')}</div>
                </div>
                <div className={styles.heading2Cost}>{t('userDetails.cost')}</div>
                <div className={styles.subHeadingCost}>{t('userDetails.cost-det')}</div>
                <div className={styles.cost}>
                  <div className={styles.wrapperLeft}>
                    <div className={styles.smallField}>
                      <InputWrapper
                        type="text"
                        onChange={() => {}}
                        disabled
                        valueObj={data.dollar}
                        className={styles.dollar}
                      />
                    </div>
                    <div className={styles.halfField}>
                      <InputWrapper
                        type="number"
                        valueObj={data.cost}
                        onKeyPress={onKeyDownCost}
                        onChange={(e: any) => {
                          handleChange(e, 'cost')
                        }}
                        schema={costSchema}
                        validateOnChange={true}
                        validateOnBlur={true}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.border}>
                  <div>
                    <div className={styles.heading2Cost}>{t('userDetails.service-fees')}</div>
                    <div className={styles.subHeadingCost}>{t('userDetails.service-charge')}</div>
                  </div>
                  <div className={styles.dollar}>
                    ${data.cost.value ? getServiceFee(data.cost.value) : 0}
                  </div>
                </div>
                <div className={styles.border}>
                  <div>
                    <div className={styles.heading2Cost}>{t('userDetails.your-fees')}</div>
                    <div className={styles.subHeadingCost}>{t('userDetails.your-charge')}</div>
                  </div>
                  <div className={`${styles.dollar} ${styles.color}`}>
                    ${data.cost.value ? getUserFee(data.cost.value) : 0}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className={styles.btnWrapper}>
            <Button
              text={isTeachersForm ? 'Register' : 'Save'}
              type="submit"
              className={styles.buttons}
              ButtonImg={() => {
                return (
                  <>
                    {sectionLoading && sectionLoading.editProfile ? (
                      <Loader className="form_loader" size={20} thickness={2} />
                    ) : (
                      <>
                        <img src={tick} alt="tick icon" className={styles.divImg} />
                      </>
                    )}
                  </>
                )
              }}
            />
            {!isTeachersForm ? (
              <Button
                text="Skip"
                type="button"
                className={styles.cancel}
                onClick={(e) => {
                  closePopup()
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </form>
      </NoEventsLoader>
    </div>
  )
}
