import React from 'react'
import styles from './TabItem.module.scss'

export const TabItem: React.FC<{
  readonly text?: string
  readonly Img?: React.FC
  readonly isActive: boolean
  readonly isSignUp?: boolean
  readonly className?: string
  readonly count?: number | null
}> = (props) => {
  const { text, isActive, className, count, Img } = props

  return (
    <div>
      <div className={styles.flex}>
        <div
          className={`${isActive ? '' : styles.tabItem} ${className ? className : ''}`}
          data-testid="text"
        >
          {text ? text : ''}
          {Img ? <Img /> : ''}
        </div>
        {count ? (
          <div>
            <span className={styles.count}> {count}</span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
