import React from 'react'
import { useTranslation } from 'react-i18next'
import { RegisterForm } from '../../components/register-form/RegisterForm'
import styles from './SignUpForm.module.scss'

export const SignUpForm: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.heading}>{t('signUp')}</div>
      <div className={styles.column} data-testid="col">
        <RegisterForm />
      </div>
    </>
  )
}
