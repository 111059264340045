import React from 'react'
import styles from './NameInitials.module.scss'

export const NameInitials: React.FC<{
  readonly firstName: string
  readonly lastName: string
  readonly className?: string
}> = ({ className, firstName, lastName }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      {`${firstName.charAt(0)}${lastName.charAt(0)}`}
    </div>
  )
}
