/* eslint-disable @typescript-eslint/no-explicit-any */
import AWS from 'aws-sdk'
import React, { createContext } from 'react'
import { appConfig } from '../../components/chat-window/Config'

const UploadContext = createContext({
  uploadFile: (obj: { file: any, S3_BUCKET: string, key: string, ACL?: string, contentType?: string }) => { },
})
export default UploadContext

export const UploadProvider: React.FC = ({ children }) => {
  const REGION = appConfig.appRegion

  const uploadFile = async ({
    file,
    S3_BUCKET,
    key,
    ACL,
    contentType
  }: {
    file: any,
    S3_BUCKET: string,
    key: string,
    ACL?: string,
    contentType?: string
  }) => {
    const myBucket = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      signatureVersion: 'v4',
      region: REGION,
    })


    return new Promise((res, rej) => {
      const params = {
        ACL: ACL || undefined,
        Body: file,
        Bucket: S3_BUCKET || '',
        Key: key,
        ContentType: contentType ? contentType : undefined,
      }
      myBucket.putObject(params, (err: any, data: any) => {
        if (err) {
          return rej({ status: 'error', error: { ...err } })
        }
        return res({
          status: 'success',
          data,
        })
      })
    })
  }

  const uploadFunctions = {
    uploadFile: uploadFile,
  }

  return <UploadContext.Provider value={uploadFunctions}>{children}</UploadContext.Provider>
}
