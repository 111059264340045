/* eslint-disable @typescript-eslint/no-explicit-any */
import { debounce } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import styles from './InviteUsers.module.scss'
import userIcon from '../../assets/images/svg/userIcon.svg'
import { useFetch } from '../../common/https'
import { Button } from '../../common/button/Button'
import { NameInitials } from '../name-initials/NameInitials'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { UsePopupContext } from '../../common/use-popup-context'
import deleteIcon from '../../assets/images/svg/delete-icon.svg'
import { Loader } from '../../common/Loader/Loader'
import pinkStar from '../../assets/images/svg/pinkStar.svg'
import star from '../../assets/images/svg/star.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'

export const InviteUsers: React.FC<{ readonly teamId: string; readonly closePopup: any }> = ({
  teamId,
  closePopup,
}) => {
  const { fetchRest, sectionLoading } = useFetch({ loader: false })

  const [searchItems, setSearchItems] = useState<any>([])
  const [searchValue, setSearchValue] = useState('')
  const [membersList, setMembersList] = useState([])

  const { close, setData: setPopupData } = UsePopupContext()

  const popUp = (content?: string) => {
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={RepeatRequest} alt="popup icon" />
        }}
        title={'Inivte request failed'}
        Buttons={() => {
          return (
            <>
              <Button text={'Got It'} className={styles['cancel-btn']} onClick={close}></Button>
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content ? content : ''}</p>
            </div>
          )
        }}
      />
    )
  }

  const onChangeHandler = (e: any) => {
    if (e.target.value.length > 0) {
      setTop(true)
    } else {
      setTop(false)
    }
    const value = e.target.value
    setSearchValue(value)
    debouncedSave(value)
  }

  const debouncedSave = useCallback(
    debounce((nextValue: React.SetStateAction<string>) => {
      fetchRest({
        method: 'GET',
        url: `/user-list?email=${nextValue}`,
      })
        .then((res) => {
          setMembersList(res.data.data)
        })
        .catch()
    }, 2000),
    []
  )

  const onClickHandler = (item: any) => {
    setSearchItems((prev: any) => {
      return [...prev, item]
    })
    setTop(false)
    setSearchValue('')
  }

  const inviteUsers = async () => {
    fetchRest(
      {
        method: 'POST',
        url: `team/${teamId}/invite-users`,
        data: {
          invitationTo: searchItems.map((each: any) => {
            return each.userId
          }),
        },
      },
      'loader'
    )
      .then((res) => {
        closePopup()
      })
      .catch((err) => {
        console.log(err)
        if (err.message) {
          popUp('This user is already a part of this team!')
        } else {
          popUp()
        }
      })
  }

  const [Top, setTop] = useState(false)

  const removeUser = (index: any) => {
    let arr = searchItems
    setSearchItems([])
    arr.forEach((each: any, i: any) => {
      if (i !== index) {
        setSearchItems((prev: any) => {
          return [...prev, each]
        })
      }
    })
  }

  return (
    <div className={`${styles.section} ${styles.search_section}`}>
      <div className={styles.search_heading}>Please, enter users email</div>
      <div className={styles['landing-page__search']}>
        <input
          type="text"
          name="searchLearn"
          id={styles.searchLearn}
          placeholder="Email..."
          value={searchValue}
          onChange={onChangeHandler}
          onFocus={() => {
            if (!Top && searchValue.length !== 0) {
              setTop(!Top)
            }
          }}
          autoComplete="off"
        />
        {Top && membersList.length > 0 ? (
          <ul className={styles.list}>
            {membersList.map((item: any, index) => (
              <li key={index} onClick={() => onClickHandler(item)}>
                <div>{item.email}</div>
              </li>
            ))}
          </ul>
        ) : (
          <></>
        )}
      </div>
      <div
        className={
          membersList.length > 0 && Top
            ? `${styles.memberTop} ${styles.mTop}`
            : `${styles.memberTop}`
        }
      >
        {searchItems.map((each: any, index: any) => {
          return (
            <div className={styles.members} key={index}>
              <div className={styles['membersList-profile']}>
                {each.imageUrl ? (
                  <img src={each.imageUrl} alt="owner" className={styles.userImg} />
                ) : (
                  <NameInitials
                    firstName={each.firstName}
                    lastName={each.lastName}
                    className={styles.userImg}
                  />
                )}
                <div className={styles['membersList-userDetails']}>
                  <div className={styles['membersList-username']}>{each.firstName}</div>
                  <div className={styles.feedbackContent}>
                    <div className={styles.rating}>
                      {Array(Number(0))
                        .fill('')
                        .map((x, i) => {
                          return <img src={pinkStar} alt="rating" key={i} className={styles.star} />
                        })}
                      {Array(5 - Number(0))
                        .fill('')
                        .map((x, i) => {
                          return <img src={star} alt="rating" key={i} className={styles.star} />
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles['membersList-actions']}>
                <img
                  src={deleteIcon}
                  alt="delete icon"
                  className={styles.delete_img}
                  onClick={() => removeUser(index)}
                />
              </div>
            </div>
          )
        })}
      </div>
      <Button
        text={'Invite users'}
        ButtonImg={() => {
          return (
            <>
              {sectionLoading && sectionLoading.loader ? (
                <Loader size={20} thickness={2} className={styles.marginBottom} />
              ) : (
                <img src={userIcon} alt="user icon" className={styles.user_img} />
              )}
            </>
          )
        }}
        onClick={() => inviteUsers()}
        className={styles.invite_btn}
      />
    </div>
  )
}
