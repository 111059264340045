/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import feedback from '../../assets/images/svg/feedback.svg'
import pinkStar from '../../assets/images/svg/pinkStar.svg'
import star from '../../assets/images/svg/star.svg'
import { NameInitials } from '../name-initials/NameInitials'
import { TabItem } from '../tab-item/TabItem'
import { TabsWrapper } from '../tabs-wrapper/TabsWrapper'
import styles from './UserProfileReviews.module.scss'
import { EmptySection } from '../../common/empty-section/EmptySection'
import Moment from 'react-moment'

export const UserProfileReviews: React.FC<{
  readonly isTeacher?: boolean
  readonly isProfile?: boolean
  readonly teachersReview: any
  readonly studentsReview: any
  readonly teacherFeedbackCount: number
  readonly studentFeedbackCount: number
  readonly isChat?: boolean
  readonly userId?: string | undefined
}> = ({
  isTeacher = false,
  teacherFeedbackCount,
  studentFeedbackCount,
  isProfile = false,
  studentsReview,
  teachersReview,
  isChat = false,
  userId = undefined,
}) => {
  const [defaultStateFeedback, setDefaultStateFeedback] = useState<number>(0)

  const [isActiveTab, setIsActiveTab] = useState<'teachers' | 'students'>('students')
  const { t } = useTranslation()
  const { search } = useLocation()
  const { push } = useHistory()

  const onTabChangeFeedback = (activeTab: number) => {
    if (isChat) {
      switch (activeTab) {
        case 1:
          if (!isProfile) {
            push(`/teacher/${userId}?tab=Reviews&subTab=TeachersFeedback`)
          }
          setIsActiveTab('teachers')
          break

        default:
          if (!isProfile) {
            push(`/teacher/${userId}?tab=Reviews&subTab=StudentsFeedback`)
          }
          setIsActiveTab('students')
      }
    } else {
      switch (activeTab) {
        case 1:
          if (!isProfile) {
            push('/user-profile?tab=Reviews&subTab=TeachersFeedback')
          }
          setIsActiveTab('teachers')
          break

        default:
          if (!isProfile) {
            push('/user-profile?tab=Reviews&subTab=StudentsFeedback')
          }
          setIsActiveTab('students')
      }
    }
  }

  const tabHeadersFeedback: ReadonlyArray<{
    key: string
    TabItemWrapper: React.FC<{ isActive: boolean }>
  }> = [
    {
      key: 'students',
      TabItemWrapper: ({ isActive }) => {
        return (
          <TabItem
            isActive={isActive}
            text="Student Feedback"
            count={studentFeedbackCount}
            className={styles.tab}
          />
        )
      },
    },
    {
      key: 'teachers',
      TabItemWrapper: ({ isActive }) => {
        return (
          <TabItem
            isActive={isActive}
            text="Teacher Feedback"
            count={teacherFeedbackCount}
            className={styles.tab}
          />
        )
      },
    },
  ]

  useEffect(() => {
    let defaultTab: number = 0
    if (!isProfile) {
      let searchParams = new URLSearchParams(search)
      let searchTab = searchParams.get('subTab')
      if (searchTab === 'StudentsFeedback') {
        defaultTab = 0
        setIsActiveTab('students')
        if (isChat) {
          push(`/teacher/${userId}?tab=Reviews&subTab=StudentsFeedback`)
        }
      } else if (searchTab === 'TeachersFeedback') {
        defaultTab = 1
        setIsActiveTab('teachers')
        if (isChat) {
          push(`/teacher/${userId}?tab=Reviews&subTab=TeachersFeedback`)
        }
      }
    } else {
      defaultTab = 0
      setIsActiveTab('students')
    }
    setDefaultStateFeedback(defaultTab)
  }, [search])

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.wrapperStudent}>
          <div className={styles.headingWrapper}>
            <div className={styles.labelStudent}>
              <img src={feedback} alt="interests" className={styles.divImg} />
              <div>{t('userDetails.feedback')}</div>
            </div>
          </div>
          {isTeacher ? (
            <div className={styles.tabsWrapper}>
              <div className={styles.border}>
                <TabsWrapper
                  tabHeaders={tabHeadersFeedback}
                  activeTabValue={defaultStateFeedback}
                  onTabChange={onTabChangeFeedback}
                />
              </div>
            </div>
          ) : (
            <></>
          )}

          <>
            {isTeacher ? (
              isActiveTab === 'teachers' ? (
                <>
                  {teachersReview && teachersReview.length ? (
                    teachersReview.map((each: any, key: any) => {
                      return (
                        <div className={styles.feedbackCol} key={key}>
                          <div className={styles.information}>
                            <div className={styles.imgFeedback}>
                              {each.reviewerInfo.imageUrl ? (
                                <>
                                  <img src={each.reviewerInfo.imageUrl} alt="user" />
                                </>
                              ) : (
                                <>
                                  <NameInitials
                                    firstName={each.reviewerInfo.firstName}
                                    lastName={each.reviewerInfo.lastName}
                                    className={styles.name_initials_container}
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              <div className={styles.headingFeedback}>
                                {each.reviewerInfo.firstName}
                              </div>
                              <div className={styles.title}>Mentor</div>
                              {/* <div className={styles.aboutContent}>{isTeacher ? 'role' : ''}</div> */}
                            </div>
                          </div>

                          <div className={styles.feedback}>
                            <div className={styles.headingFeedback}>
                              {each.sessionInfo?.proposal?.proposalDetail?.title}
                            </div>
                            <div className={styles.aboutContentFeedback}>{each.reviewSummary}.</div>
                          </div>
                          <div className={styles.feedbackContent}>
                            <div>
                              <div className={styles.rating}>
                                {Array(Number(each.reviewRating))
                                  .fill('')
                                  .map((x, i) => {
                                    return (
                                      <img
                                        src={pinkStar}
                                        alt="rating"
                                        key={i}
                                        className={styles.star}
                                      />
                                    )
                                  })}
                                {Array(5 - Number(each.reviewRating))
                                  .fill('')
                                  .map((x, i) => {
                                    return (
                                      <img
                                        src={star}
                                        alt="rating"
                                        key={i}
                                        className={styles.star}
                                      />
                                    )
                                  })}
                              </div>
                            </div>
                            <div className={styles.completedOnFeedback}>
                              Completed
                              <Moment
                                date={each?.sessionInfo?.trainerCompletionDate}
                                format="DD MMM"
                                interval={0}
                              />
                              ,
                              <Moment
                                date={each?.sessionInfo?.trainerCompletionDate}
                                format="HH:mm"
                                interval={0}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <>
                      <EmptySection subText="No reviews found! " />
                    </>
                  )}
                </>
              ) : (
                <>
                  {studentsReview && studentsReview.length ? (
                    studentsReview.map((each: any, key: any) => {
                      return (
                        <div className={styles.feedbackCol} key={key}>
                          <div className={styles.information}>
                            <div className={styles.imgFeedback}>
                              {each.reviewerInfo.imageUrl ? (
                                <>
                                  <img
                                    className={styles.imageUrl}
                                    src={each.reviewerInfo.imageUrl}
                                    alt="user"
                                  />
                                </>
                              ) : (
                                <>
                                  <NameInitials
                                    firstName={each.reviewerInfo.firstName}
                                    lastName={each.reviewerInfo.lastName}
                                    className={styles.name_initials_container}
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              <div className={styles.headingFeedback}>
                                {each.reviewerInfo.firstName}
                              </div>
                              <div className={styles.title}>Attendee</div>
                              {/* <div className={styles.aboutContent}>{isTeacher ? 'role' : ''}</div> */}
                            </div>
                          </div>
                          <div className={styles.feedback}>
                            <div className={styles.headingFeedback}>
                              {each?.sessionInfo?.proposal?.proposalDetail?.title}
                            </div>
                            <div className={styles.aboutContentFeedback}>{each.reviewSummary}</div>
                          </div>
                          <div className={styles.feedbackContent}>
                            <div>
                              <div className={styles.rating}>
                                {Array(Number(each.reviewRating))
                                  .fill('')
                                  .map((x, i) => {
                                    return (
                                      <img
                                        src={pinkStar}
                                        alt="rating"
                                        key={i}
                                        className={styles.star}
                                      />
                                    )
                                  })}
                                {Array(5 - Number(each.reviewRating))
                                  .fill('')
                                  .map((x, i) => {
                                    return (
                                      <img
                                        src={star}
                                        alt="rating"
                                        key={i}
                                        className={styles.star}
                                      />
                                    )
                                  })}
                              </div>
                            </div>
                            <div className={styles.completedOnFeedback}>
                              Completed
                              <Moment
                                date={each?.sessionInfo?.traineeCompletionDate}
                                format="DD MMM"
                                interval={0}
                              />
                              ,
                              <Moment
                                date={each?.sessionInfo?.traineeCompletionDate}
                                format="HH:mm"
                                interval={0}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <>
                      <>
                        <EmptySection subText="No reviews found!" />
                      </>
                    </>
                  )}
                </>
              )
            ) : (
              <>
                {teachersReview && teachersReview.length ? (
                  teachersReview.map((each: any, key: any) => {
                    return (
                      <div className={styles.feedbackCol} key={key}>
                        <div className={styles.information}>
                          <div className={styles.imgFeedback}>
                            {each.reviewerInfo.imageUrl ? (
                              <>
                                <img
                                  className={styles.imageUrl}
                                  src={each.reviewerInfo.imageUrl}
                                  alt="user"
                                />
                              </>
                            ) : (
                              <>
                                <NameInitials
                                  firstName={each.reviewerInfo.firstName}
                                  lastName={each.reviewerInfo.lastName}
                                  className={styles.name_initials_container}
                                />
                              </>
                            )}
                          </div>
                          <div>
                            <div className={styles.headingFeedback}>
                              {each.reviewerInfo.firstName}
                            </div>
                            {/* <div className={styles.aboutContent}>{isTeacher ? 'role' : ''}</div> */}
                          </div>
                        </div>
                        <div className={styles.feedback}>
                          <div className={styles.headingFeedback}>
                            {each.sessionInfo?.proposal?.proposalDetail?.title}
                          </div>
                          <div className={styles.aboutContentFeedback}>{each.reviewSummary}</div>
                        </div>
                        <div className={styles.feedbackContent}>
                          <div>
                            <div className={styles.rating}>
                              {Array(Number(each.reviewRating))
                                .fill('')
                                .map((x, i) => {
                                  return (
                                    <img
                                      src={pinkStar}
                                      alt="rating"
                                      key={i}
                                      className={styles.star}
                                    />
                                  )
                                })}
                              {Array(5 - Number(each.reviewRating))
                                .fill('')
                                .map((x, i) => {
                                  return (
                                    <img src={star} alt="rating" key={i} className={styles.star} />
                                  )
                                })}
                            </div>
                          </div>
                          <div className={styles.completedOnFeedback}>
                            Completed
                            <Moment
                              date={each?.sessionInfo?.trainerCompletionDate}
                              format="DD MMM"
                              interval={0}
                            />
                            ,
                            <Moment
                              date={each?.sessionInfo?.trainerCompletionDate}
                              format="HH:mm"
                              interval={0}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <>
                    <EmptySection subText="No reviews found!" />
                  </>
                )}
              </>
            )}
          </>
        </div>
      </div>
    </>
  )
}
