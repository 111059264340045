/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import closeIcon from '../../assets/images/svg/close.svg'
import styles from './MultipleSelect.module.scss'
// to be used where we select multiple values from autocomplete
export const MultipleSelect: React.FC<{
  readonly textContent: string
  readonly className?: string

  readonly closeIconFunc?: any
}> = ({ textContent, closeIconFunc, className }) => {
  return (
    <div className={className ? className : styles.option}>
      <div>{textContent}</div>
      {closeIconFunc ? (
        <img className={styles.close} src={closeIcon} onClick={closeIconFunc} alt="close" />
      ) : (
        <></>
      )}
    </div>
  )
}
