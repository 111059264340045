/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Search } from '../../common/search/Search'
import styles from './ChatWindow.module.scss'
import { ChatList } from '../chat-list/ChatList'
import { ChatContent } from '../chat-content/ChatContent'
import { OnetooneChatDisplay } from './OnetooneChatDisplay'
import { Header } from '../header/Header'
import { MessagingService } from './services/MessagingService'
import { appConfig } from './Config'
import AWS, { Credentials } from 'aws-sdk'
import { mergeArrayOfObjects } from './utilities/mergeArrays'
import {
  useChatChannelState,
  useChatInitState,
  useChatMessagingState,
} from './providers/ChatMessagesProvider'
import {
  describeChannel,
  listChannels,
  listChannelMessages,
  listChannelMembershipsForAppInstanceUser,
} from './api/ChimeAPI'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { useFetch } from '../../common/https'
import { Loader } from '../../common/Loader/Loader'
import { useUserStatusContext } from '../../common/user-status-provider/UserStatusProvider'
import { useUserUnreadInfoContext } from '../../common/user-unread-info-provider/UserUnreadInfoProvider'
import { debounce } from '@material-ui/core/utils'
import ProtectedRoute from '../../common/protected-route/ProtectedRoute'
import { useHistory, useLocation } from 'react-router'
import { TabsWrapper, TabWrapperProps } from '../tabs-wrapper/TabsWrapper'
import { TabItem } from '../tab-item/TabItem'

const ChatWindow: React.FC = () => {
  const {
    userStatus,
    connectedUsers,
    connectedUsersStatus,
    updateConnectedUsers,
    updateCurrentlyConnectedUser,
    userMessageAction,
    updateUserMessageAction,
    messengerEnabled,
  } = useUserStatusContext()
  const { initState } = useChatInitState()
  const { userDetails } = useUserDetailsContext()
  const { unreadInfo } = useUserUnreadInfoContext()
  const [member, setMember] = useState({
    userId: localStorage.getItem('identityId'),
    username: undefined,
  })
  const { fetchRest } = useFetch()
  const [value, setValue] = useState('')
  const [activeIndex, setActiveIndex] = useState<number>(-1)
  const [lastMessage, setLastMessage] = useState<string>('')
  const [searchText, setSearchText] = useState<string>('')
  const [sortedChannelsList, setSortedChannelsList] = useState([])
  const [queriedChannelsList, setQueriedChannelsList] = useState<ReadonlyArray<any>>([])
  const [showChannelLoader, setShowChannelLoader] = useState(false)
  const [uniqueIdArray, setUniqueIdArray] = useState<ReadonlyArray<any>>([])
  const [uniqueTeamIdArray, setUniqueTeamIdArray] = useState<ReadonlyArray<any>>([])
  const [mappedObjectArray, setMappedObjectArray] = useState<ReadonlyArray<any>>([])
  const [chatType, setChatType] = useState<string>('one-to-one')
  const {
    // member,
    activeChannelRef,
    channelList,
    setChannelList,
    setActiveChannel,
    activeChannel,
    activeChannelMemberships,
    setActiveChannelMemberships,
    setChannelMessageToken,
    unreadChannels,
    setUnreadChannels,
    hasMembership,
    loadingForChannelMessageFetch,
    setLoadingForChannelMessageFetch,
  } = useChatChannelState()
  const { messages, messagesRef, setMessages, onReceiveMessage } = useChatMessagingState()
  const [teamMemberList, setTeamMemberList] = useState<any>()
  const [count, setCount] = useState<number>(0)
  const [chatSelected, setChatSelected] = useState(false)
  const [activeRequest, setActiveRequest] = useState(false)
  const [defaultState, setDefaultState] = useState<number | undefined>(undefined)
  const { push } = useHistory()
  const { search } = useLocation()
  const [tabHeaders, setTabHeaders] = useState<ReadonlyArray<TabWrapperProps>>([])

  const [tabCountValues, setTabCountValues] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }>({
    requestCount: 0,
    proposalCount: 0,
    sessionCount: 0,
  })
  const getTabHeaders: (obj: { [key: string]: any }) => ReadonlyArray<TabWrapperProps> = (
    countValues
  ) => {
    return [
      {
        key: 'Request Learning',
        TabItemWrapper: ({ isActive }) => {
          return <TabItem isActive={isActive} text="Request Learning" />
        },
      },
      {
        key: 'Requests',
        TabItemWrapper: ({ isActive }) => {
          return <TabItem count={countValues.requestCount} isActive={isActive} text="Requests" />
        },
      },
      {
        key: 'Proposal',
        TabItemWrapper: ({ isActive }) => {
          return <TabItem isActive={isActive} count={countValues.proposalCount} text="Proposals" />
        },
      },
      {
        key: 'sessions',
        TabItemWrapper: ({ isActive }) => {
          return <TabItem isActive={isActive} count={countValues.sessionCount} text={'Sessions'} />
        },
      },
    ]
  }
  const getTeamMembers = (teamId: string) => {
    fetchRest({
      method: 'GET',
      url: `team/${teamId}/members`,
      queryParams: {
        getType: 'all',
      },
    })
      .then((res) => {
        if (res.data && res.data.data && res.data.data.length) {
          setCount(res.data.count)
          const obj: any = {}
          res.data.data.forEach((ele: any) => {
            obj[ele.memberId] = {
              id: ele.memberId,
              firstName: ele.memberInfo.firstName,
              lastName: ele.memberInfo.lastName,
              imageUrl: ele.memberInfo.imageUrl,
            }
          })
          setTeamMemberList(obj)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const filterChannelResult = (searchvalue: string, list: any[]) => {
    if (list && list.length) {
      const result = list.filter((ele: any) => ele.searchKey.toLowerCase().search(searchvalue) >= 0)
      setQueriedChannelsList(result)
    }
  }

  const debouncedSave = useCallback(
    debounce((nextValue: string, list: any[]) => {
      filterChannelResult(nextValue, list)
    }, 1000),
    []
  )

  const onchangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: nextValue } = e.target
    setSearchText(nextValue)
    debouncedSave(nextValue, sortedChannelsList)
  }

  const checkArrayDeepEquality = (arr1: any, arr2: any) => {
    return (
      Array.isArray(arr1) &&
      Array.isArray(arr2) &&
      arr1.length === arr2.length &&
      arr1.every((ele, index) => ele === arr2[index])
    )
  }

  const fetchChannels = async () => {
    const userChannelMemberships = await listChannelMembershipsForAppInstanceUser(member.userId)

    const userChannelList = userChannelMemberships.map(
      (channelMembership: any) => channelMembership.ChannelSummary
    )
    const publicChannels = await listChannels(appConfig.appInstanceArn, member.userId)

    setChannelList(mergeArrayOfObjects(userChannelList, publicChannels, 'ChannelArn'))
  }

  const sortChannelsByLastMessageTimeStamp = async () => {
    if (channelList.length !== 0) {
      setShowChannelLoader(true)
      channelList.sort(function (b: any, a: any) {
        return a.LastMessageTimestamp - b.LastMessageTimestamp
      })
      let updatedChannelArr = []
      const uniqueArray = channelList.filter((ele: any) => /"isOneToOne\":true/g.test(ele.Metadata))
      const uuidExpression = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/
      const uniqueTeamArray = channelList.filter(
        (ele: any) => /"isOneToOne\":false/g.test(ele.Metadata) && uuidExpression.test(ele.Name)
      )
      const idTeamArrayUnique = uniqueTeamArray.map((ele: any) => ele.Name)

      let teamMetaInfoData: any = {}
      if (
        idTeamArrayUnique.length &&
        !checkArrayDeepEquality(uniqueTeamIdArray, idTeamArrayUnique)
      ) {
        const response: any = await fetchRest({
          method: 'POST',
          data: { teamIds: idTeamArrayUnique },
          url: `/getTeamsMetaInfo`,
        })
        if (response.status === 200 && response.data) {
          teamMetaInfoData = { ...response.data }
        }
      }

      const idArrayUnique = uniqueArray.map((ele: any) => {
        const metadata = JSON.parse(ele.Metadata)
        if (metadata.student.id === userDetails.userId) {
          return metadata.teacher.id
        } else if (metadata.teacher.id === userDetails.userId) {
          return metadata.student.id
        }
        // return metadata.student.id === userDetails.userId ? metadata.teacher.id : metadata.student.id
      })
      let userMetaInfo: any = {}
      if (idArrayUnique.length && !checkArrayDeepEquality(uniqueIdArray, idArrayUnique)) {
        const response: any = await fetchRest({
          method: 'POST',
          data: { userIds: idArrayUnique },
          url: `/getUsersMetaInfo`,
        })
        if (response.status === 200 && response.data) {
          userMetaInfo = { ...response.data }
        }
      }
      if (Object.keys(userMetaInfo).length || Object.keys(teamMetaInfoData).length) {
        updatedChannelArr = channelList.map((ele: any) => {
          const metadata = JSON.parse(ele.Metadata)
          if (metadata.isOneToOne) {
            let id = null
            if (metadata.student.id === userDetails.userId) {
              id = metadata.teacher.id
            } else if (metadata.teacher.id === userDetails.userId) {
              id = metadata.student.id
            }
            return {
              ...ele,
              searchKey: userMetaInfo[id].firstName + ' ' + userMetaInfo[id].lastName,
              channelMetaInfo: userMetaInfo[id],
              channelType: 'one-to-one',
            }
          } else if (
            !metadata.isOneToOne &&
            uuidExpression.test(ele.Name) &&
            teamMetaInfoData[ele.Name]
          ) {
            return {
              ...ele,
              searchKey: teamMetaInfoData[ele.Name].teamDetails.teamName,
              channelMetaInfo: teamMetaInfoData[ele.Name].teamDetails,
              channelType: 'team',
            }
          } else {
            return { ...ele, searchKey: ele.Name }
          }
        })

        setSortedChannelsList(updatedChannelArr)
        filterChannelResult(searchText, updatedChannelArr)
        setUniqueIdArray(idArrayUnique)
        setUniqueTeamIdArray(idTeamArrayUnique)
        updateConnectedUsers(idArrayUnique)
      }
      // ----------------------------------
      setShowChannelLoader(false)
    }
  }

  const sendNewChannelInfoToSocket = async (channel: any) => {
    const metadata = JSON.parse(channel.Metadata)
    let id = null
    if (metadata.isOneToOne) {
      if (metadata.student.id === userDetails.userId) {
        id = metadata.teacher.id
      } else if (metadata.teacher.id === userDetails.userId) {
        id = metadata.student.id
      }
      updateCurrentlyConnectedUser(id)
    }
    return
  }

  const onClickChatList = async (channelArn: any) => {
    setChatSelected(false)
    setLoadingForChannelMessageFetch(true)
    if (activeChannel.ChannelArn === channelArn) return
    const newMessages = await listChannelMessages(channelArn, member?.userId)
    let channel = await describeChannel(channelArn, member?.userId)

    setMessages(newMessages.Messages)
    setChannelMessageToken(newMessages.NextToken)
    const channelMetaInfoHolder: any = queriedChannelsList.find(
      (ele: any) => ele.ChannelArn === channelArn
    )
    channel = {
      ...channel,
      searchKey: channelMetaInfoHolder?.searchKey,
      channelMetaInfo: channelMetaInfoHolder?.channelMetaInfo,
    }
    setChatType(channelMetaInfoHolder.channelType)
    setActiveChannel(channel)
    setUnreadChannels(unreadChannels.filter((c: any) => c !== channelArn))
    setLoadingForChannelMessageFetch(false)
    sendNewChannelInfoToSocket(channel)
    setChatSelected(true)
  }

  const resetChannelMessageInfo = () => {
    setMessages([])
    setChannelMessageToken('')
    setActiveChannel({})
    setUnreadChannels([])
    updateCurrentlyConnectedUser(null)
  }

  useEffect(() => {
    sortChannelsByLastMessageTimeStamp()
  }, [channelList])

  useEffect(() => {
    sortChannelsByLastMessageTimeStamp()
  }, [unreadChannels])

  useEffect(() => {
    if (userDetails.userId && initState) {
      fetchChannels()
    }
  }, [userDetails, initState])

  useEffect(() => {
    if (
      sortedChannelsList.length &&
      connectedUsersStatus &&
      Object.keys(connectedUsersStatus).length
    ) {
      const updatedChannelData: any = sortedChannelsList.map((ele: any) => {
        const metadata = JSON.parse(ele.Metadata)
        let id = null
        if (metadata.isOneToOne) {
          if (metadata.student.id === userDetails.userId) {
            id = metadata.teacher.id
          } else if (metadata.teacher.id === userDetails.userId) {
            id = metadata.student.id
          }
          return {
            ...ele,
            channelStatus: (connectedUsersStatus as any)[id]
              ? (connectedUsersStatus as any)[id].status
              : 'OFFLINE',
          }
        } else {
          return ele
        }
      })
      setSortedChannelsList(updatedChannelData)
      filterChannelResult(searchText, updatedChannelData)
    }
  }, [connectedUsersStatus])

  useEffect(() => {
    if (userDetails.userId) {
      const memberObj = {
        userId: userDetails?.identityId
          ? userDetails?.identityId
          : localStorage.getItem('identityId'),
        username: userDetails.userId,
      }
      setMember(memberObj)
    }
  }, [userDetails])
  useEffect(() => {
    if (queriedChannelsList && queriedChannelsList.length) {
      if (Object.keys(activeChannel).length) {
        const channelExist: any = queriedChannelsList.find(
          (ele: any) => ele.ChannelArn === activeChannel.ChannelArn
        )
        if (!Boolean(channelExist)) {
          resetChannelMessageInfo()
        }
      }
    } else {
      resetChannelMessageInfo()
    }
  }, [queriedChannelsList])

  useEffect(() => {
    if (chatSelected) {
      setActiveRequest(false)
      fetchRest({
        url: 'getActiveUserRequest',
        method: 'GET',
        queryParams: activeChannel.channelMetaInfo.teamId
          ? { teamId: activeChannel.channelMetaInfo.teamId, userId: userDetails.userId }
          : { userId: activeChannel.channelMetaInfo.userId },
      })
        .then((res) => {
          if (res && res.data) {
            setActiveRequest(res.data.activeRequest)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [chatSelected])

  const onTabChange = (activeTab: number) => {
    switch (activeTab) {
      case 1:
        push('/learn?tab=requests')
        break
      case 2:
        push('/learn?tab=Proposals')
        break
      case 3:
        push('/learn?tab=Sessions')
        break
      case 4:
        push('/learn?tab=Archived')
        break
      default:
        push('/learn?tab=requestLearning')
    }
  }

  const fetchStatus = () => {
    if (userDetails.userId) {
      fetchRest({
        url: '/studentStats',
        method: 'GET',
      })
        .then((res) => {
          if (res && res.data) {
            setTabCountValues(res.data)
          }
        })
        .catch()
    }
  }

  useEffect(() => {
    let searchParams = new URLSearchParams(search)
    let defaultTab: number
    let searchTab = searchParams.get('tab')
    if (searchTab === 'requests') {
      defaultTab = 1
    } else if (searchTab === 'Proposals') {
      defaultTab = 2
    } else if (searchTab === 'Sessions') {
      defaultTab = 3
    } else if (searchTab === 'Archived') {
      defaultTab = 4
    } else {
      defaultTab = 0
    }
    setDefaultState(defaultTab)
    fetchStatus()
  }, [search, userDetails.userId])

  useEffect(() => {
    setTabHeaders(getTabHeaders(tabCountValues))
  }, [tabCountValues])

  return (
    <>
      <Header />
      <div className={styles.tabHeaders}>
        <div className={styles.container}>
          <TabsWrapper
            tabHeaders={tabHeaders}
            activeTabValue={Number(defaultState)}
            onTabChange={onTabChange}
          />
        </div>
      </div>
      <div className={styles.grayBackground}>
        <div className={`${styles.wrapper} ${styles.fullWidth}`}>
          <div className={`${styles.left} ${styles.background}`}>
            <div>
              <Search
                type="text"
                placeholder="Search user..."
                onChange={onchangeText}
                value={searchText}
                autoFocus
                autoComplete="off"
              />
            </div>
            <div className={styles.content}>
              {showChannelLoader ? (
                <div className={styles.channelLoader}>
                  <Loader size={60} thickness={3} />
                </div>
              ) : null}

              {queriedChannelsList.map((channel: any, index: number) => {
                //If one to one
                var metaData = JSON.parse(channel?.Metadata)
                if (metaData?.isOneToOne === true) {
                  return (
                    <div
                      key={`one-to-one-channel-${index}`}
                      className={
                        channel.ChannelArn === activeChannel.ChannelArn
                          ? styles.active
                          : styles.chat
                      }
                      onClick={() => {
                        setActiveIndex(index)
                      }}
                    >
                      <ChatList
                        key={channel.ChannelArn}
                        channeldata={channel}
                        firstName={channel.channelMetaInfo.firstName}
                        lastName={channel.channelMetaInfo.lastName}
                        member={member}
                        onClickChatList={onClickChatList}
                        unread={unreadChannels.includes(channel.ChannelArn)}
                        channelImage={channel.channelMetaInfo.imageUrl}
                        activeChannel={activeChannel}
                        channelStatus={channel.channelStatus}
                        unreadInfo={unreadInfo}
                        // activeTab={isActive === channel.Name ? true : false}
                      />
                    </div>
                  )
                }
                //If group channel
                else {
                  return (
                    <div
                      key={`team-channel-${index}`}
                      className={
                        channel.ChannelArn === activeChannel.ChannelArn
                          ? styles.active
                          : styles.chat
                      }
                      onClick={() => {
                        setActiveIndex(index)
                        getTeamMembers(channel.channelMetaInfo.teamId)
                      }}
                    >
                      <ChatList
                        key={channel.ChannelArn}
                        channeldata={channel}
                        firstName={
                          channel.channelMetaInfo ? channel.channelMetaInfo.teamName : channel.Name
                        }
                        lastName={''}
                        channelImage={
                          channel.channelMetaInfo ? channel.channelMetaInfo.teamImage : null
                        }
                        member={member}
                        onClickChatList={onClickChatList}
                        unread={unreadChannels.includes(channel.ChannelArn)}
                        activeChannel={activeChannel}
                        unreadInfo={unreadInfo}
                        // activeTab={isActive === channel.Name ? true : false}
                      />
                    </div>
                  )
                }
              })}
            </div>
          </div>
          <div className={styles.right}>
            <ChatContent
              memberCount={count}
              memberList={teamMemberList}
              initState={initState}
              chatSelected={chatSelected}
              activeRequest={activeRequest}
              member={member}
              messages={messages}
              activeChannel={activeChannel}
              messengerEnabled={messengerEnabled}
              triggerMessageAction={updateUserMessageAction}
              isTeam={chatType === 'team'}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export const ProtectedChatWindow: React.FC = () => {
  return (
    <ProtectedRoute>
      <ChatWindow />
    </ProtectedRoute>
  )
}
