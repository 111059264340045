import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './SessionCard.module.scss'

export const SessionCard: React.FC<{
  readonly header: string
  readonly timer: string
  onCardClick(): void
  readonly imageUrl: string
}> = ({ header, timer, onCardClick, imageUrl }) => {
  const { t } = useTranslation()
  return (
    <div
      data-testid="card"
      onClick={() => {
        onCardClick()
      }}
      className={styles.sessionCard}
    >
      <div className={styles.sessionCardImg}>
        {imageUrl && imageUrl.length > 0 ? (
          <img src={imageUrl} alt="apply-now" className={styles.courseIcon} />
        ) : (
          <></>
        )}
        <div className={styles.courseTitle} dangerouslySetInnerHTML={{ __html: header }}></div>
      </div>
      <div className={styles.sessionCardTimer} data-testid="timer">
        {timer}
        {t('sessionCard.duration')}
      </div>
    </div>
  )
}
