export const appConfig = {
  apiGatewayInvokeUrl: '',
  cognitoUserPoolId: process.env.REACT_APP_CONGNITO_USER_POOL_ID || 'eu-west-2_QX28bR1on',
  cognitoAppClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID || '550ogi1gnjfnua8j52b8g7c2jv',
  cognitoIdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID || 'eu-west-2:15e7914b-f129-4c70-aa18-fee76f8bdadc',
  appInstanceArn: process.env.REACT_APP_CHIME_APP_INSTANCE_ARN || 'arn:aws:chime:us-east-1:610228335397:app-instance/3efba142-7102-4856-8a9d-6e3f55e3e5eb',
  region: process.env.REACT_APP_CHIME_REGION || 'us-east-1',
  appRegion: process.env.REACT_APP_COGNITO_REGION || 'eu-west-2',
  attachments_s3_bucket_name: process.env.REACT_APP_CHIME_S3_BUCKET_ATTACHMENT || 'ontelo-dev-chime-sdk-chat-attachment',
  appInstanceAdminArn: ''
  // "AppInstanceUserArn": "arn:aws:chime:us-east-1:610228335397:app-instance/3efba142-7102-4856-8a9d-6e3f55e3e5eb/user/ontelo-dev-chime-backend-worker"
  //   {
  //     "AppInstanceAdmin": {
  //         "Arn": "arn:aws:chime:us-east-1:610228335397:app-instance/3efba142-7102-4856-8a9d-6e3f55e3e5eb/user/ontelo-dev-chime-backend-worker",
  //         "Name": "ontelo-dev-chime-backend-worker"
  //     },
  //     "AppInstanceArn": "arn:aws:chime:us-east-1:610228335397:app-instance/3efba142-7102-4856-8a9d-6e3f55e3e5eb"
  // }
}
