/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import editMenu from '../../assets/images/svg/menu-edit.svg'
import searchIcon from '../../assets/images/svg/search-icon.svg'
import tick from '../../assets/images/svg/whiteTick.svg'
import { Button } from '../../common/button/Button'
import { AutocompleteOption } from '../../common/autocomplete-option/AutocompleteOption'
import { Loader } from '../../common/Loader/Loader'
import { useFetch } from '../../common/https'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import { charactersLeft } from '../../common/utils'
import styles from './EditRequest.module.scss'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import { queryCategorySchema } from '../../common/schema/Schema'

export const EditRequest: React.FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly data: any
  readonly onEditRequest: (value: boolean) => void
}> = ({ data, onEditRequest }) => {
  const [valuePh, setValuePh] = useState({
    isValid: true,
    errorMsg: 'Question is required',
  })
  const [categoryList, setCategoryList] = useState([])
  const { fetchRest, sectionLoading } = useFetch({ editRequest: false })
  const { t } = useTranslation()

  const [show, setShow] = useState(data.isOther ? true : false)
  let [queryCategory, setQueryCategory] = useState<{
    readonly value: string
    readonly isValid: boolean
    readonly isTouched: boolean
    readonly errorMsg: string
  }>({
    value: data.otherDescription ? data.otherDescription : '',
    isValid: data.isOther ? true : false,
    errorMsg: 'Please enter a category',
    isTouched: data.isOther ? true : false,
  })
  const [dropdownValue, setDropdownValue] = useState({
    categoryId: data.categoryId,
    courseId: data.courseId,
    subCategoryId: data.subCategoryId,
    name: data.isOther
      ? 'Others'
      : data.category != null
      ? data.category.name
      : data.course != null
      ? data.course.name
      : data.subCategory.name,
  })
  const [editData, setEditData] = useState({
    categoryId: data.categoryId ? data.categoryId : undefined,
    courseId: data.courseId ? data.courseId : undefined,
    subCategoryId: data.subCategoryId ? data.subCategoryId : undefined,
    requestId: data.requestId,
    description: data.description,
    isOther: data.isOther ? data.isOther : undefined,
    otherDescription: data.otherDescription ? data.otherDescription : undefined,
  })
  const [errorMsg, setErrMsg] = useState('')
  const editRequest = (e: any) => {
    e.preventDefault()
    if (dropdownValue.name === undefined) {
      setErrMsg('Please choose a category first')
    } else {
      if (editData.description.length > 0) {
        fetchRest(
          {
            method: 'PUT',
            url: '/editRequest',
            data: {
              ...editData,
            },
          },
          'editRequest'
        )
          .then((res) => {
            if (res.data.status === 'success') {
              onEditRequest(true)
            }
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        setValuePh((prev) => {
          return { ...prev, isValid: false }
        })
      }
    }
  }

  useEffect(() => {
    fetchRest({
      url: '/requestCategoryAndSubCategory',
      method: 'GET',
    })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res && res.data && res.data.data) {
          setCategoryList(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any

            res.data.data.map((each: any) => {
              return { id: each.subCategoryId ? each.subCategoryId : each.categoryId, ...each }
            })
          )
        } else {
          setCategoryList([])
        }
      })
  }, [])

  return (
    <NoEventsLoader isLoading={sectionLoading && sectionLoading.editRequest}>
      <form onSubmit={editRequest}>
        <div className={styles['list-card']}>
          <div className={styles['list-card-header']}>
            <div className={styles['d-flex']}>
              <div className={styles['review-card__align-items']}>
                <img src={editMenu} alt="time icon" className={styles['review-card__time-icon']} />
                <div className={styles['review-card__time-subject']}>
                  {t('requestCard.edit-request')}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={styles['edit-title']}>{t('requestCard.edit-question')}</div>
            <div className={styles.autocompletePosition}>
              <textarea
                className={styles['textarea-styles']}
                value={editData.description}
                onChange={(e) => {
                  let value = e.target.value
                  setEditData((prev) => {
                    return { ...prev, description: value }
                  })
                }}
              ></textarea>
              {!valuePh.isValid ? <div className={styles.error}>{valuePh.errorMsg}</div> : ''}
            </div>
            <div className={styles['character-count']}>
              {charactersLeft({
                currentLength:
                  editData.description && editData.description.length
                    ? editData.description.length
                    : 0,
              })}
              {t('common.charactersLeft')}
            </div>
            <div className={styles['edit-title']}>{t('requestCard.category')}</div>
            <div className={styles['edit-title-gray']}>{t('requestCard.specify-category')}</div>
            <div className={styles.autocompletePosition}>
              <div className="autocomplete-wrapper search">
                <Autocomplete
                  value={dropdownValue}
                  popupIcon={<img src={searchIcon} alt="search" />}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(event: any, newValue: any) => {
                    if (newValue.categoryId === '-1') {
                      setShow(true)
                      setEditData((prev) => {
                        return {
                          ...prev,
                          categoryId: undefined,
                          courseId: undefined,
                          subCategoryId: undefined,
                          isOther: true,
                        }
                      })
                      setDropdownValue((prev) => {
                        return {
                          ...prev,
                          name: newValue != null && newValue.name ? newValue.name : undefined,
                          categoryId: undefined,
                          courseId: undefined,
                          subCategoryId: undefined,
                        }
                      })
                    } else {
                      setShow(false)

                      setEditData((prev) => {
                        return {
                          ...prev,
                          categoryId:
                            newValue != null && newValue.categoryId
                              ? newValue.categoryId
                              : undefined,
                          courseId:
                            newValue != null && newValue.courseId ? newValue.courseId : undefined,
                          subCategoryId:
                            newValue != null && newValue.subCategoryId
                              ? newValue.subCategoryId
                              : undefined,
                          isOther: undefined,
                          otherDescription: undefined,
                        }
                      })
                      setDropdownValue((prev) => {
                        return {
                          ...prev,
                          name: newValue != null && newValue.name ? newValue.name : undefined,
                          categoryId:
                            newValue != null && newValue.categoryId
                              ? newValue.categoryId
                              : undefined,
                          courseId:
                            newValue != null && newValue.courseId ? newValue.courseId : undefined,
                          subCategoryId:
                            newValue != null && newValue.subCategoryId
                              ? newValue.subCategoryId
                              : undefined,
                        }
                      })
                    }
                  }}
                  style={{ width: '100%', height: '48px', outline: 'none' }}
                  options={categoryList}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any

                  getOptionLabel={(option: any) => {
                    return option.name
                  }}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any

                  renderOption={(option: any) => <AutocompleteOption option={option.name} />}
                  ListboxProps={{
                    style: {
                      maxHeight: '147px',
                      borderRadius: 0,
                    },
                  }}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
              </div>
              {errorMsg !== '' ? <div className={styles.error}>{errorMsg}</div> : <></>}
            </div>
          </div>
          {show ? (
            <div className={styles.mTop}>
              <div className={styles['edit-title-gray']}>Specify your Category</div>
              <div className={styles.outer}>
                <InputWrapper
                  valueObj={queryCategory}
                  // className={className ? className : styles['submit-text']}
                  type="text"
                  schema={queryCategorySchema}
                  onChange={(e: any) => {
                    setQueryCategory({
                      ...e,
                      isTouched: true,
                    })
                    setEditData((prev) => {
                      return {
                        ...prev,
                        otherDescription: e.value,
                      }
                    })
                  }}
                  validateOnChange={true}
                  placeholder="Enter the category of your request"
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className={styles['box-btn']}>
            <Button
              ButtonImg={() => {
                return (
                  <>
                    {sectionLoading && sectionLoading.editRequest ? (
                      <Loader className="form_loader" size={20} thickness={2} />
                    ) : (
                      <>
                        <img src={tick} alt="tick icon" className={styles.divImg} />
                      </>
                    )}
                  </>
                )
              }}
              type="submit"
              className={styles['btn-color']}
              text="Save"
            />
          </div>
        </div>
      </form>
    </NoEventsLoader>
  )
}
