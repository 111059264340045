/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import styles from './TeamInviteCode.module.scss'
import lock from '../../assets/images/svg/password.svg'
import reset from '../../assets/images/svg/repeat.svg'
import { useFetch } from '../../common/https'
import { Loader } from '../../common/Loader/Loader'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'

export const TeamInviteCode: React.FC<{
  readonly inviteCode: string
  readonly isAdmin?: boolean
  readonly callBack?: any
}> = ({ inviteCode, isAdmin = false, callBack = () => {} }) => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const uId = pathname.substring(pathname.lastIndexOf('/') + 1)
  const { fetchRest, sectionLoading } = useFetch({ resetCode: false })
  const [invitationCode, setInvitationCode] = useState<string>('')
  const resetCode = () => {
    fetchRest(
      {
        url: `team/${uId}/reset-invitation-key`,
        method: 'GET',
        isFullPageLoader: false,
      },
      'resetCode'
    )
      .then((res: any) => {
        setInvitationCode(res.data.invitationKey)
        callBack()
      })
      .catch((err) => {})
  }
  return (
    <>
      <div className={styles.element}>
        <div className={styles.headingWrapper}>
          <div className={styles.label}>
            <img className={styles.divImg} src={lock} alt="reset code" />
            <div>{t('teams.teamInviteCode')}</div>
          </div>
          {isAdmin ? (
            <img src={reset} alt="reset code" onClick={resetCode} className={styles.pointer} />
          ) : (
            <></>
          )}
        </div>
        {sectionLoading && sectionLoading.resetCode ? (
          <Loader size={20} thickness={2} className={styles.marginBottom} />
        ) : (
          <div className={styles.code}>{invitationCode !== '' ? invitationCode : inviteCode}</div>
        )}
      </div>
    </>
  )
}
