import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import data from "./string-localization/stringLocalization.json";

i18n 
.use(initReactI18next)
  .init({
    debug :true,
    fallbackLng : 'en',
    interpolation : {
      escapeValue : false,
    },
    resources : {
      en : {
        translation : data,
      }
    }
  });
  export default i18n;

