/**
 * Merges two arrays of objects.
 * @param {[]} original Original array data.
 * @param {[]} newdata New array data to be appended to original
 * @param {string} uniqueSelector attribute key to select unique elements.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const mergeArrayOfObjects = (original: any, newdata: any, uniqueSelector: any = '') => {
  newdata.forEach((dat: any) => {
    const foundIndex = original.findIndex((ori: any) => ori[uniqueSelector] === dat[uniqueSelector])
    if (foundIndex >= 0) original.splice(foundIndex, 1, dat)
    else original.push(dat)
  })

  return original
}
