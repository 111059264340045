/* eslint-disable @typescript-eslint/no-explicit-any */
import DOMPurify from 'dompurify'
import React, { useEffect, useState } from 'react'
import styles from './Notifications.module.scss'
import close from '../../assets/images/svg/close.svg'
import { Drawer } from '@material-ui/core'
import { Loader } from '../../common/Loader/Loader'
import { NameInitials } from '../name-initials/NameInitials'
import { useFetch } from '../../common/https'
import Moment from 'react-moment'
import { Pagination } from '@material-ui/lab'
import { EmptySection } from '../../common/empty-section/EmptySection'
import { useHistory } from 'react-router'

export const Notifications: React.FC<{
  right: boolean
  onClose: any
}> = ({ right, onClose }) => {
  const { fetchRest, sectionLoading } = useFetch({ getAllNotifications: false })
  const [paginationState, setPaginationState] = useState<{
    page: number
    perPage: number
  }>({
    perPage: 10,
    page: 1,
  })
  const [activityList, setActivityList] = useState<any>([])
  const [notificationCount, setNotificationCount] = useState<number>(0)
  const [notificationIdArray, setNotificationIdArray] = useState<any>([])
  const { push } = useHistory()
  const markAsRead = () => {
    fetchRest(
      {
        url: `/readNotification`,
        method: 'PUT',
        data: {
          notificationIds: notificationIdArray,
        },
      },
      'getAllNotifications'
    ).then((res: any) => {
      console.log(res.data.data)
    })
  }
  const changeHandler = (event: any, value: any) => {
    setPaginationState((prev) => ({ ...prev, page: value }))
    fetchNotifications({ page: value, perPage: paginationState.perPage })
  }
  const fetchNotifications = (obj: { readonly page?: number; readonly perPage?: number }) => {
    const { page = paginationState.page, perPage = paginationState.perPage } = obj
    fetchRest(
      {
        url: `/notifications`,
        method: 'GET',
        queryParams: {
          perPage,
          page,
        },
      },
      'getAllNotifications'
    ).then((res: any) => {
      setActivityList(res.data.data)
      setNotificationCount(res.data.count)
      setNotificationIdArray([])
      res.data.data.forEach((each: any) => {
        setNotificationIdArray((prev: any) => {
          return [...prev, each.notificationId]
        })
      })
      markAsRead()
    })
  }
  useEffect(() => {
    if (right === true) {
      fetchNotifications({})
    }
  }, [right])

  const redirectTo = ({ redirectionUrl = '' }: any) => {
    if (redirectionUrl) {
      push(redirectionUrl)
    }
  }

  const sanitizedData = (data: any) => ({
    __html: DOMPurify.sanitize(data),
  })

  return (
    <Drawer anchor={'right'} open={right} onClose={onClose}>
      <div className={styles.notificationWrapper}>
        <div className={styles.notificationHeader}>
          <img src={close} alt="close" onClick={onClose} />
        </div>
        <div className={styles.notificationList}>
          {sectionLoading && sectionLoading.getAllNotifications ? (
            <Loader className={styles.marginTop} />
          ) : (
            <>
              {activityList.length ? (
                activityList.map((each: any, index: any) => {
                  return (
                    <div
                      key={index}
                      onClick={(_e: any) => {
                        redirectTo(each)
                        onClose()
                      }}
                      className={`${styles.notificationListCard} ${
                        each.redirectionUrl ? styles.addCursor : ''
                      } ${each.notificationViewOn === null ? styles.read : ''}`}
                    >
                      {each.notificationFromInfo?.imageUrl ? (
                        <img
                          src={each.notificationFromInfo?.imageUrl}
                          alt="notification icon"
                          className={styles.icon}
                        />
                      ) : (
                        <NameInitials
                          firstName={each.notificationFromInfo?.firstName}
                          className={styles.icon}
                          lastName={each.notificationFromInfo?.lastName}
                        />
                      )}
                      <div className={styles.notificationContent}>
                        <div className={styles.notificationTitle}>
                          {each.notificationFromInfo?.firstName}{' '}
                          {each.notificationFromInfo?.lastName}
                        </div>

                        <div
                          className={styles.notificationDescription}
                          dangerouslySetInnerHTML={sanitizedData(each.notificationTitle)}
                        ></div>
                      </div>
                      <div className={styles.notificationDate}>
                        <Moment date={each?.notificationOn} format="DD MMM" interval={0} />,
                        <Moment date={each?.notificationOn} format="HH:mm" interval={0} />
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className={styles.marginTop}>
                  <EmptySection subText={"You don't have any new notifications!"} />
                </div>
              )}
            </>
          )}
        </div>
        <div className={styles.pagination}>
          {!(sectionLoading && sectionLoading.getAllNotifications) && notificationCount > 10 ? (
            <Pagination
              count={Math.ceil(notificationCount / paginationState.perPage)}
              onChange={changeHandler}
              page={paginationState.page}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </Drawer>
  )
}
