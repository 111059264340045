import React from 'react'
import styles from '../Loader/Loader.module.scss'
import { useLoaderContext } from '../use-loader-context'

export const Loader: React.FC<{
  readonly className?: string
  readonly size?: number
  readonly thickness?: number
}> = ({ className, size = 70, thickness = 5 }) => {
  const { load } = useLoaderContext()
  return (
    <div className={styles.loaderBody}>
      {!load ? (
        <div
          style={{ width: `${size}px`, height: `${size}px`, borderWidth: `${thickness}px` }}
          className={className ? `${styles.loader} ${className}` : styles.loader}
        ></div>
      ) : (
        <></>
      )}
    </div>
  )
}
