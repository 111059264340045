/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import editIcon from '../../assets/images/svg/editIcon.svg'
import interests from '../../assets/images/svg/interests.svg'
import personalinfoIcon from '../../assets/images/svg/personalDetailsIcon.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import Search from '../../assets/images/svg/search.svg'
import thumbsUp from '../../assets/images/svg/thumbsUp.svg'
import tick from '../../assets/images/svg/whiteTick.svg'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { AutocompleteOption } from '../../common/autocomplete-option/AutocompleteOption'
import { Button } from '../../common/button/Button'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { EmptySection } from '../../common/empty-section/EmptySection'
import { useFetch } from '../../common/https'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import { Loader } from '../../common/Loader/Loader'
import { MultipleSelect } from '../../common/multiple-select/MultipleSelect'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import {
  countryCodeSchema,
  firstNameSchema,
  lastNameSchema,
  phoneNumberSchema,
  schemaEmail,
  schemaInterests,
} from '../../common/schema/Schema'
import { UsePopupContext } from '../../common/use-popup-context'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { getUserTokens } from '../../common/utils'
import { validateInput } from '../../common/ValidateFields'
import styles from './UserProfileAccountSetting.module.scss'

interface CountryType {
  name: string | null
  dialCode: string
  codeId: string
  countryCode: string
}
interface CategorySubCategoryType {
  id: string
  name: string
  categoryId?: string
  subCategoryId?: string
  isValid: boolean
  errorMsg: string
  isTouched: boolean
}

export const UserProfileAccountSetting: React.FC = () => {
  const { fetchRest, sectionLoading } = useFetch({ editProfile: false })

  const [interestPopup, setinterestPopup] = useState(false)
  const { t } = useTranslation()
  const { userDetails, setUser } = useUserDetailsContext()
  const [optionData, setOptionData] = useState<Array<CategorySubCategoryType>>([])
  useEffect(() => {
    fetchRest({
      url: '/requestCategoryAndSubCategory',
      method: 'GET',
    }).then((res: any) => {
      if (res && res.data && res.data.data) {
        setOptionData(
          res.data.data.map((each: any) => {
            return { id: each.subCategoryId ? each.subCategoryId : each.categoryId, ...each }
          })
        )
      } else {
        setOptionData([])
      }
    })
  }, [])

  const closeIconFunc = (e: any, key: string) => {
    // let propertyToAccess: string = ''
    // if (key === 'interest') {
    //   propertyToAccess = 'categoryId'
    // }
    setdataInterest((prev: any) => {
      return {
        ...prev,
        value: dataInterest.value.filter((each: any) => {
          return each[key] !== e[key]
        }),
      }
    })
  }

  const onSubmit = () => {
    if (dataInterest.isValid) {
      const { accessToken } = getUserTokens()
      let data: any[] = []
      dataInterest.value.forEach((each: any) => {
        data.push(
          each.categoryId ? { categoryId: each.categoryId } : { subCategoryId: each.subCategoryId }
        )
      })
      fetchRest(
        {
          url: 'editProfile',
          method: 'PUT',
          data: {
            interests: data,
            accessToken,
          },
          isFullPageLoader: false,
        },
        'editProfile'
      )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          setinterestPopup(false)
          let expertise: any[] = []
          dataInterest.value.forEach((each: any) => {
            expertise.push({ category: each })
          })
          setUser({
            ...userDetails,
            userInterest: expertise,
          })
        })
        .catch()
    }
  }

  const [data, setData] = useState({
    firstName: {
      value: userDetails.firstName,
      isValid: true,
      isTouched: false,
      errorMsg: 'Please enter first name',
    },
    lastName: {
      value: userDetails.lastName,
      isValid: true,
      isTouched: false,
      errorMsg: 'Please enter last name',
    },
    email: {
      value: userDetails.email,
      isValid: true,
      isTouched: false,
      errorMsg: 'Please enter email',
    },
    phoneNo: {
      value: userDetails.phone,
      isValid: true,
      isTouched: false,
      errorMsg: 'Please enter Phone Number',
    },
    phoneCode: {
      value: { name: '', dialCode: '', codeId: userDetails.phoneCodeId, countryCode: '' },
      isValid: true,
      isTouched: false,
      errorMsg: 'Please select code',
    },
    password: { value: '', isValid: true, isTouched: false, errorMsg: 'Please enter Password' },
    oldPassword: {
      value: '',
      isValid: true,
      isTouched: false,
      errorMsg: 'Please enter Password',
    },
    countryCode: {
      value: { name: '', dialCode: '', codeId: userDetails.phoneCodeId, countryCode: '' },
      isValid: false,
      isTouched: false,
      errorMsg: 'Please select country',
    },
  })

  const [phoneCodeArray, setPhoneCodeArray] = useState<ReadonlyArray<any>>([])
  const { push } = useHistory()
  const [errormsg, setErrorMsg] = useState<string>('')
  const { close, setData: setPopupData } = UsePopupContext()
  const [dataInterest, setdataInterest] = useState<{
    value: any
    isValid: boolean
    isTouched: boolean
    errorMsg: string
  }>({
    value: [],
    isValid: false,
    isTouched: false,
    errorMsg: 'Please select your area of expertise',
  })
  const openPopup = (success: boolean) => {
    let headerContent: string = ''
    let content: string = ''
    if (success) {
      headerContent = 'Done!'
      content = 'Profile Updated Successfully'
    } else {
      headerContent = t('userDetails.error-header')
      content = 'Cound not update the account settings'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? thumbsUp : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.white}
                    text={'Got It'}
                    onClick={() => {
                      close()
                      // push('/login')
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button text={'Cancel'} className={styles['cancel-btn']} onClick={close}></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }
  const handleChange = (e: any, key: string) => {
    const localData = e.value
    setData((prev: any) => {
      return {
        ...prev,
        [key]: { isTouched: true, value: localData, ...e },
      }
    })
  }

  const autoCompleteOnChange = (newValue: any, key: string) => {
    if (key === 'phoneCode') {
      const valid = validateInput(countryCodeSchema, newValue?.codeId ? newValue : {})
      setData((prev: any) => {
        return {
          ...prev,
          [key]: {
            value: newValue?.codeId ? newValue : {},
            isValid: valid.isValid,
            isTouched: true,
            errorMsg: valid.errorMsg,
          },
        }
      })
    } else if (key === 'interest') {
      const valid = validateInput(schemaInterests, newValue)
      let details = [...dataInterest.value, newValue[newValue.length - 1]]
      let arr: any[] = []
      for (let index = 0; index < details.length; index++) {
        const element = details[index]
        let c = 0
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].name === element.name) {
            c = 1
            break
          }
        }
        if (c === 0) {
          arr.push(element)
        }
      }
      setdataInterest((prev: any) => {
        return {
          ...prev,
          isValid: valid.isValid,
          errorMsg: valid.errorMsg,
          value: arr,
        }
      })
    } else if (key === 'countryCode') {
      const valid = validateInput(countryCodeSchema, newValue?.name ? newValue : {})
      setData((prev: any) => {
        return {
          ...prev,
          [key]: {
            value: newValue,
            isValid: valid.isValid,
            isTouched: true,
            errorMsg: valid.errorMsg,
          },
        }
      })
    }
  }

  const submitForm = (e: any) => {
    e.preventDefault()
    setData((prev: any) => {
      let blockPrev = { ...prev }
      Object.keys(blockPrev).forEach((each) => {
        blockPrev = { ...blockPrev, [each]: { ...blockPrev[each], isTouched: true } }
      })
      return blockPrev
    })
    if (data.oldPassword.value === '' && data.password.value) {
      setErrorMsg('Please enter the old Password')
      setData((prev: any) => {
        return {
          ...prev,
          oldPassword: { ...prev.oldPassword, isValid: false },
        }
      })
    } else if (data.password.value === '' && data.oldPassword.value) {
      setErrorMsg('Please enter the new Password')
      setData((prev: any) => {
        return {
          ...prev,
          password: { ...prev.password, isValid: false },
        }
      })
    } else {
      if (
        data.firstName.isValid &&
        data.lastName.isValid &&
        data.phoneNo.isValid &&
        data.email.isValid &&
        data.password.isValid &&
        data.oldPassword.isValid &&
        data.phoneCode.isValid &&
        data.countryCode.isValid
      ) {
        fetchRest(
          {
            url: 'editProfile',
            method: 'PUT',
            data: {
              firstName: data.firstName.value,
              lastName: data.lastName.value,
              phoneCodeId: data.phoneCode.value.codeId,
              phone: data.phoneNo.value,
              accessToken: localStorage.getItem('accessToken'),
              previousPassword: data.oldPassword.value ? data.oldPassword.value : undefined,
              proposedPassword: data.password.value ? data.password.value : undefined,
              // countryCode:
              //   data.countryCode.value && data.countryCode.value.countryCode
              //     ? data.countryCode.value.countryCode
              //     : undefined,
            },
            isFullPageLoader: false,
          },
          'editProfile'
        )
          .then(async (res) => {
            if (res && res.data && res.data.status === 'success') {
              openPopup(true)
              setUser({
                ...userDetails,
                firstName: data.firstName.value,
                lastName: data.lastName.value,
                phone: data.phoneNo.value,
                phoneCodeId: data.phoneCode.value.codeId,
                phoneCode: data.phoneCode.value,
                countryCode: data.countryCode.value.countryCode,
              })
            } else {
              openPopup(false)
            }
          })
          .catch((err) => {
            openPopup(false)
          })
      }
    }
  }
  useEffect(() => {
    setData({
      firstName: {
        value: userDetails.firstName,
        isValid: true,
        isTouched: false,
        errorMsg: 'Please enter first name',
      },
      lastName: {
        value: userDetails.lastName,
        isValid: true,
        isTouched: false,
        errorMsg: 'Please enter last name',
      },
      email: {
        value: userDetails.email,
        isValid: true,
        isTouched: false,
        errorMsg: 'Please enter email',
      },
      phoneNo: {
        value: userDetails.phone,
        isValid: true,
        isTouched: false,
        errorMsg: 'Please enter Phone Number',
      },
      phoneCode: {
        value: userDetails.phoneCode,
        isValid: true,
        isTouched: false,
        errorMsg: 'Please select code',
      },
      password: { value: '', isValid: true, isTouched: false, errorMsg: 'Please enter Password' },
      oldPassword: {
        value: '',
        isValid: true,
        isTouched: false,
        errorMsg: 'Please enter Password',
      },
      countryCode: {
        value: phoneCodeArray.find((each) => each.countryCode === userDetails.countryCode),
        // value: { name: '', dialCode: '', codeId: '', countryCode: userDetails.countryCode },
        isValid: true,
        isTouched: false,
        errorMsg: 'Please enter Country',
      },
    })

    if (userDetails.userInterest) {
      let details: any[] = []
      if (userDetails.userInterest && userDetails.userInterest.length) {
        userDetails.userInterest.forEach((each: any) => {
          details.push(each.category != null ? each.category : each.subCategory)
        })
      }
      setdataInterest({
        value: details,
        isValid: true,
        isTouched: true,
        errorMsg: '',
      })
    }
  }, [userDetails])

  useEffect(() => {
    setData((prev) => {
      return {
        ...prev,
        countryCode: {
          ...prev.countryCode,
          value: phoneCodeArray.find((each) => each.countryCode === userDetails.countryCode),
        },
      }
    })
  }, [phoneCodeArray])

  useEffect(() => {
    fetchRest({
      url: '/phoneCode',
      method: 'GET',
    }).then((res) => {
      if (res && res.data && res.data.data) {
        setPhoneCodeArray(res.data.data)
      } else {
        setPhoneCodeArray([])
      }
    })
  }, [])

  return (
    <>
      <NoEventsLoader isLoading={sectionLoading && sectionLoading.editProfile}>
        <form onSubmit={submitForm}>
          <div className={styles.wrapperAccountSetting}>
            <div className={styles.info}>
              <div className={styles.personalInfo}>
                <div>
                  <div className={styles.label}>
                    <img src={personalinfoIcon} alt="person icon" className={styles.divImg} />
                    <div>{t('userDetails.personal-info')}</div>
                  </div>
                  <div className={styles.marginBtm}>
                    <div className={styles.wrapper}>
                      <div className={styles.eachSide}>
                        <div className={styles.heading}>{t('userDetails.fname')}</div>
                        <div>
                          <InputWrapper
                            type={'text'}
                            valueObj={data.firstName}
                            onChange={(e: any) => {
                              handleChange(e, 'firstName')
                            }}
                            schema={firstNameSchema}
                            validateOnChange={true}
                            validateOnBlur={true}
                          />
                        </div>
                      </div>
                      <div className={styles.eachSide}>
                        <div className={styles.heading}>{t('userDetails.email')}</div>
                        <div>
                          <InputWrapper
                            type={'email'}
                            valueObj={data.email}
                            onChange={(e: any) => {
                              handleChange(e, 'email')
                            }}
                            disabled
                            schema={schemaEmail}
                            validateOnChange={true}
                            validateOnBlur={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className={styles.wrapper}>
                      <div className={styles.eachSide}>
                        <div className={styles.heading}>{t('userDetails.lname')}</div>
                        <div>
                          <InputWrapper
                            type={'text'}
                            valueObj={data.lastName}
                            onChange={(e: any) => {
                              handleChange(e, 'lastName')
                            }}
                            schema={lastNameSchema}
                            validateOnChange={true}
                            validateOnBlur={true}
                          />
                        </div>
                      </div>
                      <div className={styles.eachSide}>
                        <div className={styles.heading}>{t('userDetails.phone')}</div>
                        <div className={styles.phone_number_wrapper}>
                          <div className="autocomplete-wrapper phone">
                            <Autocomplete
                              value={data.phoneCode.value}
                              onChange={(event, newValue) => {
                                autoCompleteOnChange(newValue, 'phoneCode')
                              }}
                              style={{
                                maxWidth: '135px',
                                width: '100%',
                                height: '48px',
                                outline: 'none',
                                position: 'relative',
                              }}
                              options={phoneCodeArray as CountryType[]}
                              getOptionLabel={(option) =>
                                option.dialCode && option.countryCode
                                  ? `${option.countryCode}(${option.dialCode})`
                                  : ``
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                            />
                            {data.phoneCode.isTouched && !data.phoneCode.isValid ? (
                              <div className={styles.error}>{data.phoneCode.errorMsg}</div>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className={styles.phone_number}>
                            <InputWrapper
                              type="text"
                              valueObj={data.phoneNo}
                              onChange={(e: any) => {
                                handleChange(e, 'phoneNo')
                              }}
                              schema={phoneNumberSchema}
                              validateOnChange={true}
                              validateOnBlur={true}
                              className={styles.phoneno}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.country_wrapper}>
                      <div className={styles.eachSide}>
                        <div className={styles.heading}>Country</div>
                        <div className="autocomplete-wrapper">
                          <Autocomplete
                            value={data.countryCode.value as any}
                            // value={data.countryCode.value}
                            disabled={true}
                            onChange={(event, newValue) => {
                              autoCompleteOnChange(newValue, 'countryCode')
                            }}
                            options={phoneCodeArray as CountryType[]}
                            getOptionLabel={(option) => (option.name ? `${option.name}` : ``)}
                            renderOption={(option: any) => (
                              <AutocompleteOption option={option.name} />
                            )}
                            // getOptionSelected={(option,value) => value.countryCode === option.countryCode}
                            style={{ width: '100%', position: 'relative' }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select your country"
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className={styles.password}>
            <div className={styles.label}>
              <img src={passwordIcon} alt="password icon" className={styles.divImg} />
              <div>{t('userDetails.password')}</div>
            </div>
            <div className={styles.marginBtm}>
              <div className={styles.fullField}>
                <div className={styles.heading}>{t('userDetails.enterPassword')}</div>
                <div>
                  <InputWrapper
                    inputIcon={eyeIcon}
                    iconClick={() => {
                      setIsPasswordShown(!isPasswordShown)
                    }}
                    type={isPasswordShown ? 'text' : 'password'}
                    valueObj={data.password}
                    onChange={(e: any) => {
                      handleChange(e, 'password')
                    }}
                    schema={data.password.value ? passwordSchema : undefined}
                    validateOnChange={true}
                    validateOnBlur={true}
                    className={styles.paddingRight}
                  />
                </div>
              </div>

              <div className={styles.fullField}>
                <div className={styles.heading}>{t('userDetails.oldPassword')}</div>
                <div>
                  <InputWrapper
                    inputIcon={eyeIcon}
                    iconClick={() => {
                      setIsConfirmPasswordShown(!isConfirmPasswordShown)
                    }}
                    type={isConfirmPasswordShown ? 'text' : 'password'}
                    valueObj={data.oldPassword}
                    onChange={(e: any) => {
                      handleChange(e, 'oldPassword')
                    }}
                    schema={data.oldPassword.value ? passwordSchema : undefined}
                    validateOnChange={true}
                    validateOnBlur={true}
                    className={styles.paddingRight}
                  />
                </div>
              </div>
              <div className={styles.errorMsg}>
                {errormsg != '' && (!data.oldPassword.isValid || !data.password.isValid)
                  ? errormsg
                  : ''}
              </div>
            </div>
          </div> */}
            </div>
            <div className={styles.interestWrapper}>
              <div className={styles.interests}>
                <div className={styles.about}>
                  <div className={styles.headingWrapper}>
                    <div className={styles.labelSkills}>
                      <img src={interests} alt="About icon" className={styles.divImg} />
                      <div>{t('userDetails.interests')}</div>
                    </div>
                    <div className={styles.pointer}>
                      <img
                        src={editIcon}
                        alt="edit"
                        onClick={() => {
                          setinterestPopup(true)
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.multiSelectWrapper}>
                    {userDetails && userDetails.userInterest && userDetails.userInterest.length ? (
                      userDetails.userInterest.map((each: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <div>
                              {/* <MultipleSelect textContent={each.name} key={index} /> */}
                              {each?.category?.name ? (
                                <MultipleSelect textContent={each?.category?.name} />
                              ) : (
                                <></>
                              )}
                              {each?.subCategory?.name ? (
                                <MultipleSelect textContent={each?.subCategory?.name} />
                              ) : (
                                <></>
                              )}
                            </div>
                          </React.Fragment>
                        )
                      })
                    ) : (
                      <EmptySection subText="Complete this section to receive learning recommendations based upon your interests" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.btnWraper}>
              <div className={styles.button}>
                <Button
                  text="Save"
                  type="submit"
                  className={styles.buttonClass}
                  ButtonImg={() => {
                    return (
                      <>
                        {sectionLoading && sectionLoading.editProfile ? (
                          <Loader className={styles.form_loader} size={20} thickness={2} />
                        ) : (
                          <>
                            <img src={tick} alt="tick icon" className={styles.divImg} />
                          </>
                        )}
                      </>
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </NoEventsLoader>

      <DialogWrapper
        onClose={() => {
          setinterestPopup(false)
        }}
        open={interestPopup}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <div className={styles['list-card']}>
          <div className={styles.label}>
            <img src={interests} alt="interests" className={styles.divImg} />
            <div>{t('userDetails.interests')}</div>
          </div>
          <div className={styles.marginBtm}>
            <div className={styles.heading2}>{t('userDetails.enter-interests')}</div>
            <div className={styles.subHeading}>{t('userDetails.remove')}</div>
            <div className={styles.autocompletePosition}>
              <div className="autocomplete-wrapper search">
                <Autocomplete
                  popupIcon={<img src={Search} alt="search" />}
                  multiple
                  onChange={(event, newValue) => {
                    autoCompleteOnChange(newValue, 'interest')
                  }}
                  disableCloseOnSelect={true}
                  renderOption={(option: any) => <AutocompleteOption option={option.name} />}
                  ListboxProps={{
                    style: {
                      maxHeight: '250px',
                    },
                  }}
                  handleHomeEndKeys
                  options={optionData}
                  getOptionLabel={(option) => {
                    return option.name
                  }}
                  style={{ width: '100%', position: 'relative' }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Start typing..." />
                  )}
                />
              </div>
              {dataInterest.isTouched && !dataInterest.isValid ? (
                <div className={styles.error}>{dataInterest.errorMsg}</div>
              ) : (
                ''
              )}
              <div className={styles.multiSelectWrapper}>
                {dataInterest && dataInterest.value && dataInterest.value.length ? (
                  dataInterest.value.map((each: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <div>
                          {each?.categoryId ? (
                            <MultipleSelect
                              textContent={each?.name}
                              closeIconFunc={() => {
                                closeIconFunc(each, 'categoryId')
                              }}
                            />
                          ) : (
                            <></>
                          )}
                          {each?.subCategoryId ? (
                            <MultipleSelect
                              textContent={each?.name}
                              closeIconFunc={() => {
                                closeIconFunc(each, 'subCategoryId')
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      </React.Fragment>
                    )
                  })
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className={styles.btnPopup}>
            <Button
              text="Done"
              className={styles.buttonPopup}
              ButtonImg={() => {
                return (
                  <>
                    <img src={tick} alt="tick icon" className={styles.divImg} />
                  </>
                )
              }}
              onClick={onSubmit}
            />
          </div>
        </div>
      </DialogWrapper>
    </>
  )
}
