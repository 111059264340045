/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import PopUPIcon from '../../assets/images/svg/popup-icon.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { Loader } from '../../common/Loader/Loader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { useFetch } from '../../common/https'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import { schemaEmail } from '../../common/schema/Schema'
import { UsePopupContext } from '../../common/use-popup-context'
import { Button } from '../../common/button/Button'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import styles from '../forget-password-form/ForgetPasswordForm.module.scss'
import { SMSVerification } from '../sms-verification/SMSVerification'

export const ForgetPasswordForm: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [destinationEmail, setDestinationEmail] = useState<string>('')
  const [data, setData] = useState({
    email: { value: '', isValid: false, isTouched: false, errorMsg: 'Please enter your email' },
  })
  const { t } = useTranslation()
  const { fetchRest, sectionLoading } = useFetch({ forgotPassword: false })
  const { push } = useHistory()
  const { close, setData: setPopupData } = UsePopupContext()

  const handleClickOpen = () => {
    setIsOpen(true)
  }
  const handleClose = () => {
    setIsOpen(false)
  }

  const openPopup = (success: boolean, headerMsg?: string, contentMsg?: string, type?: string) => {
    let headerContent: string = ''
    let content: string = ''
    if (success) {
      headerContent = ''
      content = ''
    } else {
      headerContent = headerMsg ? headerMsg : 'Something went wrong'
      content = contentMsg
        ? contentMsg
        : 'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? PopUPIcon : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <></>
              ) : (
                <>
                  {type === 'UserNotFoundException' ? (
                    <Button
                      text="Register"
                      onClick={() => {
                        push('/sign-up')
                        close()
                      }}
                      className={styles.white}
                    />
                  ) : (
                    <></>
                  )}
                  <Button
                    text={'Cancel'}
                    className={styles['cancel-btn']}
                    onClick={() => {
                      close()
                    }}
                  />
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }
  const handleOnChange = (e: any, key: string) => {
    setData((prev) => {
      return {
        ...prev,
        [key]: {
          value: e.value,
          isValid: e.isValid,
          isTouched: true,
          errorMsg: e.errorMsg,
        },
      }
    })
  }
  const submitForm = (e: any) => {
    e.preventDefault()
    setData((prev: any) => {
      let blockPrev = { ...prev }
      Object.keys(blockPrev).forEach((each) => {
        blockPrev = { ...blockPrev, [each]: { ...blockPrev[each], isTouched: true } }
      })
      return blockPrev
    })

    if (data.email.isValid) {
      fetchRest(
        {
          url: '/forgotPassword',
          method: 'POST',
          data: {
            email: data.email.value,
          },
          isFullPageLoader: false,
        },
        'forgotPassword'
      )
        .then((res) => {
          handleClickOpen()
          setDestinationEmail(res?.data?.data?.CodeDeliveryDetails?.Destination)
        })
        .catch((err) => {
          const error = err?.response?.data?.error?.message?.error?.code
          if (error === 'UserNotFoundException') {
            openPopup(
              false,
              'Woops',
              'It looks like this email address isn’t signed up yet!',
              'UserNotFoundException'
            )
          } else {
            openPopup(false)
          }
        })
    }
  }
  return (
    <>
      <NoEventsLoader isLoading={sectionLoading && sectionLoading.forgotPassword}>
        <form onSubmit={submitForm}>
          <div className={styles.innerBox}>
            <div className={styles.heading}>{t('login.reset-pass')}</div>
            <div className={styles.subHeading}>{t('login.enter-email')}</div>
            <div className={styles.forgetPassForm}>
              <div className={styles.field}>
                <div className={styles.formHeading}>{t('userDetails.email')}</div>
                <InputWrapper
                  valueObj={data.email}
                  type="email"
                  onChange={(e: any) => {
                    handleOnChange(e, 'email')
                  }}
                  schema={schemaEmail}
                  validateOnBlur={true}
                  validateOnChange={true}
                />
              </div>
              <Button
                text="Send Request"
                className={styles.btn}
                type="submit"
                ButtonImg={() => {
                  return (
                    <>
                      {sectionLoading && sectionLoading.forgotPassword ? (
                        <Loader className="form_loader" size={20} thickness={2} />
                      ) : (
                        <></>
                      )}
                    </>
                  )
                }}
              />
              <div className={styles.cancel}>
                <Link to="/login" className={styles.link}>
                  {t('common.cancel')}
                </Link>
              </div>
            </div>
          </div>
        </form>
      </NoEventsLoader>
      <>
        <DialogWrapper
          Title={DialogHeader}
          open={isOpen}
          onClose={handleClose}
          isFullScreen={false}
          fullHeightDialog={false}
        >
          <SMSVerification destinationEmail={destinationEmail} email={data.email.value} />
        </DialogWrapper>
      </>
    </>
  )
}
