/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Loader } from '../../common/Loader/Loader'
import { TeamDetails } from '../team-details/TeamDetail'
import styles from './Pending.module.scss'
import Pagination from '@material-ui/lab/Pagination'
import teamIcon from '../../assets/images/svg/TeamIconColor.svg'

export const Pending: React.FC<{
  readonly fetchList: any
  readonly pendingReq: any
  count: number
  totalCount: number
  isLoading: boolean
  changeHandler: any
  paginationState: { perPage: number; page: number }
}> = ({ fetchList, paginationState, pendingReq, totalCount, count, isLoading, changeHandler }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.section}>
        <div className={`${styles.row} ${styles.marginTop} ${styles.heading}`}>
          <div className={styles.marginLeft}> {t('teams.pendingRequests')} </div>
          <div className={styles.count}>&nbsp;{pendingReq.length}</div>
        </div>
      </div>
      <div className={styles.section}>
        {isLoading ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            {pendingReq.length ? (
              pendingReq.map((each: any, key: number) => {
                return (
                  <TeamDetails
                    teamInfo={each}
                    teamMembers={each.teamMembers}
                    inPending={true}
                    key={key}
                    fetchList={fetchList}
                  />
                )
              })
            ) : (
              <div className={styles.empty}>
                <div>
                  <img src={teamIcon} alt="team icon" />
                  <div className={` ${styles.heading} ${styles.width} ${styles.textCenter}`}>
                    You don't have any pending requests or invites yet!
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {count > 10 ? (
          <div className={styles.pagination}>
            <Pagination
              count={Math.ceil(totalCount / paginationState.perPage)}
              onChange={changeHandler}
              page={paginationState.page}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}
