/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUploadContext } from '../../common/use-upload-context'
import { useHistory, useLocation } from 'react-router-dom'
import edit from '../../assets/images/svg/editButton.svg'
import eyeIcon from '../../assets/images/svg/eye.svg'
import passwordIcon from '../../assets/images/svg/password.svg'
import person from '../../assets/images/svg/person.svg'
import personalinfoIcon from '../../assets/images/svg/personalDetailsIcon.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import thumbsUp from '../../assets/images/svg/thumbsUp.svg'
import tick from '../../assets/images/svg/whiteTick.svg'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { AutocompleteOption } from '../../common/autocomplete-option/AutocompleteOption'
import { Button } from '../../common/button/Button'
import { useFetch } from '../../common/https'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import { Loader } from '../../common/Loader/Loader'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import {
  countryCodeSchema,
  firstNameSchema,
  lastNameSchema,
  passwordSchema,
  phoneNumberSchema,
  schemaEmail,
} from '../../common/schema/Schema'
import { UsePopupContext } from '../../common/use-popup-context'
import { validateInput } from '../../common/ValidateFields'
import styles from './RegisterForm.module.scss'

interface CountryType {
  name: string | null
  dialCode: string
  codeId: string
  countryCode: string
}

export const RegisterForm: React.FC = () => {
  const [data, setData] = useState<any>({
    fname: {
      value: '',
      isValid: false,
      isTouched: false,
      errorMsg: 'Please enter your First Name',
    },
    lname: { value: '', isValid: false, isTouched: false, errorMsg: 'Please enter your Last Name' },
    email: { value: '', isValid: false, isTouched: false, errorMsg: 'Please enter your email' },
    phno: {
      value: '',
      isValid: false,
      isTouched: false,
      errorMsg: 'Please enter your phone number',
    },
    country: { value: '', isValid: false, isTouched: false, errorMsg: 'Please select country' },
    password: {
      value: '',
      isValid: false,
      isTouched: false,
      errorMsg: 'Please enter your password',
    },
    repassword: {
      value: '',
      isValid: false,
      isTouched: false,
      errorMsg: 'Please enter your interest',
    },
    phoneCode: {
      value: { name: null, dialCode: '', codeId: '', countryCode: '' },
      isValid: false,
      isTouched: false,
      errorMsg: 'Please select code',
    },
    img: { value: '', isValid: false },
  })
  const { search }: any = useLocation()
  const searchQueryParams = new URLSearchParams(search)
  const courseId = searchQueryParams.get('courseId')
  const description = searchQueryParams.get('description')
  const zohoRequestId = searchQueryParams.get('zohoRequestId')

  const [phoneCodeArray, setPhoneCodeArray] = useState<ReadonlyArray<any>>([])
  const { fetchRest, sectionLoading } = useFetch({ registerForm: false })
  const { t } = useTranslation()
  const { push } = useHistory()
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false)
  const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState<boolean>(false)
  const [flag, setFlag] = useState(0)
  const [supportedCountry, setSupportedCountry] = useState([])
  const [countrySupport, setCountrySupport] = useState(false)
  const { close, setData: setPopupData } = UsePopupContext()
  const { uploadFile } = useUploadContext()
  const openPopup = (success: boolean, headerMsg?: string, contentMsg?: string) => {
    let headerContent: string = ''
    let content: string = ''
    if (success) {
      headerContent = headerMsg ? headerMsg : 'You are almost there'
      content = contentMsg ? contentMsg : 'Please check your email to verify your account'
    } else {
      headerContent = headerMsg ? headerMsg : t('userDetails.error-header')
      content = contentMsg ? contentMsg : 'Cound not complete the registration process'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? thumbsUp : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.white}
                    text={'Got It'}
                    onClick={() => {
                      close()
                      push('/login')
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button text={'Cancel'} className={styles['cancel-btn']} onClick={close}></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  useEffect(() => {
    const firstName = searchQueryParams.get('firstName')
    const lastName = searchQueryParams.get('lastName')
    const email = searchQueryParams.get('email')
    const phoneCode = searchQueryParams.get('phoneCodeId')
    const phone = searchQueryParams.get('phone')

    if(firstName && lastName && email && phoneCode && phone) {
      setData((prev: any) => {
        return {
          ...prev,
          fname: {
            value: firstName,
            isValid: true,
            isTouched: true,
            errorMsg: prev.fname.errorMsg,
          },
          lname: {
            value: lastName,
            isValid: true,
            isTouched: true,
            errorMsg: prev.lname.errorMsg,
          },
          email: {
            value: email?.replaceAll(' ', '+'),
            isValid: true,
            isTouched: true,
            errorMsg: prev.email.errorMsg,
          },
          phno: {
            value: phone,
            isValid: true,
            isTouched: true,
            errorMsg: prev.phno.errorMsg,
          },
          phoneCode: {
            value: phoneCodeArray.find((res) => res.codeId === phoneCode),
            isValid: true,
            isTouched: true,
            errorMsg: prev.phoneCode.errorMsg,
          },
        }
      })
    }
  }, [search, phoneCodeArray])

  const autoCompleteOnChange = (newValue: any, key: string) => {
    if (key === 'country') {
      const valid = validateInput(countryCodeSchema, newValue?.name ? newValue : {})
      setData((prev: any) => {
        return {
          ...prev,
          [key]: {
            value: newValue,
            isValid: valid.isValid,
            isTouched: true,
            errorMsg: valid.errorMsg,
          },
        }
      })
      setCountrySupport(supportedCountry.some((e) => e === newValue.countryCode))
    } else {
      var valid = validateInput(countryCodeSchema, newValue?.codeId ? newValue : {})
      setData((prev: any) => {
        return {
          ...prev,
          [key]: {
            value: newValue?.codeId ? newValue : {},
            isValid: valid.isValid,
            isTouched: true,
            errorMsg: valid.errorMsg,
          },
        }
      })
    }
  }
  const handleChange = (e: any, key: string) => {
    const localData = e.value

    setData((prev: any) => {
      return {
        ...prev,
        [key]: { value: localData, ...e },
      }
    })
  }
  const imageHandler = (e: any) => {
    if (
      e?.target?.files?.[0] &&
      e?.target?.files?.[0]?.type.indexOf('image') !== -1 &&
      e?.target?.files?.[0]?.size / (1024 * 1024) <= 3
    ) {
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = () => {
        if (reader.readyState === 2) {
          setData((prev: any) => {
            return {
              ...prev,
              img: { value: reader.result, isValid: true },
            }
          })
        }
      }
    }
  }
  const submitForm = (e: any) => {
    e.preventDefault()
    console.log(data)
    setData((prev: any) => {
      let blockPrev = { ...prev }
      Object.keys(blockPrev).forEach((each) => {
        blockPrev = { ...blockPrev, [each]: { ...blockPrev[each], isTouched: true } }
      })
      return blockPrev
    })

    if (data.password.value !== data.repassword.value) {
      setFlag(1)
    } else {
      setFlag(0)
      if (
        data.fname.isValid &&
        data.lname.isValid &&
        data.phno.isValid &&
        data.email.isValid &&
        data.repassword.isValid &&
        data.password.isValid &&
        data.phoneCode.isValid &&
        data.country.isValid
      ) {
        const picture = data.img.value
        const body: any = {
          firstName: data.fname.value,
          lastName: data.lname.value,
          email: data.email.value,
          phoneCodeId: data.phoneCode.value.codeId,
          phone: data.phno.value,
          password: data.password.value,
          countryCode: data.country.value.countryCode,
          confirmedPassword: data.repassword.value,
          courseId: courseId !== undefined ? courseId : undefined,
          courseDescription: description !== undefined ? description : undefined,
          zohoRequestId: zohoRequestId !== undefined ? zohoRequestId : undefined,
          // userImageUrl: userDetails.userImageUrl ? userDetails.userImageUrl : undefined,
        }

        const multipartFormData = new FormData()
        Object.keys(body).forEach((key: string) => {
          multipartFormData.append(key, body[key])
        })
        if (picture) {
          multipartFormData.append('picture', picture)
        }
        fetchRest(
          {
            url: '/registerUser',
            headers: {
              'content-type': 'multipart/form-data',
            },
            method: 'POST',
            data: multipartFormData,
            isFullPageLoader: false,
          },
          'registerForm'
        )
          .then(async (res) => {
            if (res && res.data && res.data.status === 'success') {
              // const file = picture as any
              // const contentType = file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
              // const fileBuffer = Buffer.from(
              //   file.replace(/^data:image\/[a-zA-Z+]+;base64,/, ""),
              //   "base64"
              // )
              // const uploadResult: any = await uploadFile({
              //   S3_BUCKET: process.env.REACT_APP_USER_ASSET_S3 || 'ontelo-dev-user-assets',
              //   file: fileBuffer,
              //   key: `${res.data.userId}/profile_picture`,
              //   ACL: 'public-read',
              //   contentType
              // })
              openPopup(true)
            } else {
              openPopup(false)
            }
          })
          .catch((err) => {
            const error = err?.response?.data?.error?.error?.code
            if (error === 'UsernameExistsException') {
              openPopup(
                false,
                'User already exists',
                'it looks like you already have a profile, try resetting your password to access your account'
              )
            } else {
              openPopup(false)
            }
          })
      }
    }
  }

  useEffect(() => {
    fetchRest({
      url: '/phoneCode',
      method: 'GET',
    }).then((res: any) => {
      if (res && res.data && res.data.data) {
        setPhoneCodeArray(res.data.data)
      } else {
        setPhoneCodeArray([])
      }
    })

    fetchRest({ url: '/supportedCountries', method: 'GET' }).then((res) => {
      if (res && res.data && res.data.data) {
        setSupportedCountry(res.data.data)
      }
    })
  }, [])

  const popUp = () => {
    setData((prev: any) => {
      let blockPrev = { ...prev }
      Object.keys(blockPrev).forEach((each) => {
        blockPrev = { ...blockPrev, [each]: { ...blockPrev[each], isTouched: true } }
      })
      return blockPrev
    })
    if (data.password.value !== data.repassword.value) {
      setFlag(1)
    } else {
      setFlag(0)
      if (
        data.fname.isValid &&
        data.lname.isValid &&
        data.phno.isValid &&
        data.email.isValid &&
        data.repassword.isValid &&
        data.password.isValid &&
        data.phoneCode.isValid &&
        data.country.isValid
      ) {
        let headerContent = 'Online payments not available'
        let content =
          'Country selected doesnt support online payments. Payments will be handled through offline mode.'

        setPopupData(
          <AlertPopupWrapper
            title={headerContent}
            Buttons={() => {
              return (
                <>
                  <Button
                    className={styles.green}
                    text={'Got It'}
                    onClick={(e) => {
                      close()
                      submitForm(e)
                    }}
                  ></Button>
                  <Button
                    text={'Cancel'}
                    className={styles['cancel-btn']}
                    onClick={() => {
                      close()
                    }}
                  ></Button>
                </>
              )
            }}
            Content={() => {
              return (
                <div>
                  <p>{content}</p>
                </div>
              )
            }}
          />
        )
      }
    }
  }

  return (
    <>
      <NoEventsLoader isLoading={sectionLoading && sectionLoading.registerForm}>
        <div>
          <div className={styles.outerDiv}>
            <div className={styles.centerDiv}>
              <img
                src={data?.img?.value ? data.img.value : person}
                alt="id"
                id="person"
                className={styles.imageProfile}
              />
              <label htmlFor="image" className={styles.imageEdit}>
                <img src={edit} alt="signin" />
              </label>
            </div>
            <input
              type="file"
              accept="image/*"
              name="image"
              id="image"
              style={{ display: 'none' }}
              onChange={imageHandler}
            />
          </div>
          <div className={styles.formName}>
            <div className={styles.label}>
              <img src={personalinfoIcon} alt="person icon" className={styles.divImg} />
              <div>{t('userDetails.personal-info')}</div>
            </div>
            <div className={styles.marginBtm}>
              <div className={styles.wrapper}>
                <div className={styles.eachSide}>
                  <div className={styles.heading}>{t('userDetails.fname')}</div>
                  <div>
                    <InputWrapper
                      type={'text'}
                      valueObj={data.fname}
                      onChange={(e: any) => {
                        handleChange(e, 'fname')
                      }}
                      schema={firstNameSchema}
                      validateOnChange={true}
                      validateOnBlur={true}
                    />
                    {data.fname.isTouched && !data.fname.isValid ? (
                        <div className={styles.error}>{data.fname.errorMsg}</div>
                      ) : (
                        ''
                      )}
                  </div>
                </div>
                <div className={styles.eachSide}>
                  <div className={styles.heading}>{t('userDetails.lname')}</div>
                  <div>
                    <InputWrapper
                      type={'text'}
                      valueObj={data.lname}
                      onChange={(e: any) => {
                        handleChange(e, 'lname')
                      }}
                      schema={lastNameSchema}
                      validateOnChange={true}
                      validateOnBlur={true}
                    />
                    {data.lname.isTouched && !data.lname.isValid ? (
                        <div className={styles.error}>{data.lname.errorMsg}</div>
                      ) : (
                        ''
                      )}
                  </div>
                </div>
              </div>

              <div className={styles.wrapper}>
                <div className={styles.eachSide}>
                  <div className={styles.heading}>{t('userDetails.email')}</div>
                  <div>
                    <InputWrapper
                      type={'email'}
                      valueObj={data.email}
                      onChange={(e: any) => {
                        handleChange(e, 'email')
                      }}
                      schema={schemaEmail}
                      validateOnChange={true}
                      validateOnBlur={true}
                    />
                    {data.email.isTouched && !data.email.isValid ? (
                        <div className={styles.error}>{data.email.errorMsg}</div>
                      ) : (
                        ''
                      )}
                  </div>
                </div>
                <div className={styles.eachSide}>
                  <div className={styles.heading}>{t('userDetails.phone')}</div>
                  <div className={styles.phone_number_wrapper}>
                    <div className="autocomplete-wrapper phone">
                      <Autocomplete
                        value={data.phoneCode.value}
                        onChange={(event, newValue) => {
                          autoCompleteOnChange(newValue, 'phoneCode')
                        }}
                        style={{
                          maxWidth: '135px',
                          width: '100%',
                          height: '48px',
                          outline: 'none',
                          position: 'relative',
                        }}
                        options={phoneCodeArray as CountryType[]}
                        getOptionLabel={(option) =>
                          option.dialCode && option.countryCode
                            ? `${option.countryCode}(${option.dialCode})`
                            : ``
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                      {data.phoneCode.isTouched && !data.phoneCode.isValid ? (
                        <div className={styles.error}>{data.phoneCode.errorMsg}</div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className={styles.phone_number}>
                      <InputWrapper
                        type={'number'}
                        valueObj={data.phno}
                        onChange={(e: any) => {
                          handleChange(e, 'phno')
                        }}
                        schema={phoneNumberSchema}
                        validateOnChange={true}
                        validateOnBlur={true}
                        className={styles.phoneno}
                      />
                      {data.phno.isTouched && !data.phno.isValid ? (
                      <div className={styles.error}>{data.phno.errorMsg}</div>
                      ) : (
                      ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className={styles.label}>
                <div>Country</div>
              </div>
              <div className={styles.country}>
                <div className="autocomplete-wrapper">
                  <Autocomplete
                    value={data.country.value}
                    onChange={(event, newValue) => {
                      autoCompleteOnChange(newValue, 'country')
                    }}
                    options={phoneCodeArray as CountryType[]}
                    getOptionLabel={(option) => (option.name ? `${option.name}` : ``)}
                    renderOption={(option: any) => <AutocompleteOption option={option.name} />}
                    style={{ maxWidth: '715px', width: '100%', position: 'relative' }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" placeholder="Select your country" />
                    )}
                  />
                  {data.country.isTouched && !data.country.isValid ? (
                    <div className={styles.error}>{data.country.errorMsg}</div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>

            <div className={styles.label}>
              <img src={passwordIcon} alt="password icon" className={styles.divImg} />
              <div>{t('userDetails.password')}</div>
            </div>
            <div className={styles.marginBtm}>
              <div className={styles.fullField}>
                <div className={styles.heading}>{t('userDetails.enter-password')}</div>
                <div>
                  <InputWrapper
                    inputIcon={eyeIcon}
                    iconClick={() => {
                      setIsPasswordShown(!isPasswordShown)
                    }}
                    type={isPasswordShown ? 'text' : 'password'}
                    valueObj={data.password}
                    onChange={(e: any) => {
                      handleChange(e, 'password')
                    }}
                    schema={passwordSchema}
                    validateOnChange={true}
                    validateOnBlur={true}
                    className={styles.paddingRight}
                  />
                </div>
              </div>

              <div className={styles.fullField}>
                <div className={styles.heading}>{t('userDetails.repeat-password')}</div>
                <div>
                  <InputWrapper
                    inputIcon={eyeIcon}
                    iconClick={() => {
                      setIsConfirmPasswordShown(!isConfirmPasswordShown)
                    }}
                    type={isConfirmPasswordShown ? 'text' : 'password'}
                    valueObj={data.repassword}
                    onChange={(e: any) => {
                      handleChange(e, 'repassword')
                    }}
                    // schema={passwordConfirmSchema}
                    // validateOnChange={true}
                    // validateOnBlur={true}
                    className={styles.paddingRight}
                  />
                </div>
              </div>
              {flag === 1 && data.repassword.isValid ? (
                <div className={styles.errorPassword}>{t('connectPopup.passwordsDontMatch')}</div>
              ) : (
                <></>
              )}
            </div>
            <Button
              text="Register"
              type="submit"
              className={
                flag && data.repassword.isValid
                  ? `${styles.buttons}`
                  : `${styles.buttons} ${styles.btnMrgn}`
              }
              onClick={countrySupport ? submitForm : popUp}
              ButtonImg={() => {
                return (
                  <>
                    {sectionLoading && sectionLoading.registerForm ? (
                      <Loader className="form_loader" size={20} thickness={2} />
                    ) : (
                      <>
                        <img src={tick} alt="register icon" className={styles.divImg} />
                      </>
                    )}
                  </>
                )
              }}
            />
          </div>
        </div>
      </NoEventsLoader>
    </>
  )
}
