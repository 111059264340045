/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useEffect, useState } from 'react'
import { useFetch } from '../https'
import { UnknownObject } from '../utils'

export const UserDetailsContext = createContext({
  userDetails: { isLogged: false } as UnknownObject,
  setUser: (value: UnknownObject) => {
    return 0 as number
  },
  resetUser: () => {
    return 0 as number
  },
  fetchUserDetails: (obj: {
    accessToken: string
    identityId: string | null
    idToken: string | null
  }) => {
    return 0 as number
  },
  countries: [] as any,
  notificationCount: 0 as number,
  getNotificationCount: () => {},
})

export const UserDetailsProvider: React.FC<{}> = ({ children }) => {
  const [userDetails, setUserDetails] = useState<UnknownObject>({ isLogged: false })
  const { fetchRest } = useFetch()
  const [countries, setCountries] = useState<any>([])
  const [notificationCount, setNotificationCount] = useState(0)

  const fetchUserDetails = (obj: {
    readonly accessToken: string
    readonly identityId: string | null
    readonly idToken: string | null
  }) => {
    fetchRest({ url: '/getUserDetails ', method: 'PUT', data: obj, isFullPageLoader: true })
      .then((res) => {
        if (res && res.data && res.data.userData) {
          // --remove the existing credentials
          localStorage.removeItem('accessKeyId')
          localStorage.removeItem('secretAccessKey')
          localStorage.removeItem('sessionToken')
          localStorage.removeItem('sessionExpiration')
          // --adding the new credentials
          localStorage.setItem('accessKeyId', res.data.identity.data.Credentials.AccessKeyId)
          localStorage.setItem('secretAccessKey', res.data.identity.data.Credentials.SecretKey)
          localStorage.setItem('sessionToken', res.data.identity.data.Credentials.SessionToken)
          localStorage.setItem('sessionExpiration', res.data.identity.data.Credentials.Expiration)
          setUser({ ...res.data.userData, isLoader: true })
        } else {
          setUser({})
        }
      })
      .catch()
    return 0
  }

  const getNotificationCount = () => {
    fetchRest({
      url: `/unReadNotificationCount`,
      method: 'GET',
      isFullPageLoader: true,
    })
      .then((res: any) => {
        if (res && res.data) {
          setNotificationCount(res.data.count)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getSupportedCountries = () => {
    fetchRest({
      url: `/supportedCountries`,
      method: 'GET',
    })
      .then((res) => {
        setCountries(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const setUser = (value: any) => {
    if (value.userId) {
      setUserDetails({ ...value, isLogged: true })
    } else {
      setUserDetails({ isLogged: false })
    }
    return 0
  }

  const resetUser = () => {
    setUserDetails({ isLogged: false })
    return 0
  }

  const setDataFunction = {
    userDetails,
    setUser,
    resetUser,
    fetchUserDetails,
    countries,
    notificationCount,
    getNotificationCount,
  }

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken')
    const identityId = localStorage.getItem('identityId')
    const idToken = localStorage.getItem('idToken')
    if (accessToken) {
      setUserDetails({ isLogged: true })
      fetchUserDetails({ accessToken, identityId, idToken })
      getNotificationCount()
    }
    getSupportedCountries()
  }, [])

  return (
    <UserDetailsContext.Provider value={setDataFunction}>{children}</UserDetailsContext.Provider>
  )
}
