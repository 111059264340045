/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useEffect, useState } from 'react'
import { listChannelMessages } from '../chat-window/api/ChimeAPI'
import { NameInitials } from '../name-initials/NameInitials'
import styles from './ChatList.module.scss'
import { MessagingService } from '../chat-window/services/MessagingService'
import { useChatChannelState } from '../chat-window/providers/ChatMessagesProvider'
const ChatMessagingServiceContext = createContext(MessagingService)
export const ChatList: React.FC<{
  readonly firstName: string
  readonly lastName?: string
  readonly channeldata: any
  readonly member: any
  readonly onClickChatList: any
  readonly unread: boolean
  readonly channelImage?: string
  readonly activeChannel?: any
  readonly channelStatus?: string
  readonly unreadInfo?: any
}> = ({
  lastName = 'A',
  firstName,
  channeldata,
  member,
  onClickChatList,
  unread,
  channelImage = undefined,
  activeChannel,
  channelStatus = undefined,
  unreadInfo = undefined,
}) => {
  const [clicked, setIsClicked] = useState<boolean>(false)
  const [lastMessage, setLastMessage] = useState<string>('')
  async function getChannelMessages() {
    const res = await listChannelMessages(channeldata.ChannelArn, member.userId)
    setLastMessage(res?.Messages[res.Messages?.length - 1]?.Content)
  }
  useEffect(() => {
    getChannelMessages()
  }, [])

  useEffect(() => {
    if (unread) {
      getChannelMessages()
    }
  }, [unread])

  return (
    <>
      <div
        className={styles.chat}
        onClick={() => {
          setIsClicked(!clicked)
          onClickChatList(channeldata.ChannelArn)
        }}
      >
        <div>
          <div className={`${styles.row} `}>
            <div className={styles.icon}>
              {channelImage ? (
                <img src={channelImage} className={styles.img} alt="channel icon" />
              ) : (
                <NameInitials firstName={firstName} lastName={lastName} className={styles.radius} />
              )}
              <div
                className={`${styles.activitystatus} ${
                  channelStatus && channelStatus === 'ONLINE'
                    ? styles.onlineStatus
                    : styles.offlineStatus
                }`}
              ></div>
            </div>
          </div>
        </div>
        <div className={styles.name}>
          <div>
            <div className={styles.width}>
              <div className={styles.fullName}>
                {firstName} . {lastName.charAt(0)}
              </div>
            </div>
          </div>
          <div className={styles.chatText}>{lastMessage}</div>
        </div>
        <div className={styles.chatWrapper}>
          {unread ||
          (channeldata.channelMetaInfo &&
            Object.keys(unreadInfo).length &&
            unreadInfo[channeldata.channelMetaInfo.userId] &&
            channeldata.ChannelArn !== activeChannel.ChannelArn) ? (
            <div className={styles.count}>*</div>
          ) : null}
        </div>
      </div>
    </>
  )
}
