/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useStripe } from '@stripe/react-stripe-js'
import React, { useEffect, useState } from 'react'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { AutocompleteOption } from '../../common/autocomplete-option/AutocompleteOption'
import { Button } from '../../common/button/Button'
import { useFetch } from '../../common/https'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import { UsePopupContext } from '../../common/use-popup-context'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import styles from './BankDetailsForm.module.scss'
import PopUPIcon from '../../assets/images/svg/popup-icon.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import cost from '../../assets/images/svg/HourRate.svg'
import { Loader } from '../../common/Loader/Loader'
import tick from '../../assets/images/svg/whiteTick.svg'
import { getUserTokens } from '../../common/utils'
import {
  accountRoutingNameSchema,
  accountNumberSchema,
  accountHolderNameSchema,
} from '../../common/schema/Schema'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'

export const BankDetailsForm: React.FC<{
  readonly popupClose?: any
}> = ({ popupClose }) => {
  const stripe = useStripe()
  const { fetchUserDetails, userDetails } = useUserDetailsContext()

  const [bankDetails, setBankDetails] = useState({
    country: {
      value: '',
      isTouched: false,
      isValid: false,
      errorMsg: 'Please select a country',
    },
    currency: {
      value: '',
      isTouched: false,
      isValid: false,
      errorMsg: '',
    },
    account_number: {
      value: '',
      isTouched: false,
      isValid: false,
      errorMsg: 'Please enter account number',
    },
    account_holder_name: {
      value: '',
      isTouched: false,
      isValid: false,
      errorMsg: 'Please enter account holder name',
    },
    account_holder_type: {
      value: '',
      isTouched: false,
      isValid: false,
      errorMsg: 'Please select account holder type',
    },
    routing_number: {
      value: '',
      isTouched: false,
      isValid: false,
      errorMsg: 'Please enter routing number',
    },
  })

  const { fetchRest, sectionLoading } = useFetch({ loader: false })

  const { close, setData: setPopupData } = UsePopupContext()

  useEffect(() => {
    fetchRest(
      {
        url: '/countryList',
        method: 'GET',
      },
      'loader'
    ).then((res: any) => {
      let currencyCode = ''
      res.data.data.forEach((each: any) => {
        if (each.id === userDetails.countryCode) {
          currencyCode = each.default_currency
        }
      })
      setBankDetails((prev: any) => {
        return {
          ...prev,
          currency: {
            ...prev.currency,
            value: currencyCode,
            isValid: true,
          },
          country: {
            ...prev.currency,
            value: userDetails.countryCode,
            isValid: true,
          },
        }
      })
    })
  }, [])

  const openPopup = (success: boolean, message?: string) => {
    let content: string = ''
    let headerContent: string = ''
    if (success && message === 'delete') {
      headerContent = 'Success'
      content = 'Your Bank account has been deleted Successfully!'
    } else if (success && message === 'add') {
      headerContent = 'Success'
      content = 'Your Bank account has been added Successfully!'
    } else {
      headerContent = 'Something went wrong'
      content = message
        ? message
        : 'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? PopUPIcon : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.white}
                    text={'Got It'}
                    onClick={() => {
                      close()
                      popupClose()
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button
                    text={'Go back!'}
                    onClick={(e) => {
                      close()
                    }}
                    className={styles.white}
                  ></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  const addBankAccount = (token: string) => {
    fetchRest(
      {
        url: '/addBankAccount',
        method: 'POST',
        data: {
          accountId: userDetails.stripeAccountId,
          stripeAccount: 'UK',
          externalToken: token,
        },
      },
      'loader'
    )
      .then((res: any) => {
        const { accessToken, identityId, idToken } = getUserTokens()
        if (accessToken) {
          fetchUserDetails({ accessToken, identityId, idToken })
        }
        openPopup(true, 'add')
      })
      .catch((err: any) => {
        openPopup(false)
      })
  }

  const submit = (e: any) => {
    e.preventDefault()

    if (
      bankDetails.routing_number.isValid &&
      bankDetails.account_number.isValid &&
      bankDetails.account_holder_type.isValid &&
      bankDetails.account_holder_name.isValid
    ) {
      var bankAccountParams = {
        country: bankDetails.country.value,
        currency: bankDetails.currency.value,
        account_number: bankDetails.account_number.value,
        account_holder_name: bankDetails.account_holder_name.value,
        account_holder_type: bankDetails.account_holder_type.value,
        routing_number: bankDetails.routing_number.value,
      }

      stripe
        ?.createToken('bank_account', bankAccountParams)
        .then((res: any) => {
          if (res && res.token) {
            addBankAccount(res.token.id)
          } else {
            openPopup(false, res.error.message)
          }
        })
        .catch((err: any) => {
          console.log(err)
        })
    } else {
      setBankDetails((prev: any) => {
        return {
          ...prev,
          account_number: { ...prev.account_number, isTouched: true },
          account_holder_name: { ...prev.account_holder_name, isTouched: true },
          account_holder_type: { ...prev.account_holder_type, isTouched: true },
          routing_number: { ...prev.routing_number, isTouched: true },
        }
      })
    }
  }

  return (
    <NoEventsLoader isLoading={sectionLoading && sectionLoading.loader}>
      <div className={styles.bankDetails}>
        <div className={styles.labelCost}>
          <img src={cost} alt="costs" className={styles.divImg} />
          <div>Bank Details</div>
        </div>
        <div className={styles['form-row']}>
          <div className={styles.label}>Country</div>
          <div className="autocomplete-wrapper">
            <Autocomplete
              value={bankDetails.country.value}
              disabled={true}
              handleHomeEndKeys
              options={[]}
              style={{ maxWidth: '715px', width: '100%', position: 'relative' }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" placeholder="Select your country" />
              )}
            />
          </div>
        </div>
        <div className={styles['form-row']}>
          <div className={styles.label}>Currency</div>
          <div className="autocomplete-wrapper">
            <Autocomplete
              value={bankDetails.currency.value}
              disabled={true}
              handleHomeEndKeys
              options={[]}
              style={{ maxWidth: '715px', width: '100%', position: 'relative' }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" placeholder="Select currency" />
              )}
            />
          </div>
        </div>
        <div className={styles['form-row']}>
          <div className={styles.label}>Routing Number</div>
          <InputWrapper
            onChange={(_e: any) => {
              if (_e.value) {
                setBankDetails((prev: any) => {
                  return { ...prev, routing_number: { ..._e } }
                })
              }
            }}
            schema={accountRoutingNameSchema}
            valueObj={bankDetails.routing_number}
            placeholder="Routing number"
          />
        </div>
        <div className={styles['form-row']}>
          <div className={styles.label}>Account Number</div>
          <InputWrapper
            onChange={(_e: any) => {
              if (_e.value) {
                setBankDetails((prev: any) => {
                  return { ...prev, account_number: { ..._e } }
                })
              }
            }}
            schema={accountNumberSchema}
            valueObj={bankDetails.account_number}
            placeholder="Account number"
          />
        </div>
        <div className={styles['form-row']}>
          <div className={styles.label}>Account Holder Name</div>
          <InputWrapper
            onChange={(_e: any) => {
              if (_e.value) {
                setBankDetails((prev: any) => {
                  return { ...prev, account_holder_name: { ..._e } }
                })
              }
            }}
            schema={accountHolderNameSchema}
            valueObj={bankDetails.account_holder_name}
            placeholder="Account holder name"
          />
        </div>
        <div className={styles['form-row']}>
          <div className={styles.label}>Account Holder Type</div>
          <div className={`${styles.holderType} autocomplete-wrapper`}>
            <Autocomplete
              value={bankDetails.account_holder_type.value}
              onChange={(event, newValue) => {
                setBankDetails((prev: any) => {
                  return {
                    ...prev,
                    account_holder_type: {
                      ...prev.account_holder_type,
                      value: newValue,
                      isValid: true,
                    },
                  }
                })
              }}
              handleHomeEndKeys
              options={['Individual', 'Business']}
              getOptionLabel={(option) => {
                return option
              }}
              renderOption={(option: any) => <AutocompleteOption option={option} />}
              ListboxProps={{
                style: {
                  maxHeight: '147px',
                },
              }}
              style={{ maxWidth: '715px', width: '100%', position: 'relative' }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" placeholder="Select account type" />
              )}
            />
            {bankDetails.account_holder_type.isValid === false &&
            bankDetails.account_holder_type.isTouched === true ? (
              <div className={styles.error}>{bankDetails.account_holder_type.errorMsg}</div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <Button
          text={'Submit'}
          ButtonImg={() => {
            return (
              <>
                {sectionLoading && sectionLoading.loader ? (
                  <Loader className="form_loader" size={20} thickness={2} />
                ) : (
                  <>
                    <img src={tick} alt="tick icon" className={styles.divImg} />
                  </>
                )}
              </>
            )
          }}
          onClick={submit}
          className={styles.submit}
        />
      </div>
    </NoEventsLoader>
  )
}
