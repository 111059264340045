import React from 'react'
import logo from '../../assets/images/svg/logo.svg'
import { LoginWrapper } from '../../common/login-wrapper/LoginWrapper'
import { LoginBox } from '../../components/login-box/LoginBox'
import { LoginForm } from '../../components/login-form/LoginForm'
import styles from '../login/Login.module.scss'

export const Login: React.FC = () => {
  return (
    <>
      <LoginWrapper>
        <div className={styles.boxWrapper}>
          <div className={styles.header}>
            <img src={logo} alt="logo" />
          </div>
          <LoginBox>
            <LoginForm />
          </LoginBox>
        </div>
      </LoginWrapper>
    </>
  )
}
