import React, { createContext, useContext, useEffect, useState } from 'react'
import { useFetch } from '../https'
import { useUserDetailsContext } from '../use-user-details-context';
import { UnknownObject } from '../utils'

export const UserUnreadInfoContext = createContext({
    unreadInfo: {} as UnknownObject,
    setUnreadInfoVal(id: string) {
        return 0 as number
    },
    removeUnreadInfoVal(id: string) {
        return 0 as number
    }
});

export const UserUnreadInfoProvider: React.FC<{}> = ({ children }) => {
    const { userDetails } = useUserDetailsContext();
    const [unreadInfo, setUnreadInfo] = useState<UnknownObject>({});
    const [newVal, setNewVal] = useState<string | undefined>(undefined);
    const [removedVal, setRemovedVal] = useState<string | undefined>(undefined);
    const { fetchRest } = useFetch()

    const setUnreadInfoVal = (id: string) => {
        setNewVal(id);
        return 0;
    }

    const removeUnreadInfoVal = (id: string) => {
        setRemovedVal(id)
        
        return 0;
    }

    const getAllTheUnreadInfo = () => {
        fetchRest({ url: '/getAllTheUnreadInfo ', method: 'GET', isFullPageLoader: false })
          .then((res) => {
            if (res && res.data && res.data.data) {
              const obj: UnknownObject = {};
              res.data.data.forEach((ele: UnknownObject) => {
                obj[ele.senderUserId] = true;
              });
              setUnreadInfo({ ...obj });
            }
          })
          .catch();
      }

    useEffect(() => {
        if(userDetails.isLogged && Boolean(userDetails.userId)) {
            getAllTheUnreadInfo();
        }
    }, [userDetails]);

    useEffect(() => {
        if(newVal) {
            const obj: UnknownObject = { ...unreadInfo }
            obj[newVal] = true;
            setUnreadInfo({ ...obj });
            setNewVal(undefined);
        }
    }, [newVal]);

    useEffect(() => {
        if(removedVal) {
            const obj: UnknownObject = { ...unreadInfo }
            delete obj[removedVal];
            setUnreadInfo({ ...obj });
            setRemovedVal(undefined);
        }
    }, [removedVal]);

    const setDataFunction = {
        unreadInfo,
        setUnreadInfoVal,
        removeUnreadInfoVal
    }
    return (
        <UserUnreadInfoContext.Provider value={setDataFunction}>
            {children}
        </UserUnreadInfoContext.Provider>
    )
};

export const useUserUnreadInfoContext = () => {
    const context = useContext(UserUnreadInfoContext);

    if (!context) {
        throw new Error(
            'useUserUnreadInfoContext must be implemented after UserDetailsProvider'
        );
    }

    return context;
};