/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import calendar from '../../assets/images/svg/calendar.svg'
import filter from '../../assets/images/svg/filter.svg'
import stripeIcon from '../../assets/images/svg/stripe.svg'
import cost from '../../assets/images/svg/HourRate.svg'
import time from '../../assets/images/svg/sandClock.svg'
import tick from '../../assets/images/svg/whiteTick.svg'
import { DropDown, ListArrayItemProps } from '../../common/drop-down/DropDown'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import { Button } from '../../common/button/Button'
import { TabItem } from '../tab-item/TabItem'
import { TabsWrapper, TabWrapperProps } from '../tabs-wrapper/TabsWrapper'
import styles from './UserProfileFinancialSetting.module.scss'
import { EmptySection } from '../../common/empty-section/EmptySection'
import { useTranslation } from 'react-i18next'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { AddNew } from '../add-new-payment-method/AddNew'
import deleteIcon from '../../assets/images/svg/delete.svg'
import { ConfirmationOtp } from '../confirmation-otp/ConfirmationOtp'
import { useFetch } from '../../common/https'
import PopUPIcon from '../../assets/images/svg/popup-icon.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { Loader } from '../../common/Loader/Loader'
import { UsePopupContext } from '../../common/use-popup-context'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import Moment from 'react-moment'
import { NameInitials } from '../name-initials/NameInitials'
import { BankDetailsForm } from '../bank-details-form/BankDetailsForm'
import { ElementsWrapper } from '../../common/elements-wrapper/ElementsWrapper'
import { FormControlLabel, Radio, RadioGroup, Tooltip } from '@material-ui/core'

export const PaymentMethods: React.FC<{ readonly withdrawal?: boolean }> = () => {
  const { userDetails } = useUserDetailsContext()
  const [addNewMethod, setAddNewMethod] = useState<boolean>(false)

  const { fetchRest, sectionLoading } = useFetch({ paymentListApi: false, delete: false })
  const [paymentList, setPaymentList] = useState({
    data: [],
  })
  const { close, setData: setPopupData } = UsePopupContext()
  const openPopup = (success: boolean) => {
    let content: string = ''
    let headerContent: string = ''
    if (success) {
      headerContent = 'Success'
      content = 'Your Payment Method has been deleted Successfully!'
    } else {
      headerContent = 'Something went wrong'
      content =
        'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? PopUPIcon : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.whitePopup}
                    text={'Got It'}
                    onClick={() => {
                      close()
                      getCustomerCard()
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button
                    text={'Go back!'}
                    onClick={(e) => {
                      close()
                    }}
                    className={styles.whitePopup}
                  ></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  // Call this function where ever it is required
  const getCustomerCard = () => {
    fetchRest(
      {
        url: `/getCustomerCards?stripeCustomerId=${
          userDetails.stripeCustomerIdUK
        }&stripeAccount=${'UK'}`,
        method: 'GET',
      },
      'paymentListApi'
    )
      .then((res: any) => {
        setPaymentList((prev: any) => {
          return {
            ...prev,
            data: res.data.data.data,
          }
        })
      })
      .catch((err: any) => {})
  }

  useEffect(() => {
    if (userDetails.countryCode) {
      getCustomerCard()
    }
  }, [userDetails])

  // Call this function where ever it is required
  const deleteCard = (cardId: string) => {
    fetchRest(
      {
        url: `/deleteCard`,
        method: 'DELETE',
        data: {
          source: cardId,
          stripeCustomerId: userDetails.stripeCustomerIdUK,
          stripeAccount: 'UK',
        },
      },
      'delete'
    )
      .then((res: any) => {
        openPopup(true)
      })
      .catch((err: any) => {
        openPopup(false)
      })
  }

  paymentList?.data?.forEach((each: any, index: number) => {})
  return (
    <>
      <div>
        {sectionLoading && sectionLoading.paymentListApi ? (
          <Loader className="form_loader" size={40} thickness={3} />
        ) : (
          <div>
            {paymentList.data.length ? (
              paymentList.data.map((each: any, index: number) => {
                return (
                  <div className={styles.payments} key={index}>
                    <div className={styles.cardNumber}>********{each.last4}</div>
                    <div
                      className={styles.deleteDiv}
                      onClick={() => {
                        deleteCard(each.id)
                      }}
                    >
                      {sectionLoading && sectionLoading.delete ? (
                        <Loader thickness={2} size={20} />
                      ) : (
                        <img src={deleteIcon} className={styles.divImg} alt="delete" key={index} />
                      )}
                    </div>
                  </div>
                )
              })
            ) : (
              <EmptySection subText={'No details provided'} />
            )}
          </div>
        )}
        <Button
          text="Add new"
          // disabled={true}
          ButtonImg={() => {
            return <img src={tick} alt="last month" className={styles.divImg} />
          }}
          className={styles.white}
          onClick={() => setAddNewMethod(true)}
        />
      </div>
      <DialogWrapper
        onClose={() => {
          setAddNewMethod(false)
        }}
        open={addNewMethod}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <ElementsWrapper>
          <AddNew
            countryCode={userDetails.countryCode}
            onClose={() => {
              setAddNewMethod(false)
              getCustomerCard()
            }}
          />
        </ElementsWrapper>
      </DialogWrapper>
    </>
  )
}

export const BankAccounts: React.FC = () => {
  const { userDetails } = useUserDetailsContext()
  const [addNewMethod, setAddNewMethod] = useState<boolean>(false)

  const { fetchRest, sectionLoading } = useFetch({ paymentListApi: false, delete: false })
  const [paymentList, setPaymentList] = useState({
    data: [],
  })
  const { close, setData: setPopupData } = UsePopupContext()

  const openPopup = (success: boolean, key: string, id?: string) => {
    let content: string = ''
    let headerContent: string = ''
    if (id) {
      headerContent = 'Confirmation'
      content = 'Are you sure, want to delete the account'
    } else if (success && key === 'delete') {
      headerContent = 'Success'
      content = 'Your Bank account has been deleted Successfully!'
    } else if (success && key === 'update') {
      headerContent = 'Success'
      content = 'Your Bank account has been updated Successfully!'
    } else if (!success && key === 'delete') {
      headerContent = 'Something went wrong'
      content =
        'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
    } else if (!success && key === 'delete') {
      headerContent = 'Something went wrong'
      content =
        'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
    } else if (!success && key === 'unsupported') {
      headerContent = 'Unsupported country'
      content = 'Currently your country is not supported.'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? PopUPIcon : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.whitePopup}
                    text={id ? 'Delete' : 'Got It'}
                    onClick={() => {
                      close()
                      if (id) {
                        deleteBankAccount(id)
                      } else {
                        getBankAccounts()
                      }
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button
                    text={'Go back!'}
                    onClick={(e) => {
                      close()
                    }}
                    className={styles.whitePopup}
                  ></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  // Call this function where ever it is required
  const getBankAccounts = () => {
    fetchRest(
      {
        url: `/bankAccountList?accountId=${userDetails.stripeAccountId}&stripeAccount=${'UK'}`,
        method: 'GET',
      },
      'paymentListApi'
    )
      .then((res: any) => {
        setPaymentList((prev: any) => {
          return {
            ...prev,
            data: res.data.data.data,
          }
        })
      })
      .catch((err: any) => {})
  }

  useEffect(() => {
    if (userDetails.stripeAccountId) {
      getBankAccounts()
    }
  }, [userDetails])

  useEffect(() => {
    if (paymentList.data.length > 0) {
      paymentList.data.forEach((each: any) => {
        if (each.default_for_currency) {
          setFormState(each.id)
        }
      })
    }
  }, [paymentList])

  // Call this function where ever it is required
  const deleteBankAccount = (accountId: string) => {
    fetchRest({
      url: `/deleteBankAccount`,
      method: 'DELETE',
      data: {
        bankAccountId: accountId,
        stripeAccount: 'UK',
        customerAccountId: userDetails.stripeAccountId,
      },
    })
      .then((res: any) => {
        openPopup(true, 'delete')
      })
      .catch((err: any) => {
        openPopup(false, 'delete')
      })
  }

  const updateBankAccount = (accountID: String) => {
    fetchRest({
      url: `/updateBankAccount`,
      method: 'PUT',
      data: {
        stripeAccountId: userDetails.stripeAccountId,
        bankAccountId: accountID,
        stripeAccount: 'UK',
      },
    })
      .then((res: any) => {
        openPopup(true, 'update')
      })
      .catch((err: any) => {
        openPopup(false, 'update')
      })
  }

  const updateBank = (event: any) => {
    event.persist()
    if (event && event.target && event.target.value) {
      setFormState(event.target.value)
      updateBankAccount(event.target.value)
    }
  }

  const [formState, setFormState] = useState<String>('')
  const { countries } = useUserDetailsContext()

  return (
    <>
      <div>
        {sectionLoading && sectionLoading.paymentListApi ? (
          <Loader className="form_loader" size={40} thickness={3} />
        ) : (
          <div className="green_radio_buttons">
            <div className="green_radio_buttons">
              <RadioGroup name="radio-buttons-group" value={formState} onChange={updateBank}>
                {paymentList.data.length ? (
                  paymentList.data.map((each: any, index: number) => {
                    return (
                      <div key={index} className={styles.radio_label}>
                        <div className={styles.bankAccounts}>
                          <div className={styles['d-flex']}>
                            <FormControlLabel
                              value={each.id}
                              control={<Radio size={'medium'} disableRipple />}
                              className={styles.cardNumber}
                              label={`********${each.last4}`}
                            />
                            {/* <div className={styles.cardNumber}>********{each.last4}</div> */}
                            <div className={styles.cardNumber}>{each.bank_name}</div>
                          </div>
                          <div
                            className={styles.deleteDiv}
                            onClick={() => {
                              if (!each.default_for_currency) {
                                openPopup(true, '', each.id)
                              }
                            }}
                          >
                            {each.default_for_currency ? (
                              <Tooltip title="You cannot delete the default bank account. Please make another account as default and then delete this account">
                                <img
                                  src={deleteIcon}
                                  className={styles.divImg}
                                  alt="delete"
                                  key={index}
                                />
                              </Tooltip>
                            ) : (
                              <img
                                src={deleteIcon}
                                className={styles.divImg}
                                alt="delete"
                                key={index}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <EmptySection subText={'No details provided'} />
                )}
              </RadioGroup>
            </div>
          </div>
        )}
        <Button
          text="Add new"
          // disabled={true}
          ButtonImg={() => {
            return <img src={tick} alt="last month" className={styles.divImg} />
          }}
          className={styles.white}
          onClick={() => {
            let supported = false
            if (countries.length > 0) {
              countries.forEach((each: any) => {
                if (each === userDetails.countryCode) {
                  supported = true
                }
              })
            }
            if (supported) {
              setAddNewMethod(true)
            } else {
              openPopup(false, 'unsupported')
            }
          }}
        />
      </div>
      <DialogWrapper
        onClose={() => {
          setAddNewMethod(false)
        }}
        open={addNewMethod}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <ElementsWrapper>
          <BankDetailsForm
            popupClose={() => {
              setAddNewMethod(false)
              getBankAccounts()
            }}
          />
        </ElementsWrapper>
      </DialogWrapper>
    </>
  )
}

export const Transaction: React.FC<{ readonly isStudent: boolean }> = ({ isStudent }) => {
  const [data, setData] = useState({
    value: '',
    isValid: true,
    isTouched: false,
    errorMsg: '',
  })
  const { userDetails } = useUserDetailsContext()
  const [trans, setTrans] = useState<any>([])
  const [proposalsTeacher, setProposalsTeacher] = useState([])
  const [proposalsStudent, setProposalsStudent] = useState([])
  const [transactionObjectArray, setTransactionObjectArray] = useState<ReadonlyArray<any>>([])
  const { fetchRest, sectionLoading } = useFetch({
    transactionApi: false,
    student: false,
    teacher: false,
  })
  const [proposals, setProposals] = useState([])

  useEffect(() => {
    fetchRest(
      {
        url: `/proposal/${isStudent ? 'student' : 'teacher'}`,
        method: 'GET',
        isFullPageLoader: false,
      },
      'proposalList'
    )
      .then((res) => {
        if (res && res.data && res.data.data) {
          setProposals(res.data.data)
        } else {
          setProposals([])
        }
      })
      .catch()
  }, [userDetails])
  const [timer, setTimer] = useState<'Last Month' | 'Last Week' | 'Last Year'>('Last Month')
  const ListArray: ReadonlyArray<ListArrayItemProps> = [
    {
      value: 'Last Month',
      listContent: (
        <>
          <div className={styles.element}>
            <div>Last Month</div>
          </div>
        </>
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick: (_e: any) => {
        setTimer('Last Month')
      },
    },
    {
      value: 'Last Week',
      listContent: (
        <div className={styles.element}>
          <div>Last Week</div>
        </div>
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick: (_e: any) => {
        setTimer('Last Week')
      },
    },
    {
      value: 'Last Year ',
      // disabled: true,
      listContent: (
        <div className={styles.element}>
          <div>Last Year</div>
        </div>
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick: (_e: any) => {
        setTimer('Last Year')
      },
    },
  ]
  const [status, setStatus] = useState<'Show all' | 'Unanswered only' | 'Answered only'>('Show all')
  const FilterArray: ReadonlyArray<ListArrayItemProps> = [
    {
      value: 'Show all',
      listContent: (
        <>
          <div className={styles.element}>
            <div>Show all</div>
          </div>
        </>
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick: (_e: any) => {
        setStatus('Show all')
      },
    },
    {
      value: 'Unanswered only',
      listContent: (
        <div className={styles.element}>
          <div>Unanswered only</div>
        </div>
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick: (_e: any) => {
        setStatus('Unanswered only')
      },
    },
    {
      value: 'Answered only ',
      // disabled: true,
      listContent: (
        <div className={styles.element}>
          <div>Answered only</div>
        </div>
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick: (_e: any) => {
        setStatus('Answered only')
      },
    },
  ]
  const proposalDetails = (each: any) => {
    if (each && each?.transfer_group) {
      if (proposalsStudent.length && proposalsTeacher.length === 0) {
        proposalsStudent?.forEach((every: any, keys: number) => {
          if (every.proposalId === each?.transfer_group) {
            setTransactionObjectArray((prev: any) => {
              return [
                ...prev,
                {
                  img: every.proposal.owner.imageUrl,
                  firstName: every.proposal.owner.firstName,
                  lastName: every.proposal.owner.lastName,
                  title: every.proposal.proposalDetail.title,
                  date: each.created,
                  amount: each.amount / 100,
                  id: each.id,
                  withdrawal: false,
                },
              ]
            })
          }
        })
      }
      if (proposalsTeacher.length && proposalsStudent.length === 0) {
        proposalsTeacher?.forEach((every: any) => {
          if (every?.proposalId === each.transfer_group) {
            setTransactionObjectArray((prev: any) => {
              return [
                ...prev,
                {
                  img: every.proposal.request.user.imageUrl,
                  firstName: every.proposal.request.user.firstName,
                  lastName: every.proposal.request.user.lastName,
                  title: every.proposal.proposalDetail.title,
                  date: each.created,
                  amount: each.amount / 100,
                  id: each.id,
                  withdrawal: false,
                },
              ]
            })
          }
        })
      }
      if (proposalsStudent.length && proposalsStudent.length) {
        proposalsStudent?.forEach((every: any, keys: number) => {
          proposalsTeacher?.forEach((i: any) => {
            if (every.proposalId === each.transfer_group) {
              //  set proposal detaisl object here
              setTransactionObjectArray((prev: any) => {
                return [
                  ...prev,
                  {
                    img: every.proposal.owner.imageUrl,
                    firstName: every.proposal.owner.firstName,
                    lastName: every.proposal.owner.lastName,
                    title: every.proposal.proposalDetail.title,
                    date: each.created,
                    amount: each.amount / 100,
                    id: each.id,
                    withdrawal: false,
                  },
                ]
              })
            }
            if (i?.proposalId === each.transfer_group) {
              setTransactionObjectArray((prev: any) => {
                return [
                  ...prev,
                  {
                    img: every.proposal.request.user.imageUrl,
                    firstName: every.proposal.request.user.firstName,
                    lastName: every.proposal.request.user.lastName,
                    title: every.proposal.request.title,
                    date: each.created,
                    amount: each.amount / 100,
                    id: each.id,
                    withdrawal: false,
                  },
                ]
              })
            }
          })
        })
      }
    } else {
      setTransactionObjectArray((prev: any) => {
        return [
          ...prev,
          {
            date: each.created,
            amount: each.amount / 100,
            id: each.id,
            withdrawal: true,
          },
        ]
      })
    }
  }
  useEffect(() => {
    if (userDetails.userId !== '') {
      setTrans([])
      fetchRest(
        {
          url: `/session/student`,
          method: 'GET',
          isFullPageLoader: false,
        },
        'student'
      )
        .then((res) => {
          if (res && res.data && res.data.data) {
            setProposalsStudent(res.data.data)
            setTransactionObjectArray([])
          } else {
            setProposalsStudent([])
          }
        })
        .catch()
      fetchRest(
        {
          url: `/session/teacher`,
          method: 'GET',
          isFullPageLoader: false,
        },
        'teacher'
      )
        .then((res) => {
          if (res && res.data && res.data.data) {
            setProposalsTeacher(res.data.data)
            setTransactionObjectArray([])
          } else {
            setProposalsTeacher([])
          }
        })
        .catch()

      if (userDetails && userDetails.userId) {
        if (userDetails.isTeacher) {
          fetchRest(
            {
              method: 'GET',
              url: `/accountTransactions?userId=${userDetails.userId}`,
            },
            'transactionApi'
          )
            .then((res: any) => {
              setTrans((prev: any) => {
                return [...prev, ...res.data.data]
              })
              setTransactionObjectArray([])
            })
            .catch((err: any) => {
              console.log(err)
            })
        }

        fetchRest(
          {
            method: 'GET',
            url: `/customerTransactions?customerId=${
              userDetails.stripeCustomerIdUK
            }&stripeAccount=${'UK'}`,
          },
          'transactionApi'
        )
          .then((res: any) => {
            setTrans((prev: any) => {
              return [...prev, ...res.data.data]
            })
          })
          .catch((err: any) => {
            console.log(err)
          })
      }
    }
  }, [userDetails])

  useEffect(() => {
    if (trans && trans.length) {
      trans.forEach((each: any) => {
        if (each) {
          proposalDetails(each)
        }
      })
    }
  }, [trans])

  return (
    <>
      <form>
        <InputWrapper
          valueObj={data}
          onChange={() => {}}
          placeholder="Search by id, description..."
        />
        <div className={styles.btnWrapper}>
          <div className={styles.dropDownWrapper}>
            <div className={styles.dropdown_container}>
              <DropDown
                menuProps={{
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  transformOrigin: { vertical: 'top', horizontal: 'left' },
                }}
                ListArray={ListArray}
              >
                <div className={styles.dropDownIcon}>
                  <img src={calendar} alt="last month" className={styles.divImg} />
                  <div> {timer}</div>
                </div>
              </DropDown>
            </div>
            <div>
              <DropDown
                menuProps={{
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  transformOrigin: { vertical: 'top', horizontal: 'left' },
                }}
                ListArray={FilterArray}
              >
                <div className={styles.dropDownIcon}>
                  <img src={filter} alt="show all" className={styles.divImg} />
                  <div>{status}</div>
                </div>
              </DropDown>
            </div>
          </div>
        </div>
      </form>
      <div>
        {sectionLoading &&
        (sectionLoading.student || sectionLoading.teacher || sectionLoading.transactionApi) ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            {transactionObjectArray.length ? (
              <>
                {transactionObjectArray.map((each: any, key: number) => {
                  return (
                    <div className={styles.borderTop} key={key}>
                      <div className={styles.transactionWrapper}>
                        <div className={styles.column}>
                          <div className={styles.date}>
                            <Moment date={each.date * 1000} format="DD MMM yyyy" interval={0} />
                          </div>
                          <div className={styles.cost}>{each.amount}</div>
                        </div>
                        {each.withdrawal ? (
                          <>
                            <div className={styles.cardNumber}>*******121212</div>
                          </>
                        ) : (
                          <div className={styles.rowWrapper}>
                            <div className={styles.icon}>
                              {each.img ? (
                                <img src={each.img} alt="userImage" className={styles.icon} />
                              ) : (
                                <>
                                  <NameInitials
                                    firstName={each.firstName}
                                    lastName={each.lastName}
                                  />
                                </>
                              )}
                            </div>
                            <div className={styles.column}>
                              <div className={styles.course}>{each.title}</div>
                              <div className={styles.userName}>
                                {each.firstName} {each.lastName}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className={styles.column}>
                          <div className={styles.id}> {each.id}</div>
                          <div className={styles.key}>Withdrawal</div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </>
            ) : (
              <EmptySection subText="No transactions yet " />
            )}
          </>
        )}
      </div>
    </>
  )
}

export const UserProfileFinancialSetting: React.FC<{ readonly isStudent?: boolean }> = ({
  isStudent = false,
}) => {
  const { push } = useHistory()
  const { t } = useTranslation()
  const [pending, setPending] = useState(0)
  const [currency, setCurrency] = useState('')
  const [defaultState, setDefaultState] = useState<number>(0)
  const [isActiveTab, setIsActiveTab] = useState<'Transaction' | 'PaymentMethods' | 'BankAccounts'>(
    'Transaction'
  )
  const { search } = useLocation()
  const { fetchRest } = useFetch({})
  const [sendOtp, setSendOtp] = useState<boolean>(false)
  const { userDetails } = useUserDetailsContext()

  useEffect(() => {
    if (userDetails && userDetails.userId && userDetails.isTeacher) {
      getTotalVolume()
      fetchRest({
        url: `/balance?userId=${userDetails.userId}`,
        method: 'GET',
      })
        .then((res: any) => {
          setPending(res.data.pending)
        })
        .catch((err: any) => {
          console.log(err)
          setPending(0)
        })
    }
  }, [userDetails])

  const [totalVolume, setTotalVolume] = useState('0')

  const getTotalVolume = () => {
    fetchRest(
      {
        method: 'GET',
        url: `/getTotalVolume?userId=${userDetails.userId}`,
      },
      'transactionApi'
    )
      .then((res: any) => {
        setCurrency(res.data.currency)
        setTotalVolume(`${res.data.totalAmount / 100} ${res.data.currency}`)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  useEffect(() => {
    let searchParams = new URLSearchParams(search)
    let defaultTab: number
    let searchTab = searchParams.get('subTab')
    if (searchTab === 'PaymentMethods') {
      defaultTab = 1
      setIsActiveTab('PaymentMethods')
    } else if (searchTab === 'BankAccounts') {
      defaultTab = 2
      setIsActiveTab('BankAccounts')
    } else {
      defaultTab = 0
      setIsActiveTab('Transaction')
    }
    setDefaultState(defaultTab)
  }, [search])

  const onTabChange = (activeTab: number) => {
    switch (activeTab) {
      case 1:
        push('/user-profile?tab=FinancialSetting&subTab=PaymentMethods')
        setIsActiveTab('PaymentMethods')
        break
      case 2:
        push('/user-profile?tab=FinancialSetting&subTab=BankAccounts')
        setIsActiveTab('BankAccounts')
        break
      default:
        push('/user-profile?tab=FinancialSetting&subTab=Transaction')
        setIsActiveTab('Transaction')
    }
  }

  const tabHeadersTeachers: ReadonlyArray<TabWrapperProps> = [
    {
      key: 'Transactions',
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Transactions" className={styles.tab} />
      },
    },
    {
      key: 'Payments Methods',
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Payments Methods" className={styles.tab} />
      },
    },
    {
      key: 'Bank Accounts',
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Bank Accounts" className={styles.tab} />
      },
    },
  ]

  const tabHeadersStudents: ReadonlyArray<TabWrapperProps> = [
    {
      key: 'Transactions',
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Transactions" className={styles.tab} />
      },
    },
    {
      key: 'Payments Methods',
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Payments Methods" className={styles.tab} />
      },
    },
  ]

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.innerWrapper}>
        {isStudent ? (
          <></>
        ) : (
          <div className={styles.wrapper}>
            <div className={styles.eachSide}>
              <div className={styles.availableClass}>
                <div className={styles.label}>
                  <img src={time} alt="time" className={styles.divImg} />
                  <div>{t('financialSettings.pending')}</div>
                </div>
                <div className={styles.stripeIcon}>
                  {/* <span>powered by </span> */}
                  <img src={stripeIcon} alt="stripe" />
                </div>
              </div>
              <div className={styles.fundDetails}>
                <div>
                  <div className={styles.amountPending}>
                    {pending} {currency}
                  </div>
                  <div className={styles.aboutFund}>{t('financialSettings.inprogress')}</div>
                </div>
              </div>
            </div>
            <div className={styles.eachSide}>
              <div className={styles.availableClass}>
                <div className={styles.label}>
                  <img src={cost} alt="cost" className={styles.divImg} />
                  <div>Paid Funds</div>
                </div>
                <div className={styles.stripeIcon}>
                  {/* <span>powered by </span> */}
                  <img src={stripeIcon} alt="stripe" />
                </div>
              </div>
              <div className={styles.fundDetails}>
                <div>
                  <div className={styles.amountAvailable}>{totalVolume}</div>
                  <div className={styles.aboutFund}>These are the funds that user earned</div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={styles.innerWrapper}>
          <div className={styles.tabsWrapper}>
            <TabsWrapper
              tabHeaders={isStudent ? tabHeadersStudents : tabHeadersTeachers}
              onTabChange={onTabChange}
              activeTabValue={defaultState}
            />
            <div className={styles.component}>
              {isActiveTab === 'Transaction' ? <Transaction isStudent={true} /> : <></>}
              {isActiveTab === 'PaymentMethods' ? <PaymentMethods /> : <></>}
              {isActiveTab === 'BankAccounts' && !isStudent ? <BankAccounts /> : <></>}
            </div>
          </div>
        </div>
      </div>
      <DialogWrapper
        onClose={() => {
          setSendOtp(false)
        }}
        open={sendOtp}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <ConfirmationOtp
          onClose={() => {
            setSendOtp(false)
          }}
        />
      </DialogWrapper>
    </div>
  )
}
