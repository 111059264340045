/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../common/button/Button'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import { charactersLeft } from '../../common/utils'
import whiteTick from '../../assets/images/svg/whiteTick.svg'
import styles from './CreateTeam.module.scss'
import teamsPic from '../../assets/images/svg/teamsPic.svg'
import edit from '../../assets/images/svg/editButton.svg'
import Join from '../../assets/images/svg/SendJoinRequest.svg'
import { teamIdSchema, teamNameSchema, teamTitleSchema } from '../../common/schema/Schema'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { UsePopupContext } from '../../common/use-popup-context'
import createTeam from '../../assets/images/svg/teamSuccessPopup.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import { TeamDetails } from '../team-details/TeamDetail'
import { useFetch } from '../../common/https'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import { Loader } from '../../common/Loader/Loader'
import { useUserDetailsContext } from '../../common/use-user-details-context'
export const CreateTeam: React.FC<{ readonly join?: boolean; callBackFunction: () => void }> = ({
  join = false,
  callBackFunction,
}) => {
  const { fetchRest, sectionLoading } = useFetch({
    createTeam: false,
    joinTeam: false,
    getTeam: false,
  })

  const [teamData, setTeamData] = useState({
    Name: { value: '', isTouched: false, isValid: false, errorMsg: '' },
    Title: { value: '', isTouched: false, isValid: false, errorMsg: '' },
    Image: { value: '', isValid: true },
  })

  const [joinTeamData, setJoinTeamData] = useState({
    ID: { value: '', isTouched: false, isValid: false, errorMsg: '' },
  })

  const [description, setDescription] = useState('')
  const { t } = useTranslation()
  const { close, setData: setPopupData } = UsePopupContext()

  const [team, setTeam] = useState<{ teamId: string }>()
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const { userDetails } = useUserDetailsContext()

  const imageHandler = (e: any) => {
    if (
      e?.target?.files?.[0] &&
      e?.target?.files?.[0]?.type.indexOf('image') !== -1 &&
      e?.target?.files?.[0]?.size / (1024 * 1024) <= 3
    ) {
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = () => {
        if (reader.readyState === 2) {
          setTeamData((prev: any) => {
            return {
              ...prev,
              Image: { value: reader.result, isValid: true },
            }
          })
        }
      }
    }
  }

  const openPopup = (success: boolean, headerMsg?: string, contentMsg?: string) => {
    let headerContent: string = ''
    let content: string = ''
    if (success) {
      if (join) {
        headerContent = t('teams.createTeam.joinRequest')
        content = t('teams.createTeam.contentJoin')
      } else {
        headerContent = t('teams.createTeam.teamCreated')
        content = t('teams.createTeam.congratulations')
      }
    } else if (!success && headerMsg) {
      headerContent = headerMsg
      content = 'Please try again with a different Team Name'
    } else {
      headerContent = t('teams.createTeam.error')
      content = t('teams.createTeam.errorMsg')
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? createTeam : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.green}
                    text={'Got It'}
                    onClick={() => {
                      close()
                      callBackFunction()
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button text={'Go back'} className={styles.cancel} onClick={close}></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  const changeHandler = (e: any, type: string) => {
    const localData = e.value
    if (type === 'ID') {
      setJoinTeamData((prev: any) => {
        return {
          ...prev,
          ID: { value: localData, ...e },
        }
      })
    } else {
      setTeamData((prev: any) => {
        return {
          ...prev,
          [type]: { value: localData, ...e },
        }
      })
    }
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    if (join) {
      setJoinTeamData((prev: any) => {
        let blockPrev = { ...prev }
        Object.keys(blockPrev).forEach((each) => {
          blockPrev = { ...blockPrev, [each]: { ...blockPrev[each], isTouched: true } }
        })
        return blockPrev
      })
      setIsSubmitted(true)
      if (joinTeamData.ID.isValid && joinTeamData.ID.value.length === 8 && team) {
        fetchRest(
          {
            url: `team/${team.teamId}/join-invitation-action/Join_Request/user/${userDetails.userId}`,
            method: 'GET',
            isFullPageLoader: false,
          },
          'joinTeam'
        )
          .then((res: any) => {
            openPopup(true)
          })
          .catch((err) => {
            // openPopup(false)
          })
      }
    }

    setTeamData((prev: any) => {
      let blockPrev = { ...prev }
      Object.keys(blockPrev).forEach((each) => {
        blockPrev = { ...blockPrev, [each]: { ...blockPrev[each], isTouched: true } }
      })
      return blockPrev
    })
    // api call comes here and the popup is also called here
    if (teamData.Name.isValid && teamData.Title.isValid) {
      fetchRest(
        {
          url: '/team/create',
          method: 'POST',
          isFullPageLoader: false,
          data: {
            teamName: teamData.Name.value,
            teamTitle: teamData.Title.value,
            teamDescription: description,
            teamImage: teamData.Image.value,
          },
        },
        'createTeam'
      )
        .then((res: any) => {
          openPopup(true)
        })
        .catch((err) => {
          const error = err?.response?.data?.error?.message
          openPopup(false, error)
        })
    }
  }

  const fetchTeam = () => {
    fetchRest(
      {
        url: `team-detail/${joinTeamData.ID.value}`,
        method: 'GET',
        isFullPageLoader: false,
      },
      'getTeam'
    )
      .then((res: any) => {
        setTeam(res.data)
      })
      .catch((err) => {
        setTeam({ teamId: '' })
      })
  }

  useEffect(() => {
    if (joinTeamData.ID.value.length === 8) {
      fetchTeam()
    }
  }, [joinTeamData.ID.value])

  return (
    <div className={styles.wrapper}>
      <NoEventsLoader
        isLoading={
          sectionLoading &&
          (sectionLoading.createTeam || sectionLoading.joinTeam || sectionLoading.getTeam)
        }
      >
        <form onSubmit={onSubmit}>
          {join ? (
            <>
              <div className={styles.element}>
                <div className={styles.bold}>{t('teams.createTeam.joinTeam')}</div>
                <div className={styles.inputWrapper}>
                  <InputWrapper
                    onChange={(e: any) => {
                      changeHandler(e, 'ID')
                    }}
                    valueObj={joinTeamData.ID}
                    placeholder="Invitation ID.."
                    schema={teamIdSchema}
                    validateOnChange={true}
                    validateOnBlur={true}
                  />
                </div>
              </div>
              {sectionLoading && sectionLoading.getTeam ? (
                <Loader />
              ) : (
                <div className={styles.teamDets}>
                  {joinTeamData.ID.value.length === 8 && team && team?.teamId !== '' ? (
                    <TeamDetails teamInfo={team} join={true} />
                  ) : (
                    <></>
                  )}
                </div>
              )}
              <Button
                text={'Send Join Request'}
                className={`${styles.button} ${styles.largeWidth}`}
                ButtonImg={() => {
                  return (
                    <>
                      {sectionLoading && sectionLoading.joinTeam ? (
                        <Loader className={styles.form_loader} size={20} thickness={1} />
                      ) : (
                        <img src={Join} className={styles.divImg} alt="join" />
                      )}
                    </>
                  )
                }}
              />
              <div className={styles.relative}>
                <div className={styles.error}>
                  {team?.teamId === '' && isSubmitted ? 'Please select valid invitation id' : ''}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.element}>
                <div className={styles.align}>
                  <img
                    src={teamData?.Image?.value ? teamData.Image.value : teamsPic}
                    alt="team pic"
                    id="team"
                    className={styles.teamPic}
                  />
                  <label htmlFor="image" className={styles.imageEdit}>
                    <img className={styles.edit} src={edit} alt="edit" />
                  </label>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  id="image"
                  style={{ display: 'none' }}
                  onChange={imageHandler}
                />
              </div>
              <div className={styles.element}>
                <div className={styles.bold}>{t('teams.createTeam.teamName')}</div>
                <div className={styles.description}>{t('teams.createTeam.teamDesc')}</div>
                <div className={styles.inputWrapper}>
                  <InputWrapper
                    type="text"
                    valueObj={teamData.Name}
                    onChange={(e: any) => {
                      changeHandler(e, 'Name')
                    }}
                    placeholder="For example, My Team"
                    schema={teamNameSchema}
                    validateOnChange={true}
                    validateOnBlur={true}
                  />
                </div>
              </div>
              <div className={styles.element}>
                <div className={styles.bold}>{t('teams.createTeam.teamTitle')}</div>
                <div className={styles.description}>{t('teams.createTeam.titleDesc')}</div>
                <div className={styles.inputWrapper}>
                  <InputWrapper
                    onChange={(e: any) => {
                      changeHandler(e, 'Title')
                    }}
                    valueObj={teamData.Title}
                    placeholder="Specify..."
                    schema={teamTitleSchema}
                    validateOnChange={true}
                    validateOnBlur={true}
                  />
                </div>
              </div>
              <div className={styles.element}>
                <div className={styles.bold}>{t('teams.createTeam.desc')}</div>
                <div className={styles.description}>{t('teams.createTeam.about')}</div>
                <div className={styles.inputWrapper}>
                  <div>
                    <textarea
                      className={styles['textarea-styles']}
                      value={description}
                      onChange={(e: any) => {
                        let value = e.target.value
                        setDescription(value)
                      }}
                    ></textarea>
                    <div className={styles['character-count']}>
                      {charactersLeft({
                        currentLength: description?.length > 0 ? description?.length : 0,
                      })}
                      {t('common.charactersLeft')}
                    </div>
                  </div>
                </div>
              </div>
              <Button
                text={'Create'}
                className={styles.button}
                ButtonImg={() => {
                  return (
                    <>
                      {sectionLoading && sectionLoading.createTeam ? (
                        <Loader className={styles.form_loader} size={20} thickness={2} />
                      ) : (
                        <img src={whiteTick} className={styles.divImg} alt="tick" />
                      )}
                    </>
                  )
                }}
                type="submit"
              />
            </>
          )}
        </form>
      </NoEventsLoader>
    </div>
  )
}
