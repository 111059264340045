import { Dialog, DialogContent } from '@material-ui/core'
import React, { createContext, ReactElement, useState } from 'react'

const AlertContext = createContext({
  setData: (value: ReactElement) => {
    return 0 as number
  },
  close: () => {
    return 0 as number
  },
})
export default AlertContext

export const PopupProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [alertPopup, setAlertPopupProps] = useState<ReactElement>(() => <></>)

  const setData = (value: ReactElement) => {
    setOpen(true)
    setAlertPopupProps(value)
    return 0
  }

  const close = () => {
    setOpen(false)
    return 0
  }

  return (
    <AlertContext.Provider value={{ setData, close }}>
      {children}
      <Dialog
        className="popup-alert"
        PaperProps={{
          style: {
            maxWidth: '404px',
            padding: '65px',
            width: '100%',
            background: '#f1f2f4',
          },
        }}
        open={open}
      >
        <DialogContent>{alertPopup}</DialogContent>
      </Dialog>
    </AlertContext.Provider>
  )
}
