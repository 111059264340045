/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import notificationIcon from '../../assets/images/png/notification-icon.png'
import account from '../../assets/images/svg/account.svg'
import chatIcon from '../../assets/images/svg/chat-icon.svg'
import eyeIcon from '../../assets/images/svg/eye.svg'
import headerMenu from '../../assets/images/svg/header-menu.svg'
import closeMenu from '../../assets/images/svg/close-menu.svg'
import finance from '../../assets/images/svg/finance.svg'
import login from '../../assets/images/svg/login.svg'
import logo from '../../assets/images/svg/logo.svg'
import Logout from '../../assets/images/svg/logout.svg'
import passwordIcon from '../../assets/images/svg/password.svg'
import personalInfo from '../../assets/images/svg/Personal-info.svg'
import PopUPIcon from '../../assets/images/svg/popup-icon.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import tick from '../../assets/images/svg/whiteTick.svg'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { Button } from '../../common/button/Button'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { DropDown, ListArrayItemProps } from '../../common/drop-down/DropDown'
import { useFetch } from '../../common/https'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import { Loader } from '../../common/Loader/Loader'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import { passwordSchema } from '../../common/schema/Schema'
import { UsePopupContext } from '../../common/use-popup-context'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { getUserTokens, removeUserTokens } from '../../common/utils'
import { TabItem } from '../tab-item/TabItem'
import { TabsWrapper, TabWrapperProps } from '../tabs-wrapper/TabsWrapper'
import styles from './Header.module.scss'
import { useUserUnreadInfoContext } from '../../common/user-unread-info-provider/UserUnreadInfoProvider'
import { Notifications } from '../notifications/Notifications'

export const PasswordForm: React.FC<{
  readonly closePopup: () => void
}> = ({ closePopup }) => {
  const { fetchRest, sectionLoading } = useFetch({ passwordForm: false })
  const [data, setData] = useState({
    password: { value: '', isValid: false, isTouched: false, errorMsg: 'Please enter Password' },
    oldPassword: {
      value: '',
      isValid: false,
      isTouched: false,
      errorMsg: 'Please enter old Password',
    },
  })
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false)
  const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState<boolean>(false)
  const [formError, setFormError] = useState<string>('')
  const { t } = useTranslation()
  const { close, setData: setPopupData } = UsePopupContext()

  const handleChange = (e: any, key: string) => {
    const localData = e.value
    setFormError('')
    setData((prev: any) => {
      return {
        ...prev,
        [key]: { isTouched: true, value: localData, ...e },
      }
    })
  }
  const closeAll = () => {
    close()
    closePopup()
  }
  const openPopup = (success: boolean, headerMsg?: string, contentMsg?: string) => {
    let headerContent: string = ''
    let content: string = ''
    if (success) {
      headerContent = headerMsg ? headerMsg : 'Updated!'
      content = contentMsg ? contentMsg : 'Password updated successfully'
    } else {
      headerContent = headerMsg ? headerMsg : 'Something went wrong'
      content = contentMsg
        ? contentMsg
        : 'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? PopUPIcon : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.white}
                    text={'Got It'}
                    onClick={() => closeAll()}
                  ></Button>
                </>
              ) : (
                <>
                  <Button
                    text={'Go back'}
                    onClick={(e) => {
                      close()
                    }}
                    className={styles.white}
                  ></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }
  const onSubmit = (e: any) => {
    e.preventDefault()
    const { accessToken } = getUserTokens()
    if (data.password.isValid && data.oldPassword.isValid) {
      if (data.password.value === data.oldPassword.value) {
        setFormError('New password cannot be same as old password')
      } else {
        setFormError('')
        fetchRest(
          {
            url: 'editProfile',
            method: 'PUT',
            data: {
              accessToken: accessToken,
              previousPassword: data.oldPassword.value ? data.oldPassword.value : undefined,
              proposedPassword: data.password.value ? data.password.value : undefined,
            },
            isFullPageLoader: false,
          },
          'passwordForm'
        )
          .then(async (res) => {
            if (res && res.data && res.data.status === 'success') {
              openPopup(true)
            }
          })
          .catch((err: any) => {
            const error = err?.response?.data?.error?.message?.error?.code
            if (error === 'NotAuthorizedException') {
              openPopup(
                false,
                'Password incorrect',
                'The old password you entered seems to be incorrect!'
              )
            } else {
              openPopup(false)
            }
          })
      }
    } else {
      setFormError('')
      setData({
        password: {
          value: data.password.value ? data.password.value : '',
          isValid: data.password.isValid,
          isTouched: true,
          errorMsg: '',
        },
        oldPassword: {
          value: data.oldPassword.value ? data.oldPassword.value : '',
          isValid: data.oldPassword.isValid,
          isTouched: true,
          errorMsg: '',
        },
      })
    }
  }
  return (
    <>
      <NoEventsLoader isLoading={sectionLoading && sectionLoading.passwordForm}>
        <form onSubmit={onSubmit}>
          <div className={styles.password}>
            <div className={styles.label}>
              <img src={passwordIcon} alt="password icon" className={styles.divImg} />
              <div>{t('userDetails.password')}</div>
            </div>
            <div className={styles.marginBtm}>
              <div className={styles.fullField}>
                <div className={styles.heading}>{t('userDetails.oldPassword')}</div>
                <div>
                  <InputWrapper
                    inputIcon={eyeIcon}
                    iconClick={() => {
                      setIsConfirmPasswordShown(!isConfirmPasswordShown)
                    }}
                    type={isConfirmPasswordShown ? 'text' : 'password'}
                    valueObj={data.oldPassword}
                    onChange={(e: any) => {
                      handleChange(e, 'oldPassword')
                    }}
                    schema={passwordSchema}
                    validateOnChange={true}
                    validateOnBlur={true}
                    className={styles.paddingRight}
                  />
                </div>
              </div>
              <div className={styles.fullField}>
                <div className={styles.heading}>{t('userDetails.enterPassword')}</div>
                <div>
                  <InputWrapper
                    inputIcon={eyeIcon}
                    iconClick={() => {
                      setIsPasswordShown(!isPasswordShown)
                    }}
                    type={isPasswordShown ? 'text' : 'password'}
                    valueObj={data.password}
                    onChange={(e: any) => {
                      handleChange(e, 'password')
                    }}
                    schema={passwordSchema}
                    validateOnChange={true}
                    validateOnBlur={true}
                    className={styles.paddingRight}
                  />
                </div>
              </div>
            </div>
            <div className={styles.btnPopup}>
              {formError ? <div className={styles.formError}>{formError}</div> : <></>}
              <Button
                text="Save"
                className={styles.buttonPopup}
                ButtonImg={() => {
                  return (
                    <>
                      {sectionLoading && sectionLoading.passwordForm ? (
                        <Loader className="form_loader" size={20} thickness={2} />
                      ) : (
                        <>
                          <img src={tick} alt="tick icon" className={styles.divImg} />
                        </>
                      )}
                    </>
                  )
                }}
                type="submit"
              />
            </div>
          </div>
        </form>
      </NoEventsLoader>
    </>
  )
}

export const Header: React.FC<{
  readonly isSignUp?: boolean
  readonly inHelpCenter?: boolean
}> = ({ isSignUp = false, inHelpCenter = false }) => {
  const location = useLocation()
  const [defaultTabState, setDefaultTabState] = useState<number>(-1)
  const { pathname } = useLocation()
  const { push } = useHistory()
  const [right, setRight] = useState(false)
  const { userDetails, resetUser, notificationCount, getNotificationCount } =
    useUserDetailsContext()
  const { unreadInfo } = useUserUnreadInfoContext()
  const { t } = useTranslation()
  const [dataPopup, setdataPopup] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [link, setLink] = useState('')
  const [totalUnread, setTotalUnread] = useState(0)

  useEffect(() => {
    if (pathname === '/learn') {
      setDefaultTabState(0)
    }
    if (pathname === '/mentor') {
      setDefaultTabState(1)
    }
    if (pathname === '/teams') {
      setDefaultTabState(2)
    }
    if (pathname === '/mentor') {
      setLink('/chat?type=mentor')
    } else {
      setLink('/chat?type=learn')
    }
    if (pathname.substring(pathname.indexOf('/') + 1, 8) === 'teacher') {
      setLink('/chat?type=learn')
    }
    if (pathname.substring(pathname.indexOf('/') + 1, 8) === 'student') {
      setLink('/chat?type=mentor')
    }
    if (pathname.substring(pathname.indexOf('/') + 1, 6) === 'teams') {
      setDefaultTabState(2)
    }
  }, [pathname])

  const [notifications, setNotifications] = useState(0)

  useEffect(() => {
    setNotifications(notificationCount)
  }, [notificationCount])

  const [width, setWidth] = useState(() => {
    if (typeof window !== 'undefined') return window.innerWidth
    else return 1200
  })
  const updateDimensions = () => {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateDimensions)
      return () => window.removeEventListener('resize', updateDimensions)
    }
  }, [])
  useEffect(() => {
    if (width <= 768) {
    } else if (width > 768) {
      if (openMenu) {
        setOpenMenu(false)
      }
    }
  }, [width])
  useEffect(() => {
    if (openMenu) {
      document.documentElement.style.overflow = 'hidden'
    } else if (!openMenu) {
      document.documentElement.style.overflow = 'visible'
    }
  }, [openMenu])

  useEffect(() => {
    setTotalUnread(Object.keys(unreadInfo).length)
  }, [unreadInfo])

  const baseRedirectionUrl = 'https://demo.divami.com/ontelo-static/'

  const baseUrl = process.env.REACT_APP_LANDING_REDIRECT_URL
    ? process.env.REACT_APP_LANDING_REDIRECT_URL
    : baseRedirectionUrl

  const commonHeaderLinks = [
    {
      text: t('header.knowledge'),
      to: `${baseRedirectionUrl}knowledge`,
    },
    {
      text: t('header.expert'),
      to: `${baseUrl}expert`,
    },
    {
      text: t('header.teams'),
      to: `${baseUrl}teams`,
    },
  ]

  const tabHeaders: ReadonlyArray<TabWrapperProps> = [
    {
      key: 'Learn',
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Learn" />
      },
    },
    {
      key: 'Mentor',
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Mentor" />
      },
    },
    // to be implemented later
    {
      key: 'Teams',
      // disabled: true,
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Teams" />
      },
    },
  ]

  const onTabChange = (activeTab: number) => {
    switch (activeTab) {
      case 1:
        push('/mentor?tab=findRequest')
        break
      case 2:
        push('/teams?tabs=myTeams')
        break
      default:
        push('/learn?tab=requestLearning')
    }
  }

  const logout = () => {
    // e.preventDefault()
    removeUserTokens()
    resetUser()
    push('/login')
    // const baseRedirectionUrl = 'https://demo.divami.com/ontelo-static/'
    // const baseUrl = process.env.REACT_APP_LANDING_REDIRECT_URL
    //   ? process.env.REACT_APP_LANDING_REDIRECT_URL
    //   : baseRedirectionUrl
    // window.location.href = `${baseUrl}home`
  }

  const openResetPasswordPopup = () => {
    setdataPopup(true)
  }

  const ListArray: ReadonlyArray<ListArrayItemProps> = [
    {
      value: 'View Profile',
      listContent: (
        <>
          <div className={styles.element}>
            <img src={personalInfo} alt="personal_info" className={styles.elementImage} />
            <div>Visit Profile</div>
          </div>
        </>
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick: (_e: any) => {
        push('/user-profile')
      },
    },
    {
      value: 'Account Setting',
      listContent: (
        <div className={styles.element}>
          <img src={account} alt="account" className={styles.elementImage} />
          <div>Account setting</div>
        </div>
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick: (_e: any) => {
        push('/user-profile?tab=AccountSetting')
      },
    },
    {
      value: 'Financial Setting',
      // disabled: true,
      listContent: (
        <div className={styles.element}>
          <img src={finance} alt="finance" className={styles.elementImage} />
          <div>Financial setting</div>
        </div>
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick: (_e: any) => {
        push('/user-profile?tab=FinancialSetting')
      },
    },
    {
      value: 'resetPassword',
      listContent: (
        <div className={styles.element}>
          <img src={passwordIcon} alt="reset_password" className={styles.elementImage} />
          <div>{t('header.resetPassword')}</div>
        </div>
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick: (_e: any) => {
        openResetPasswordPopup()
      },
    },
    {
      value: 'Log out',
      listContent: (
        <div className={styles.element}>
          <img src={Logout} alt="logout" className={styles.elementImage} />
          <div>Logout</div>
        </div>
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick: (_e: any) => {
        logout()
      },
    },
  ]

  return (
    <>
      <div className={styles.header}>
        <Link
          to={userDetails.isLogged ? '/learn?tab=requestLearning' : '/'}
          className={`${styles.home_link} ${styles.desktopView}`}
        >
          <div className={styles.logo}>
            <img src={logo} alt="logo" data-testid="logo" />
          </div>
        </Link>
        <a href={baseUrl} className={`${styles.home_link} ${styles.mobileView}`}>
          <div className={styles.logo}>
            <img src={logo} alt="logo" data-testid="logo" />
          </div>
        </a>

        {inHelpCenter ? (
          <></>
        ) : (
          <>
            {!isSignUp ? (
              <>
                {!userDetails.isLogged ? (
                  <div className={styles['header-nav']}>
                    {commonHeaderLinks.map((each, index) => (
                      <React.Fragment key={index}>
                        <Link to={each.to}>
                          <div
                            className={
                              pathname === each.to ? styles.active_tab : styles['header-tab']
                            }
                          >
                            {each.text}
                          </div>
                        </Link>
                      </React.Fragment>
                    ))}
                  </div>
                ) : (
                  <div className={styles.tab_container}>
                    <TabsWrapper
                      tabHeaders={tabHeaders}
                      activeTabValue={defaultTabState}
                      onTabChange={onTabChange}
                    />
                  </div>
                )}
                {!userDetails.isLogged ? (
                  <>
                    <div className={styles['header-btns']}>
                      <div>
                        <button
                          className={styles.login}
                          data-testid="login"
                          onClick={() => {
                            push('/login')
                          }}
                        >
                          <img src={login} className={styles.log} alt="login icon" />

                          {t('login.login')}
                        </button>
                      </div>
                      <div>
                        <button
                          className={styles['common-btn']}
                          data-testid="signup"
                          onClick={() => {
                            push('/sign-up')
                          }}
                        >
                          {t('signUp')}
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={styles['header-btns']}>
                    <div className={styles['chat-head']}>
                      <img
                        style={{ opacity: 0.4 }}
                        src={notificationIcon}
                        alt="notifications"
                        onClick={() => setRight(true)}
                      />
                      {notifications > 0 ? (
                        <div className={styles['chat-unread-counter']}>
                          {notifications > 100 ? '99+' : notifications}
                        </div>
                      ) : null}
                    </div>
                    <div className={styles['chat-head']}>
                      <img
                        src={chatIcon}
                        alt="chat"
                        className={styles.iconMargins}
                        onClick={() => push(link)}
                      />
                      {totalUnread ? (
                        <div className={styles['chat-unread-counter']}>
                          {totalUnread > 100 ? '99+' : totalUnread}
                        </div>
                      ) : null}
                    </div>
                    <>
                      <DropDown
                        menuProps={{
                          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                          transformOrigin: { vertical: 'top', horizontal: 'right' },
                        }}
                        ListArray={ListArray}
                      >
                        {userDetails.imageUrl ? (
                          <>
                            <div className={styles.icon}>
                              <img
                                className={styles.userIcon}
                                src={userDetails.imageUrl}
                                alt="user"
                              />
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              width: '48px',
                              height: '48px',
                              display: 'flex',
                              justifyContent: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            <div className={styles.icon}>
                              <div className={styles.backgroundImg}>
                                {userDetails.firstName && userDetails.lastName
                                  ? `${userDetails.firstName
                                      .toUpperCase()
                                      .charAt(0)}${userDetails.lastName.toUpperCase().charAt(0)}`
                                  : ''}
                              </div>
                            </div>
                          </div>
                        )}
                      </DropDown>
                    </>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className={openMenu ? styles.menuActive : styles.menu}>
                  <div className={styles['close-menu-icon-wrapper']}>
                    <div className={styles['close-menu']} onClick={() => setOpenMenu(!openMenu)}>
                      <img src={closeMenu} alt="close" />
                    </div>
                  </div>
                  <div className={styles['menu-authenticationPart']}>
                    <div>
                      {/* <Link to="/login">
                    <button className={styles.login} data-testid="login">
                      <img src={login} alt="login" className={styles.divImg} />
                      <div className={styles['button-text']}>Login</div>
                    </button>
                  </Link> */}
                    </div>
                    <div>
                      <Link to="/sign-up">
                        <button className={styles['common-btn']}>Sign Up</button>
                      </Link>
                    </div>
                  </div>
                  <div
                    className={styles['mobile-menus']}
                    onClick={() => {
                      setOpenMenu(false)
                    }}
                  >
                    {commonHeaderLinks.map((each, index) => (
                      <React.Fragment key={index}>
                        <a href={each.to}>
                          <div className={styles['header-tab-mobile-wrapper']}>
                            <span
                              className={
                                location.pathname === each.to
                                  ? styles.active_tab_mobile
                                  : styles['header-tab-mobile']
                              }
                            >
                              {each.text}
                            </span>
                          </div>
                        </a>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
                <div className={styles['hamburger-menu']}>
                  <img
                    src={headerMenu}
                    alt="menu"
                    onClick={() => {
                      setOpenMenu(true)
                    }}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
      <DialogWrapper
        onClose={() => {
          setdataPopup(false)
        }}
        open={dataPopup}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <PasswordForm
          closePopup={() => {
            setdataPopup(false)
          }}
        />
      </DialogWrapper>
      <Notifications
        right={right}
        onClose={() => {
          setRight(false)
          getNotificationCount()
        }}
      />
    </>
  )
}
