import React from 'react'
import star from '../../assets/images/svg/star.svg'
import timeIcon from '../../assets/images/svg/time-icon.svg'
import styles from './ReviewCard.module.scss'

export const ReviewCard: React.FC<{
  readonly expire: string
  readonly subject: string
  readonly review: string
  readonly UserImg?: React.FC
  readonly username: string
  readonly starRating: number
  readonly responded?: boolean
}> = ({ expire, starRating, subject, review, UserImg, username, responded = false }) => {
  const elem = Array.from({ length: starRating }, (_, index) => index + 1)

  return (
    <div className={styles['review-card']}>
      <div className={styles['review-card__time']}>
        <div className={styles['review-card__align-items']}>
          <img src={timeIcon} alt="time icon" className={styles['review-card__time-icon']} />
          <div className={styles['review-card__time-subject']}>{expire}</div>
        </div>
      </div>
      <div className={styles['review-card__subject']}>{subject}</div>
      <div className={styles['review-card__review']}>{review}</div>
      <div className={styles['review-card__profile']}>
        {UserImg ? <UserImg /> : <></>}
        <div className={styles['review-card__star-rating']}>
          <div className={styles['review-card__username']}>{username}</div>
          <div className={styles['review-card__rating']}>
            {elem.map((item) => {
              return (
                <img
                  src={star}
                  alt="star icons"
                  key={'star' + item}
                  className={styles['review-card__rating-icons']}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
