/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { Autocomplete, Pagination } from '@material-ui/lab'
// import { CloseOutlined } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import boxView from '../../assets/images/svg/box-view.svg'
import listView from '../../assets/images/svg/list-view.svg'
import Search from '../../assets/images/svg/search.svg'
import sortFilter from '../../assets/images/svg/sort-icon.svg'
import sortIcon from '../../assets/images/svg/sort-request.svg'
import { AutocompleteOption } from '../../common/autocomplete-option/AutocompleteOption'
import { DropDown, ListArrayItemProps } from '../../common/drop-down/DropDown'
import { EmptySection } from '../../common/empty-section/EmptySection'
import { useFetch } from '../../common/https'
import { Loader } from '../../common/Loader/Loader'
import { LoadingWrapper } from '../../common/loading-wrapper/LoadingWrapper'
import { RequestListCard } from '../../common/request-list-card/RequestListCard'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { TabItem } from '../../components/tab-item/TabItem'
import { TabsWrapper, TabWrapperProps } from '../../components/tabs-wrapper/TabsWrapper'
import styles from './TeacherRequestList.module.scss'

export const TeacherRequestList: React.FC<{
  readonly isTeams?: boolean
  readonly statsFunction?: () => void
}> = ({ isTeams = false, statsFunction = () => {} }) => {
  const { t } = useTranslation()
  const { fetchRest, sectionLoading } = useFetch({ requests: true })
  const { userDetails } = useUserDetailsContext()

  const [count, setCount] = useState<number>(0)
  const [options, setOptions] = useState<
    ReadonlyArray<{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any
    }>
  >([])
  const [data, setData] = useState<
    ReadonlyArray<{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any
    }>
  >([])
  const [filterTag, setFilterTag] = useState({ index: 0, value: 'All', label: 'Show all' })
  const [dropdownTag, setDropdownTag] = useState({
    index: 0,
    value: 'dateCreated',
    label: 'By Date',
    sort: 'DESC',
  })
  const [currentTab, setCurrentTab] = useState<number>(0)
  const [paginationState, setPaginationState] = useState<{
    readonly perPage: number
    readonly page: number
  }>({
    perPage: 9,
    page: 1,
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [searchItems, setSearchItems] = useState<ReadonlyArray<{ [key: string]: any }>>([
    ...userDetails.teacherSkill.map((each: any) => {
      return each.subCategory ? each.subCategory : each.category
    }),
    ...userDetails.teacherAreaOfExpertise.map((each: any) => {
      return each.subCategory ? each.subCategory : each.category
    }),
  ])

  const fetchAndSetOptions = () => {
    fetchRest({ method: 'GET', url: 'requestCategoryAndSubCategory' })
      .then((res) => {
        if (res && res.data && res.data.data) {
          setOptions(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            res.data.data.map((each: any) => ({
              ...each,
              id: each.categoryId ? each.categoryId : each.subCategoryId,
            }))
          )
        }
      })
      .catch()
  }

  const onTabChange = (activeTab: number) => {
    setCurrentTab(activeTab)
  }

  const getRequestListBody = () => {
    const categories =
      searchItems && searchItems.length
        ? searchItems.filter((each) => each.categoryId).map((each) => each.categoryId)
        : []
    const subCategories =
      searchItems && searchItems.length
        ? searchItems.filter((each) => each.subCategoryId).map((each) => each.subCategoryId)
        : []

    return { categories, subCategories }
  }

  const fetchAndSetData = () => {
    fetchRest(
      {
        method: 'PUT',
        url: 'trainerRequestList',
        queryParams: {
          type: filterTag.value,
          page: paginationState.page,
          perPage: paginationState.perPage,
          orderBy: dropdownTag.value,
          sort: dropdownTag.sort,
        },
        data: getRequestListBody(),
      },
      'requests'
    )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res) => {
        if (res && res.data && res.data.data) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const massagedData = res.data.data.map((each: any) => {
            let subject: string = ''
            if (each?.category?.name) {
              subject = each.category.name
            }
            if (each?.subCategory?.name) {
              subject = each.subCategory.name
            }
            if (each?.course?.name) {
              subject = each.course.name
            }
            return {
              ...each,
              subject,
            }
          })
          setData(massagedData)
          setCount(res?.data?.count)
        }
      })
      .catch()
  }

  const setSort = (variantIndex: number) => {
    if (variantIndex === 1) {
      setDropdownTag({ index: 1, value: 'dateCreated', label: 'Expires Soon', sort: 'ASC' })
    } else {
      setDropdownTag({ index: 0, value: 'dateCreated', label: 'By Date', sort: 'DESC' })
    }
  }

  const setFilter = (variantIndex: number) => {
    switch (variantIndex) {
      case 1:
        setFilterTag({ index: 1, value: 'unanswered', label: 'Unanswered only' })
        break
      case 2:
        setFilterTag({ index: 2, value: 'answered', label: 'Answered only' })
        break
      default:
        setFilterTag({ index: 0, value: 'All', label: 'Show all' })
    }
  }

  const listDropDown: ReadonlyArray<ListArrayItemProps> = [
    {
      listContent: (
        <>
          <div className={styles.element}>
            <div>{t('teacher_dashboard.byDate')}</div>
          </div>
        </>
      ),
      value: 'dateCreated',
      onClick: (_e) => {
        setSort(0)
      },
    },
    {
      listContent: (
        <>
          <div className={styles.element}>
            <div>{t('teacher_dashboard.expiresSoon')}</div>
          </div>
        </>
      ),
      value: 'expiresSoon',
      onClick: (_e) => {
        setSort(1)
      },
    },
  ]

  const listFilter: ReadonlyArray<ListArrayItemProps> = [
    {
      listContent: (
        <>
          <div className={styles.element}>
            <div>{t('teacher_dashboard.showAll')}</div>
          </div>
        </>
      ),
      value: 'All',
      onClick: (_e) => {
        setSearchItems([])
        setFilter(0)
        setPaginationState((prev) => {
          return {
            ...prev,
            page: 1,
          }
        })
      },
    },
    {
      listContent: (
        <>
          <div className={styles.element}>
            <div>{t('teacher_dashboard.unAnsweredOnly')}</div>
          </div>
        </>
      ),
      value: 'unanswered',
      onClick: (_e) => {
        setSearchItems([])
        setFilter(1)
        setPaginationState((prev) => {
          return {
            ...prev,
            page: 1,
          }
        })
      },
    },
    {
      listContent: (
        <>
          <div className={styles.element}>
            <div>{t('teacher_dashboard.answeredOnly')}</div>
          </div>
        </>
      ),
      value: 'answered',
      onClick: (_e) => {
        setSearchItems([])
        setFilter(2)
        setPaginationState((prev) => {
          return {
            ...prev,
            page: 1,
          }
        })
      },
    },
  ]

  const tabHeaders: ReadonlyArray<TabWrapperProps> = [
    {
      key: 'box view',
      TabItemWrapper: ({ isActive }) => {
        return (
          <TabItem
            isActive={isActive}
            Img={() => {
              return <img src={boxView} alt="box view" />
            }}
          />
        )
      },
    },
    {
      key: 'list view',
      TabItemWrapper: ({ isActive }) => {
        return (
          <TabItem
            isActive={isActive}
            Img={() => {
              return <img src={listView} alt="list view" />
            }}
          />
        )
      },
    },
  ]

  useEffect(() => {
    fetchAndSetOptions()
  }, [])

  useEffect(() => {
    fetchAndSetData()
  }, [paginationState, dropdownTag, searchItems])
  return (
    <>
      <div className={`${styles.section} ${styles.search_section}`}>
        <div className={styles.search_heading}>
          {isTeams ? t('teams.teamsCount') : t('teacher_dashboard.requestsFound')}
          <span className={styles.request_count}>{count}</span>
        </div>
        <div className="autocomplete-wrapper autocomplete_disable_icon_animation">
          <Autocomplete
            popupIcon={<img src={Search} alt="search_icon" />}
            placeholder={t('teacher_dashboard.searchRequestsIEDesignOrFigma')}
            value={searchItems as any[]}
            onChange={(_event, newValue) => {
              setPaginationState((prev) => ({ ...prev, page: 1 }))
              setSearchItems(newValue)
            }}
            multiple
            getOptionSelected={(option) =>
              searchItems.find((each) => each.id === option.id) ? true : false
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderOption={(option: any) => (
              <AutocompleteOption
                option={
                  <>
                    {option.name}
                    <span className={styles.option_count}>
                      {option.requestCount ? option.requestCount : 0}
                    </span>
                  </>
                }
              />
            )}
            ListboxProps={{
              style: {
                maxHeight: '147px',
              },
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            options={options as any[]}
            getOptionLabel={(option) => {
              return option.name
            }}
            ChipProps={{
              deleteIcon: <CloseOutlined />,
            }}
            classes={{ tag: 'autocomplete-custom-tag' }}
            style={{ width: '100%', position: 'relative' }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('teacher_dashboard.searchRequestsIEDesignOrFigma')}
                variant="outlined"
              />
            )}
          />
        </div>
        <div className={styles['list-card-footer']}>
          <div className={styles['d-flex']}>
            <div className={styles.dropdown_container}>
              <DropDown
                menuProps={{
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  transformOrigin: { vertical: 'top', horizontal: 'left' },
                }}
                ListArray={listDropDown}
                selectedItem={dropdownTag.index}
              >
                <div className={styles['list-filter']}>
                  <img src={sortFilter} alt="sort" /> {dropdownTag.label}
                </div>
              </DropDown>
            </div>
            <div className={styles.dropdown_container}>
              <DropDown
                menuProps={{
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  transformOrigin: { vertical: 'top', horizontal: 'left' },
                }}
                ListArray={listFilter}
                selectedItem={filterTag.index}
              >
                <div className={styles['list-dropdown']}>
                  <img src={sortIcon} alt="sort" /> {filterTag.label}
                </div>
              </DropDown>
            </div>
          </div>
          <div className={styles.contain}>
            <TabsWrapper
              tabHeaders={tabHeaders}
              activeTabValue={currentTab}
              onTabChange={onTabChange}
            />
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <LoadingWrapper
          isLoading={sectionLoading && sectionLoading.requests}
          LoaderComponent={() => (
            <div className={styles.loader_wrapper}>
              <Loader />
            </div>
          )}
        >
          <div
            className={`${styles.request_container} ${
              data && data.length && data.length % 3 ? styles.request_container_after : ''
            }`}
          >
            {data && data.length ? (
              <>
                {data.map((each) => {
                  return (
                    <React.Fragment key={each.requestId}>
                      <div
                        className={
                          currentTab === 0
                            ? styles.request_card
                            : `${styles.request_card} ${styles.fullWidth}`
                        }
                      >
                        <RequestListCard
                          data={each}
                          callback={() => {
                            fetchAndSetData()
                          }}
                          isTeacher={true}
                          actionMenu={each.response && each.response.length}
                          resetProposalStatus={() => {
                            fetchAndSetData()
                          }}
                          statsFunction={statsFunction}
                        />
                      </div>
                    </React.Fragment>
                  )
                })}
              </>
            ) : (
              <>
                <EmptySection subText={t('teacher_dashboard.youGotNoRequestsYet')} />
              </>
            )}
          </div>
          {data && data.length ? (
            <div className={styles.pagination_wrapper}>
              <Pagination
                page={paginationState.page}
                onChange={(event, value) => {
                  setPaginationState((prev) => ({ ...prev, page: value }))
                }}
                count={Math.ceil(count / paginationState.perPage)}
              />
            </div>
          ) : (
            <></>
          )}
        </LoadingWrapper>
      </div>
    </>
  )
}
