import React from 'react'
import timeIcon from '../../assets/images/svg/time-icon.svg'
import styles from './AutocompleteOption.module.scss'

export const AutocompleteOption: React.FC<{ option: React.ReactNode }> = ({ option }) => {
  return (
    <div className={styles['autocomplete-dropdown']}>
      <img src={timeIcon} alt="time-icon" />
      <div>{option}</div>
    </div>
  )
}
