/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { debounce, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PopUPIcon from '../../assets/images/svg/popup-icon.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import tick from '../../assets/images/svg/tick.svg'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { AutocompleteOption } from '../../common/autocomplete-option/AutocompleteOption'
import { Button } from '../../common/button/Button'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { useFetch } from '../../common/https'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import { Loader } from '../../common/Loader/Loader'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import {
  categoryAndSoftwareSchema,
  queryCategorySchema,
  querySchema,
} from '../../common/schema/Schema'
import { UsePopupContext } from '../../common/use-popup-context'
import { validateInput } from '../../common/ValidateFields'
import { UserDetailsForm } from '../user-details-form/UserDetailsForm'
import styles from './SubmitRequest.module.scss'

interface CategorySubCategoryType {
  id: string
  name: string
  categoryId?: string
  subCategoryId?: string
  isValid: boolean
  errorMsg: string
  isTouched: boolean
}
export const SubmitRequest: React.FC<{
  readonly isDashboard?: boolean
  readonly className?: string
  readonly teamId?: string
  readonly statsFunction?: () => void
}> = ({ isDashboard, className, teamId = '', statsFunction = () => {} }) => {
  const { t } = useTranslation()
  let [categoryValue, setCategoryValue] = useState<{ [key: string]: any }>({})
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [categoryArray, setCategoryArray] = useState<Array<CategorySubCategoryType>>([])
  const [dropDown, setDropDown] = useState({
    category: {
      value: {
        id: '',
        name: '',
        categoryId: '',
        subCategoryId: '',
        isValid: false,
        errorMsg: '',
        isTouched: false,
      },
      isValid: false,
      isTouched: false,
      errorMsg: 'Please enter a category',
    },
  })
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { close, setData: setPopupData } = UsePopupContext()
  const [show, setShow] = useState(false)

  let [query, setQuery] = useState<{
    readonly value: string
    readonly isValid: boolean
    readonly isTouched: boolean
    readonly errorMsg: string
  }>({
    value: '',
    isValid: false,
    errorMsg: 'Please Enter Question',
    isTouched: false,
  })

  let [queryCategory, setQueryCategory] = useState<{
    readonly value: string
    readonly isValid: boolean
    readonly isTouched: boolean
    readonly errorMsg: string
  }>({
    value: '',
    isValid: false,
    errorMsg: 'Please enter a category',
    isTouched: false,
  })
  const { fetchRest, sectionLoading } = useFetch({ submitRequest: false })

  const fetchCategoryOrSoftware = () => {
    fetchRest({
      url: '/requestCategoryAndSubCategory',
      method: 'GET',
      isFullPageLoader: false,
    }).then((res: any) => {
      if (res && res.data && res.data.data) {
        setCategoryArray(
          res.data.data.map((each: any) => {
            return { id: each.subCategoryId ? each.subCategoryId : each.categoryId, ...each }
          })
        )
      } else {
        setCategoryArray([])
      }
    })
  }

  const autoCompleteOnChange = (localValue: any, key: string) => {
    if (localValue?.id) {
      var valid = validateInput(categoryAndSoftwareSchema, localValue)
      setDropDown((prev: any) => {
        return {
          ...prev,
          [key]: {
            value: localValue,
            isValid: valid.isValid,
            isTouched: true,
            errorMsg: valid.errorMsg,
          },
        }
      })
    } else {
      setDropDown((prev: any) => {
        return {
          ...prev,
          [key]: {
            value: '',
            isValid: false,
            isTouched: true,
            errorMsg: 'Please select the Category',
          },
        }
      })
    }
  }
  const onChangeQuery = (e: any) => {
    setQuery({
      ...e,
      isTouched: true,
    })
    if (isDashboard) {
      debouncedSave(e.value)
    }
  }
  const debouncedSave = useCallback(
    debounce((nextValue: React.SetStateAction<string>) => {
      fetchRest({
        url: 'searchCatalogue',
        method: 'GET',
        queryParams: { search: nextValue, returnAllOnEmpty: 'T' },
        isFullPageLoader: false,
      }).then((res) => {
        if (res && res.data && res.data.data) {
          const massagedCategories = res.data.data.map((each: any) => {
            return { id: each.subCategoryId ? each.subCategoryId : each.categoryId, ...each }
          })
          const selectedCategory = dropDown?.category?.value?.id ? dropDown.category.value.id : ''
          if (selectedCategory) {
            const isCategoryAvailable = massagedCategories.find(
              (each: any) => each.id === selectedCategory
            )
            if (!isCategoryAvailable) {
              setDropDown((prev: any) => {
                return {
                  ...prev,
                  category: { ...prev.category, value: {} },
                }
              })
            }
          }
          setCategoryArray(massagedCategories)
        } else {
          setCategoryArray([])
        }
      })
    }, 1000),
    [dropDown]
  )
  const closePopup = () => {
    close()
    setQuery((prev: any) => {
      return {
        value: '',
        isTouched: false,
        isValid: false,
        errorMsg: '',
      }
    })
    if (show) {
      setQueryCategory((prev: any) => {
        return {
          value: '',
          isTouched: false,
          isValid: false,
          errorMsg: '',
        }
      })
    }
    setDropDown((prev: any) => {
      return {
        ...prev,
        category: { ...prev.category, value: '', isTouched: false, isValid: false, errorMsg: '' },
      }
    })
    setShow(false)
  }
  const openPopup = (success: boolean) => {
    let content: string = ''
    let headerContent: string = ''
    if (success) {
      headerContent = 'Great, you’ve submitted your learning request!'
      content = 'Now sit tight, one of the ontelo experts will be in touch with you soon'
    } else {
      headerContent = 'Something went wrong'
      content =
        'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? PopUPIcon : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button className={styles.white} text={'Got It'} onClick={closePopup}></Button>
                </>
              ) : (
                <>
                  <Button text={'Cancel'} className={styles['cancel-btn']} onClick={close}></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }
  const handleClickOpen = (e: any) => {
    e.preventDefault()
    setDropDown((prev: any) => {
      return {
        ...prev,
        category: { ...prev.category, isTouched: true },
      }
    })
    setQuery((prev) => {
      return {
        ...prev,
        isTouched: true,
      }
    })
    if (show) {
      setQueryCategory((prev) => {
        return {
          ...prev,
          isTouched: true,
          errorMsg: 'Please enter a category',
        }
      })
    }
    if (query && query.isValid && dropDown.category.isValid) {
      if (!isDashboard) {
        setIsOpen(true)
      } else {
        fetchRest(
          {
            url: '/userSubmitRequest',
            method: 'POST',
            data: {
              description: query.value,
              categoryId: show
                ? undefined
                : dropDown.category.value?.categoryId
                ? dropDown.category.value.categoryId
                : undefined,
              subCategoryId: show
                ? undefined
                : dropDown.category.value?.subCategoryId
                ? dropDown.category.value.subCategoryId
                : undefined,
              isOther: show ? show : undefined,
              otherDescription: show ? queryCategory.value : undefined,
            },
            queryParams: teamId ? { teamId } : {},
          },
          'submitRequest'
        )
          .then((res: any) => {
            openPopup(true)
            fetchCategoryOrSoftware()
            statsFunction()
          })
          .catch((err: any) => {
            openPopup(false)
          })
      }
    }
  }
  const handleClose = () => {
    setIsOpen(false)
    setQuery({
      value: '',
      isTouched: false,
      isValid: false,
      errorMsg: 'Please Enter Question',
    })
  }

  useEffect(() => {
    if (isDashboard) {
      fetchCategoryOrSoftware()
    }
  }, [])

  return (
    <>
      <div className={isDashboard ? styles.dashboard : styles['submit-form']}>
        <NoEventsLoader isLoading={sectionLoading && sectionLoading.submitRequest}>
          <form
            className={isDashboard ? styles.dashboard : styles['submit-form']}
            onSubmit={handleClickOpen}
          >
            <div className={styles.outer}>
              <InputWrapper
                valueObj={query}
                className={className ? className : styles['submit-text']}
                type="text"
                schema={querySchema}
                onChange={onChangeQuery}
                validateOnChange={true}
                placeholder={t('homeLanding.placeholder.textField1')}
              />
            </div>
            {isDashboard ? (
              <div className={styles.autocompletePosition}>
                <div className="autocomplete-wrapper dashboard">
                  <Autocomplete
                    value={dropDown.category.value}
                    onChange={(event, newValue) => {
                      autoCompleteOnChange(newValue, 'category')
                      if (newValue?.categoryId === '-1') {
                        setShow(true)
                      } else {
                        setShow(false)
                      }
                    }}
                    handleHomeEndKeys
                    options={categoryArray}
                    getOptionLabel={(option) => {
                      return option.name
                    }}
                    renderOption={(option: any) => <AutocompleteOption option={option.name} />}
                    ListboxProps={{
                      style: {
                        maxHeight: '147px',
                      },
                    }}
                    style={{
                      width: '100%',
                      position: 'relative',
                      border: 'none !important',
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Specify category or software..."
                      />
                    )}
                  />
                </div>
                {dropDown.category.isValid === false && dropDown.category.isTouched === true ? (
                  <div className={styles.error}>{dropDown.category.errorMsg}</div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
            {show ? (
              <>
                <div className={styles.outer}>
                  <InputWrapper
                    valueObj={queryCategory}
                    className={className ? className : styles['submit-text']}
                    type="text"
                    schema={queryCategorySchema}
                    onChange={(e: any) => {
                      setQueryCategory({
                        ...e,
                        isTouched: true,
                      })
                    }}
                    validateOnChange={true}
                    placeholder="Enter the category of your request"
                  />
                </div>
              </>
            ) : (
              <></>
            )}
            <div className={styles['apply-btn']}>
              <Button
                text="Find a mentor"
                type="submit"
                ButtonImg={() => {
                  return (
                    <>
                      {sectionLoading && sectionLoading.submitRequest ? (
                        <Loader className="form_loader" size={20} thickness={2} />
                      ) : (
                        <>
                          <img src={tick} alt="apply-now" className={styles['btn-img']} />
                        </>
                      )}
                    </>
                  )
                }}
              />
            </div>
          </form>
        </NoEventsLoader>
      </div>
      {!isDashboard ? (
        <DialogWrapper
          Title={(titleProps) => {
            return <DialogHeader {...titleProps} title={t('userDetails.title')} />
          }}
          open={isOpen}
          onClose={handleClose}
          isFullScreen={false}
          fullHeightDialog={true}
        >
          <UserDetailsForm
            btnText={t('sessionDetails.btn-text')}
            metaData={{
              categoryId: categoryValue.categoryId,
              subCategoryId: categoryValue.subCategoryId,
              description: query.value,
            }}
            courseFlag={false}
            needCategory={true}
            onSubmitSuccess={() => {
              handleClose()
            }}
          />
        </DialogWrapper>
      ) : (
        <></>
      )}
    </>
  )
}
