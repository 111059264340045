import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { ElementsWrapper } from '../../common/elements-wrapper/ElementsWrapper'
import { useFetch } from '../../common/https'
import ProtectedRoute from '../../common/protected-route/ProtectedRoute'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { Footer } from '../../components/footer/Footer'
import { Header } from '../../components/header/Header'
import { ProposalList } from '../../components/proposal-list/ProposalList'
import { RequestLearning } from '../../components/request-learning/RequestLearning'
import { RequestList } from '../../components/request-list/RequestList'
import { SessionList } from '../../components/session-list/SessionList'
import { TabItem } from '../../components/tab-item/TabItem'
import { TabsWrapper, TabWrapperProps } from '../../components/tabs-wrapper/TabsWrapper'
import { WelcomeWizard } from '../../components/welcome-wizard/WelcomeWizard'
import styles from './StudentDashboard.module.scss'

const StudentDashboard: React.FC = () => {
  const { push } = useHistory()
  const { fetchRest } = useFetch()
  const { search } = useLocation()

  const [defaultState, setDefaultState] = useState<number>(-1)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const [tabCountValues, setTabCountValues] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }>({
    requestCount: 0,
    proposalCount: 0,
    sessionCount: 0,
  })
  const [tabHeaders, setTabHeaders] = useState<ReadonlyArray<TabWrapperProps>>([])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getTabHeaders: (obj: { [key: string]: any }) => ReadonlyArray<TabWrapperProps> = (
    countValues
  ) => {
    return [
      {
        key: 'Request Learning',
        TabItemWrapper: ({ isActive }) => {
          return <TabItem isActive={isActive} text="Request Learning" />
        },
      },
      {
        key: 'Requests',
        TabItemWrapper: ({ isActive }) => {
          return <TabItem count={countValues.requestCount} isActive={isActive} text="Requests" />
        },
      },
      {
        key: 'Proposal',
        TabItemWrapper: ({ isActive }) => {
          return <TabItem isActive={isActive} count={countValues.proposalCount} text="Proposals" />
        },
      },
      {
        key: 'sessions',
        TabItemWrapper: ({ isActive }) => {
          return <TabItem isActive={isActive} count={countValues.sessionCount} text={'Sessions'} />
        },
      },
    ]
  }
  const { userDetails } = useUserDetailsContext()

  const fetchStatus = () => {
    if (userDetails.userId) {
      fetchRest({
        url: '/studentStats',
        method: 'GET',
      })
        .then((res) => {
          if (res && res.data) {
            setTabCountValues(res.data)
          }
        })
        .catch()
    }
  }
  const handleDialogClose = () => {
    setIsDialogOpen(false)
  }

  const onTabChange = (activeTab: number) => {
    switch (activeTab) {
      case 1:
        push('/learn?tab=requests')
        break
      case 2:
        push('/learn?tab=Proposals')
        break
      case 3:
        push('/learn?tab=Sessions')
        break
      case 4:
        push('/learn?tab=Archived')
        break
      default:
        push('/learn?tab=requestLearning')
    }
  }

  useEffect(() => {
    let searchParams = new URLSearchParams(search)
    let defaultTab: number
    let searchTab = searchParams.get('tab')
    if (searchTab === 'requests') {
      defaultTab = 1
    } else if (searchTab === 'Proposals') {
      defaultTab = 2
    } else if (searchTab === 'Sessions') {
      defaultTab = 3
    } else if (searchTab === 'Archived') {
      defaultTab = 4
    } else {
      defaultTab = 0
    }
    setDefaultState(defaultTab)
    let wizardTab = searchParams.get('welcomeWizard')
    const welcomeWizard = localStorage.getItem('welcomeWizard')
    if (wizardTab === 'true' && welcomeWizard === 'true') {
      setIsDialogOpen(true)
    } else {
      setIsDialogOpen(false)
    }
    fetchStatus()
  }, [search, userDetails.userId])

  useEffect(() => {
    setTabHeaders(getTabHeaders(tabCountValues))
  }, [tabCountValues])

  return (
    <>
      <Header />
      <div className={styles.tabHeaders}>
        <div className={styles.container}>
          <TabsWrapper
            tabHeaders={tabHeaders}
            activeTabValue={defaultState}
            onTabChange={onTabChange}
          />
        </div>
      </div>
      {defaultState === 0 ? <RequestLearning statsFunction={fetchStatus} /> : <></>}
      {defaultState === 1 ? (
        <>
          <RequestList statsFunction={fetchStatus} />
        </>
      ) : (
        <></>
      )}
      {defaultState === 2 ? (
        <div className={styles.tabContainer}>
          <ProposalList inStudentDashboard={true} statsFunction={fetchStatus} />
        </div>
      ) : (
        <></>
      )}
      {defaultState === 3 ? (
        <div className={styles.tabContainer}>
          <SessionList inStudentDashboard={true} statsFunction={fetchStatus} />
        </div>
      ) : (
        <></>
      )}
      <div className={styles.wrapper}>
        <Footer />
      </div>
      <DialogWrapper
        Title={DialogHeader}
        open={isDialogOpen}
        onClose={handleDialogClose}
        isFullScreen={false}
        fullHeightDialog={false}
      >
        <ElementsWrapper>
          <WelcomeWizard closePopup={handleDialogClose} />
        </ElementsWrapper>
      </DialogWrapper>
    </>
  )
}

export const ProtectedStudentDashboard: React.FC = () => {
  return (
    <ProtectedRoute>
      <StudentDashboard />
    </ProtectedRoute>
  )
}
