/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */ import {
  Accordion,
  AccordionDetails,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import DOMPurify from 'dompurify'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import PopUPIcon from '../../assets/images/svg/popup-icon.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import '../../common.scss'
import { AccordionWrapper } from '../../common/accordion-wrapper/AccordionWrapper'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { Button } from '../../common/button/Button'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { useFetch } from '../../common/https'
import { Loader } from '../../common/Loader/Loader'
import { LoadingWrapper } from '../../common/loading-wrapper/LoadingWrapper'
import { UsePopupContext } from '../../common/use-popup-context'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { CourseDetails } from '../course-details/CourseDetails'
import { SummaryWrapper } from '../summary-wrapper/SummaryWrapper'
import { UserDetailsForm } from '../user-details-form/UserDetailsForm'
import styles from './RecommendCourseAccordion.module.scss'

export const RecommendCourseAccordion: React.FC<{
  teamId: string
  selectedCategory: any
  setSelected: any
}> = ({ selectedCategory, setSelected, teamId }) => {
  const [expandAccordion, setExpandAccordion] = useState({
    categoryId: '',
    subCategoryId: '',
  })
  const { userDetails } = useUserDetailsContext()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [accordionData, setAccordionData] = useState<ReadonlyArray<any>>([])
  const { fetchRest, sectionLoading } = useFetch({ accordion: true, submitRequest: false })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedCourse, setSelectedCourse] = useState<any>({})
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false)
  const { close, setData: setPopupData } = UsePopupContext()
  const [courses, setCourses] = useState({
    course: {
      value: '',
      label: '',
      isValid: false,
      isTouched: false,
      errorMsg: 'Please select Course',
    },
  })
  const sanitizedData = (data: any) => ({
    __html: DOMPurify.sanitize(data),
  })
  const openPopup = (success: boolean) => {
    let content: string = ''
    let headerContent: string = ''
    if (success) {
      headerContent = 'Great, you’ve submitted your learning request!'
      content = 'Now sit tight, one of the ontelo experts will be in touch with you soon'
    } else {
      headerContent = 'Something went wrong'
      content =
        'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? PopUPIcon : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.white}
                    text={'Got It'}
                    onClick={() => {
                      close()
                      handleClose()
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button text={'Cancel'} className={styles['cancel-btn']} onClick={close}></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }
  const onClickOpen = () => {
    if (userDetails.isLogged) {
      fetchRest(
        {
          url: '/userSubmitRequest',
          method: 'POST',
          data: {
            description: selectedCourse.title,
            courseId: selectedCourse.courseId,
          },
          queryParams: teamId ? { teamId } : {},
        },
        'submitRequest'
      )
        .then((res: any) => {
          openPopup(true)
        })
        .catch((err) => {
          openPopup(false)
        })
    } else {
      setIsFormOpen(true)
      setTimeout(() => {
        const ele = document.getElementById('user-details-form-wrapper')
        if (ele) {
          ele.scrollIntoView()
        }
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickOpen = (course: any) => {
    setSelectedCourse(course)
    setIsDialogOpen(true)
  }
  const setCourseOnChange = (ele: any, item: any, child: any) => {
    setSelected(ele, item, child)
  }
  const handleClose = () => {
    if (isFormOpen) {
      setIsFormOpen(false)
    } else {
      setIsDialogOpen(false)
    }
  }

  const incrementCount = (obj: { readonly id: string; readonly isCategory: boolean }) => {
    const { id, isCategory } = obj
    fetchRest({
      url: 'incrementCounter',
      data: {
        categoryId: isCategory ? id : undefined,
        subCategoryId: isCategory ? undefined : id,
      },
      isFullPageLoader: false,
      method: 'PUT',
    })
      .then()
      .catch()
  }

  useEffect(() => {
    fetchRest(
      {
        url: 'courseCatalogue',
        method: 'GET',
      },
      'accordion'
    ).then((res) => {
      if (res && res.data && res.data.data) {
        setAccordionData(res.data.data)
      } else {
        setAccordionData([])
      }
    })
  }, [])

  return (
    <div data-testid="accordion" className={styles.accordionSection}>
      <LoadingWrapper
        isLoading={sectionLoading && sectionLoading.accordion}
        LoaderComponent={() => <Loader />}
      >
        {accordionData.length > 0 ? (
          <div className={`${styles['accordionSection ']} accordion-wrapper`}>
            <AccordionWrapper
              expandedPanel={expandAccordion.categoryId ? [expandAccordion.categoryId] : []}
              className={styles.accordionWrapper}
            >
              {({ expanded, onChange }) => (
                <>
                  {accordionData.map((item, key: number) => {
                    return (
                      <>
                        {item.categoryId === selectedCategory?.categoryId ? (
                          <>
                            <AccordionDetails className={styles.accordionDetails} key={key}>
                              <AccordionWrapper
                                className={styles.accordionWrapper}
                                expandedPanel={
                                  expandAccordion.subCategoryId
                                    ? [expandAccordion.subCategoryId]
                                    : []
                                }
                              >
                                {({ expanded: isChildExpanded, onChange: onChildChange }) => (
                                  <>
                                    {item.subCategory.length > 0 ? (
                                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                      item?.subCategory?.map((child: any) => {
                                        return (
                                          <Accordion
                                            id={child.subCategoryId}
                                            key={child.subCategoryId}
                                            className={styles.accordionChild}
                                            onChange={(_event: any, isExpanded: boolean) => {
                                              onChildChange({
                                                panel: child.subCategoryId,
                                                isExpanded,
                                              })
                                              if (isExpanded) {
                                                incrementCount({
                                                  id: child.subCategoryId,
                                                  isCategory: false,
                                                })
                                              }
                                            }}
                                            expanded={isChildExpanded.includes(child.subCategoryId)}
                                          >
                                            <SummaryWrapper
                                              className={styles.dashboardBackground}
                                              expanded={isChildExpanded.includes(
                                                child.subCategoryId
                                              )}
                                              Summary={() => {
                                                return (
                                                  <div className={styles.accordionHeaderSection}>
                                                    {child.imageUrl ? (
                                                      <img
                                                        src={child.imageUrl}
                                                        alt="content icon"
                                                        className={styles.headerIcon}
                                                      />
                                                    ) : (
                                                      <></>
                                                    )}
                                                    <span>{child.name}</span>
                                                    <span className={styles.blue}>
                                                      {child.course.length}
                                                    </span>
                                                  </div>
                                                )
                                              }}
                                            />
                                            <AccordionDetails className={styles.accordionDetails}>
                                              <div className={styles.sessionSection}>
                                                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                                <RadioGroup
                                                  name="radio-buttons-group"
                                                  value={courses.course.value}
                                                  onChange={(e) => {
                                                    let value = e.target.value
                                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                    setCourses((prev: any) => {
                                                      return {
                                                        ...prev,
                                                        course: {
                                                          value: value,
                                                          label: value,
                                                          isValid: true,
                                                          isTouched: true,
                                                          errorMsg: 'Please select course',
                                                        },
                                                      }
                                                    })
                                                  }}
                                                >
                                                  {child.course.length ? (
                                                    child.course.map((ele: any) => {
                                                      return (
                                                        <React.Fragment key={ele.courseId}>
                                                          <div className={styles['radio-wrapper']}>
                                                            <div className="green_radio_buttons">
                                                              <div className={styles.radio_label}>
                                                                <FormControlLabel
                                                                  value={ele.title}
                                                                  control={
                                                                    <Radio
                                                                      size={'medium'}
                                                                      disableRipple
                                                                    />
                                                                  }
                                                                  onChange={() => {
                                                                    setCourseOnChange(
                                                                      ele,
                                                                      child,
                                                                      item
                                                                    )
                                                                  }}
                                                                  label={''}
                                                                  classes={{
                                                                    label: styles.label,
                                                                  }}
                                                                />
                                                                <div
                                                                  onClick={() => {
                                                                    handleClickOpen(ele)
                                                                  }}
                                                                >
                                                                  <div
                                                                    dangerouslySetInnerHTML={sanitizedData(
                                                                      ele.title
                                                                    )}
                                                                  ></div>
                                                                  <div className={styles.duration}>
                                                                    {ele.duration} Hour Session
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </React.Fragment>
                                                      )
                                                    })
                                                  ) : (
                                                    <div className={styles.emptyMessage}>
                                                      {t('common.noCourses')}
                                                    </div>
                                                  )}
                                                </RadioGroup>
                                              </div>
                                            </AccordionDetails>
                                          </Accordion>
                                        )
                                      })
                                    ) : (
                                      <div className={styles.sessionSection}>
                                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                        {item?.course?.length ? (
                                          item?.course?.map((eachSession: any) => {
                                            return (
                                              <>
                                                <React.Fragment key={eachSession.courseId}>
                                                  <div className={styles['radio-wrapper']}>
                                                    <div className="green_radio_buttons">
                                                      <div className={styles.radio_label}>
                                                        <FormControlLabel
                                                          value={eachSession.title}
                                                          control={
                                                            <Radio size={'medium'} disableRipple />
                                                          }
                                                          onChange={() => {
                                                            // setCourseOnChange(eachSession)
                                                          }}
                                                          label={''}
                                                          classes={{
                                                            label: styles.label,
                                                          }}
                                                        />
                                                        <div
                                                          onClick={() => {
                                                            handleClickOpen(eachSession)
                                                          }}
                                                        >
                                                          <div
                                                            dangerouslySetInnerHTML={sanitizedData(
                                                              eachSession.title
                                                            )}
                                                          ></div>
                                                          <div className={styles.duration}>
                                                            {eachSession.duration} Hour Session
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              </>
                                            )
                                          })
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    )}
                                  </>
                                )}
                              </AccordionWrapper>
                            </AccordionDetails>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )
                  })}
                </>
              )}
            </AccordionWrapper>

            <DialogWrapper
              Title={(titleProps) => {
                return (
                  <DialogHeader {...titleProps} title={isFormOpen ? t('userDetails.title') : ''} />
                )
              }}
              open={isDialogOpen}
              onClose={handleClose}
              isFullScreen={false}
            >
              {isFormOpen ? (
                <div id="user-details-form-wrapper">
                  <UserDetailsForm
                    btnText={t('sessionDetails.btn-text')}
                    onSubmitSuccess={() => {
                      handleClose()
                    }}
                    metaData={{ course: selectedCourse }}
                    courseFlag={true}
                  />
                </div>
              ) : (
                <CourseDetails
                  fromTeams={true}
                  selectedCourse={selectedCourse}
                  onSubmitRequest={onClickOpen}
                  isRequestSubmitting={sectionLoading && sectionLoading.submitRequest}
                />
              )}
            </DialogWrapper>
          </div>
        ) : (
          <></>
        )}
      </LoadingWrapper>
    </div>
  )
}
