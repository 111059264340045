/* eslint-disable @typescript-eslint/no-explicit-any */
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import PopUPIcon from '../../assets/images/svg/popup-icon.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import warn from '../../assets/images/svg/warn.svg'
import { Button } from '../../common/button/Button'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { AutocompleteOption } from '../../common/autocomplete-option/AutocompleteOption'
import { Loader } from '../../common/Loader/Loader'
import { useFetch } from '../../common/https'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import { UsePopupContext } from '../../common/use-popup-context'
import styles from './UserDetailsForm.module.scss'
import {
  categorySchema,
  countryCodeSchema,
  firstNameSchema,
  lastNameSchema,
  phoneNumberSchema,
  schemaEmail,
} from '../../common/schema/Schema'

interface CountryType {
  name: string | null
  dialCode: string
  codeId: string
  countryCode: string
  isValid: boolean
  errorMsg: string
  isTouched: boolean
}
interface CategoryType {
  id: string
  name: string
  categoryId?: string
  subCategoryId?: string
  isValid: boolean
  errorMsg: string
  isTouched: boolean
}
// const firstNameSchema = yup.object().shape({
//   value: yup
//     .string()
//     .matches(/^[A-Za-z ]*$/, 'First Name is not valid')
//     .required('First Name is required')
//     .max(40, 'Maximum allowed characters is 40')
//     .test({
//       name: 'trim',
//       message: 'First Name is not valid',
//       test: (value) => {
//         let trimmedValue = value ? value?.trim() : ''
//         return trimmedValue ? true : false
//       },
//     }),
// })
// const lastNameSchema = yup.object().shape({
//   value: yup
//     .string()
//     .matches(/^[A-Za-z ]*$/, 'Last Name is not valid')
//     .required('Last Name is required')
//     .max(40, 'Maximum allowed characters is 40')
//     .test({
//       name: 'trim',
//       message: 'Last Name is not valid',
//       test: (value) => {
//         let trimmedValue = value ? value?.trim() : ''
//         return trimmedValue ? true : false
//       },
//     }),
// })
// const phoneNumberSchema = yup.object().shape({
//   value: yup
//     .string()
//     .required('Phone Number is required')
//     .matches(/^[0-9]+$/, 'Invalid Phone Number')
//     .min(3, 'Invalid Phone Number')
//     .max(15, 'Invalid Phone Number'),
// })
// const countryCodeSchema = yup.object().shape({
//   value: yup.string().required('Please select Code'),
// })
// const categorySchema = yup.object().shape({
//   value: yup.string().required('Please select a Category/Sub Category'),
// })
// const schemaEmail = yup.object().shape({
//   value: yup.string().required('Email is required').email('Enter a valid email'),
// })

export const UserDetailsForm: React.FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly metaData?: { [key: string]: any }
  readonly courseFlag?: boolean
  readonly needCategory?: boolean
  readonly teachers?: boolean
  readonly applyNow?: boolean
  readonly btnText: string
  onSubmitSuccess(): void
}> = ({
  metaData,
  courseFlag = false,
  needCategory = false,
  onSubmitSuccess,
  teachers = false,
  applyNow = false,
  btnText,
}) => {
  const { t } = useTranslation()
  const { fetchRest, sectionLoading } = useFetch({ userDetailsForm: false })
  // @TODO : this needs to be clubed with data object
  const [value, setValue] = React.useState<CategoryType>({
    errorMsg: 'Please select the category',
    isValid: false,
    id: '',
    name: '',
    isTouched: false,
  })
  const [valuePh, setValuePh] = React.useState<CountryType>({
    name: null,
    dialCode: '',
    codeId: '',
    countryCode: '',
    isValid: false,
    errorMsg: 'Please select the code',
    isTouched: false,
  })
  const [optionData, setOptionData] = useState<Array<CategoryType>>([])
  const [phoneData, setPhoneData] = useState<ReadonlyArray<any>>([])
  const [data, setData] = useState({
    firstName: { value: '', isValid: false, isTouched: false, errorMsg: 'Please enter First name' },
    lastName: { value: '', isValid: false, isTouched: false, errorMsg: 'Please enter last name' },
    email: { value: '', isValid: false, isTouched: false, errorMsg: 'Please enter email id' },
    phNo: { value: '', isValid: false, isTouched: false, errorMsg: 'Please enter Phone Number' },
    course: {
      value: metaData?.course?.title,
      isValid: true,
      isTouched: false,
      errorMsg: 'Please enter Your Course',
    },
    address: { value: '', isValid: true, isTouched: false, errorMsg: 'Please enter your Address' },
    interest: {
      value: '',
      isValid: true,
      isTouched: false,
      errorMsg: 'Please enter your Interests',
    },
    skills: { value: '', isValid: true, isTouched: false, errorMsg: 'Please enter your Skills' },
    other: {
      value: metaData?.description,
      isValid: true,
      isTouched: false,
      errorMsg: '',
    },
  })
  const { close, setData: setPopupData } = UsePopupContext()
  const closePopup = () => {
    close()
    onSubmitSuccess()
  }

  const openPopup = (success: boolean) => {
    let headerContent: string = ''
    let content: string = ''
    if (success) {
      if (applyNow) {
        if (teachers) {
          headerContent = t('userDetails.headerContent-teacher')
          content = t('userDetails.content-teacher')
        } else {
          headerContent = t('userDetails.headerContent-student')
          content = t('userDetails.content-student')
        }
      } else {
        headerContent = t('userDetails.headerContent-request')
        content = t('userDetails.content-request')
      }
    } else {
      headerContent = t('userDetails.error-header')
      content = t('userDetails.content-error')
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? PopUPIcon : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.white}
                    text={'Got It'}
                    onClick={() => closePopup()}
                  ></Button>
                </>
              ) : (
                <>
                  <Button
                    text={'Repeat Request'}
                    onClick={(e) => {
                      submitForm(e)
                      close()
                    }}
                    className={styles.white}
                  ></Button>
                  <Button text={'Cancel'} className={styles['cancel-btn']} onClick={close}></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }
  const validateAutoComplete = (schema: yup.AnySchema, e?: any) => {
    try {
      schema.validateSyncAt('value', { value: e })
      return { errorMsg: '', isValid: true }
    } catch (error) {
      const localError = error as any
      return { errorMsg: localError.errors[0] as string, isValid: false }
    }
  }
  const autoCompleteOnChange = (newValue: any, key: string) => {
    if (key === 'valuePh') {
      const valid = validateAutoComplete(countryCodeSchema, newValue?.codeId)
      if (newValue?.codeId) {
        setValuePh({
          ...newValue,
          isValid: valid.isValid,
          errorMsg: valid.errorMsg,
          isTouched: true,
        })
      } else {
        setValuePh({
          dialCode: '',
          codeId: '',
          countryCode: '',
          name: '',
          isValid: valid.isValid,
          errorMsg: valid.errorMsg,
          isTouched: true,
        })
      }
    } else if (key === 'value') {
      const valid = validateAutoComplete(categorySchema, newValue?.id)
      if (newValue?.id) {
        setValue({ ...newValue, isValid: valid.isValid, errorMsg: valid.errorMsg })
      } else {
        setValue({
          id: '',
          name: '',
          isValid: valid.isValid,
          errorMsg: valid.errorMsg,
          isTouched: true,
        })
      }
    }
  }

  const handleChange = (e: any, key: string) => {
    const localData = e.value
    setData((prev) => {
      return {
        ...prev,
        [key]: { value: localData, ...e, isTouched: true },
      }
    })
  }
  const submitForm = (e: any) => {
    e.preventDefault()
    setValue((prev) => {
      return { ...prev, isTouched: true }
    })
    setValuePh((prev) => {
      return { ...prev, isTouched: true }
    })
    setData((prev: any) => {
      let localPrev = { ...prev }
      Object.keys(localPrev).forEach((each: string) => {
        localPrev = { ...localPrev, [each]: { ...localPrev[each], isTouched: true } }
      })
      return localPrev
    })
    if (
      data.firstName.isValid &&
      data.lastName.isValid &&
      data.phNo.isValid &&
      data.email.isValid &&
      ((needCategory && value.isValid) || !needCategory) &&
      valuePh.isValid
    ) {
      fetchRest(
        {
          url: 'submitRequest',
          method: 'POST',
          data: {
            firstName: data.firstName.value,
            lastName: data.lastName.value,
            email: data.email.value,
            phoneCodeId: valuePh?.codeId,
            phone: data.phNo.value,
            address: data.address && data.address.value ? data.address.value : undefined,
            categoryId: value?.categoryId,
            subCategoryId: value?.subCategoryId,
            courseId: metaData?.course?.courseId,
            details: data.other && data.other.value ? data.other.value : undefined,
            skills: data.skills && data.skills.value ? data.skills.value : undefined,
            interests: data.interest && data.interest.value ? data.interest.value : undefined,
            type: applyNow ? 'REGISTER' : 'REQUEST',
            registerType: applyNow ? (teachers ? 'TEACHER' : 'STUDENT') : undefined,
          },
        },
        'userDetailsForm'
      )
        .then((res) => {
          openPopup(true)
        })
        .catch((err) => {
          openPopup(false)
        })
    }
  }

  useEffect(() => {
    fetchRest({
      url: '/requestCategoryAndSubCategory',
      method: 'GET',
    }).then((res) => {
      if (res && res.data && res.data.data) {
        setOptionData(
          res.data.data.map((each: any) => {
            return { id: each.subCategoryId ? each.subCategoryId : each.categoryId, ...each }
          })
        )
      } else {
        setOptionData([])
      }
    })
    fetchRest({
      url: '/phoneCode',
      method: 'GET',
    }).then((res) => {
      if (res && res.data && res.data.data) {
        setPhoneData(res.data.data)
      } else {
        setPhoneData([])
      }
    })
  }, [])

  return (
    <>
      <div>
        <div className={styles.box}>
          <NoEventsLoader isLoading={sectionLoading && sectionLoading.userDetailsForm}>
            <form data-testid="userData" onSubmit={submitForm}>
              <div className={styles.input}>
                <div className={styles.block}>
                  <div className={styles.label}>
                    {t('userDetails.fname')} <span className={styles.error}>*</span>
                  </div>
                  <div>
                    <InputWrapper
                      valueObj={data.firstName}
                      type={'text'}
                      className={styles['input-styles']}
                      onChange={(e: any) => {
                        handleChange(e, 'firstName')
                      }}
                      schema={firstNameSchema}
                      validateOnChange={true}
                      validateOnBlur={true}
                      data-testid="fname"
                    />
                  </div>
                </div>
                <div className={styles.block}>
                  <div className={styles.label}>
                    {t('userDetails.lname')}
                    <span className={styles.error}>*</span>
                  </div>
                  <div>
                    <InputWrapper
                      valueObj={data.lastName}
                      type={'text'}
                      className={styles['input-styles']}
                      onChange={(e: any) => {
                        handleChange(e, 'lastName')
                      }}
                      schema={lastNameSchema}
                      validateOnChange={true}
                      validateOnBlur={true}
                      data-testid="lname"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.input}>
                <div className={styles.block}>
                  <div className={styles.label}>
                    {t('userDetails.email')} <span className={styles.error}>*</span>
                  </div>
                  <div>
                    <InputWrapper
                      valueObj={data.email}
                      type="email"
                      className={styles['input-styles']}
                      onChange={(e: any) => {
                        handleChange(e, 'email')
                      }}
                      schema={schemaEmail}
                      validateOnChange={true}
                      data-testid="email"
                    />
                  </div>
                </div>
                <div className={styles.block}>
                  <div className={styles.label}>
                    {t('userDetails.phno')} <span className={styles.error}>*</span>
                  </div>
                  <div className={styles.ph}>
                    <div className="autocomplete-wrapper phone">
                      <Autocomplete
                        value={valuePh}
                        onChange={(event, newValue) => {
                          autoCompleteOnChange(newValue, 'valuePh')
                        }}
                        style={{
                          maxWidth: '102px',
                          width: '100%',
                          height: '48px',
                          outline: 'none',
                        }}
                        options={phoneData as CountryType[]}
                        getOptionLabel={(option) =>
                          option.dialCode && option.countryCode
                            ? `${option.dialCode}  (${option.countryCode})`
                            : ``
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                      {valuePh.isTouched && !valuePh.isValid ? (
                        <div className={styles.error}>{valuePh.errorMsg}</div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div>
                      <InputWrapper
                        valueObj={data.phNo}
                        type="text"
                        className={styles['input-styles']}
                        onChange={(e: any) => {
                          handleChange(e, 'phno')
                        }}
                        schema={phoneNumberSchema}
                        validateOnChange={true}
                        validateOnBlur={true}
                        data-testid="phno"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.label}>{t('userDetails.address')}</div>
              <div>
                <InputWrapper
                  valueObj={data.address}
                  type="text"
                  className={styles['input-styles']}
                  onChange={(e: any) => {
                    handleChange(e, 'address')
                  }}
                  data-testid="address"
                />
              </div>
              <div className={styles.margins}>
                {needCategory ? (
                  <>
                    <div className={styles.label}>
                      {t('userDetails.category')} <span className={styles.error}>*</span>
                    </div>
                    <div className="autocomplete-wrapper">
                      <Autocomplete
                        value={value}
                        onChange={(event, newValue) => {
                          autoCompleteOnChange(newValue, 'value')
                        }}
                        handleHomeEndKeys
                        options={optionData}
                        getOptionLabel={(option) => {
                          return option.name
                        }}
                        renderOption={(option: any) => <AutocompleteOption option={option.name} />}
                        ListboxProps={{
                          style: {
                            maxHeight: '147px',
                          },
                        }}
                        style={{ maxWidth: '711px', width: '100%' }}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                      />
                    </div>
                    {value.isTouched && !value.isValid ? (
                      <div className={styles.error}>{value.errorMsg}</div>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>

              {courseFlag ? (
                <>
                  <div className={styles.label}>
                    {t('userDetails.course')}
                    <span className={styles.error}>*</span>
                  </div>
                  <div>
                    <InputWrapper
                      valueObj={data.course}
                      disabled
                      type="text"
                      className={styles['input-styles']}
                      onChange={(e: any) => {
                        handleChange(e, 'course')
                      }}
                      data-testid="course"
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
              {applyNow ? (
                <>
                  <div className={styles.label}>{t('userDetails.interest')}</div>
                  <div>
                    <InputWrapper
                      valueObj={data.interest}
                      type="text"
                      className={styles['input-styles']}
                      onChange={(e: any) => {
                        handleChange(e, 'interest')
                      }}
                      data-testid="interest"
                    />
                  </div>
                  {teachers ? (
                    <>
                      <div className={styles.label}>{t('userDetails.skills')}</div>
                      <div>
                        <InputWrapper
                          valueObj={data.skills}
                          type="text"
                          className={styles['input-styles']}
                          onChange={(e: any) => {
                            handleChange(e, 'skills')
                          }}
                          data-testid="skills"
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              <div className={styles.label}>{t('userDetails.other')}</div>
              <textarea
                className={styles['textarea-styles']}
                value={data.other.value}
                onChange={(e) => {
                  handleChange(e.target, 'other')
                }}
                data-testid="other"
              ></textarea>
              <div className={styles['box-btn']}>
                <Button
                  ButtonImg={() => {
                    return (
                      <>
                        {sectionLoading && sectionLoading.userDetailsForm ? (
                          <Loader className="form_loader" size={20} thickness={2} />
                        ) : (
                          <>
                            <img src={warn} alt="apply-now" className={styles['btn-img']} />
                          </>
                        )}
                      </>
                    )
                  }}
                  type="submit"
                  className={styles['btn-color']}
                  text={btnText}
                />
              </div>
            </form>
          </NoEventsLoader>
        </div>
      </div>
    </>
  )
}
