import React, { useState } from 'react'
import styles from './TeamRecommendCourse.module.scss'
import star from '../../assets/images/svg/gray2-star.svg'
import { Button } from '../../common/button/Button'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { RecommendCourseForm } from '../recommend-course-form/RecommendCourseForm'

export const TeamRecommendCourse: React.FC<{ readonly teamId: string }> = ({ teamId }) => {
  const [buttonIsClicked, setButtonIsClicked] = useState<boolean>(false)
  return (
    <div className={styles.section}>
      <div className={styles.title}>
        <div>
          <img src={star} className={styles.divImg} alt="star" />
        </div>
        <div>Recommend courses to team members</div>
      </div>
      <div>
        <Button
          text="Specify course & users"
          className={styles.button}
          onClick={() => {
            setButtonIsClicked(true)
          }}
        />
      </div>
      <DialogWrapper
        Title={DialogHeader}
        open={buttonIsClicked}
        onClose={() => {
          setButtonIsClicked(false)
        }}
        isFullScreen={false}
        fullHeightDialog={false}
      >
        <RecommendCourseForm
          teamId={teamId}
          closePopup={() => {
            setButtonIsClicked(false)
          }}
        />
      </DialogWrapper>
    </div>
  )
}
