/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { debounce } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EmptySection } from '../../common/empty-section/EmptySection'
import { useFetch } from '../../common/https'
import PopUpIcon from '../../assets/images/svg/popup-icon.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import { Loader } from '../../common/Loader/Loader'
import { LoadingWrapper } from '../../common/loading-wrapper/LoadingWrapper'
import { Search } from '../../common/search/Search'
import { CreateProposal } from '../create-proposal/CreateProposal'
import { ProposalListCard } from '../proposal-list-card/ProposalListCard'
import styles from './ProposalList.module.scss'
import grayStar from '../../assets/images/svg/gray-star.svg'
import { SessionCard } from '../session-card/SessionCard'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { CourseDetails } from '../course-details/CourseDetails'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { Button } from '../../common/button/Button'
import { UsePopupContext } from '../../common/use-popup-context'

export const ProposalList: React.FC<{
  readonly proposalDetails?: (pre: any) => void
  readonly callBackForProposal?: () => void
  readonly inStudentDashboard?: boolean
  readonly inChat?: boolean
  readonly studentId?: string
  readonly teamId?: string
  readonly forApproval?: boolean
  readonly statsFunction?: () => void
  readonly memberRole?: any
}> = ({
  inStudentDashboard = false,
  inChat = false,
  memberRole = '',
  callBackForProposal = () => {},
  proposalDetails = () => {},
  studentId = undefined,
  teamId = '',
  forApproval = false,
  statsFunction = () => {},
}) => {
  const { t } = useTranslation()
  const { fetchRest, sectionLoading } = useFetch({ proposalList: false })

  const [count, setCount] = useState(0)
  const [createPopup, setCreatePopup] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [proposals, setProposals] = useState([])
  const [paginationState, setPaginationState] = useState<{
    readonly perPage: number
    readonly page: number
  }>({
    perPage: 9,
    page: 1,
  })
  const [searchItems, setSearchItems] = useState<string>('')

  const { close, setData: setPopupData } = UsePopupContext()

  const [userRecommendation, setUserRecommendation] = useState<
    ReadonlyArray<{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any
    }>
  >([])
  const [selectedCourse, setSelectedCourse] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }>({})
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const getUserRecommendations = () => {
    fetchRest({ url: 'recommendedCourse', method: 'GET' }, 'recommendations')
      .then((res: any) => {
        if (res && res.data && res.data.data) {
          setUserRecommendation(res.data.data)
        } else {
          setUserRecommendation([])
        }
      })
      .catch()
  }

  const openPopup = (success: boolean) => {
    let content: string = ''
    let headerContent: string = ''
    if (success) {
      headerContent = 'Great, you’ve submitted your learning request!'
      content = 'Now sit tight, one of the ontelo experts will be in touch with you soon'
    } else {
      headerContent = 'Something went wrong'
      content =
        'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? PopUpIcon : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.white}
                    text={'Got It'}
                    onClick={() => {
                      close()
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button
                    text={'Cancel'}
                    className={styles['cancel-btn']}
                    onClick={() => {
                      close()
                    }}
                  ></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  const onSubmitRequest = () => {
    // Do API Call here
    fetchRest(
      {
        url: '/userSubmitRequest',
        method: 'POST',
        data: {
          description: selectedCourse.title,
          courseId: selectedCourse.courseId,
        },
      },
      'submitRequest'
    )
      .then((_res) => {
        openPopup(true)
      })
      .catch((err) => {
        openPopup(false)
      })
  }

  const closeDialog = () => {
    setIsDialogOpen(false)
    setTimeout(() => {
      setSelectedCourse({})
    }, 200)
  }

  const fetchProposalList = (obj: {
    readonly search?: string
    readonly page?: number
    readonly perPage?: number
  }) => {
    const {
      search = searchItems ? searchItems : undefined,
      page = paginationState.page,
      perPage = paginationState.perPage,
    } = obj
    fetchRest(
      {
        url: teamId ? `/proposal/team` : `/proposal/${inStudentDashboard ? 'student' : 'teacher'}`,
        method: 'GET',
        queryParams: teamId
          ? !forApproval
            ? {
                search,
                page,
                perPage,
                teamId,
                status: 'Approved',
              }
            : {
                search,
                page,
                perPage,
                teamId,
              }
          : { search, page, perPage, studentId },
        isFullPageLoader: false,
      },
      'proposalList'
    )
      .then((res) => {
        if (res && res.data && res.data.data) {
          setProposals(res.data.data)
          setCount(res.data.count)
        } else {
          setProposals([])
        }
      })
      .catch()
  }

  // const getProposalList = (filterValue: {
  //   sort?: string
  //   page?: number
  //   perPage?: number
  // }) => {
  //   if (filterValue === undefined) {
  //     filterValue = {
  //       perPage: paginationState.perPage,
  //       page: paginationState.page,
  //     }
  //   }
  //   fetchProposalList()
  // }

  const changeHandler = (event: any, value: any) => {
    setPaginationState((prev) => ({ ...prev, page: value }))
    fetchProposalList({ page: value, perPage: paginationState.perPage })
  }

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: nextValue } = e.target
    setPaginationState((prev) => ({ ...prev, page: 1 }))
    setSearchItems(nextValue)
    debouncedSave(nextValue)
  }

  const debouncedSave = useCallback(
    debounce((nextValue: string) => {
      fetchProposalList({ search: nextValue, page: 1 })
    }, 1000),
    []
  )

  useEffect(() => {
    fetchProposalList({})
    if (!teamId) {
      getUserRecommendations()
    }
  }, [])

  return (
    <>
      <div className={styles.rowWrapper}>
        <div
          className={teamId ? `${styles.proposalWrapper} ${styles.mTop}` : styles.proposalWrapper}
        >
          <div
            className={
              inChat
                ? `${styles.section} ${styles.chat_section}`
                : `${styles.section} ${styles.search_section}`
            }
          >
            <div className={styles.search_heading}>
              {t('teacher_dashboard.activeProposals')}:
              <span className={styles.request_count}>&nbsp;{count}</span>
            </div>
            <div>
              <Search
                type="text"
                placeholder={
                  inStudentDashboard
                    ? 'Search proposal or mentor name...'
                    : 'Search proposal or attendee name...'
                }
                onChange={onChangeSearch}
                value={searchItems}
                autoFocus
                autoComplete="off"
              />
            </div>
            {/* <div className={styles['list-card-footer']}>
            {!inStudentDashboard ? (
              <Button
                text={'Create Proposal'}
                onClick={(e) => {
                  setIsEdit(false)
                  setCreatePopup(true)
                }}
                className={styles.white}
                ButtonImg={() => {
                  return <img src={createProposal} alt="create proposal" />
                }}
              ></Button>
            ) : (
              <></>
            )}
          </div> */}
          </div>

          <div className={styles['d-flex']}>
            <div className={`${styles.section} ${styles.proposal_list} ${styles.marginBtm}`}>
              <LoadingWrapper
                isLoading={sectionLoading && sectionLoading.proposalList}
                LoaderComponent={() => <Loader />}
              >
                {proposals.length ? (
                  proposals.map((each: any, index) => {
                    return (
                      <div key={index}>
                        <ProposalListCard
                          forApproval={forApproval}
                          proposalDetails={proposalDetails}
                          callBackFromChat={callBackForProposal}
                          inChat={inChat}
                          data={each}
                          inStudentDashboard={inStudentDashboard || teamId ? true : false}
                          onEdit={() => {
                            fetchProposalList({})
                          }}
                          fetchProposalList={() => {
                            fetchProposalList({})
                            statsFunction()
                          }}
                          teamId={teamId}
                          // teamStats={statsFunction}
                          memberRole={memberRole}
                        />
                      </div>
                    )
                  })
                ) : (
                  <div className={styles.center}>
                    <EmptySection subText="No Proposals here!" />
                  </div>
                )}
                {count ? (
                  <div className={styles.pagination}>
                    <Pagination
                      count={Math.ceil(count / paginationState.perPage)}
                      onChange={changeHandler}
                      page={paginationState.page}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </LoadingWrapper>
            </div>
            {inStudentDashboard ? (
              <div className={styles['request-lists-right']}>
                <div className={styles['review-card__align-items']}>
                  <img src={grayStar} alt="star" className={styles['review-card__time-icon']} />
                  <div className={styles['time-subject']}>
                    {t('requestDetails.recommendations')}
                  </div>
                </div>
                <LoadingWrapper
                  isLoading={sectionLoading && sectionLoading.recommendations}
                  LoaderComponent={Loader}
                >
                  {userRecommendation.map((item) => {
                    return (
                      <SessionCard
                        imageUrl={item.imageUrl}
                        key={item.courseId}
                        header={item.title}
                        timer={item.duration}
                        onCardClick={() => {
                          setSelectedCourse(item)
                          setIsDialogOpen(true)
                        }}
                      />
                    )
                  })}
                </LoadingWrapper>
              </div>
            ) : (
              <> </>
            )}
          </div>
        </div>
      </div>
      <DialogWrapper
        onClose={() => {
          setCreatePopup(false)
        }}
        open={createPopup}
        Title={DialogHeader}
      >
        <CreateProposal
          isEdit={isEdit}
          createProposal={() => {
            setCreatePopup(false)
            statsFunction()
          }}
        />
      </DialogWrapper>
      <DialogWrapper
        Title={(titleProps) => {
          return <DialogHeader {...titleProps} />
        }}
        open={isDialogOpen}
        onClose={closeDialog}
        isFullScreen={false}
      >
        <CourseDetails
          isRequestSubmitting={sectionLoading && sectionLoading.submitRequest}
          selectedCourse={selectedCourse}
          onSubmitRequest={onSubmitRequest}
        />
      </DialogWrapper>
    </>
  )
}
