/* eslint-disable @typescript-eslint/no-explicit-any */
import { Accordion, AccordionDetails } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import PopUPIcon from '../../assets/images/svg/popup-icon.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import '../../common.scss'
import { AccordionWrapper } from '../../common/accordion-wrapper/AccordionWrapper'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { Button } from '../../common/button/Button'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { useFetch } from '../../common/https'
import { Loader } from '../../common/Loader/Loader'
import { LoadingWrapper } from '../../common/loading-wrapper/LoadingWrapper'
import { UsePopupContext } from '../../common/use-popup-context'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { CourseDetails } from '../course-details/CourseDetails'
import { SessionCard } from '../session-card/SessionCard'
import { SummaryWrapper } from '../summary-wrapper/SummaryWrapper'
import { UserDetailsForm } from '../user-details-form/UserDetailsForm'
import styles from './AccordionSection.module.scss'

export const AccordionSection: React.FC<{
  teamId?: string
  selectedData?: any
  readonly inDashboard?: boolean
  readonly statsFunction?: () => void
}> = ({ selectedData, inDashboard = false, teamId = '', statsFunction = () => {} }) => {
  const [expandAccordion, setExpandAccordion] = useState({
    categoryId: '',
    subCategoryId: '',
  })
  const { userDetails } = useUserDetailsContext()
  const { push } = useHistory()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [accordionData, setAccordionData] = useState<ReadonlyArray<any>>([])
  const { fetchRest, sectionLoading } = useFetch({ accordion: true, submitRequest: false })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedCourse, setSelectedCourse] = useState<any>({})
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false)
  const { close, setData: setPopupData } = UsePopupContext()

  const openPopup = (success: boolean) => {
    let content: string = ''
    let headerContent: string = ''
    if (success) {
      headerContent = 'Great, you’ve submitted your learning request!'
      content = 'Now sit tight, one of the ontelo experts will be in touch with you soon'
    } else {
      headerContent = 'Something went wrong'
      content =
        'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? PopUPIcon : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.white}
                    text={'Got It'}
                    onClick={() => {
                      close()
                      handleClose()
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button text={'Cancel'} className={styles['cancel-btn']} onClick={close}></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }
  const onClickOpen = () => {
    if (userDetails.isLogged) {
      fetchRest(
        {
          url: '/userSubmitRequest',
          method: 'POST',
          data: {
            description: selectedCourse.title,
            courseId: selectedCourse.courseId,
          },
          queryParams: teamId ? { teamId } : {},
        },
        'submitRequest'
      )
        .then((res: any) => {
          openPopup(true)
          statsFunction()
        })
        .catch((err) => {
          openPopup(false)
        })
    } else {
      setIsFormOpen(true)
      setTimeout(() => {
        const ele = document.getElementById('user-details-form-wrapper')
        if (ele) {
          ele.scrollIntoView()
        }
      })
    }
  }
  const updateLink = (courseId: number) => {
    push({ search: `?courseId=${courseId}` })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickOpen = (course: any) => {
    setSelectedCourse(course)
    setIsDialogOpen(true)
  }

  const handleClose = () => {
    if (isFormOpen) {
      setIsFormOpen(false)
    } else {
      setIsDialogOpen(false)
      if (!teamId) {
        push({ search: '' })
      }
    }
  }

  const incrementCount = (obj: { readonly id: string; readonly isCategory: boolean }) => {
    const { id, isCategory } = obj
    fetchRest({
      url: 'incrementCounter',
      data: {
        categoryId: isCategory ? id : undefined,
        subCategoryId: isCategory ? undefined : id,
      },
      isFullPageLoader: false,
      method: 'PUT',
    })
      .then()
      .catch()
  }

  useEffect(() => {
    fetchRest(
      {
        url: 'courseCatalogue',
        method: 'GET',
      },
      'accordion'
    ).then((res) => {
      if (res && res.data && res.data.data) {
        setAccordionData(res.data.data)
      } else {
        setAccordionData([])
      }
    })
  }, [])

  useEffect(() => {
    let index: string
    if (selectedData && selectedData.subCategoryId) {
      setExpandAccordion({
        categoryId: accordionData.find((element) => {
          const data = element.subCategory
          return data.find((item: any) => {
            return item.subCategoryId === selectedData.subCategoryId
          })
        }).categoryId,
        subCategoryId: selectedData.subCategoryId,
      })
      index = selectedData.subCategoryId
    } else if (selectedData && selectedData.categoryId) {
      setExpandAccordion({
        categoryId: selectedData.categoryId,
        subCategoryId: '',
      })
      index = selectedData.categoryId
    }
    setTimeout(() => {
      const titleElement = document.getElementById(index)
      if (titleElement && titleElement.scrollIntoView) {
        titleElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }, 1000)
  }, [selectedData])

  return (
    <div data-testid="accordion" className={styles.accordionSection}>
      <div className={styles.marginTop}>
        <LoadingWrapper
          isLoading={sectionLoading && sectionLoading.accordion}
          LoaderComponent={() => <Loader />}
        >
          {accordionData.length > 0 ? (
            <div className={`${styles['accordionSection ']} accordion-wrapper`}>
              {inDashboard ? (
                <div className={styles.dashboardAccordion}>
                  {t('coursesAccordion.dashboardTitle')}
                </div>
              ) : (
                <div className={styles.accordionTitle}>{t('coursesAccordion.title')}</div>
              )}
              <AccordionWrapper
                expandedPanel={expandAccordion.categoryId ? [expandAccordion.categoryId] : []}
                className={styles.accordionWrapper}
              >
                {({ expanded, onChange }) => (
                  <>
                    {accordionData.map((item) => {
                      return (
                        <Accordion
                          id={item.categoryId}
                          key={item.categoryId}
                          className={styles.accordion}
                          onChange={(_event: any, isExpanded: boolean) => {
                            onChange({
                              panel: item.categoryId,
                              isExpanded,
                            })
                            if (isExpanded) {
                              incrementCount({
                                id: item.categoryId,
                                isCategory: true,
                              })
                            }
                          }}
                          expanded={expanded.includes(item.categoryId)}
                        >
                          <SummaryWrapper
                            className={inDashboard ? styles.dashboardBackground : ''}
                            expanded={expanded.includes(item.categoryId)}
                            Summary={() => {
                              return (
                                <div className={styles.accordionHeaderSection}>
                                  {item.imageUrl ? (
                                    <img
                                      src={item.imageUrl}
                                      alt="content icon"
                                      className={styles.headerIcon}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                  <span>{item.name}</span>
                                  <span className={styles.blue}>{item.subCategory.length}</span>
                                </div>
                              )
                            }}
                          />
                          <AccordionDetails className={styles.accordionDetails}>
                            <AccordionWrapper
                              className={styles.accordionWrapper}
                              expandedPanel={
                                expandAccordion.subCategoryId ? [expandAccordion.subCategoryId] : []
                              }
                            >
                              {({ expanded: isChildExpanded, onChange: onChildChange }) => (
                                <>
                                  {item.subCategory.length > 0 ? (
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    item?.subCategory?.map((child: any) => {
                                      return (
                                        <Accordion
                                          id={child.subCategoryId}
                                          key={child.subCategoryId}
                                          className={styles.accordionChild}
                                          onChange={(_event: any, isExpanded: boolean) => {
                                            onChildChange({
                                              panel: child.subCategoryId,
                                              isExpanded,
                                            })
                                            if (isExpanded) {
                                              incrementCount({
                                                id: child.subCategoryId,
                                                isCategory: false,
                                              })
                                            }
                                          }}
                                          expanded={isChildExpanded.includes(child.subCategoryId)}
                                        >
                                          <SummaryWrapper
                                            className={
                                              inDashboard ? styles.dashboardBackground : ''
                                            }
                                            expanded={isChildExpanded.includes(child.subCategoryId)}
                                            Summary={() => {
                                              return (
                                                <div className={styles.accordionHeaderSection}>
                                                  {child.imageUrl ? (
                                                    <img
                                                      src={child.imageUrl}
                                                      alt="content icon"
                                                      className={styles.headerIcon}
                                                    />
                                                  ) : (
                                                    <></>
                                                  )}
                                                  <span>{child.name}</span>
                                                  <span className={styles.blue}>
                                                    {child.course.length}
                                                  </span>
                                                </div>
                                              )
                                            }}
                                          />
                                          <AccordionDetails className={styles.accordionDetails}>
                                            <div className={styles.sessionSection}>
                                              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                              {child.course.length ? (
                                                child.course.map((ele: any) => {
                                                  return (
                                                    <React.Fragment key={ele.courseId}>
                                                      <SessionCard
                                                        imageUrl={ele.imageUrl}
                                                        timer={ele.duration}
                                                        header={ele.title}
                                                        onCardClick={() => {
                                                          handleClickOpen(ele)
                                                          // @TODO change condition
                                                          if ((teamId = '')) {
                                                            updateLink(ele.courseId)
                                                          }
                                                        }}
                                                      />
                                                    </React.Fragment>
                                                  )
                                                })
                                              ) : (
                                                <div className={styles.emptyMessage}>
                                                  {t('common.noCourses')}
                                                </div>
                                              )}
                                            </div>
                                          </AccordionDetails>
                                        </Accordion>
                                      )
                                    })
                                  ) : (
                                    <div className={styles.sessionSection}>
                                      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                      {item?.course?.length ? (
                                        item?.course?.map((eachSession: any) => {
                                          return (
                                            <React.Fragment key={eachSession.courseId}>
                                              <SessionCard
                                                imageUrl={eachSession.imageUrl}
                                                timer={eachSession.duration}
                                                header={eachSession.title}
                                                onCardClick={() => {
                                                  handleClickOpen(eachSession)
                                                  updateLink(eachSession.courseId)
                                                }}
                                              />
                                            </React.Fragment>
                                          )
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  )}
                                </>
                              )}
                            </AccordionWrapper>
                          </AccordionDetails>
                        </Accordion>
                      )
                    })}
                  </>
                )}
              </AccordionWrapper>

              <DialogWrapper
                Title={(titleProps) => {
                  return (
                    <DialogHeader
                      {...titleProps}
                      title={isFormOpen ? t('userDetails.title') : ''}
                    />
                  )
                }}
                open={isDialogOpen}
                onClose={handleClose}
                isFullScreen={false}
              >
                {isFormOpen ? (
                  <div id="user-details-form-wrapper">
                    <UserDetailsForm
                      btnText={t('sessionDetails.btn-text')}
                      onSubmitSuccess={() => {
                        handleClose()
                      }}
                      metaData={{ course: selectedCourse }}
                      courseFlag={true}
                    />
                  </div>
                ) : (
                  <CourseDetails
                    selectedCourse={selectedCourse}
                    onSubmitRequest={onClickOpen}
                    isRequestSubmitting={sectionLoading && sectionLoading.submitRequest}
                  />
                )}
              </DialogWrapper>
            </div>
          ) : (
            <></>
          )}
        </LoadingWrapper>
      </div>
    </div>
  )
}
