/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Button } from '../../common/button/Button'
import whiteTick from '../../assets/images/svg/whiteTick.svg'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import styles from './ConfirmationOtp.module.scss'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { UsePopupContext } from '../../common/use-popup-context'
import DoneIcon from '../../assets/images/png/done.png'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import { cardNumber } from '../../common/schema/Schema'
import { useTranslation } from 'react-i18next'

export const ConfirmationOtp: React.FC<{ readonly onClose: () => void }> = ({ onClose }) => {
  const [counter, setCounter] = useState(480)
  const { t } = useTranslation()
  const [code, setCode] = useState({
    code: { value: '', isValid: false, isTouched: false, errorMsg: 'Please enter the code' },
  })
  const resend = (e: any) => {
    clicked()
    // resendCode(e)
  }
  const clicked = () => {
    if (counter <= 0) {
      setCounter(479)
    }
  }
  const { close, setData: setPopupData } = UsePopupContext()
  const openPopup = (success: boolean) => {
    let content: string = ''
    let headerContent: string = ''
    if (success) {
      headerContent = 'Funds Transferred'
      content = 'Your Payment has been sent Successfully!'
    } else {
      headerContent = 'Something went wrong'
      content =
        'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? DoneIcon : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.white}
                    text={'Got It'}
                    onClick={() => {
                      close()
                      // onSuccess()
                      onClose()
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button
                    text={'Go back!'}
                    onClick={(e) => {
                      close()
                      onClose()
                    }}
                    className={styles.white}
                  ></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  //   const resendCode = (e: any) => {
  //     setCounter(480)
  //     fetchRest(
  //       {
  //         url: '/forgotPassword',
  //         method: 'POST',
  //         data: {
  //           email,
  //         },
  //         isFullPageLoader: false,
  //       },
  //       'forgotPass'
  //     )
  //       .then((res) => {
  //         //   openPopup(true)
  //       })
  //       .catch((err) => {
  //         //   openPopup(false)
  //       })
  //   }
  useEffect(() => {
    const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])
  const onSubmit = (_e: any) => {
    if (code.code.isValid) {
      openPopup(true)
    } else {
      openPopup(false)
    }
  }
  return (
    <div className={`${styles.whiteBackground} ${styles.padding} `}>
      <div className={styles.marginBtm}>
        <div className={styles.boldText}>{t('get-paid.confirmation.otp')} ****</div>
        <div className={styles.marginTop}>
          <InputWrapper
            valueObj={code.code}
            onChange={(_e: any) => {
              setCode((prev: any) => {
                return {
                  ...prev,
                  code: {
                    value: _e.value,
                    isTouched: true,
                    isValid: _e.isValid,
                    errorMsg: _e.errorMsg,
                  },
                }
              })
            }}
            placeholder="Code from SMS"
            schema={cardNumber}
            validateOnBlur={true}
            validateOnChange={true}
          />
        </div>
      </div>
      <div className={styles.commonBtnWrapper}>
        <Button
          ButtonImg={() => {
            return <img src={whiteTick} className={styles.divImg} alt="WhiteTick" />
          }}
          text="Done"
          className={`${styles.white} ${styles.marginRight}`}
          onClick={onSubmit}
        />

        <Button
          text={counter > 0 ? `Resend in ${Math.floor(counter / 60)}:${counter % 60}` : 'Resend'}
          disabled={counter > 0 ? true : false}
          ButtonImg={() => {
            return (
              <>
                {/* {sectionLoading && sectionLoading.forgotPass ? (
                        <Loader className="form_loader" size={20} thickness={2} />
                      ) : (
                        <></>
                      )} */}
              </>
            )
          }}
          onClick={(e: any) => {
            resend(e)
          }}
          className={counter > 0 ? styles.resend : styles.white}
        />
      </div>
    </div>
  )
}
