/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';


import { appConfig } from '../../Config';
import { describeChannel, createMemberArn } from '../../api/ChimeAPI';
import { MessagingService } from '../../services/MessagingService';
import { mergeArrayOfObjects } from '../../utilities/mergeArrays';
import { useUserDetailsContext } from '../../../../common/use-user-details-context'
import AWS from 'aws-sdk'
import { useFetch } from '../../../../common/https'

const ChatMessagingServiceContext = createContext(MessagingService);
const ChatMessagingState = createContext();
const ChatChannelState = createContext();
const ChatInitState = createContext();

const MessagingProvider = ({ children }) => {
  const { fetchRest } = useFetch()
  // const { member, isAuthenticated } = useAuthContext();
  const [timeOutHandler, setTimeOutHandler] = useState(null)
  const [recallTokens, setRecallTokens] = useState(false)
  const { userDetails } = useUserDetailsContext()
  const [member, setMember] = useState(undefined);
  const [startSubscription, setStartSubscription] = useState(false);
  const [messagingService] = useState(() => new MessagingService());
  // Channel related
  const [isChatInitiated, setChatInitiated] = useState({ initState: false });
  const [activeChannel, setActiveChannel] = useState({});
  const [activeChannelMemberships, setActiveChannelMemberships] = useState([]);
  const activeChannelRef = useRef(activeChannel.ChannelArn);
  const [channelList, setChannelList] = useState([]);
  const [unreadChannels, setUnreadChannels] = useState([]);
  const [loadingForChannelMessageFetch, setLoadingForChannelMessageFetch] = useState(false)
  const unreadChannelsListRef = useRef(unreadChannels);
  const [hasMembership, setHasMembership] = useState([]);
  // const hasMembership =
  //   activeChannelMemberships
  //     .map((m) => m.Member.Arn)
  //     .indexOf(createMemberArn(member.userId)) > -1;
  // Messages
  const [messages, setMessages] = useState([]);
  const messagesRef = useRef(messages);
  const channelListRef = useRef(channelList);
  const activeChannelMembershipsRef = useRef(activeChannelMemberships);
  const [channelMessageToken, setChannelMessageToken] = useState('');
  const channelMessageTokenRef = useRef(channelMessageToken);
  // Meeting
  // const [meetingInfo, setMeetingInfo] = useState('');
  const processChannelMessage = async (message) => {
    const promise = Promise.resolve(message);
    const newMessage = await promise.then((m) => m);

    let isDuplicate = false;

    messagesRef.current.forEach((m, i, self) => {
      if ((m.response?.MessageId || m.MessageId) === newMessage.MessageId) {
        isDuplicate = true;
        self[i] = newMessage;
      }
    });

    let newMessages = [...messagesRef.current];

    if (!isDuplicate && newMessage.Persistence === 'PERSISTENT') {
      newMessages = [...newMessages, newMessage];
    }

    setMessages(newMessages);
  };

  const messagesProcessor = async (message) => {
    const messageType = message?.headers['x-amz-chime-event-type'];
    //Her changes have been done.
    const record = JSON.parse(message?.payload);
    switch (messageType) {
      // Channel Messages
      case 'CREATE_CHANNEL_MESSAGE':
      case 'REDACT_CHANNEL_MESSAGE':
      case 'UPDATE_CHANNEL_MESSAGE':
      case 'DELETE_CHANNEL_MESSAGE':
        // Process ChannelMessage
        if (record.Metadata) {
          // const metadata = JSON.parse(record.Metadata);
        }

        if (activeChannelRef.current.ChannelArn === record?.ChannelArn) {
          processChannelMessage(record);
          // const newUnreads = [
          //   ...unreadChannelsListRef.current,
          //   record.ChannelArn,
          // ];
          //setUnreadChannels(newUnreads);
        } else {
          const findMatch = unreadChannelsListRef.current.find(
            (chArn) => chArn === record.ChannelArn
          );
          if (findMatch) return;
          const newUnreads = [
            ...unreadChannelsListRef.current,
            record.ChannelArn,
          ];
          setUnreadChannels(newUnreads);
        }
        break;
      // Channels actions
      case 'CREATE_CHANNEL':
      case 'UPDATE_CHANNEL':
        {
          const newChannelArn = record.ChannelArn;
          const updatedChannelList = channelListRef.current.map((c) => {
            if (c.ChannelArn !== newChannelArn) {
              return c;
            }
            return record;
          });
          setChannelList(updatedChannelList);
          setActiveChannel(record);
        }
        break;
      case 'DELETE_CHANNEL': {
        setChannelList(
          channelListRef.current.filter(
            (chRef) => chRef.ChannelArn !== record.ChannelArn
          )
        );
        break;
      }
      // Channel Memberships
      case 'CREATE_CHANNEL_MEMBERSHIP':
        {
          const newChannel = await describeChannel(
            record.ChannelArn,
            member.userId
          );

          if (newChannel.Metadata) {
            let metadata = JSON.parse(newChannel.Metadata);
            if (metadata.isHidden) return;
          }

          const newChannelList = mergeArrayOfObjects(
            [newChannel],
            channelListRef.current,
            'ChannelArn'
          );
          setChannelList(newChannelList);
        }
        break;
      case 'UPDATE_CHANNEL_MEMBERSHIP':
        if (
          `${appConfig.appInstanceArn}/user/${member.userId}` !==
          record?.InvitedBy.Arn
        ) {
          const channel = await describeChannel(
            record?.ChannelArn,
            member.userId
          );
          const newChannelList = mergeArrayOfObjects(
            [channel],
            channelListRef.current,
            'ChannelArn'
          );
          setChannelList(newChannelList);
        }
        break;
      case 'DELETE_CHANNEL_MEMBERSHIP':
        // You are removed
        if (record.Member.Arn.includes(member.userId)) {
          setChannelList(
            channelListRef.current.filter(
              (chRef) => chRef.ChannelArn !== record.ChannelArn
            )
          );
          if (activeChannelRef.current.ChannelArn === record.ChannelArn) {
            setActiveChannel({});
          }
        } else {
          // Someone else is removed
          const updatedMemberships = activeChannelMembershipsRef.current.filter(
            (m) => m.Member.Arn !== record.Member.Arn
          );
          setActiveChannelMemberships(updatedMemberships);
        }
        break;
      default:
        console.log(`Unexpected message type! ${messageType}`);
    }
  };

  const setupTheConfig = () => {
    // AWS.config.update({
    //   region: 'us-east-1',
    //   credentials: {
    //     accessKeyId: localStorage.getItem('accessKeyId'),
    //     secretAccessKey: localStorage.getItem('secretAccessKey'),
    //     sessionToken: localStorage.getItem('sessionToken'),
    //   }
    // })
    AWS.config.region = 'us-east-1'
    AWS.config.credentials = {
      accessKeyId: localStorage.getItem('accessKeyId'),
      authenticated: true,
      identityId: localStorage.getItem('identityId'),
      secretAccessKey: localStorage.getItem('secretAccessKey'),
      sessionToken: localStorage.getItem('sessionToken'),
      sessionExpiration: localStorage.getItem('sessionExpiration')
    };
  }

  const getNewCredentials = async () => {
    localStorage.removeItem('accessKeyId')
    localStorage.removeItem('secretAccessKey')
    localStorage.removeItem('sessionToken')
    localStorage.removeItem('sessionExpiration')
    const result = await fetchRest({
      url: '/getTempCredentials ',
      method: 'POST',
      data: {
        idToken: localStorage.getItem('idToken'),
        identityId: localStorage.getItem('identityId')
      },
      isFullPageLoader: false
    })
    if (result && result.data && result.data.identity) {
      localStorage.setItem('accessKeyId', result.data.identity.Credentials.AccessKeyId)
      localStorage.setItem('secretAccessKey', result.data.identity.Credentials.SecretKey)
      localStorage.setItem('sessionToken', result.data.identity.Credentials.SessionToken)
      localStorage.setItem('sessionExpiration', result.data.identity.Credentials.Expiration)
      setupTheConfig();
    } else {
      console.error(result)
    }
  }

  useEffect(() => {
    if (recallTokens) {
      const timer = new Date(localStorage.getItem('sessionExpiration')) - new Date() - 300000;
      if (timer > 0 && userDetails.isLogged) {
        const handler = setTimeout(() => {
          getNewCredentials()
          clearTimeout(timeOutHandler);
          setRecallTokens(true);
        }, timer);
        setTimeOutHandler(handler);
      }
    }
    setRecallTokens(false);
    return () => {
      clearTimeout(timeOutHandler);
    };
  }, [recallTokens]);


  useEffect(() => {
    if (userDetails.userId) {
      const memberObj = {
        userId: userDetails?.identityId ? userDetails?.identityId : localStorage.getItem('identityId'),
        username: userDetails.userId
      }; 
      setMember(memberObj);
      setHasMembership(
        activeChannelMemberships
      .map((m) => m.Member.Arn)
      .indexOf(createMemberArn(memberObj.userId)) > -1
      );
      setupTheConfig();
      setChatInitiated({ initState: true })
      setRecallTokens(true);
      messagingService.connect(memberObj);
      setStartSubscription(true);
      return () => {
        messagingService.close();
      };
    }
  }, [userDetails])

  useEffect(() => {
    messagesRef.current = messages;
    activeChannelRef.current = activeChannel;
    channelListRef.current = channelList;
    unreadChannelsListRef.current = unreadChannels;
    activeChannelMembershipsRef.current = activeChannelMemberships;
    channelMessageTokenRef.current = channelMessageToken;
  })

  // Subscribe to MessagingService for updates
  useEffect(() => {
    if (userDetails.userId && startSubscription) {
      messagingService.subscribeToMessageUpdate(messagesProcessor);
      return () => {
        messagingService.unsubscribeFromMessageUpdate(messagesProcessor);
      };
    }
  }, [messagingService, userDetails, startSubscription]);

  // Providers values
  const messageStateValue = {
    messages,
    messagesRef,
    setMessages,
  };
  const channelStateValue = {
    member,
    channelList,
    activeChannel,
    activeChannelRef,
    channelListRef,
    unreadChannels,
    activeChannelMemberships,
    hasMembership,
    channelMessageToken,
    channelMessageTokenRef,
    setActiveChannel,
    setActiveChannelMemberships,
    setChannelMessageToken,
    setChannelList,
    setUnreadChannels,
    loadingForChannelMessageFetch,
    setLoadingForChannelMessageFetch
  };
  return (
    <ChatMessagingServiceContext.Provider value={messagingService}>
      <ChatChannelState.Provider value={channelStateValue}>
        <ChatMessagingState.Provider value={messageStateValue}>
          <ChatInitState.Provider value={isChatInitiated}>
            {children}
          </ChatInitState.Provider>
        </ChatMessagingState.Provider>
      </ChatChannelState.Provider>
    </ChatMessagingServiceContext.Provider>
  );
};

const useChatMessagingService = () => {
  const context = useContext(ChatMessagingServiceContext);

  if (!context) {
    throw new Error(
      'useChatMessagingService must be used within ChatMessagingServiceContext'
    );
  }

  return context;
};

const useChatMessagingState = () => {
  const context = useContext(ChatMessagingState);

  if (!context) {
    throw new Error(
      'useChatMessagingState must be used within ChatMessagingState'
    );
  }

  return context;
};

const useChatChannelState = () => {
  const context = useContext(ChatChannelState);

  if (!context) {
    throw new Error('useChatChannelState must be used within ChatChannelState');
  }

  return context;
};

const useChatInitState = () => {
  const context = useContext(ChatInitState);

  if (!context) {
    throw new Error('Channel init failed !!!');
  }

  return context;
}

export {
  MessagingProvider,
  useChatChannelState,
  useChatMessagingService,
  useChatMessagingState,
  useChatInitState
};
