/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import styles from './ChatUserProfile.module.scss'
import { Loader } from '../../common/Loader/Loader'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { Header } from '../header/Header'
import { Footer } from '../footer/Footer'
import { TabItem } from '../tab-item/TabItem'
import { TabsWrapper, TabWrapperProps } from '../tabs-wrapper/TabsWrapper'
import { useHistory, useLocation } from 'react-router'
import { UserProfileReviews } from '../user-profile-reviews/UserProfileReviews'
import { UserProfileInformation } from '../user-profile-information/UserProfileInformation'
import { useFetch } from '../../common/https'
import { NameInitials } from '../name-initials/NameInitials'

interface TeachersFeedback {
  reviewRating: string
  reviewSummary: string
  reviewerName: string
  reviewerImage: string
  sessionName: string
}
export const TeacherChatUserProfile: React.FC<{
  readonly isChat?: boolean
  readonly userId?: string
}> = ({ isChat = true, userId = '' }) => {
  const { push } = useHistory()
  const { pathname } = useLocation()
  const { search } = useLocation()
  const [teachersReview, setTeachersReview] = useState<ReadonlyArray<TeachersFeedback>>([])
  const [teacherFeedbackCount, setTeacherFeedbackCount] = useState<number>(0)
  const [studentsReview, setStudentsReview] = useState<ReadonlyArray<TeachersFeedback>>([])
  const [studentFeedbackCount, setStudentFeedbackCount] = useState<number>(0)
  const { fetchRest, sectionLoading } = useFetch({ userInfo: false, review: false })
  const uId = pathname.substring(pathname.lastIndexOf('/') + 1)
  const { userDetails, setUser } = useUserDetailsContext()
  const [defaultState, setDefaultState] = useState<number>(-1)
  const [isActiveTab, setIsActiveTab] = useState('Information')
  const [userData, setUserData] = useState<any>({})

  const teachersAdditionalTab: ReadonlyArray<TabWrapperProps> = [
    {
      key: 'Information',
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Information" className={styles.tab} />
      },
    },
    {
      key: 'Reviews',
      TabItemWrapper: ({ isActive }) => {
        return (
          <TabItem
            isActive={isActive}
            text="Reviews"
            count={teacherFeedbackCount + studentFeedbackCount}
            className={styles.tab}
          />
        )
      },
    },
  ]

  const onTabChange = (activeTab: number) => {
    switch (activeTab) {
      case 1:
        if (isChat) {
          push(`/teacher/${uId}?tab=Reviews`)
        }
        setIsActiveTab('Reviews')
        break
      default:
        if (isChat) {
          push(`/teacher/${uId}?tab=Information`)
        }
        setIsActiveTab('Information')
    }
  }

  useEffect(() => {
    if (uId || !isChat) {
      fetchRest(
        {
          url: 'userReview',
          method: 'GET',
          queryParams: {
            queriedUserId: isChat ? uId : userId,
          },
        },
        'review'
      ).then((res) => {
        if (res && res.data) {
          if (userDetails.isTeacher) {
            setStudentsReview(res.data.studentsFeedback)
            setStudentFeedbackCount(res.data.studentsFeedbackCount)
          }
          setTeachersReview(res.data.teachersFeedback)
          setTeacherFeedbackCount(res.data.teacherFeedbackCount)
        }
      })
    }
  }, [uId])

  useEffect(() => {
    fetchRest(
      {
        method: 'POST',
        url: '/getUserInfo',
        data: { userId: isChat ? uId : userId },
      },
      'userInfo'
    )
      .then((res: any) => {
        setUserData({ ...res.data.userData })
      })
      .catch((err: any) => {
        setUserData((prev: any) => {
          return { ...prev }
        })
      })
  }, [pathname, userId])

  useEffect(() => {
    let defaultTab: number = -1
    if (isChat) {
      let searchParams = new URLSearchParams(search)
      let searchTab = searchParams.get('tab')
        ? searchParams.get('tab')
        : userDetails.isTeacher
        ? 'Information'
        : 'Reviews'
      if (searchTab) {
        setIsActiveTab(searchTab)
      }
      if (userDetails.isTeacher) {
        if (searchTab === 'Information') {
          defaultTab = 0
        } else if (searchTab === 'Reviews') {
          defaultTab = 1
        }
      }
    } else {
      defaultTab = 0
      setIsActiveTab('Information')
    }
    setDefaultState(defaultTab)
  }, [search, userDetails])

  return (
    <>
      {isChat ? <Header /> : <></>}
      <div className={styles.background}>
        <div className={styles.center}>
          <div className={styles.wrapper}>
            <div className={styles.imgWrapper}>
              <>
                {sectionLoading && sectionLoading.userInfo ? (
                  <Loader />
                ) : userData ? (
                  userData?.imageUrl ? (
                    <img className={styles.userImg} src={userData.imageUrl} alt="profile" />
                  ) : (
                    <>
                      <div className={`${styles.imgWrapperIcon}`}>
                        <div className={styles.align}>
                          {userData.firstName && userData.lastName
                            ? `${userData.firstName.toUpperCase().charAt(0)}${userData.lastName
                                .toUpperCase()
                                .charAt(0)}`
                            : ''}
                        </div>
                      </div>
                    </>
                  )
                ) : (
                  <></>
                )}
              </>
            </div>
            <div className={styles.userInfoWrapper}>
              <div className={styles.name}>
                {userData.firstName} {userData.lastName}
              </div>
            </div>
          </div>
        </div>
        {userData.isTeacher ? (
          <div className={styles.center}>
            <div className={styles.tabsWrapper}>
              <TabsWrapper
                tabHeaders={teachersAdditionalTab}
                activeTabValue={defaultState}
                onTabChange={onTabChange}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className={styles.background}>
        <div className={styles.center}>
          {isActiveTab === 'Information' ? (
            <UserProfileInformation isChat={true} userData={userData} />
          ) : (
            <></>
          )}
        </div>
        {isActiveTab === 'Reviews' ? (
          <UserProfileReviews
            isTeacher={userData.isTeacher}
            isChat={true}
            userId={uId}
            isProfile={!isChat}
            studentFeedbackCount={studentFeedbackCount}
            teacherFeedbackCount={teacherFeedbackCount}
            teachersReview={teachersReview}
            studentsReview={studentsReview}
          />
        ) : (
          <></>
        )}
      </div>
      {isChat ? (
        <div className={styles.center}>
          <Footer />
        </div>
      ) : (
        <> </>
      )}
    </>
  )
}

export const StudentChatUserProfile: React.FC<{
  readonly isChat?: boolean
  readonly userId?: string
}> = ({ isChat = true, userId = '' }) => {
  const { userDetails, setUser } = useUserDetailsContext()
  const [teachersReview, setTeachersReview] = useState<ReadonlyArray<TeachersFeedback>>([])
  const [teacherFeedbackCount, setTeacherFeedbackCount] = useState<number>(0)
  const [studentsReview, setStudentsReview] = useState<ReadonlyArray<TeachersFeedback>>([])
  const [studentFeedbackCount, setStudentFeedbackCount] = useState<number>(0)
  const { fetchRest, sectionLoading } = useFetch({ userInfo: false, review: false })
  const { pathname } = useLocation()
  const [userData, setUserData] = useState<any>({})
  const uId = pathname.substring(pathname.lastIndexOf('/') + 1)

  useEffect(() => {
    fetchRest(
      {
        url: 'userReview',
        method: 'GET',
        queryParams: {
          queriedUserId: isChat ? uId : userId,
        },
      },
      'review'
    ).then((res) => {
      if (res && res.data) {
        if (userDetails.isTeacher) {
          setStudentsReview(res.data.studentsFeedback)
          setStudentFeedbackCount(res.data.studentsFeedbackCount)
        }
        setTeachersReview(res.data.teachersFeedback)
        setTeacherFeedbackCount(res.data.teacherFeedbackCount)
      }
    })
  }, [])
  useEffect(() => {
    fetchRest(
      {
        method: 'POST',
        url: '/getUserInfo',
        data: { userId: isChat ? uId : userId },
      },
      'userInfo'
    )
      .then((res: any) => {
        setUserData({ ...res.data.userData })
      })
      .catch((err: any) => {
        setUserData((prev: any) => {
          return { ...prev }
        })
      })
  }, [pathname])
  return (
    <>
      {isChat ? <Header /> : <></>}
      <div className={styles.background}>
        <div className={styles.center}>
          <div className={styles.wrapper}>
            <div className={styles.imgWrapper}>
              <>
                {sectionLoading && sectionLoading.userInfo ? (
                  <Loader />
                ) : (
                  <>
                    {userData ? (
                      userData.imageUrl ? (
                        <img className={styles.userImg} src={userData.imageUrl} alt="profile" />
                      ) : (
                        <>
                          <div className={`${styles.imgWrapperIcon}`}>
                            <div className={styles.align}>
                              {userData.firstName && userData.lastName
                                ? `${userData.firstName.toUpperCase().charAt(0)}${userData.lastName
                                    .toUpperCase()
                                    .charAt(0)}`
                                : ''}
                            </div>
                          </div>
                        </>
                      )
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            </div>
            <div className={styles.userInfoWrapper}>
              <div className={styles.name}>
                {userData ? userData.firstName : ''} {userData ? userData.lastName : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.background}>
        <UserProfileReviews
          isTeacher={!userData.isTeacher}
          isProfile={!isChat}
          studentFeedbackCount={studentFeedbackCount}
          teacherFeedbackCount={teacherFeedbackCount}
          teachersReview={teachersReview}
          studentsReview={studentsReview}
        />
      </div>

      {isChat ? (
        <div className={styles.center}>
          <Footer />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
