/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { DropDown } from '../../common/drop-down/DropDown'
import styles from './ChatContent.module.scss'
import dotMenu from '../../assets/images/svg/dots-menu.svg'
import person from '../../assets/images/svg/personalDetailsIcon.svg'
import createProposal from '../../assets/images/svg/createProposal.svg'
import attachment from '../../assets/images/svg/attachment.svg'
import send from '../../assets/images/svg/send.svg'
import { Chat } from '../chat/Chat'
import { useHistory } from 'react-router'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { Button } from '../../common/button/Button'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { CreateProposal } from '../create-proposal/CreateProposal'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import { ProposalList } from '../proposal-list/ProposalList'
import { sendChannelMessage } from '../chat-window/api/ChimeAPI'
import AWS from 'aws-sdk'
import { appConfig } from '../chat-window/Config'
import { useChatChannelState } from '../chat-window/providers/ChatMessagesProvider'
import { Loader } from '../../common/Loader/Loader'
import { NameInitials } from '../name-initials/NameInitials'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import { AnyLengthString } from 'aws-sdk/clients/comprehend'
import { MultipleSelect } from '../../common/multiple-select/MultipleSelect'
import { useUploadContext } from '../../common/use-upload-context'

// const S3_BUCKET = appConfig.attachments_s3_bucket_name
// const REGION = appConfig.appRegion

// AWS.config.update({
//   region: appConfig.appRegion,
//   apiVersion: 'latest',
//   credentials: {
//     accessKeyId: localStorage.getItem('accessKeyId') || '',
//     secretAccessKey: localStorage.getItem('secretAccessKey') || '',
//     sessionToken: localStorage.getItem('sessionToken') || '',
//   },
// })

// const myBucket = new AWS.S3({
//   params: { Bucket: S3_BUCKET },
//   region: REGION,
// })

// const getFileUrl = (userId: string, fileName: string) => {
//   return new Promise<string>((resolve, reject) => {
//     myBucket.getSignedUrl(
//       'getObject',
//       {
//         Bucket: S3_BUCKET || '',
//         Key: `protected/${userId}/uploadfiles/${fileName}`,
//         Expires: 86400,
//       },
//       (err, url) => {
//         if (err) {
//           return reject({ status: 'error', error: { ...err } })
//         } else {
//         }
//         return resolve(url)
//       }
//     )
//   })
// }

export const ChatContent: React.FC<{
  readonly memberCount?: number
  readonly memberList?: any
  readonly member: any
  readonly messages: any
  readonly activeChannel: any
  readonly initState?: any
  readonly triggerMessageAction?: any
  readonly messengerEnabled?: any
  readonly isTeam?: any
  readonly chatSelected?: boolean
  readonly activeRequest?: boolean
  readonly viewType?: string
}> = ({
  memberList = [],
  memberCount = 0,
  member,
  chatSelected,
  activeRequest,
  messages,
  activeChannel,
  initState = undefined,
  triggerMessageAction = () => {},
  messengerEnabled = false,
  isTeam = false,
  viewType = 'chat-view',
}) => {
  const S3_BUCKET = appConfig.attachments_s3_bucket_name
  const REGION = appConfig.appRegion
  const [myBucket, setMyBucket] = useState<any>(undefined)
  const uploadObjDefaults: any = {
    name: '',
    file: '',
    type: '',
    response: null,
    key: '',
  }
  const href = window.location.href
  const mentor = href.substring(href.indexOf('?') + 1)
  const { push } = useHistory()
  const { userDetails } = useUserDetailsContext()
  const { uploadFile } = useUploadContext()
  // const [member, setMember] = useState<any>({
  //   userId: userDetails?.identityId ? userDetails?.identityId : localStorage.getItem('identityId'),
  //   username: userDetails.userId,
  // })
  // const [link, setLink] = useState('')
  const [selectedFile, setSelectedFile] = useState<any>(uploadObjDefaults)
  const [errorMsg, setErrorMsg] = useState<boolean>(false)
  const [channelName, setChannelName] = useState<string>('')
  const [details, setDetails] = useState<any>()
  const [isProposal, setIsProposal] = useState<boolean>(false)
  const [openProposal, setOpenProposal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data1, setData1] = useState({
    chat: { value: '', isValid: false, isTouched: false, errorMsg: '' },
  })
  const [freezeSend, setFreezeSend] = useState<boolean>(false)
  const { loadingForChannelMessageFetch } = useChatChannelState()
  const uploadRef = useRef<any>()
  const [createProposalDialog, setCreateProposalDialog] = useState(false)
  const [chatContent, setChatContent] = useState({ chat: [] })
  const [setCreatedProposal] = useState<any>()
  const [triggerTheSocket, setTriggerTheSocket] = useState<boolean>(false)
  const [loadChatUI, setLoadChatUI] = useState<boolean>(false)

  const viewProfile = () => {
    if (activeChannel?.channelMetaInfo) {
      if (userDetails.isTeacher && mentor === 'type=learn') {
        push(`/teacher/${activeChannel?.channelMetaInfo.userId}?tab=Information`)
      } else {
        push(`/student/${activeChannel?.channelMetaInfo.userId}`)
      }
    }
  }
  const listArray = [
    {
      listContent: (
        <>
          <div className={styles.element}>
            <img src={person} alt="person icon" className={styles.elementImage} />
            <div>View Profile</div>
          </div>
        </>
      ),
      value: 'View Profile',
      onClick: (_e: any) => {
        // push(link)
        viewProfile()
      },
    },
    // {
    //   listContent: (
    //     <>
    //       <div className={styles.element}>
    //         <img src={block} alt="block icon" className={styles.elementImage} />
    //         {isBlocked ? <div>Un-block user</div> : <div>Block user</div>}
    //       </div>
    //     </>
    //   ),
    //   value: 'Block user',
    //   onClick: (_e: any) => {
    //     setIsBlocked(!isBlocked)
    //   },
    // },
    // {
    //   listContent: (
    //     <>
    //       <div className={styles.element}>
    //         <img src={deleteIcon} alt="delete icon" className={styles.elementImage} />
    //         <div>Delete Conversation</div>
    //       </div>
    //     </>
    //   ),
    //   value: 'Delete Conversation',
    //   onClick: (_e: any) => {
    //     setChatContent((prev: any) => {
    //       return {
    //         ...prev,
    //         chat: [
    //           {
    //             text: '',
    //             isMessage: true,
    //             isAttachment: false,
    //             isProposal: false,
    //             isSender: false,
    //           },
    //         ],
    //       }
    //     })
    //   },
    // },
  ]

  /**
   *
   * @param pre
   */
  const proposalDetails = (proposal: any) => {
    setIsProposal(true)
    setOpenProposal(false)
    setDetails(proposal)
    messageHandler('Proposal', proposal)
    // setData1((prev: any) => {
    //   return {
    //     ...prev,
    //     chat: {
    //       value: 'Proposal',
    //       isTouched: true,
    //       isValid: true,
    //       errorMsg: '',
    //     },
    //   }
    // })
  }

  /**
   *
   */
  const OnCloseCreateProposal = (data: any) => {
    setCreateProposalDialog(false)
    setCreatedProposal((prev: any) => {
      return {
        ...prev,
        ...data,
      }
    })
    messageHandler('Proposal', { proposalId: data.proposalId })
  }
  const handleProposalChange = () => {
    // setIsProposal(true)
    // setOpenProposal(false)
    // setData1((prev: any) => {
    //   return {
    //     ...prev,
    //     chat: {
    //       value: 'Proposal',
    //       isTouched: true,
    //       isValid: true,
    //       errorMsg: '',
    //     },
    //   }
    // })
  }

  /**
   *
   * @param e
   * @returns
   */
  const fileHandler = (e: any) => {
    const file1 = e.currentTarget.files[0]
    if (!file1) return

    if (file1.size / 1024 / 1024 < 5) {
      setSelectedFile({
        file: file1,
        name: file1.name,
      })
    } else {
      setErrorMsg(true)
    }
  }

  /**
   *
   * @param e
   */
  const handleChange = (e: any) => {
    const valueChat = e.value
    setData1((prev: any) => {
      return {
        ...prev,
        chat: { value: valueChat, isValid: e.isValid, isTouched: true, errorMsg: e.errorMsg },
      }
    })
  }

  /**
   * fileMessageHandler: handles the message and file upload
   * to S3.
   */
  const messageHandler = async (type: string = '', proposalData: any = undefined) => {
    const options: any = {
      Metadata: `{"text": "${data1.chat.value}"}`,
    }
    // -- file handling
    if (uploadRef.current.files[0]) {
      // --- uploading the file to s3
      // const uploadResult: any = await uploadFile(
      //   uploadRef.current.files[0],
      //   S3_BUCKET,
      //   `protected/${localStorage.getItem('identityId')}/uploadfiles/${selectedFile.name}`
      // )
      const uploadResult: any = await uploadFile({
        file: uploadRef.current.files[0],
        S3_BUCKET,
        key: `protected/${localStorage.getItem('identityId')}/uploadfiles/${selectedFile.name}`,
      })
      if (uploadResult.status !== 'success') {
        return
      }
      options.Metadata = JSON.stringify({
        attachments: [
          {
            fileKey: selectedFile.name,
            size: selectedFile.file.size,
            type: selectedFile.file.type,
          },
        ],
      })
    }
    // --- proposal handling
    if (type === 'Proposal') {
      options.Metadata = JSON.stringify({
        proposal: {
          proposalId: proposalData?.proposalId,
        },
      })
    }
    // --- sending the message and metadata
    await sendChannelMessage(
      activeChannel.ChannelArn,
      data1.chat.value || ' ',
      'PERSISTENT',
      member,
      options
    ).then((res: any) => {
      setFreezeSend(false)
    })
    // --- Cleanup
    if (type === 'File') {
      setSelectedFile(uploadObjDefaults)
      uploadRef.current.value = ''
    } else if (type === 'Proposal') {
      setIsProposal(false)
      setDetails(undefined)
    }
    setData1({
      chat: { value: '', isValid: false, isTouched: false, errorMsg: '' },
    })
    if (!triggerTheSocket) {
      triggerMessageAction(true)
      setTriggerTheSocket(true)
    }
  }

  /**
   *
   * @param e
   * @param type
   */
  const sendMsg = async (e: any, type: string) => {
    e.preventDefault()
    if (
      (type === 'Msg' && data1.chat.value !== '') ||
      (type === 'File' && selectedFile) ||
      type === 'Proposal'
    ) {
      setFreezeSend(true)
      setIsLoading(true)
      setChatContent((prev: any) => {
        return {
          ...prev,
          chat: [
            ...prev.chat,
            {
              text:
                type === 'Msg'
                  ? data1.chat.value
                  : type === 'File'
                  ? selectedFile
                  : details?.proposalDetail?.title,
              isMessage: type === 'Msg' ? true : false,
              isAttachment: type === 'File' ? true : false,
              isProposal: type === 'Proposal' ? true : false,
              isSender: false,
              time: new Date(),
              proposalDetails: type === 'Proposal' ? details : {},
            },
          ],
        }
      })
      messageHandler(type)
      setIsLoading(false)
    }
  }

  /**
   *
   */
  const handleClose = () => {
    setSelectedFile(uploadObjDefaults)
    uploadRef.current.value = ''
  }

  /**
   *
   */
  const func = async () => {
    // const res = await getMembershipData(activeChannel)
    setChannelName(
      activeChannel.channelMetaInfo.firstName + ' ' + activeChannel.channelMetaInfo.lastName
    )
  }

  /**
   *
   * @param event
   */
  const onSubmitAction = (event: any) => {
    if (event) {
    }
    uploadRef?.current?.files[0]
      ? sendMsg(event, 'File')
      : isProposal
      ? sendMsg(event, 'Proposal')
      : sendMsg(event, 'Msg')
  }

  const getFileUrl = (userId: string, fileName: string) => {
    return new Promise<string>((resolve, reject) => {
      myBucket.getSignedUrl(
        'getObject',
        {
          Bucket: S3_BUCKET || '',
          Key: `protected/${userId}/uploadfiles/${fileName}`,
          Expires: 86400,
        },
        (err: any, url: any) => {
          if (err) {
            return reject({ status: 'error', error: { ...err } })
          } else {
          }
          return resolve(url)
        }
      )
    })
  }

  const noError = () => {
    if (errorMsg) {
      setErrorMsg(false)
      setSelectedFile('')
      uploadRef.current.value = ''
    }
  }

  /**
   * UseEffect implementation
   */
  // useEffect(() => {
  //   /**
  //    * need to add the route validation as well
  //    * if route : /mentor/chat then show teacher chat UI && isTeacher === true
  //    */
  //   if (activeChannel?.channelMetaInfo) {
  //     if (userDetails.isTeacher && mentor === 'mentor=false') {
  //       setLink(`/teacher/${activeChannel?.channelMetaInfo.userId}?tab=Information`)
  //     } else {
  //       setLink(`/student/${activeChannel?.channelMetaInfo.userId}`)
  //     }
  //   }
  // }, [messages])

  useEffect(() => {
    if (userDetails.userId && initState) {
      setMyBucket(
        new AWS.S3({
          params: { Bucket: S3_BUCKET },
          signatureVersion: 'v4',
          region: REGION,
        })
      )
      setLoadChatUI(true)
    }
    // -- the aws credentials update work.
  }, [userDetails, initState])

  useEffect(() => {
    if (activeChannel?.Metadata) {
      var metaData = JSON.parse(activeChannel?.Metadata)
      if (metaData?.isOneToOne) {
        func()
      } else {
        if (activeChannel.channelMetaInfo) {
          setChannelName(activeChannel.channelMetaInfo.teamName)
        } else setChannelName(activeChannel?.Name)
      }
    } else setChannelName(activeChannel?.Name)
    setTriggerTheSocket(false)
  }, [activeChannel])

  useEffect(() => {
    if (messengerEnabled) {
      setTriggerTheSocket(false)
    }
  }, [messengerEnabled])
  return (
    <>
      <div
        className={`${styles.wrapper} ${
          isTeam && viewType === 'team' ? styles['team-wrapper'] : styles['default-wrapper']
        }`}
      >
        <div className={styles.content}>
          {/* ----- show the header only when the chat content are not for team(in team information tab) ----- */}
          {viewType !== 'team' ? (
            <div className={styles.header}>
              <div className={styles.details} onClick={viewProfile}>
                {loadingForChannelMessageFetch ? (
                  <Loader size={20} thickness={1} />
                ) : (
                  <>
                    <div className={styles.name}>{channelName}</div>
                    {isTeam ? <div className={styles.member}>Members : {memberCount}</div> : <></>}
                  </>
                )}
                {/* <div className={styles.info}> */}
                {/* {isBlocked ? <span className={styles.blocked}>User Blocked</span> : <></>} */}
                {/* {messages.length!=0?<Moment date={new Date()} format="HH:mm" interval={0} />:null} */}
                {/* change the hardcoded time to actual time */}
                {/* </div> */}
              </div>

              {/* ----- Menu and create proposal action ----- */}
              <div className={styles['d-flex']}>
                {/** ----- create proposal button ----- */}
                {userDetails.isTeacher && chatSelected && activeRequest ? (
                  <>
                    <Button
                      className={styles.buttonClass}
                      text={'Create proposal'}
                      ButtonImg={() => {
                        return (
                          <>
                            <img
                              src={createProposal}
                              alt="create proposal"
                              className={styles.btnImg}
                            />
                          </>
                        )
                      }}
                      onClick={() => {
                        setCreateProposalDialog(true)
                      }}
                      type="submit"
                    ></Button>
                  </>
                ) : null}
                {/** ----- chat menu action ----- */}
                <div className={styles.menu}>
                  {messages && activeChannel?.channelMetaInfo ? (
                    isTeam ? (
                      <></>
                    ) : (
                      <DropDown
                        menuProps={{
                          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                          transformOrigin: { vertical: 'top', horizontal: 'right' },
                        }}
                        ListArray={listArray}
                      >
                        <img src={dotMenu} alt="menu" className={styles['dotted-menu']} />
                      </DropDown>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          ) : null}

          {/* ----- Message and loader action ----- */}
          {loadingForChannelMessageFetch ? (
            <div className={styles.loader}>
              <Loader size={50} thickness={3} />
            </div>
          ) : (
            <div className={styles.col}>
              <>
                {/* check this once again */}
                {member.userId && loadChatUI
                  ? messages
                      .slice(0)
                      .reverse()
                      .map((each: any, index: any) => {
                        return (
                          <div key={index}>
                            <Chat
                              message={each}
                              isLoading={isLoading}
                              channelMetaInfo={activeChannel?.channelMetaInfo}
                              userDetails={userDetails}
                              member={member}
                              getFileUrl={getFileUrl}
                              isTeam={isTeam}
                            />
                          </div>
                        )
                      })
                  : null}
                <div className={styles.channel}>
                  {/* {messages && messages.length === 0 && activeChannel?.channelMetaInfo ? (
                    <div>
                      <div>
                        {activeChannel?.channelMetaInfo?.imageUrl ? (
                          <img
                            src={activeChannel?.channelMetaInfo?.imageUrl}
                            className={styles.iconUrl}
                            alt="user channel"
                          />
                        ) : (
                          <>
                            <NameInitials
                              firstName={'A'}
                              lastName={activeChannel?.channelMetaInfo?.lastName}
                              className={styles.iconUrl}
                            />
                          </>
                        )}
                      </div>
                      <div className={styles.flex}> {channelName}</div>
                    </div>
                  ) : (
                    <></>
                  )} */}
                </div>
              </>
            </div>
          )}
        </div>

        {/**form for attachment */}
        {/* <div className={styles.options}> */}
        {/* activeChannel && Object.keys(activeChannel).length !== 0 */}
        <NoEventsLoader isLoading={freezeSend}>
          <form onSubmit={onSubmitAction} className={styles.options}>
            {selectedFile.name ? (
              <div
                className={
                  mentor === 'type=mentor'
                    ? styles.mentorFile
                    : isTeam
                    ? styles.fileTeams
                    : styles.file
                }
              >
                <MultipleSelect
                  textContent={selectedFile.name}
                  closeIconFunc={(e: any) => {
                    handleClose()
                  }}
                />
              </div>
            ) : (
              <></>
            )}
            <div className={`${styles['d-flex']} ${styles.height}`}>
              {/** ----- file attachment here ----- */}
              <input
                type="file"
                accept="file_extension|audio/*|video/*|image/*|media_type"
                id="attachmentId"
                ref={uploadRef}
                name="attachmentName"
                style={{ display: 'none' }}
                onChange={fileHandler}
              />
              <label htmlFor="attachmentId">
                <img
                  src={attachment}
                  alt="attachmentIcon"
                  className={
                    activeChannel && Object.keys(activeChannel).length !== 0
                      ? styles.doc
                      : `${styles.hide} ${styles.doc}`
                  }
                />
              </label>

              {/** ----- Proposal attachment here ---- */}
              {mentor === 'type=mentor' ? (
                <div
                  className={
                    activeChannel && Object.keys(activeChannel).length !== 0
                      ? styles.proposal
                      : `${styles.proposal} ${styles.hide}`
                  }
                  onClick={() => {
                    setOpenProposal(true)
                    setErrorMsg(false)
                  }}
                >
                  P
                </div>
              ) : null}

              {/** ----- selected file popup here ----- */}

              {/** ----- input field here ----- */}
              <div onClick={noError}>
                <InputWrapper
                  valueObj={data1.chat}
                  placeholder="Type something"
                  onChange={handleChange}
                  className={`${styles.textbox} ${
                    mentor === 'type=mentor'
                      ? activeChannel && Object.keys(activeChannel).length !== 0
                        ? styles.teachersWidth
                        : `${styles.teachersWidth} ${styles.hide}`
                      : activeChannel && Object.keys(activeChannel).length !== 0
                      ? styles.fullwidth
                      : `${styles.fullwidth} ${styles.hide}`
                  }`}
                  onKeyPress={(e) => {
                    if (freezeSend) {
                      e.key === 'Enter' && e.preventDefault()
                    }
                  }}
                />
              </div>
              {/** ---- send action here ---- */}
              <img
                src={send}
                alt="attachmentIcon"
                className={
                  activeChannel && Object.keys(activeChannel).length !== 0
                    ? styles.send
                    : `${styles.send} ${styles.hide}`
                }
                onClick={onSubmitAction}
              />
            </div>
            {errorMsg ? <div className={styles.error}>Size limit for file exceeded</div> : null}
          </form>
        </NoEventsLoader>
      </div>

      {/**create proposal action */}
      <DialogWrapper
        onClose={() => {
          setCreateProposalDialog(false)
        }}
        open={createProposalDialog}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <CreateProposal
          isEdit={false}
          createProposal={OnCloseCreateProposal}
          isChat={true}
          channelMetaInfo={activeChannel.channelMetaInfo}
        />
      </DialogWrapper>

      {/**Show the proposal list */}
      <DialogWrapper
        onClose={() => {
          setOpenProposal(false)
        }}
        open={openProposal}
        isFullScreen={true}
        Title={DialogHeader}
      >
        {/* <OpenProposals proposals={proposals} /> */}
        <ProposalList
          inChat={true}
          proposalDetails={proposalDetails}
          callBackForProposal={handleProposalChange}
          studentId={activeChannel?.channelMetaInfo?.userId}
        />
      </DialogWrapper>
    </>
  )
}

// export { getFileUrl }
