/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dotMenu from '../../assets/images/svg/dots-menu.svg'
import editMenu from '../../assets/images/svg/menu-edit.svg'
import timeIcon from '../../assets/images/svg/time-icon.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { DropDown, ListArrayItemProps } from '../../common/drop-down/DropDown'
import { CreateProposal } from '../create-proposal/CreateProposal'
import { NameInitials } from '../name-initials/NameInitials'
import { ProposalDetails } from '../proposal-details/ProposalDetails'
import styles from './ProposalListCard.module.scss'
import Moment from 'react-moment'
import { useFetch } from '../../common/https'
import tick from '../../assets/images/svg/gray_tick.svg'
import closeIcon from '../../assets/images/svg/closeGrey.svg'
import { UsePopupContext } from '../../common/use-popup-context'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { Button } from '../../common/button/Button'
import deleteRequestIcon from '../../assets/images/svg/delete-request.svg'
import Done from '../../assets/images/png/done.png'
import repeatRequest from '../../assets/images/svg/repeatRequest.svg'
import { WithdrawResponse } from '../withdraw-response/WithdrawResponse'
import { GetPaid } from '../get-paid/GetPaid'
import { ElementsWrapper } from '../../common/elements-wrapper/ElementsWrapper'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { Loader } from '../../common/Loader/Loader'

export const ProposalListCard: React.FC<{
  readonly proposalDetails?: (pre: any) => void
  readonly callBackFromChat?: () => void
  readonly inChat?: boolean
  readonly teamId?: string
  readonly teamStats?: () => void
  data: any
  readonly inStudentDashboard?: boolean
  onEdit: () => void
  readonly fetchProposalList?: any
  readonly forApproval?: boolean
  readonly memberRole?: string
}> = ({
  data,
  memberRole = '',
  teamStats = () => {},
  inStudentDashboard = false,
  onEdit,
  fetchProposalList,
  teamId = '',
  inChat = false,
  callBackFromChat = () => {},
  proposalDetails = (pre: any) => {},
  forApproval = false,
}) => {
  // const [currentProposal, setCurrentProposal] = useState({})
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const [createPopup, setCreatePopup] = useState(false)
  const { t } = useTranslation()
  const { fetchRest, sectionLoading } = useFetch({ approve: false, accept: false })
  const { close, setData: setPopupData } = UsePopupContext()
  const [onClickCancel, setOnClickCancel] = useState<boolean>(false)
  const [getPaid, setGetPaid] = useState<boolean>(false)

  const { userDetails, countries } = useUserDetailsContext()
  const closePopup = () => {
    setIsPopupOpen(false)
  }
  const openPopup = () => {
    setIsPopupOpen(true)
  }
  const popUp = (key: string, success: boolean) => {
    let headerContent: string = ''
    let content: string = ''
    if (key === 'accept' && success) {
      headerContent = t('requestDetails.accepted')
      content = ''
    } else if (key === 'accept' && !success) {
      headerContent = t('requestDetails.failed-to-accept')
      content = ''
    } else if (key === 'approve') {
      if (success) {
        headerContent = t('requestDetails.approved')
        content = t('requestDetails.approvedContent')
      } else {
        headerContent = t('requestDetails.cannotapprove')
        content = ''
      }
    } else if (key === 'reject' && success) {
      headerContent = t('requestDetails.ignored')
      content = t('requestDetails.ignoredContent')
    } else if (key === 'reject' && !success) {
      headerContent = t('requestDetails.failed-to-ignore')
      content = ''
    } else if (key === 'cancel' && success) {
      headerContent = t('requestDetails.withdrawn')
      content = t('requestDetails.withdrawnContent')
    } else if (key === 'payment' && success) {
      headerContent = 'Payment option not available'
      content = 'Mentor country doesnt accept online payments. Please do offline payments.'
    } else if (key === 'approve') {
      if (success) {
        headerContent = t('requestDetails.approved')
        content = t('requestDetails.approvedContent')
      } else {
        headerContent = t('requestDetails.cannotapprove')
        content = ''
      }
    } else {
      headerContent = t('requestDetails.withdrawn')
      content = t('requestDetails.failed-to-withdraw')
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return (
            <img
              src={
                success
                  ? key === 'accept' || key === 'payment' || key === 'approve'
                    ? Done
                    : deleteRequestIcon
                  : repeatRequest
              }
              alt="popup icon"
              className={styles.width}
            />
          )
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {(key === 'accept' || key === 'reject' || key === 'cancel' || key === 'approve') &&
              success ? (
                <>
                  <Button
                    className={styles.green}
                    text={'Got It'}
                    onClick={() => {
                      close()
                      // onClose()
                      fetchProposalList({})
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button
                    text={'OK'}
                    onClick={(e) => {
                      close()
                    }}
                    className={styles.green}
                  ></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }
  const acceptProposal = () => {
    fetchRest(
      {
        url: `/acceptProposal/${data.proposalId}`,
        method: 'PUT',
        isFullPageLoader: true,
      },
      'accept'
    )
      .then((res) => {
        popUp('accept', true)
      })
      .catch((err) => {
        if (err?.response?.data?.error?.errorStatus) {
          errorProposalPopup(err.response.data.error?.message)
        } else {
          popUp('accept', false)
        }
      })
  }

  const menuList: ReadonlyArray<ListArrayItemProps> = [
    {
      listContent: (
        <>
          <div className={styles.element}>
            <img src={editMenu} alt="edit icon" className={styles.elementImage} />
            <div>Edit</div>
          </div>
        </>
      ),
      value: 'Edit',
      onClick: (_e: any) => {
        setCreatePopup(true)
      },
    },
    {
      listContent: (
        <>
          <div className={styles.element}>
            <img src={closeIcon} alt="close" className={styles.elementImage} />
            <div>{t('createProposal.cancelProposal')}</div>
          </div>
        </>
      ),
      value: 'Cancel Proposal',
      onClick: (_e: any) => {
        setOnClickCancel(true)
      },
    },
    // {
    //   listContent: (
    //     <>
    //       <div className={styles.element}>
    //         {/* <img src={editMenu} alt="edit icon " className={styles.elementImage} /> */}
    //         <div>Open in new window</div>
    //       </div>
    //     </>
    //   ),
    //   value: 'Open in new window',
    //   onClick: (_e: any) => {},
    // },
  ]

  const approveProposal = () => {
    fetchRest(
      {
        url: `/approveProposal/${data.proposalId}`,
        method: 'GET',
      },
      'approve'
    )
      .then((res: any) => {
        popUp('approve', true)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const menuListStudent: ReadonlyArray<ListArrayItemProps> = [
    {
      listContent: (
        <>
          <div className={styles.element}>
            <img src={tick} className={styles.elementImage} alt="edit" />
            {teamId ? (
              forApproval ? (
                <div>Approve</div>
              ) : (
                <div>
                  Pay $
                  {Number(data.proposalDetail.hourlyRate) *
                    Number(data.proposalDetail.sessionTimeInHour)}
                </div>
              )
            ) : (
              <div>
                Accept and pay $
                {Number(data.proposalDetail.hourlyRate) *
                  Number(data.proposalDetail.sessionTimeInHour)}
              </div>
            )}
          </div>
        </>
      ),
      value: teamId ? (forApproval ? 'Pay' : 'Approve') : 'Accept and pay',
      onClick: (_e: any) => {
        if (teamId && forApproval) {
          approveProposal()
        } else {
          setGetPaid(true)
        }
      },
    },
    {
      listContent: (
        <>
          <div className={styles.element}>
            <img src={closeIcon} className={styles.elementImage} alt="close" />
            <div>Reject Proposal</div>
          </div>
        </>
      ),
      value: 'Reject Proposal',
      onClick: (_e: any) => {
        //   rejectProposal()      },
        setOnClickCancel(true)
      },
    },
  ]

  const errorProposalPopup = (errorMessage: string = '') => {
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img className={styles.popupImage} src={RepeatRequest} alt="popup icon" />
        }}
        title={`${t('common.failed')}!`}
        Buttons={() => {
          return (
            <>
              <Button
                className={styles.proposal_cancel_btn}
                text={t('common.cancel')}
                onClick={() => {
                  close()
                }}
              ></Button>
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{errorMessage}</p>
            </div>
          )
        }}
      />
    )
  }

  return (
    <>
      <div onClick={() => {}} className={styles['list-card']}>
        <div className={styles['list-card-header']}>
          <div className={styles['d-flex']}>
            <div className={styles['review-card__align-items']}>
              <img src={timeIcon} alt="time icon" className={styles['review-card__time-icon']} />
              <div className={styles['review-card__time-subject']}>
                {t('requestDetails.posted')} {data.createdOn} {t('requestDetails.ago')}
              </div>
            </div>
          </div>
          <div className="menu-list-wrapper">
            {data?.status !== 'Rejected' ? (
              forApproval && memberRole === 'Member' ? (
                <></>
              ) : (
                <>
                  <DropDown
                    menuProps={{
                      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                      transformOrigin: { vertical: 'top', horizontal: 'right' },
                    }}
                    ListArray={inStudentDashboard ? menuListStudent : menuList}
                  >
                    <div className={styles['dropdown-menu']}>
                      <img src={dotMenu} alt="menu" className={styles['dotted-menu']} />
                    </div>
                  </DropDown>
                </>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
        <div
          className={styles['dotted-menu']}
          onClick={() => {
            if (inChat === false) {
              openPopup()
            } else {
              callBackFromChat()
              proposalDetails(data)
            }
          }}
        >
          <div className={styles['list-card-title']}>
            {data.proposalDetail.title}
            <div className={styles['list-card-content']}>
              <Moment date={data?.proposalDetail?.sessionDate} format="DD MMM" interval={0} /> ,
              <Moment date={data?.proposalDetail?.sessionDate} format="HH:mm" interval={0} /> -
              <Moment
                date={data?.proposalDetail.sessionDate}
                format="HH:mm"
                interval={0}
                add={{ hours: data.proposalDetail.sessionTimeInHour }}
              />
            </div>
          </div>
          <div className={styles.spaceBetween}>
            <div className={styles['review-card__profile']}>
              <div>
                {inStudentDashboard ? (
                  <>
                    {data.owner.imageUrl !== undefined && data.owner.imageUrl.length > 0 ? (
                      <img src={data.owner.imageUrl} alt="owner" className={styles.icon} />
                    ) : (
                      <NameInitials
                        firstName={data.owner.firstName}
                        lastName={data.owner.lastName}
                        className={styles.icon}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {data.request.user &&
                    data.request.user.imageUrl !== undefined &&
                    data.request.user.imageUrl.length > 0 ? (
                      <img src={data.request.user.imageUrl} alt="owner" className={styles.icon} />
                    ) : (
                      <NameInitials
                        firstName={
                          data.request.user && data.request.user.firstName
                            ? data.request.user.firstName
                            : ''
                        }
                        lastName={
                          data.request.user && data.request.user.lastName
                            ? data.request.user.lastName
                            : ''
                        }
                        className={styles.icon}
                      />
                    )}
                  </>
                )}
              </div>
              <div className={styles['review-card__star-rating']}>
                <div className={styles['review-card__username']}>
                  {inStudentDashboard ? (
                    <>
                      {data.owner.firstName} {data.owner.lastName.charAt(0)}.
                    </>
                  ) : (
                    <>
                      {data.request.user && data.request.user.firstName
                        ? data.request.user.firstName
                        : ''}{' '}
                      {data.request.user && data.request.user.lastName
                        ? data.request.user.lastName.charAt(0)
                        : ''}
                      .
                    </>
                  )}
                </div>
                <div className={styles['review-card__rating']}>
                  {inStudentDashboard ? (
                    <>{t('requestDetails.creator')}</>
                  ) : (
                    <> {t('requestDetails.attendee')}</>
                  )}
                </div>
              </div>
            </div>
            {sectionLoading && (sectionLoading.approve || sectionLoading.accept) ? (
              <Loader size={20} thickness={3} />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <DialogWrapper
        onClose={closePopup}
        open={isPopupOpen}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <ProposalDetails
          forApproval={forApproval}
          objectReceived={data}
          isAttendee={inStudentDashboard || teamId ? true : false}
          onEdit={onEdit}
          onClose={() => {
            closePopup()
            fetchProposalList({})
          }}
          memberRole={memberRole}
          teamId={teamId}
          teamStats={teamStats}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setCreatePopup(false)
        }}
        open={createPopup}
        Title={DialogHeader}
      >
        <CreateProposal
          isEdit={true}
          createProposal={() => {
            setCreatePopup(false)
            onEdit()
          }}
          data={data}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setGetPaid(false)
        }}
        open={getPaid}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <ElementsWrapper>
          <GetPaid
            availableFund={
              Number(data.proposalDetail.hourlyRate) * Number(data.proposalDetail.sessionTimeInHour)
            }
            proposalId={data.proposalId}
            proposal={data}
            onClosePopup={() => {
              setGetPaid(false)
              acceptProposal()
            }}
          />
        </ElementsWrapper>
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setOnClickCancel(false)
        }}
        open={onClickCancel}
        Title={DialogHeader}
      >
        <WithdrawResponse
          responseId={data?.proposalId}
          inProposal={true}
          onSuccess={() => {
            setOnClickCancel(false)
            fetchProposalList({})
          }}
          isAttendee={inStudentDashboard}
          // heading={t('withdrawResponse.proposal.cancel-proposal')}
          // reason1 = {t('withdrawResponse.proposal')}
        />
      </DialogWrapper>
    </>
  )
}
