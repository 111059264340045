import React, { MouseEventHandler } from 'react'
import close from '../../assets/images/svg/close.svg'
import styles from './DialogHeader.module.scss'

export const DialogHeader: React.FC<{
  readonly isFullScreen: boolean
  readonly title: string
  readonly handleClose: MouseEventHandler<HTMLImageElement>
  // readonly expand: MouseEventHandler<HTMLImageElement>
}> = ({ title, handleClose }) => {
  return (
    <div className={styles['dialog-header__options']}>
      <img src={close} alt="close" onClick={handleClose} className={styles.close} />
      {title ? <div className={styles.title}>{title}</div> : <></>}
    </div>
  )
}
