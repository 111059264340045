import React from 'react'
import styles from './Search.module.scss'

interface SearchWrapperProps extends React.HTMLProps<HTMLInputElement> {
  type: string
  placeholder: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  className?: string
  title?: string
}

export const Search: React.FC<SearchWrapperProps> = ({
  type,
  placeholder,
  className,
  onChange,
  title,
  ...rest
}) => {
  return (
    <>
      <div className={styles.search}>
        {title ? <div className={styles['search-title']}>{title}</div> : <></>}
        <input
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          name="searchHelp"
          {...rest}
          className={className ? `${className} ${styles['input-search']}` : styles['input-search']}
        />
      </div>
    </>
  )
}
