/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import connectionMethod from '../../assets/images/svg/connection-method.svg'
import createProposalGray from '../../assets/images/svg/create-proposal-gray.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import question from '../../assets/images/svg/question-mark.svg'
import Popover from '@material-ui/core/Popover'
import editRequestIcon from '../../assets/images/svg/editRequest.svg'
import Search from '../../assets/images/svg/search.svg'
import summary from '../../assets/images/svg/summary-gray.svg'
import terms from '../../assets/images/svg/terms-gray.svg'
import tick from '../../assets/images/svg/whiteTick.svg'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { AutocompleteOption } from '../../common/autocomplete-option/AutocompleteOption'
import { Button } from '../../common/button/Button'
import { DatePickerComponent } from '../../common/date-picker/DatePicker'
import { useFetch } from '../../common/https'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import { Loader } from '../../common/Loader/Loader'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import { UsePopupContext } from '../../common/use-popup-context'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { charactersLeft } from '../../common/utils'
import styles from './CreateProposal.module.scss'
import questionMark from '../../assets/images/svg/question-mark.svg'

interface CategorySubCategoryType {
  id?: string
  name: string
  categoryId?: string
  subCategoryId?: string
  isValid: boolean
  errorMsg: string
  isTouched: boolean
}

const costSchema = yup.object().shape({
  value: yup
    .number()
    .typeError('Please enter a number')
    .max(9999, 'Maximum amount is $9999')
    .min(1, 'Minimum amount should be $1')
    .required('Please enter hourly rate'),
})

const titleSchema = yup.object().shape({
  value: yup.string().required('Please enter the title'),
})
const linkSchema = yup.object().shape({
  value: yup.string().required('Please enter the link'),
})

export const CreateProposal: React.FC<{
  isEdit?: boolean
  createProposal: (data?: any) => void
  data?: any
  isSession?: boolean
  statsFunction?: () => void
  isChat?: boolean
  resetProposalStatus?: () => void
  channelMetaInfo?: any
}> = ({
  isEdit = false,
  createProposal,
  data = {},
  isSession = false,
  statsFunction = () => {},
  resetProposalStatus = () => {},
  isChat = false,
  channelMetaInfo = '',
}) => {
  const { t } = useTranslation()
  const { fetchRest, sectionLoading } = useFetch({ createProposal: false })
  const { close, setData: setPopupData } = UsePopupContext()
  const { userDetails } = useUserDetailsContext()
  const now = new Date(new Date().getTime() + 5 * 60 * 1000).toLocaleTimeString('en-GB')
  var start = now.slice(0, -3)
  const startTiming = start.length < 5 ? '0' + start : start
  const end = (Number(start.split(':')[0]) + 1).toString() + ':' + start.split(':')[1]
  const endTiming = end.length < 5 ? '0' + end : end
  const [categoryArray, setCategoryArray] = useState<Array<CategorySubCategoryType>>([])
  const [requestArray, setRequestArray] = useState<Array<any>>([])
  const [dropdownValue, setDropdownValue] = useState<any>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [proposalData, setProposalData] = useState<any>({
    title: {
      value: '',
      isTouched: false,
      isValid: false,
      errorMsg: '',
    },
    sessionCategory: {
      categoryId: '',
      name: '',
      isValid: false,
      errorMsg: 'Select a Category',
      isTouched: false,
    },
    sessionDescription: '',
    preRequisiteKnowledge: '',
    learningOutcome: '',
    learningRequirement: '',
    hourlyRate: {
      value: 0,
      isTouched: false,
      isValid: false,
      errorMsg: '',
    },
    startTime: {
      value: startTiming,
      isTouched: false,
      isValid: true,
      errorMsg: '',
    },
    endTime: {
      value: endTiming,
      isTouched: false,
      isValid: true,
      errorMsg: '',
    },
    selectedDate: new Date(),
    specifyStudent: [],
    request: {
      value: '',
      isValid: false,
      errorMsg: 'Select a Request',
      isTouched: false,
    },
    sessionTimeInHour: {
      value: '1',
      label: '1h',
    },
    connectionMode: {
      value: '',
      label: '',
      isValid: false,
      isTouched: false,
      errorMsg: 'Please select connection mode',
      connectionLink: {
        value: '',
        isValid: false,
        isTouched: false,
        errorMsg: 'Please add valid link',
      },
    },
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const radioOptions: ReadonlyArray<{
    readonly value: string
    readonly label: string
  }> = [
    {
      value: 'Zoom',
      label: 'Zoom',
    },
    {
      value: 'MS Teams',
      label: 'MS Teams',
    },
    {
      value: 'Others',
      label: 'Others',
    },
  ]

  const checkboxValue: ReadonlyArray<{
    readonly value: string
    readonly label: string
  }> = [
    { value: '1', label: '1h' },
    { value: '2', label: '2h' },
    { value: '3', label: '3h' },
    { value: '4', label: '4h' },
  ]

  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const timeStart = (time: string) => {
    let date = new Date(time).toString()
    return date.substring(16, 21)
  }
  const timeEnd = (time: string) => {
    let date = new Date(time).toString()
    const timeString = date.substring(16, 18)
    const interval = data ? data.proposalDetail?.sessionTimeInHour : '2'
    let endTime = Number(timeString) + Number(interval)
    if (endTime >= 24) {
      endTime = endTime - 24
    }
    let returnTime = endTime.toString().concat(':' + time?.substring(20, 22))
    if (returnTime.length !== 5) {
      returnTime = '0' + returnTime
    }
    return returnTime
  }

  const fetchCategoryOrSoftware = () => {
    fetchRest({
      url: '/requestCategoryAndSubCategory',
      method: 'GET',
      isFullPageLoader: false,
    }).then((res) => {
      if (res && res.data && res.data.data) {
        setCategoryArray(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.map((each: any) => {
            return { id: each.subCategoryId ? each.subCategoryId : each.categoryId, ...each }
          })
        )
      } else {
        setCategoryArray([])
      }
    })
  }

  const fetchRequestForProposal = () => {
    fetchRest({
      url: '/requestListForProposal',
      method: 'GET',
      queryParams: {
        studentId: channelMetaInfo.userId,
      },
      isFullPageLoader: false,
    }).then((res) => {
      if (res && res.data && res.data.data) {
        setRequestArray(res.data.data)
      } else {
        setRequestArray([])
      }
    })
  }

  // const fetchStudentRequest = (id: string) => {
  //   let attendiesID: string[] = []
  //   proposalData.specifyStudent.map((item: any) => {
  //     attendiesID.push(item.userId)
  //   })
  //   fetchRest({
  //     url: '/studentRequest',
  //     method: 'GET',
  //     queryParams: {
  //       attendeeId: attendiesID,
  //     },
  //     isFullPageLoader: false,
  //   }).then((res) => {
  //     if (res && res.data && res.data.request) {
  //       setRequestDetails(res.data.request)
  //     } else {
  //       setRequestDetails([])
  //     }
  //   })
  // }

  // const fetchConnectedUser = () => {
  //   fetchRest({
  //     url: '/connectedUser',
  //     method: 'GET',
  //     isFullPageLoader: false,
  //   }).then((res) => {
  //     if (res && res.data && res.data.user) {
  //       setAttendiesEmail(res.data.user)
  //     } else {
  //       setAttendiesEmail([])
  //     }
  //   })
  // }
  const onsubmit = (submit: boolean) => {
    let headerContent: string = ''
    let content: string = ''
    if (submit) {
      if (!isEdit) {
        headerContent = t('createProposal.popupSuccessHeader')
        content =
          (isChat ? channelMetaInfo.firstName : data.user.firstName) +
          t('createProposal.popupSuccessContent')
      } else if (isSession) {
        headerContent = 'Session Edited'
        content = 'Session edit successfully'
      } else {
        headerContent = 'Proposal Edited'
        content = 'Proposal edit successfully'
      }
    } else {
      headerContent = t('createProposal.popupSuccessHeader')
      content = 'Proposal not published due to an unexpected error. Please try again'
    }
    const timeDiff =
      new Date(
        (
          proposalData.selectedDate.getFullYear() +
          '-' +
          (proposalData.selectedDate.getMonth() + 1) +
          '-' +
          proposalData.selectedDate.getDate() +
          ' ' +
          proposalData.startTime.value
        ).replace(/-/g, '/')
      ).getTime() - new Date().getTime()
    // API for publishing/edit the proposal
    if (
      proposalData.title.isValid &&
      proposalData.sessionCategory.isValid &&
      proposalData.hourlyRate.isValid &&
      proposalData.connectionMode.isValid &&
      proposalData.connectionMode.connectionLink.isValid &&
      (isChat ? proposalData.request.isValid : data.requestId) &&
      timeDiff > 0
    ) {
      let dataObj = {
        title: proposalData.title.value,
        sessionDescription: proposalData.sessionDescription,
        preRequisiteKnowledge: proposalData.preRequisiteKnowledge,
        learningOutcome: proposalData.learningOutcome,
        learningRequirement: proposalData.learningRequirement,
        connectionMode: proposalData.connectionMode.value,
        connectionLink: proposalData.connectionMode.connectionLink.value,
        hourlyRate: proposalData.hourlyRate.value,
        sessionTimeInHour: proposalData.sessionTimeInHour.value,
        sessionDate: new Date(
          (
            proposalData.selectedDate.getFullYear() +
            '-' +
            (proposalData.selectedDate.getMonth() + 1) +
            '-' +
            proposalData.selectedDate.getDate() +
            ' ' +
            proposalData.startTime.value
          ).replace(/-/g, '/')
        ).toISOString(),
        subCategoryId: proposalData.sessionCategory.subCategoryId,
        categoryId: proposalData.sessionCategory.categoryId,
        // attendees: [
        //   {
        //     attendeeId: '6e55da05-9b4f-4e2b-9479-275b0888cca5',
        //     isPayer: true,
        //   },
        //   {
        //     attendeeId: 'b412c28f-5c4b-403d-8d18-7050730323d7',
        //     isPayer: false,
        //   },
        // ],
      }
      if (isSession) {
        fetchRest(
          {
            url: `/editSession/${data.sessionId}`,
            method: 'PUT',
            isFullPageLoader: false,
            data: {
              connectionMode: dataObj.connectionMode,
              connectionLink: dataObj.connectionLink,
              sessionDate: dataObj.sessionDate,
              title: dataObj.title,
              sessionDescription: dataObj.sessionDescription,
              preRequisiteKnowledge: dataObj.preRequisiteKnowledge,
              learningOutcome: dataObj.learningOutcome,
              learningRequirement: dataObj.learningRequirement,
            },
          },
          'createProposal'
        )
          .then((res) => {
            setPopupData(
              <AlertPopupWrapper
                Img={() => {
                  return <img src={editRequestIcon} alt="popup icon" />
                }}
                title={headerContent}
                Buttons={() => {
                  return (
                    <>
                      <Button
                        className={styles.white}
                        text={'Got It'}
                        onClick={() => {
                          close()
                          createProposal()
                          // createProposal(res.data)
                        }}
                      ></Button>
                    </>
                  )
                }}
                Content={() => {
                  return (
                    <div>
                      <p>{content}</p>
                    </div>
                  )
                }}
              />
            )
          })
          .catch((error) => {
            if (error?.response?.data?.error?.errorStatus) {
              errorMessagePopup(error.response.data.error?.message)
            } else {
              errorMessagePopup('Failed to edit session, please try again after sometime.')
            }
          })
      } else if (isEdit && !isSession) {
        fetchRest(
          {
            url: `/editProposal/${data.proposalId}`,
            method: 'PUT',
            isFullPageLoader: false,
            data: dataObj,
          },
          'createProposal'
        )
          .then((res) => {
            setPopupData(
              <AlertPopupWrapper
                Img={() => {
                  return <img src={editRequestIcon} alt="popup icon" />
                }}
                title={headerContent}
                Buttons={() => {
                  return (
                    <>
                      <Button
                        className={styles.white}
                        text={'Got It'}
                        onClick={() => {
                          close()
                          createProposal()
                        }}
                      ></Button>
                    </>
                  )
                }}
                Content={() => {
                  return (
                    <div>
                      <p>{content}</p>
                    </div>
                  )
                }}
              />
            )
          })
          .catch((error) => {
            if (error?.response?.data?.error?.errorStatus) {
              errorMessagePopup(error.response.data.error?.message)
            } else {
              errorMessagePopup('Failed to edit proposal, please try again after sometime.')
            }
          })
      } else {
        fetchRest(
          {
            url: '/createProposal',
            method: 'POST',
            isFullPageLoader: false,
            data: { ...dataObj, requestId: isChat ? dropdownValue.requestId : data.requestId },
          },
          'createProposal'
        )
          .then((res) => {
            setPopupData(
              <AlertPopupWrapper
                Img={() => {
                  return <img src={editRequestIcon} alt="popup icon" />
                }}
                title={headerContent}
                Buttons={() => {
                  return (
                    <>
                      <Button
                        className={styles.white}
                        text={'Got It'}
                        onClick={() => {
                          close()
                          statsFunction()
                          createProposal(res.data)
                        }}
                      ></Button>
                    </>
                  )
                }}
                Content={() => {
                  return (
                    <div>
                      <p className={styles.dflex}>{content}</p>
                    </div>
                  )
                }}
              />
            )
            resetProposalStatus()
          })
          .catch((error) => {
            errorMessagePopup('Failed to create proposal, please try again after sometime.')
          })
      }
    } else {
      setProposalData((prev: any) => {
        return {
          ...prev,
          title: { ...prev.title, isTouched: true },
          sessionCategory: { ...prev.sessionCategory, isTouched: true },
          hourlyRate: { ...prev.hourlyRate, isTouched: true },
          request: { ...prev.request, isTouched: true },
          connectionMode: {
            ...prev.connectionMode,
            isTouched: true,
            connectionLink: { ...prev.connectionMode.connectionLink, isTouched: true },
          },
          startTime: {
            ...prev.startTime,
            errorMsg: 'Past time cannot be selected',
            isTouched: true,
            isValid: timeDiff > 0 ? true : false,
          },
        }
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onKeyDownCost = (e: any) => {
    if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
      e.preventDefault()
    }
  }

  useEffect(() => {
    fetchCategoryOrSoftware()
    fetchRequestForProposal()
    // fetchConnectedUser()
  }, [])

  const [minimumBal, setMinimumBal] = useState(false)
  const [teacherAmount, setTeacherAmount] = useState(0)
  const [onteloFee, setOnteloFee] = useState(0)
  const [otherCharges, setOtherCharges] = useState(0)

  const totalTeacherAmount = (hourlyAmount: any, sessionTime: String) => {
    if (hourlyAmount * Number(sessionTime) < 3) {
      setMinimumBal(true)
      setTeacherAmount(0)
      setOnteloFee(0)
      setOtherCharges(0)
    } else {
      setMinimumBal(false)
      let amount = 0

      // const currencyRate = userDetails.countryCode === 'AE' ? 3.67 : 0.75
      // const payoutFixedFee = userDetails.countryCode === 'AE' ? 5.5 : 0.1
      // const payoutVariableFee = userDetails.countryCode === 'AE' ? 0.0025 : 0.0025
      // const cardVariableFee = userDetails.countryCode === 'AE' ? 0.019 : 0.029
      // const cardFixedFee = userDetails.countryCode === 'AE' ? 1.0 : 0.2

      const currencyRate = 0.75
      const payoutFixedFee = 0.1
      const payoutVariableFee = 0.0025
      const cardVariableFee = 0.029
      const cardFixedFee = 0.2
      const hourlyRate = hourlyAmount * Number(sessionTime)

      // convert currency based on stripe account country
      amount = hourlyRate * currencyRate

      // remove cardFee from total hourly rate
      amount = amount - (amount * cardVariableFee + cardFixedFee)

      // remove platform fee
      const onteloPlatformFee = Number(Number((amount * 0.1) / currencyRate).toFixed(2))
      setOnteloFee(Number(Number((amount * 0.1) / currencyRate).toFixed(2)))
      amount = amount * 0.9

      // remove payout fee
      amount = amount - (amount * payoutVariableFee + payoutFixedFee)

      // convert currency back to USD
      amount = amount / currencyRate

      setTeacherAmount(Number(amount.toFixed(2)))
      setOtherCharges(
        Number(Number(hourlyRate - Number(amount.toFixed(2)) - onteloPlatformFee).toFixed(2))
      )
    }
  }

  useEffect(() => {
    if (!isEdit) {
      setProposalData({
        title: {
          value: '',
          isTouched: false,
          isValid: false,
          errorMsg: '',
        },
        sessionCategory: {
          categoryId: data?.category ? data?.category.categoryId : undefined,
          subCategoryId: data?.subCategory ? data?.subCategory.subCategoryId : undefined,
          name: data?.category
            ? data?.category?.name
            : data?.subCategory
            ? data?.subCategory?.name
            : '',
          isValid: data?.subCategory || data?.category ? true : false,
          errorMsg: 'Select a Category',
          isTouched: data?.subCategory || data?.category ? true : false,
        },
        sessionDescription: '',
        preRequisiteKnowledge: '',
        learningOutcome: '',
        learningRequirement: '',
        hourlyRate: {
          value: data?.response ? data?.response[0].hourlyRate : userDetails.hourlyRate,
          isTouched: false,
          isValid: true,
          errorMsg: '',
        },
        startTime: {
          value: startTiming,
          isTouched: false,
          isValid: true,
          errorMsg: '',
        },
        endTime: {
          value: endTiming,
          isTouched: false,
          isValid: true,
          errorMsg: '',
        },
        selectedDate: new Date(),
        specifyStudent: [],
        request: {
          value: '',
          isValid: false,
          errorMsg: 'Select a Request',
          isTouched: false,
        },
        sessionTimeInHour: {
          value: '1',
          label: '1h',
        },
        connectionMode: {
          value: '',
          label: '',
          isValid: false,
          isTouched: false,
          errorMsg: 'Please select connection mode',
          connectionLink: {
            value: '',
            isValid: false,
            isTouched: false,
            errorMsg: 'Please add valid link',
          },
        },
      })
      totalTeacherAmount(
        data?.response ? data?.response[0].hourlyRate : userDetails.hourlyRate,
        '1'
      )
    } else {
      setProposalData({
        title: {
          value: data?.proposalDetail?.title,
          isTouched: false,
          isValid: true,
          errorMsg: '',
        },
        sessionCategory: {
          categoryId: data?.proposalDetail?.category
            ? data?.proposalDetail?.category.categoryId
            : undefined,
          subCategoryId: data?.proposalDetail?.subCategory
            ? data?.proposalDetail?.subCategory.subCategoryId
            : undefined,
          name: data?.proposalDetail?.category
            ? data?.proposalDetail.category?.name
            : data?.proposalDetail.subCategory?.name,
          isValid: true,
          errorMsg: '',
          isTouched: false,
        },
        sessionDescription: data?.proposalDetail.sessionDescription,
        preRequisiteKnowledge: data?.proposalDetail.preRequisiteKnowledge,
        learningOutcome: data?.proposalDetail.learningOutcome,
        learningRequirement: data?.proposalDetail.learningRequirement,
        hourlyRate: {
          value: data?.proposalDetail.hourlyRate,
          isTouched: false,
          isValid: true,
          errorMsg: '',
        },
        startTime: {
          value: timeStart(data.proposalDetail.sessionDate),
          isTouched: false,
          isValid: true,
          errorMsg: '',
        },
        endTime: {
          value: timeEnd(data.proposalDetail.sessionDate),
          isTouched: false,
          isValid: true,
          errorMsg: '',
        },
        selectedDate: new Date(data.proposalDetail.sessionDate),
        specifyStudent: [],
        request: {
          value: 'I want to understand Photoshop',
          isValid: true,
          errorMsg: '',
          isTouched: false,
        },
        sessionTimeInHour: {
          value: data?.proposalDetail?.sessionTimeInHour,
          label: data?.proposalDetail?.sessionTimeInHour + 'h',
        },
        connectionMode: {
          value: data?.proposalDetail?.connectionMode,
          label: data?.proposalDetail?.connectionMode,
          isValid: true,
          isTouched: false,
          errorMsg: 'Please select connection mode',
          connectionLink: {
            value: data?.proposalDetail?.connectionLink,
            isValid: true,
            isTouched: false,
            errorMsg: 'Please add valid link',
          },
        },
      })
      totalTeacherAmount(data?.proposalDetail.hourlyRate, data?.proposalDetail?.sessionTimeInHour)
    }
  }, [isEdit, userDetails])
  const errorMessagePopup = (errorMessage: string = '') => {
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img className={styles.popupImage} src={RepeatRequest} alt="popup icon" />
        }}
        title={`${t('common.failed')}!`}
        Buttons={() => {
          return (
            <>
              <Button
                className={styles.proposal_cancel_btn}
                text={t('common.cancel')}
                onClick={() => {
                  close()
                }}
              ></Button>
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{errorMessage}</p>
            </div>
          )
        }}
      />
    )
  }
  return (
    <>
      <NoEventsLoader isLoading={sectionLoading && sectionLoading.createProposal}>
        <div className={styles['list-card']}>
          <div className={styles['list-card-header']}>
            <div className={styles['d-flex']}>
              <div className={styles['request-respond']}>
                <img src={createProposalGray} alt="create icon" className={styles.divImg} />
                {t('createProposal.sessionDescription')}
              </div>
            </div>
          </div>
          <div className={styles['list-card-title']}>{t('createProposal.title')}</div>
          <div className={styles['list-card-content']}>{t('createProposal.shortName')}</div>
          <div className={styles['input-wrapper']}>
            <InputWrapper
              valueObj={proposalData.title}
              type={'text'}
              placeholder="For example, Photoshop introduction"
              className={styles['input-styles']}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(e: any) => {
                let value = e.value
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setProposalData((prev: any) => {
                  return {
                    ...prev,
                    title: { value: value, isValid: e.isValid, isTouched: true, errorMsg: '' },
                  }
                })
              }}
              schema={titleSchema}
              validateOnChange={true}
              validateOnBlur={true}
            />
          </div>
          {!isSession ? (
            <>
              <div className={styles['list-card-title']}>{t('requestCard.category')}</div>
              <div className={styles['list-card-content']}>{t('requestCard.specify-category')}</div>
              <div className={styles.autocompletePosition}>
                <div className="autocomplete-wrapper autocomplete_disable_icon_animation">
                  <Autocomplete
                    disabled={isSession}
                    popupIcon={<img src={Search} alt="search_icon" />}
                    value={proposalData.sessionCategory}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        setProposalData((prev: any) => {
                          return {
                            ...prev,
                            sessionCategory: {
                              categoryId: newValue.categoryId ? newValue.categoryId : undefined,
                              subCategoryId: newValue.subCategoryId
                                ? newValue.subCategoryId
                                : undefined,
                              name: newValue.name
                                ? newValue.name
                                : proposalData.sessionCategory.name,
                              isValid: newValue.name ? true : false,
                              errorMsg: '',
                              isTouched: true,
                            },
                          }
                        })
                      }
                    }}
                    handleHomeEndKeys
                    options={categoryArray}
                    getOptionLabel={(option) => {
                      return option.name
                    }}
                    renderOption={(option) => <AutocompleteOption option={option.name} />}
                    ListboxProps={{
                      style: {
                        maxHeight: '147px',
                      },
                    }}
                    style={{
                      width: '100%',
                      position: 'relative',
                      border: 'none !important',
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" placeholder="Start Typing" />
                    )}
                  />
                </div>
                {proposalData.sessionCategory.isValid === false &&
                proposalData.sessionCategory.isTouched === true ? (
                  <div className={styles.error}>{proposalData.sessionCategory.errorMsg}</div>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <> </>
          )}
          <div className={styles['list-card-title']}>
            {isEdit || isSession ? 'Session description' : 'Course outline'}
          </div>
          <div className={styles['list-card-content']}>
            {t('createProposal.specifySessionContent')}
          </div>
          <div>
            <textarea
              className={styles['textarea-styles']}
              value={proposalData.sessionDescription}
              onChange={(e: any) => {
                let value = e.target.value
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setProposalData((prev: any) => {
                  return { ...prev, sessionDescription: value }
                })
              }}
            ></textarea>
            <div className={styles['character-count']}>
              {charactersLeft({
                currentLength:
                  proposalData?.sessionDescription?.length > 0
                    ? proposalData?.sessionDescription?.length
                    : 0,
              })}
              {t('common.charactersLeft')}
            </div>
          </div>
          <div className={styles['list-card-title']}>{t('sessionDetails.preRequisiteKnow')}</div>
          <div className={styles['list-card-content']}>
            The knowledge or skills that the student should already have
          </div>
          <div>
            <textarea
              className={styles['textarea-styles']}
              value={proposalData.preRequisiteKnowledge}
              onChange={(e) => {
                let value = e.target.value
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setProposalData((prev: any) => {
                  return { ...prev, preRequisiteKnowledge: value }
                })
              }}
            ></textarea>
            <div className={styles['character-count']}>
              {charactersLeft({
                currentLength:
                  proposalData?.preRequisiteKnowledge?.length > 0
                    ? proposalData?.preRequisiteKnowledge?.length
                    : 0,
              })}
              {t('common.charactersLeft')}
            </div>
          </div>
          <div className={styles['list-card-title']}>Learning outcomes</div>
          <div className={styles['list-card-content']}>What the student will learn”</div>
          <div>
            <textarea
              className={styles['textarea-styles']}
              value={proposalData.learningOutcome}
              onChange={(e) => {
                let value = e.target.value
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setProposalData((prev: any) => {
                  return { ...prev, learningOutcome: value }
                })
              }}
            ></textarea>
            <div className={styles['character-count']}>
              {charactersLeft({
                currentLength:
                  proposalData?.learningOutcome?.length > 0
                    ? proposalData?.learningOutcome?.length
                    : 0,
              })}
              {t('common.charactersLeft')}
            </div>
          </div>
          <div className={styles['list-card-title']}>Software / hardware requirements</div>
          <div className={styles['list-card-content']}>
            What software / hardware the student should have
          </div>
          <div>
            <textarea
              className={styles['textarea-styles']}
              value={proposalData.learningRequirement}
              onChange={(e) => {
                let value = e.target.value
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setProposalData((prev: any) => {
                  return { ...prev, learningRequirement: value }
                })
              }}
            ></textarea>
            <div className={styles['character-count']}>
              {charactersLeft({
                currentLength:
                  proposalData?.learningRequirement?.length > 0
                    ? proposalData?.learningRequirement?.length
                    : 0,
              })}
              {t('common.charactersLeft')}
            </div>
          </div>
        </div>
        {/* to be uncommented later */}
        {/* <div className={styles['list-card']}>
        <div className={styles['list-card-header']}>
          <div className={styles['d-flex']}>
            <div className={styles['request-respond']}>
              <img src={attendies} alt="attendies" className={styles.divImg} />
              Attendies
            </div>
          </div>
        </div>
        <div className={styles['list-card-title']}>Specify student</div>
        <div className={styles['list-card-content']}>Select one or more attendies</div>
        <div className={styles.autocompletePosition}>
          <div className="autocomplete-wrapper">
            <Autocomplete
              onChange={(event, newValue) => {
                if (newValue) {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  setSelectedStudents((prev: any) => {
                    return [...prev, newValue]
                  })
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  setProposalData((prev: any) => {
                    return {
                      ...prev,
                      specifyStudent: [
                        ...prev.specifyStudent,
                        {
                          userId: newValue.userId,
                          value: newValue.value,
                        },
                      ],
                    }
                  })
                  fetchStudentRequest(newValue.userId)
                }
              }}
              handleHomeEndKeys
              options={attendiesEmail}
              getOptionLabel={(option: any) => {
                return option.firstName
              }}
              renderOption={(option: any) => {
                return (
                  <div className={styles['autocomplete-dropdown']}>
                    <img src={option.imageUrl} alt="time-icon" />
                    <div>{option.firstName}</div>
                  </div>
                )
              }}
              value={proposalData.specifyStudent}
              ListboxProps={{
                style: {
                  maxHeight: '147px',
                },
              }}
              style={{
                width: '100%',
                position: 'relative',
                border: 'none !important',
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select or start typing email"
                />
              )}
            />
          </div>
          {proposalData.specifyStudent.length === 0 ? (
            <div className={styles.error}>Please select a attendie</div>
          ) : (
            <></>
          )}
        </div>
        <div className="green_radio_buttons">
          <RadioGroup name="radio-buttons-group">
            {selectedStudents.map((each: any, index: Key | null | undefined) => {
              return (
                <div className={styles.selectedUser} key={index}>
                  <div className={styles['review-card__profile']}>
                    {each.imageUrl ? (
                      <img
                        src={each.imageUrl}
                        className={styles['review-card__username-img']}
                        alt="user"
                      />
                    ) : (
                      <></>
                    )}
                    <div className={styles['review-card__star-rating']}>
                      <div className={styles['review-card__username']}>{each.firstName}</div>
                      <div className={styles['review-card__rating']}>
                        {elem.map((item) => {
                          return (
                            <img
                              src={star}
                              alt="star icons"
                              key={'star' + item}
                              className={styles['review-card__rating-icons']}
                            />
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  <FormControlLabel
                    value={each.firstName}
                    className={`${styles.radioGroup} ${styles.radio_label}`}
                    control={<Radio size={'medium'} disableRipple />}
                    label={'Payer'}
                    classes={{
                      label: styles.label,
                    }}
                  />
                  <div
                    className={styles['gray-bg']}
                    onClick={() => {
                      setSelectedStudents(
                        selectedStudents.filter((item: any, i: Key | null | undefined) => {
                          return index != i
                        })
                      )
                    }}
                  >
                    <img src={deleteMenu} alt="delete" />
                  </div>
                </div>
              )
            })}
          </RadioGroup>
        </div>
      </div> */}
        {isChat ? (
          <div className={styles['list-card']}>
            <div className={styles['list-card-header']}>
              <div className={styles['d-flex']}>
                <div className={styles['request-respond']}>
                  <img src={questionMark} alt="question" className={styles.divImg} />
                  Request
                </div>
              </div>
            </div>
            <div className={styles['list-card-title']}>Specify request</div>
            <div className={styles['list-card-content']}>Select active request</div>
            <div className={styles.autocompletePosition}>
              <div className="autocomplete-wrapper">
                <Autocomplete
                  value={dropdownValue}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(event, newValue: any) => {
                    if (newValue) {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      setProposalData((prev: any) => {
                        return {
                          ...prev,
                          request: {
                            value: newValue.description,
                            requestId: newValue.requestId,
                            isValid: newValue !== '' ? true : false,
                            errorMsg: '',
                            isTouched: true,
                          },
                        }
                      })
                      setDropdownValue((prev: any) => {
                        return {
                          ...prev,
                          ...newValue,
                        }
                      })
                    } else {
                      setProposalData((prev: any) => {
                        return {
                          ...prev,
                          request: {
                            ...prev.request,
                            isValid: false,
                            errorMsg: 'Select a request',
                            isTouched: true,
                          },
                        }
                      })
                    }
                  }}
                  handleHomeEndKeys
                  options={requestArray}
                  getOptionLabel={(option) => {
                    return option.description.replace(/(<([^>]+)>)/gi, '')
                  }}
                  renderOption={(option) => (
                    <AutocompleteOption option={option.description.replace(/(<([^>]+)>)/gi, '')} />
                  )}
                  ListboxProps={{
                    style: {
                      maxHeight: '147px',
                    },
                  }}
                  style={{
                    width: '100%',
                    position: 'relative',
                    border: 'none !important',
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="First, select the student..."
                    />
                  )}
                />
              </div>
              {proposalData.request.isValid === false && proposalData.request.isTouched === true ? (
                <div className={styles.error}>{proposalData.request.errorMsg}</div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : null}
        <div className={styles['list-card']}>
          <div className={styles['list-card-header']}>
            <div className={styles['d-flex']}>
              <div className={styles['request-respond']}>
                <img src={connectionMethod} alt="connection" className={styles.divImg} />
                Connection method
              </div>
            </div>
          </div>
          <div className={styles['radio-wrapper']}>
            <div className="green_radio_buttons">
              <RadioGroup
                name="radio-buttons-group"
                value={proposalData.connectionMode.value}
                onChange={(e) => {
                  let value = e.target.value
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  setProposalData((prev: any) => {
                    return {
                      ...prev,
                      connectionMode: {
                        value: value,
                        label: value,
                        isValid: true,
                        isTouched: true,
                        errorMsg: 'Please select connection mode',
                        connectionLink: {
                          value: '',
                          isValid: false,
                          isTouched: false,
                          errorMsg: 'Please add valid link',
                        },
                      },
                    }
                  })
                }}
              >
                {radioOptions.map((each, index: number) => {
                  return (
                    <div key={index} className={styles.radio_label}>
                      <FormControlLabel
                        value={each.value}
                        control={<Radio size={'medium'} disableRipple />}
                        label={each.label}
                        classes={{
                          label: styles.label,
                        }}
                      />
                      {each.value === proposalData.connectionMode.value ? (
                        <div>
                          <div className={styles['list-card-title']}>Got the room link?</div>
                          <div className={styles['input-wrapper']}>
                            <InputWrapper
                              valueObj={proposalData.connectionMode.connectionLink}
                              type={'text'}
                              placeholder="Put it here"
                              className={styles['input-styles']}
                              schema={linkSchema}
                              //eslint-disable-next-line @typescript-eslint/no-explicit-any
                              onChange={(e: any) => {
                                let value = e.value
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                setProposalData((prev: any) => {
                                  return {
                                    ...prev,
                                    connectionMode: {
                                      ...prev.connectionMode,
                                      connectionLink: {
                                        value: value,
                                        isValid: e.isValid,
                                        isTouched: true,
                                        errorMsg: 'Please add valid link',
                                      },
                                    },
                                  }
                                })
                              }}
                              validateOnChange={true}
                              validateOnBlur={true}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )
                })}
              </RadioGroup>
            </div>
            {proposalData.connectionMode.isValid === false &&
            proposalData.connectionMode.isTouched === true ? (
              <div className={styles.error}>{proposalData.connectionMode.errorMsg}</div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={styles['list-card']}>
          <div className={styles['list-card-header']}>
            <div className={styles['d-flex']}>
              <div className={styles['request-respond']}>
                <img src={terms} alt="terms" className={styles.divImg} />
                Terms
              </div>
            </div>
          </div>
          {!isSession ? (
            <>
              <div className={styles['d-flex']}>
                <div className={styles.wrapperLeft}>
                  <div className={styles['list-card-title']}>Hourly Rate</div>
                  <div className={styles['list-card-content']}>
                    Your profile rate: ${proposalData.hourlyRate.value}/hr
                  </div>
                  <div className={styles['d-flex']}>
                    <div className={styles.smallField}>
                      <InputWrapper
                        type="text"
                        disabled
                        valueObj={{ value: '$', isTouched: false, isValid: true, errorMsg: '' }}
                        className={styles.dolorBold}
                        onChange={undefined}
                      />
                    </div>
                    <div className={styles.halfField}>
                      <InputWrapper
                        type="number"
                        onKeyDown={onKeyDownCost}
                        valueObj={proposalData.hourlyRate}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onChange={(e: any) => {
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          if (isNaN(Number(e.value)) || Number(e.value) > 9999) {
                            setProposalData((prev: any) => {
                              return {
                                ...prev,
                                hourlyRate: {
                                  ...prev.hourlyRate,
                                  isTouched: true,
                                },
                              }
                            })
                          } else {
                            setProposalData((prev: any) => {
                              return {
                                ...prev,
                                hourlyRate: {
                                  value: e.value,
                                  isValid: e.isValid,
                                  errorMsg: e.errorMsg,
                                  isTouched: true,
                                },
                              }
                            })
                            totalTeacherAmount(e.value, proposalData.sessionTimeInHour.value)
                          }
                        }}
                        schema={costSchema}
                        validateOnChange={true}
                        validateOnBlur={true}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.wrapperRight}>
                  <div className={`${styles['list-card-title']} ${styles.flex}`}>
                    <div>Session time</div>
                    <div
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                      // title="ontelo micro-learning sessions last upto 4hrs. If your event requires longer, please create multiple sessions"
                    >
                      <img src={question} alt="Question icon" />
                    </div>
                    <Popover
                      id="mouse-over-popover"
                      style={{
                        pointerEvents: 'none',
                        // width: '500px',
                      }}
                      open={open}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      onClose={handlePopoverClose}
                      disableRestoreFocus
                    >
                      <div className={styles.hide}>
                        ontelo micro-learning sessions last upto 4hrs. If your event requires
                        longer, please create multiple sessions
                      </div>
                    </Popover>
                  </div>
                  <div className={styles['list-card-content']}>
                    Indicate the length of the training session
                  </div>

                  <div className="checkbox_groups">
                    <RadioGroup
                      value={proposalData.sessionTimeInHour.value}
                      name="radio-buttons-group"
                      onChange={(e) => {
                        let value: string
                        let num =
                          Number(proposalData.startTime.value.split(':')[0]) +
                          Number(e.target.value)
                        if (num >= 24) {
                          value = num - 24 + ':' + proposalData.startTime.value.split(':')[1]
                        } else {
                          value = num + ':' + proposalData.startTime.value.split(':')[1]
                        }
                        if (value.length !== 5) {
                          value = '0' + value
                        }
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        setProposalData((prev: any) => {
                          return {
                            ...prev,
                            sessionTimeInHour: {
                              value: e.target.value,
                              label: e.target.value + 'h',
                            },
                            endTime: {
                              value: value,
                              isTouched: true,
                              isValid: true,
                              errorMsg: '',
                            },
                          }
                        })
                        totalTeacherAmount(proposalData.hourlyRate.value, e.target.value)
                      }}
                    >
                      {checkboxValue.map((each, index) => {
                        return (
                          <FormControlLabel
                            className={styles.boxes_label}
                            value={each.value}
                            control={<Radio size={'medium'} disableRipple />}
                            label={each.label}
                            key={index}
                          />
                        )
                      })}
                    </RadioGroup>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className={styles['d-flex']}>
            <div className={styles.wrapperLeft}>
              <div className={styles['list-card-title']}>Session date</div>
              {proposalData.session}
              <DatePickerComponent
                minDate={new Date()}
                onChange={(newValue: MaterialUiPickersDate) => {
                  setProposalData((prev: any) => {
                    return {
                      ...prev,
                      selectedDate: newValue,
                    }
                  })
                }}
                value={proposalData.selectedDate}
              />
            </div>
            <div className={styles.wrapperRight}>
              <div className={styles['d-flex']}>
                <div className={styles.timeBlock}>
                  <div className={styles['list-card-title']}>Start time</div>
                  <div className={styles['input-wrapper']}>
                    <InputWrapper
                      valueObj={proposalData.startTime}
                      type={'time'}
                      className={styles['input-time']}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onChange={(e: any) => {
                        let value: string
                        let num =
                          Number(e.value.split(':')[0]) +
                          Number(proposalData.sessionTimeInHour.value)
                        if (num >= 24) {
                          value = num - 24 + ':' + e.value.split(':')[1]
                        } else {
                          value = num + ':' + e.value.split(':')[1]
                        }
                        if (value.length !== 5) {
                          value = '0' + value
                        }
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        setProposalData((prev: any) => {
                          return {
                            ...prev,
                            startTime: {
                              value: e.value,
                              isTouched: true,
                              isValid: e.isValid,
                              errorMsg: '',
                            },
                            endTime: {
                              value: value,
                              isTouched: false,
                              isValid: true,
                              errorMsg: '',
                            },
                          }
                        })
                      }}
                      validateOnChange={true}
                      validateOnBlur={true}
                    />
                  </div>
                  {proposalData.startTime.isValid === false &&
                  proposalData.startTime.isTouched === true ? (
                    <div className={styles.error}>{proposalData.startTime.errorMsg}</div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className={styles.timeBlock}>
                  <div className={styles['list-card-title']}>End time</div>
                  <div className={styles['input-wrapper']}>
                    <InputWrapper
                      disabled
                      valueObj={proposalData.endTime}
                      type={'time'}
                      className={styles['input-end-time']}
                      validateOnChange={true}
                      validateOnBlur={true}
                      onChange={undefined}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {minimumBal ? (
            <div className={styles.error}>Session price should be atleast 3$</div>
          ) : (
            <></>
          )}
        </div>
        <div className={styles['list-card']}>
          {!isSession ? (
            <>
              <div className={styles['list-card-header']}>
                <div className={styles['d-flex']}>
                  <div className={styles['request-respond']}>
                    <img src={summary} alt="summary" className={styles.divImg} />
                    Summary
                  </div>
                </div>
              </div>
              <div className={styles.borderLine}>
                <div className={styles.titleContainer}>
                  <div className={styles.headingCost}>Session Price</div>
                  <div className={styles.subHeadingCost}>
                    Total amount the client will see on your proposal
                  </div>
                </div>
                {minimumBal ? (
                  <div className={styles.dolar}>$0</div>
                ) : (
                  <div className={styles.dolar}>
                    ${proposalData.hourlyRate.value * Number(proposalData.sessionTimeInHour.value)}
                  </div>
                )}
              </div>
              <div className={styles.borderLine}>
                <div className={styles.titleContainer}>
                  <div className={styles.headingCost}>{t('userDetails.service-fees')}</div>
                  <div className={styles.subHeadingCost}>{t('userDetails.service-charge')}</div>
                </div>
                <div className={styles.dolar}>${onteloFee}</div>
              </div>
              <div className={styles.borderLine}>
                <div className={styles.titleContainer}>
                  <div className={styles.headingCost}>Other charges</div>
                  <div className={styles.subHeadingCost}>Estimated bank transaction fee's</div>
                </div>
                <div className={`${styles.dolar}`}>${otherCharges}</div>
              </div>
              <div className={styles.borderLine}>
                <div className={styles.titleContainer}>
                  <div className={styles.headingCost}>{t('userDetails.your-fees')}</div>
                  <div className={styles.subHeadingCost}>{t('userDetails.your-charge')}</div>
                </div>
                <div className={`${styles.dolar} ${styles.color}`}>${teacherAmount}</div>
              </div>
            </>
          ) : (
            <> </>
          )}
          <div className={styles.buttonLeft}>
            <Button
              text={isSession ? 'Save' : isEdit ? 'Edit Proposal' : 'Publish Proposal'}
              className={isSession ? styles.btnClass : styles.buttonClass}
              onClick={() => {
                if (!minimumBal) {
                  onsubmit(true)
                }
              }}
              disabled={minimumBal}
              ButtonImg={() => {
                return (
                  <>
                    {sectionLoading && sectionLoading.createProposal ? (
                      <Loader className="form_loader" size={20} thickness={2} />
                    ) : (
                      <>
                        <img src={tick} alt="tick icon" className={styles.divImg} />
                      </>
                    )}
                  </>
                )
              }}
              type="submit"
            />
          </div>
        </div>
      </NoEventsLoader>
    </>
  )
}
