/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useFetch } from '../../common/https'
import { useUserInfoContext } from '../../common/use-user-info-context'
import { NameInitials } from '../name-initials/NameInitials'
import styles from './UserInfo.module.scss'

interface UserInfoInterface {
  userId: string
  firstName: string
  lastName: string
  imageUrl: string
}

export const UserInfo: React.FC<{ readonly userId: string }> = ({ userId }) => {
  const { fetchRest } = useFetch({})

  const { getUserData, setUser } = useUserInfoContext()

  const [userInfo, setUserInfo] = useState<UserInfoInterface>()

  useEffect(() => {
    let user: any = []
    if (userId) {
      user = getUserData(userId)
      if (!user) {
        fetchRest({ url: `/getBasicUserInfo?userId=${userId}`, method: 'GET' })
          .then((res: any) => {
            setUser(res.data.data)
            setUserInfo(res.data.data)
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        setUserInfo(user)
      }
    }
  }, [userId])

  return (
    <div>
      <div className={styles.icon}>
        {userInfo && userInfo.imageUrl ? (
          <img src={userInfo.imageUrl} className={styles.img} alt="channel icon" />
        ) : userInfo ? (
          <NameInitials
            firstName={userInfo && userInfo?.firstName}
            lastName={userInfo && userInfo?.lastName}
          />
        ) : (
          <div className={`${styles.container}`}></div>
        )}
      </div>
    </div>
  )
}
