import { alpha, FormControlLabel, Radio, RadioGroup, styled, Switch } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import done from '../../assets/images/png/done.png'
import dispute from '../../assets/images/svg/openDispute.svg'
import gray_cross from '../../assets/images/png/gray_cross.png'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import tick from '../../assets/images/svg/whiteTick.svg'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { Loader } from '../../common/Loader/Loader'
import { useFetch } from '../../common/https'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import { UsePopupContext } from '../../common/use-popup-context'
import { charactersLeft } from '../../common/utils'
import { Button } from '../../common/button/Button'
import styles from './WithdrawResponse.module.scss'
import warn from '../../assets/images/svg/warningIcon.svg'
import attention from '../../assets/images/svg/attention.svg'
import { green } from '@material-ui/core/colors'

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: green[600],
    '&:hover': {
      backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: green[600],
  },
}))

interface WithdrawResponseProps {
  readonly responseId: string
  readonly onSuccess?: () => void
  readonly onError?: () => void
  readonly inProposal?: boolean
  readonly isSession?: boolean
  readonly isAttendee?: boolean
  readonly openDispute?: boolean
}

export const WithdrawResponse: React.FC<WithdrawResponseProps> = ({
  responseId,
  onError = () => {},
  onSuccess = () => {},
  inProposal = false,
  isSession = false,
  isAttendee = false,
  openDispute = false,
}) => {
  const { t } = useTranslation()
  const { close, setData: setPopupData } = UsePopupContext()
  const { fetchRest, sectionLoading } = useFetch({
    withdrawResponse: false,
    deleteProposal: false,
    cancelSession: false,
    rejectProposal: false,
    openDispute: false,
  })
  const [toggleForSession, setToggleForSession] = useState<boolean>(false)
  const [isTextAreaTouched, setIsTextAreaTouched] = useState<boolean>(false)
  const [errorOnTextArea, setErrorOnTextArea] = useState<boolean>(false)
  const cancelBtnText =
    isSession || openDispute || toggleForSession
      ? 'Done'
      : inProposal
      ? isAttendee
        ? 'Reject Proposal'
        : ' Cancel Proposal'
      : 'Withdraw Response'
  const [formState, setFormState] = useState<{
    readonly reason: string
    readonly moreInfo: string
  }>(
    inProposal || isSession || openDispute || toggleForSession
      ? { reason: "It's something else", moreInfo: '' }
      : {
          reason: 'appliedByMistake',
          moreInfo: '',
        }
  )
  const radioOptions: ReadonlyArray<{
    readonly value: string | number
    readonly label: string
  }> = [
    {
      value: 'appliedByMistake',
      label: t('withdrawResponse.appliedByMistake'),
    },
    {
      value: 'circumstancesHaveChanged',
      label: t('withdrawResponse.circumstancesHaveChanged'),
    },
    {
      value: "It's something else",
      label: t('withdrawResponse.somethingElse'),
    },
  ]
  const openDisputeReasonForStudent: ReadonlyArray<{
    readonly value: string | undefined
    readonly label: string | undefined
  }> = [
    {
      value: 'My mentor did not turn up / left the session early',
      label: 'My mentor did not turn up / left the session early',
    },
    {
      value: "It's something else",
      label: t('withdrawResponse.proposal.mentor.reason4'),
    },
  ]
  const openDisputeReasonForMentor: ReadonlyArray<{
    readonly value: string | undefined
    readonly label: string | undefined
  }> = [
    {
      value: "The haven't received my funds yet for this session.",
      label: "The haven't received my funds yet for this session.",
    },
    {
      value: "It's something else",
      label: t('withdrawResponse.proposal.mentor.reason4'),
    },
  ]
  const cancelProposalForMentor: ReadonlyArray<{
    readonly value: string | undefined
    readonly label: string | undefined
  }> = [
    {
      value: 'The student has not responded',
      label: t('withdrawResponse.proposal.mentor.reason1'),
    },
    {
      value: 'I am no longer available',
      label: t('withdrawResponse.proposal.mentor.reason2'),
    },
    {
      value: 'I am no longer keen to deliver the session',
      label: t('withdrawResponse.proposal.mentor.reason3'),
    },
    {
      value: "It's something else",
      label: t('withdrawResponse.proposal.mentor.reason4'),
    },
  ]

  const cancelProposalForStudent: ReadonlyArray<{
    readonly value: string | undefined
    readonly label: string | undefined
  }> = [
    {
      value: 'The mentor is too expensive',
      label: t('withdrawResponse.proposal.student.reason1'),
    },
    {
      value: 'The proposal did not meet my objectives',
      label: t('withdrawResponse.proposal.student.reason2'),
    },
    {
      value: 'I no longer want/need the training',
      label: t('withdrawResponse.proposal.student.reason3'),
    },
    {
      value: "It's something else",
      label: t('withdrawResponse.proposal.student.reason4'),
    },
  ]
  const cancelSessionForMentor: ReadonlyArray<{
    readonly value: string | undefined
    readonly label: string | undefined
  }> = [
    {
      value: 'I am no longer keen to deliver the session',
      label: t('withdrawResponse.session.mentor.reason1'),
    },
    {
      value: "It's something else",
      label: t('withdrawResponse.session.mentor.reason2'),
    },
  ]
  const cancelSessionForStudent: ReadonlyArray<{
    readonly value: string | undefined
    readonly label: string | undefined
  }> = [
    {
      value: 'I no longer want/need the training',
      label: t('withdrawResponse.session.student.reason1'),
    },
    {
      value: "It's something else",
      label: t('withdrawResponse.session.student.reason2'),
    },
  ]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onReasonChange = (event: any) => {
    event.persist()
    if (event && event.target && event.target.value) {
      setFormState((prev) => ({ ...prev, reason: event.target.value }))
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onInfoChange = (event: any) => {
    event.persist()
    setIsTextAreaTouched(true)
    setFormState((prev) => ({ ...prev, moreInfo: event.target.value }))
  }

  const openPopup = (isSuccess: boolean) => {
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return (
            <img
              className={styles.popupImage}
              src={isSuccess ? (toggleForSession || openDispute ? dispute : done) : RepeatRequest}
              alt="popup icon"
            />
          )
        }}
        title={isSuccess ? `${t('common.done')}!` : `${t('common.failed')}!`}
        Buttons={() => {
          return (
            <>
              {isSuccess ? (
                <Button
                  className={styles.white}
                  text={`${t('common.gotIt')}!`}
                  onClick={() => {
                    onSuccess()
                    close()
                  }}
                ></Button>
              ) : (
                <Button
                  className={styles.cancel_btn}
                  text={t('common.cancel')}
                  onClick={() => {
                    onError()
                    close()
                  }}
                ></Button>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>
                {isSuccess
                  ? inProposal
                    ? isAttendee
                      ? t('withdrawResponse.proposal-rejected')
                      : t('withdrawResponse.proposal-deleted')
                    : toggleForSession || openDispute
                    ? t('withdrawResponse.session.open-dispute')
                    : isSession
                    ? t('withdrawResponse.session.cancel-session-content')
                    : t('withdrawResponse.responseHasBeenWithdrawn')
                  : inProposal
                  ? isAttendee
                    ? t('withdrawResponse.could-not-reject')
                    : t('withdrawResponse.could-not-delete')
                  : toggleForSession || openDispute
                  ? t('withdrawResponse.session.open-dispute-failed')
                  : isSession
                  ? t('withdrawResponse.session.cancel-failed')
                  : t('withdrawResponse.failedToWithdrawTheResponse')}
              </p>
            </div>
          )
        }}
      />
    )
  }

  const errorMessagePopup = (errorMessage: string = '') => {
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img className={styles.popupImage} src={RepeatRequest} alt="popup icon" />
        }}
        title={`${t('common.failed')}!`}
        Buttons={() => {
          return (
            <>
              <Button
                className={styles.cancel_btn}
                text={t('common.cancel')}
                onClick={() => {
                  onError()
                  close()
                }}
              ></Button>
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{errorMessage}</p>
            </div>
          )
        }}
      />
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (event: any) => {
    event.preventDefault()
    if (
      (formState.reason === "It's something else" && formState.moreInfo) ||
      (formState.reason && formState.reason !== "It's something else")
    ) {
      if (formState.reason) {
        if (toggleForSession || openDispute) {
          fetchRest(
            {
              url: `disputeSession/${responseId}`,
              method: 'PUT',
              data: {
                reasonForDispute: formState.reason,
                additionalDisputeDetail: formState?.moreInfo ? formState?.moreInfo : undefined,
              },
            },
            'openDispute'
          )
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((res: any) => {
              openPopup(true)
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((err: any) => {
              if (err?.response?.data?.error?.errorStatus) {
                errorMessagePopup(err.response.data.error?.message)
              } else {
                openPopup(false)
              }
            })
        } else {
          inProposal
            ? isAttendee
              ? fetchRest(
                  {
                    url: `/rejectProposal/${responseId}`,
                    method: 'PUT',
                    data: {
                      reasonForRejection: formState.reason,
                      additionalRejectionDetails: formState?.moreInfo
                        ? formState?.moreInfo
                        : undefined,
                    },
                  },
                  'rejectProposal'
                )
                  .then((res) => {
                    openPopup(true)
                  })
                  .catch((err) => {
                    if (err?.response?.data?.error?.errorStatus) {
                      errorMessagePopup(err.response.data.error?.message)
                    } else {
                      openPopup(false)
                    }
                  })
              : fetchRest(
                  {
                    url: `deleteProposal/${responseId}`,
                    method: 'PUT',
                    data: {
                      reasonForCancellation: formState.reason,
                      additionalCancellationDetails: formState?.moreInfo
                        ? formState?.moreInfo
                        : undefined,
                    },
                  },
                  'deleteProposal'
                )
                  .then((res) => {
                    openPopup(true)
                  })
                  .catch((err) => {
                    if (err?.response?.data?.error?.errorStatus) {
                      errorMessagePopup(err.response.data.error?.message)
                    } else {
                      openPopup(false)
                    }
                  })
            : isSession && !toggleForSession
            ? fetchRest(
                {
                  url: `cancelSession/${responseId}`,
                  method: 'PUT',
                  data: {
                    reasonForCancellation: formState.reason,
                    additionalCancellationDetails: formState?.moreInfo
                      ? formState?.moreInfo
                      : undefined,
                  },
                },
                'cancelSession'
              )
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((res: any) => {
                  openPopup(true)
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .catch((err: any) => {
                  if (err?.response?.data?.error?.errorStatus) {
                    errorMessagePopup(err.response.data.error?.message)
                  } else {
                    openPopup(false)
                  }
                })
            : fetchRest(
                {
                  url: 'withdrawResponse',
                  method: 'PUT',
                  data: {
                    responseId,
                    withdrawReason: formState.reason,
                    withdrawDetails: formState.moreInfo ? formState.moreInfo : undefined,
                  },
                },
                'withdrawResponse'
              )
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((res: any) => {
                  openPopup(true)
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .catch((err: any) => {
                  openPopup(false)
                })
        }
      } else {
        alert('fill up the fields')
      }
    } else {
      setErrorOnTextArea(true)
    }
  }
  const labelOption = isSession
    ? isAttendee
      ? cancelSessionForStudent
      : cancelSessionForMentor
    : inProposal
    ? isAttendee
      ? cancelProposalForStudent
      : cancelProposalForMentor
    : openDispute
    ? isAttendee
      ? openDisputeReasonForStudent
      : openDisputeReasonForMentor
    : radioOptions
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <img src={openDispute ? warn : gray_cross} alt="cross_icon" className={styles.gray_cross} />
        {inProposal
          ? isAttendee
            ? 'Reject Proposal'
            : t('createProposal.cancelProposal')
          : isSession
          ? t('withdrawResponse.session.cancel-session')
          : openDispute
          ? 'Open Dispute'
          : t('withdrawResponse.withdrawRespond')}
      </div>
      {/* Wrap the form in NoEventsLoader component */}
      <NoEventsLoader
        isLoading={
          sectionLoading &&
          (sectionLoading.withdrawResponse ||
            sectionLoading.deleteProposal ||
            sectionLoading.cancelSession ||
            sectionLoading.rejectProposal ||
            sectionLoading.openDispute)
        }
      >
        <form onSubmit={onSubmit}>
          <div className="green_radio_buttons">
            <RadioGroup
              onChange={onReasonChange}
              value={formState.reason}
              name="radio-buttons-group"
            >
              {(isSession && toggleForSession) || openDispute
                ? isAttendee
                  ? openDisputeReasonForStudent.map((each, index: number) => {
                      return (
                        <FormControlLabel
                          className={styles.radio_label}
                          value={each.value}
                          control={<Radio size={'medium'} disableRipple />}
                          label={each.label}
                          key={index}
                          classes={{
                            label: styles.label,
                          }}
                        />
                      )
                    })
                  : openDisputeReasonForMentor.map((each, index: number) => {
                      return (
                        <FormControlLabel
                          className={styles.radio_label}
                          value={each.value}
                          control={<Radio size={'medium'} disableRipple />}
                          label={each.label}
                          key={index}
                          classes={{
                            label: styles.label,
                          }}
                        />
                      )
                    })
                : labelOption.map((each, index: number) => {
                    return (
                      <FormControlLabel
                        className={styles.radio_label}
                        value={each.value}
                        control={<Radio size={'medium'} disableRipple />}
                        label={each.label}
                        key={index}
                        classes={{
                          label: styles.label,
                        }}
                      />
                    )
                  })}
            </RadioGroup>
          </div>
          <div className={styles.text_area_container}>
            <div className={styles.text_area_heading}>{t('withdrawResponse.tellUsMore')}</div>
            <div className={styles.text_area_subHeading}>
              {t('withdrawResponse.shareAdditionalDetails')}
            </div>
            <div>
              <textarea
                onChange={onInfoChange}
                value={formState.moreInfo}
                className={styles.textarea}
              />
              <div className={styles.text_area_help_text}>
                <div className="error">
                  {formState.moreInfo && formState.moreInfo.length > 5000
                    ? t('common.moreThan5000CharactersNotAllowed')
                    : ''}
                </div>
                <div>
                  {charactersLeft({
                    currentLength:
                      formState.moreInfo && formState.moreInfo.length
                        ? formState.moreInfo.length
                        : 0,
                  })}
                  {t('common.charactersLeft')}
                </div>
              </div>
              <div className={styles.errorText}>
                {(isTextAreaTouched &&
                  formState.reason === "It's something else" &&
                  formState.moreInfo === '') ||
                errorOnTextArea
                  ? 'Please enter additional information'
                  : ''}
              </div>
            </div>
          </div>
          {isSession || openDispute ? (
            <>
              <div>
                {isSession ? (
                  <div className={styles.switchWrapper}>
                    <GreenSwitch
                      checked={toggleForSession}
                      onChange={() => setToggleForSession(!toggleForSession)}
                    />
                    <div className={styles.colFlex}>
                      <div className={styles.headingSessionCancel}>I want to open the dispute</div>
                      <div className={styles.subHeading}>ontelo here to help you!</div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div>
                  {openDispute ? <div className={styles.border}></div> : <></>}
                  {(isSession && toggleForSession) || openDispute ? (
                    <div className={styles.switchWrapper}>
                      <img src={attention} alt="attention" className={styles.divImg} />
                      <div className={`${styles.subHeading} ${styles.fontSize}`}>
                        Please note that at the beginning of the dispute, the session money in the
                        escrow will be frozen until the dispute is resolved.
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div>
            <Button
              type="submit"
              className={` ${styles.submit_button} ${
                inProposal ? styles.width : styles.widthSubmit
              }`}
              ButtonImg={() => {
                return (
                  <>
                    {sectionLoading &&
                    (sectionLoading.withdrawResponse ||
                      sectionLoading.deleteProposal ||
                      sectionLoading.cancelSession ||
                      sectionLoading.rejectProposal ||
                      sectionLoading.openDispute) ? (
                      <Loader className="form_loader" size={20} thickness={2} />
                    ) : (
                      <>
                        <img className={styles.tick_image} src={tick} alt="submit_icon" />
                      </>
                    )}
                  </>
                )
              }}
              text={cancelBtnText}
            />
          </div>
        </form>
      </NoEventsLoader>
    </div>
  )
}
