/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SwiperCore, { Navigation, SwiperOptions } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import { Loader } from '../../common/Loader/Loader'
import { useFetch } from '../../common/https'
import { LoadingWrapper } from '../../common/loading-wrapper/LoadingWrapper'
import { ReviewCard } from '../review-card/ReviewCard'
import styles from './ReviewSection.module.scss'
import { useUserDetailsContext } from '../../common/use-user-details-context'

export const ReviewSection: React.FC<{
  readonly isSignUp?: boolean
}> = ({ isSignUp = false }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [reviewData, setReviewData] = useState<ReadonlyArray<any>>([])
  const [proposalCount, setProposalCount] = useState('0')
  const [requestCount, setRequestCount] = useState('0')
  const { userDetails } = useUserDetailsContext()
  const sliderPerViewCount = isSignUp ? 3 : 4
  SwiperCore.use([Navigation])
  const { fetchRest, sectionLoading } = useFetch({ reviewSection: true })
  const { t } = useTranslation()
  const swiperOptions: SwiperOptions = isSignUp
    ? {
        slidesPerView: 2,
        spaceBetween: 10,
        centeredSlides: true,
        loop: true,
        initialSlide: 2,
        navigation: true,
        breakpoints: {
          '1280': {
            slidesPerView: 2,
          },
          '1920': {
            slidesPerView: 2.5,
          },
        },
      }
    : {
        slidesPerView: sliderPerViewCount,
        spaceBetween: 20,
        loop: true,
        breakpoints: {
          '1280': {
            slidesPerView: sliderPerViewCount,
          },
          '1920': {
            slidesPerView: 5,
          },
        },
        navigation: true,
      }

  useEffect(() => {
    if (userDetails.userId) {
      fetchRest(
        {
          url: 'requestAndProposalStats',
          method: 'GET',
        },
        'reviewSection'
      ).then(
        (res) => {
          const data = res.data
          setProposalCount(data.proposalCount)
          setRequestCount(data.requestCount)
        },
        (error) => {}
      )
    }

    fetchRest(
      {
        url: 'request',
        method: 'GET',
      },
      'reviewSection'
    ).then(
      (res) => {
        if (res && res.data && res.data.data) {
          const data = res.data.data.map((each: any) => {
            let subject = ''
            if (each && each.category && each.category.name) {
              subject = each.category.name
            } else if (each && each.subCategory && each.subCategory.name) {
              subject = each.subCategory.name
            } else if (each && each.course && each.course.title) {
              subject = each.course.title
            }
            return {
              ...each,
              subject,
            }
          })
          setReviewData(data)
        }
      },
      (error) => {}
    )
  }, [userDetails])

  return (
    <section
      className={`${styles.reviews} ${isSignUp ? styles.signUpHeight : styles.reviewHeight}`}
    >
      {isSignUp ? (
        <>
          <div className={`${styles.reviewsHeader} ${styles.signUpHeaderWidth} `}>
            {t('review.register')}
          </div>
        </>
      ) : (
        <></>
      )}
      <div
        className={
          isSignUp ? styles.signUpHead : `${styles.reviewsHeader} ${styles.reviewHeaderWidth}`
        }
      >
        {t('review.requestGenerated')}
        {requestCount}
        {t('review.learnersRequest')}
        {proposalCount} {t('review.answer')}
      </div>
      <LoadingWrapper
        isLoading={sectionLoading && sectionLoading.reviewSection}
        LoaderComponent={() => <Loader />}
      >
        <Swiper
          className={`review-swiper ${isSignUp ? 'slider_with_focus' : ''}`}
          {...swiperOptions}
        >
          {reviewData.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <div className={styles.cardWidth}>
                  <ReviewCard
                    expire={`Exp in ${item.expiresIn} hours`}
                    starRating={5}
                    subject={item.subject ? item.subject : ''}
                    review={item.description}
                    UserImg={() => {
                      return item?.user?.imageUrl ? (
                        <img
                          src={item.user.imageUrl}
                          className={styles['review-card__username-img']}
                          alt="user"
                        />
                      ) : (
                        <></>
                      )
                    }}
                    username={
                      item.user
                        ? `${item?.user?.firstName ? item.user.firstName : ''} ${
                            item?.user?.lastName ? item.user.lastName : ''
                          }`
                        : ''
                    }
                  />
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </LoadingWrapper>
    </section>
  )
}
