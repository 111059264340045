/* eslint-disable @typescript-eslint/no-explicit-any */
import * as jwt from 'jsonwebtoken'
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useUserDetailsContext } from '../use-user-details-context'
import { getUserTokens } from '../utils'

const ProtectedRoute: React.FC = ({ children }) => {
  const { userDetails } = useUserDetailsContext()
  const { push } = useHistory()
  const { pathname, search } = useLocation()

  const routeToLogin = ({ path = '', redirectTo = '' }: { redirectTo?: string, path: string }) => {
    push(redirectTo ? `/login?redirectTo=${redirectTo}` : '/login', {
      path,
    })
  }

  useEffect(() => {
    let searchParams = new URLSearchParams(search)
    let redirectTo = searchParams.get('redirectTo')
    const { accessToken = '' } = getUserTokens()
    if (accessToken) {
      const decodedToken: any = jwt.decode(accessToken)
      const { exp } = decodedToken
      if (!exp || (exp && exp * 1000 < Date.now())) {
        routeToLogin({
          path: pathname,
          redirectTo: redirectTo ? redirectTo : undefined
        })
      }
    } else {
      routeToLogin({
        path: pathname,
        redirectTo: redirectTo ? redirectTo : undefined
      })
    }
  }, [userDetails])
  return <>{children}</>
}

export default ProtectedRoute
