/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Done from '../../assets/images/png/done.png'
import eye from '../../assets/images/svg/eye.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { Button } from '../../common/button/Button'
import { useFetch } from '../../common/https'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import { Loader } from '../../common/Loader/Loader'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import { loginPasswordSchema, schemaEmail } from '../../common/schema/Schema'
import { UsePopupContext } from '../../common/use-popup-context'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import styles from '../login-form/LoginForm.module.scss'

export const LoginForm: React.FC = () => {
  const [data, setData] = useState({
    email: { value: '', isValid: false, isTouched: false, errorMsg: 'Please enter your email' },
    password: {
      value: '',
      isValid: false,
      isTouched: false,
      errorMsg: 'Please enter the password',
    },
  })
  const [isIconClicked, setIsIconClicked] = useState<boolean>(false)
  const { t } = useTranslation()
  const { close, setData: setPopupData } = UsePopupContext()
  const { push } = useHistory()
  const { fetchRest, sectionLoading } = useFetch({ loginForm: false, userDetails: false })
  const { setUser, getNotificationCount } = useUserDetailsContext()

  const { state, search }: any = useLocation()
  const searchQueryParams = new URLSearchParams(search)
  const redirectTo = searchQueryParams.get('redirectTo')
  const courseId = searchQueryParams.get('courseId')
  const description = searchQueryParams.get('description')
  const zohoRequestId = searchQueryParams.get('zohoRequestId')

  const openEmailVerificationPopup = (success: boolean, errStatus: string = '') => {
    let content: string = ''
    let headerContent: string = ''
    if (success) {
      headerContent = 'Done!'
      content = 'Email Verified'
    } else {
      if (errStatus === 'USER_CONFIRMED') {
        headerContent = 'Already registered, please login!'
      } else if (errStatus === 'INVALID_USER') {
        headerContent = 'Invalid user!'
      } else if (errStatus === 'EXPIRED_CODE') {
        headerContent = 'Invalid verification link!'
      } else {
        headerContent = 'Failed!'
      }
      content = 'Email Verification failed'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return (
            <img
              className={styles.email_verification_image}
              src={success ? Done : RepeatRequest}
              alt="popup icon"
            />
          )
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              <Button
                text={success ? 'Got it' : 'Cancel'}
                className={success ? styles.white : styles['cancel-btn']}
                onClick={() => {
                  if (window.innerWidth <= 768) {
                    window.location.href = `${baseUrl}desktop-warning`
                  }
                  close()
                }}
              ></Button>
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  const openPopup = (success: boolean, headerMsg?: string, contentMsg?: string) => {
    let content: string = ''
    let headerContent: string = ''
    if (success) {
      push('/')
      if (headerMsg === 'request') {
        headerContent = 'Great, you’ve submitted your learning request!'
        content = 'Now sit tight, one of the ontelo experts will be in touch with you soon'
      } else {
        headerContent = 'Something went wrong'
        content =
          'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
      }
    } else {
      headerContent = headerMsg ? headerMsg : 'Login unsuccessfull'
      content = contentMsg
        ? contentMsg
        : 'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              <Button text={'Cancel'} className={styles['cancel-btn']} onClick={close}></Button>
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  const logInUser = (obj: { readonly email: string; readonly password: string }) =>
    fetchRest(
      { url: '/logInUser ', method: 'POST', data: obj, isFullPageLoader: false },
      'loginForm'
    )

  const fetchUserDetails = (obj: {
    readonly accessToken: string
    readonly identityId: string
    readonly idToken: string
  }) =>
    fetchRest(
      { url: '/getUserDetails ', method: 'PUT', data: obj, isFullPageLoader: false },
      'userDetails'
    )

  const submitForm = (e: any) => {
    e.preventDefault()
    setData((prev: any) => {
      let blockPrev = { ...prev }
      Object.keys(blockPrev).forEach((each) => {
        blockPrev = { ...blockPrev, [each]: { ...blockPrev[each], isTouched: true } }
      })
      return blockPrev
    })
    if (data.email.isValid && data.password.isValid) {
      logInUser({
        email: data.email.value,
        password: data.password.value,
      })
        .then(async (res) => {
          if (res && res.data && res.data.tokens) {
            let date = new Date()
            date.setDate(date.getDate() + 1)
            localStorage.setItem('idToken', res.data.tokens.IdToken)
            localStorage.setItem('accessToken', res.data.tokens.AccessToken)
            localStorage.setItem('refreshToken', res.data.tokens.RefreshToken)
            localStorage.setItem('identityId', res.data.IdentityId)
            // -- from identity
            document.cookie = `accessToken = ${res.data.tokens.AccessToken};Domain=ontelo.io;expires=${date};path=/`
            const userDetails = await fetchUserDetails({
              accessToken: res.data.tokens.AccessToken,
              identityId: res.data.IdentityId,
              idToken: res.data.tokens.IdToken,
            })
            localStorage.setItem('welcomeWizard', userDetails.data.userData.welcomeWizard)
            if (userDetails && userDetails.data && userDetails.data.userData) {
              // --remove the existing credentials
              localStorage.removeItem('accessKeyId')
              localStorage.removeItem('secretAccessKey')
              localStorage.removeItem('sessionToken')
              localStorage.removeItem('sessionExpiration')
              // -- from identity
              localStorage.setItem(
                'accessKeyId',
                userDetails.data.identity.data.Credentials.AccessKeyId
              )
              localStorage.setItem(
                'secretAccessKey',
                userDetails.data.identity.data.Credentials.SecretKey
              )
              localStorage.setItem(
                'sessionToken',
                userDetails.data.identity.data.Credentials.SessionToken
              )
              localStorage.setItem(
                'sessionExpiration',
                userDetails.data.identity.data.Credentials.Expiration
              )
              // setUser(userDetails.data.userData)
              setUser({ ...userDetails.data.userData, isLoader: true })
              getNotificationCount()
              if (state && state.path) {
                push(state.path)
              } else if (redirectTo) {
                push(redirectTo.replaceAll("'", ''))
              } else if (userDetails.data.userData.welcomeWizard) {
                push('/learn?tab=requestLearning&welcomeWizard=true')
              } else {
                push('/learn?tab=requestLearning')
              }
            }

            console.log("Zoho request Id --->", zohoRequestId);
            
            if (courseId !== undefined) {
              fetchRest(
                {
                  url: '/userSubmitRequest',
                  method: 'POST',
                  data: {
                    description: description,
                    courseId: courseId,
                    zohoRequestId: zohoRequestId,
                  },
                },
                'submitRequest'
              )
                .then((_res) => {
                  openPopup(true, 'request')
                })
                .catch((err) => {
                  openPopup(true, 'failed-request', err.error.message)
                })
            }
          }
        })
        .catch((err) => {
          const error = err?.response?.data?.error?.message?.error?.code
          if (error === 'NotAuthorizedException') {
            openPopup(
              false,
              'Whoops',
              ' It looks like you’ve entered the wrong password and email combination'
            )
          } else if (error === 'UserNotFoundException') {
            openPopup(
              false,
              'This email address is not registered',
              'You may have registered with another email address, try again'
            )
          } else if (error === 'UserNotConfirmedException') {
            openPopup(
              false,
              'Whoops!',
              `it looks like you haven't verified your email address.
            Please check your inbox (and spam) and click on the link to verify your email.
            Still having problems, please email support admin@ontelo.io`
            )
          } else {
            openPopup(false)
          }
        })
    }
  }

  const handleOnChange = (e: any, key: string) => {
    setData((prev) => {
      return {
        ...prev,
        [key]: {
          ...e,
          isTouched: true,
        },
      }
    })
  }

  const baseRedirectionUrl = 'https://demo.divami.com/ontelo-static/'

  const baseUrl = process.env.REACT_APP_LANDING_REDIRECT_URL
    ? process.env.REACT_APP_LANDING_REDIRECT_URL
    : baseRedirectionUrl

  const [width, setWidth] = useState(() => {
    if (typeof window !== 'undefined') return window.innerWidth
    else return 1200
  })

  const updateDimensions = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateDimensions)
      return () => window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const userId = searchParams.get('userId')
    const code = searchParams.get('code')
    if (userId && code) {
      fetchRest({
        method: 'PUT',
        url: '/verifyUser',
        data: { userId, code },
        isFullPageLoader: true,
      })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          openEmailVerificationPopup(true)
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: any) => {
          const errInfo = err?.response?.data?.error?.message?.error
          if (errInfo) {
            if (errInfo.message.match(/Current status is CONFIRMED/)) {
              openEmailVerificationPopup(false, 'USER_CONFIRMED')
            } else if (errInfo.code === 'UserNotFoundException') {
              openEmailVerificationPopup(false, 'INVALID_USER')
            }
            //  else if(errInfo.code === 'ExpiredCodeException') {
            //   openEmailVerificationPopup(false, 'EXPIRED_CODE')
            // }
            else {
              openEmailVerificationPopup(false)
            }
          } else {
            openEmailVerificationPopup(false)
          }
        })
    } else {
      if (window.innerWidth <= 768) {
        window.location.href = `${baseUrl}desktop-warning`
      }
    }
  }, [window.innerWidth, width])

  return (
    <>
      <div className={styles.innerBox}>
        <div className={styles.heading}>{t('login.login')}</div>
        <div className={styles.subHeading}>{t('login.new-account')}</div>
        <div className={styles.link}>
          <Link to="/sign-up" className={styles.link}>
            {t('login.register-now')}
          </Link>
        </div>
        <NoEventsLoader
          isLoading={sectionLoading && (sectionLoading.loginForm || sectionLoading.userDetails)}
        >
          <form className={styles.loginForm}>
            <div className={styles.field}>
              <div className={styles.formHeading}>{t('userDetails.email')}</div>
              <InputWrapper
                valueObj={data.email}
                type="email"
                onChange={(e: any) => {
                  handleOnChange(e, 'email')
                }}
                schema={schemaEmail}
                validateOnBlur={true}
                validateOnChange={true}
              />
            </div>
            <div className={styles.field}>
              <div className={styles.formHeading}>{t('login.password')}</div>
              <InputWrapper
                valueObj={data.password}
                type={isIconClicked ? 'text' : 'password'}
                onChange={(e: any) => {
                  handleOnChange(e, 'password')
                }}
                inputIcon={eye}
                iconClick={() => setIsIconClicked(!isIconClicked)}
                schema={loginPasswordSchema}
                validateOnBlur={true}
                validateOnChange={true}
              />
            </div>
            <Button
              text="Login"
              className={styles.btn}
              onClick={(e: any) => {
                submitForm(e)
              }}
              ButtonImg={() => {
                return (
                  <>
                    {sectionLoading && (sectionLoading.loginForm || sectionLoading.userDetails) ? (
                      <Loader className={styles.form_loader} size={20} thickness={2} />
                    ) : (
                      <></>
                    )}
                  </>
                )
              }}
              type="submit"
            />
            <div className={styles.forgotPass}>
              <Link to="/forgot-password" className={styles.linkForgotPass}>
                {t('login.forgot-pass')}
              </Link>
            </div>
          </form>
        </NoEventsLoader>
      </div>
    </>
  )
}
