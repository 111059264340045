/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import aboutIcon from '../../assets/images/svg/about.svg'
import editIcon from '../../assets/images/svg/editIcon.svg'
import education from '../../assets/images/svg/education.svg'
import cost from '../../assets/images/svg/HourRate.svg'
import interests from '../../assets/images/svg/interests.svg'
import languages from '../../assets/images/svg/Languages.svg'
import plusIcon from '../../assets/images/svg/plusIcon.svg'
import Search from '../../assets/images/svg/search.svg'
import tick from '../../assets/images/svg/whiteTick.svg'
import { AutocompleteOption } from '../../common/autocomplete-option/AutocompleteOption'
import { Loader } from '../../common/Loader/Loader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { useFetch } from '../../common/https'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import { MultipleSelect } from '../../common/multiple-select/MultipleSelect'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import { costSchema, schemaAreaOfExpertise, schemaSkills } from '../../common/schema/Schema'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { charactersLeft, getServiceFee, getUserFee, getUserTokens } from '../../common/utils'
import { validateInput } from '../../common/ValidateFields'
import { Button } from '../../common/button/Button'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { EmptySection } from '../../common/empty-section/EmptySection'
import styles from './UserProfileInformation.module.scss'

interface Popups {
  readonly about: boolean
  readonly areaOfExpertise: boolean
  readonly hour: boolean
  readonly skills: boolean
}

interface CategoryType {
  categoryId: string
  description?: string
  imageUrl?: string
  name: string
}

interface SubcategoryType {
  subCategoryId: string
  description?: string
  imageUrl?: string
  name: string
}

export const AboutForm: React.FC<{
  readonly closePopup: () => void
  readonly isTeam?: boolean
  readonly teamId?: string
}> = ({ closePopup, isTeam, teamId }) => {
  const { fetchRest, sectionLoading } = useFetch({ aboutform: false })
  const { t } = useTranslation()
  const { userDetails, setUser } = useUserDetailsContext()
  const [aboutTitle, setaboutTitle] = useState<{
    value: string
    isValid: boolean
    isTouched: boolean
    errorMsg: string
  }>({
    value: '',
    isValid: false,
    isTouched: false,
    errorMsg: 'Please enter your Title',
  })
  const [aboutContent, setaboutContent] = useState<{
    value: string
    isValid: boolean
    isTouched: boolean
    errorMsg: string
  }>({
    value: '',
    isValid: true,
    isTouched: false,
    errorMsg: 'Please enter your Detailed info',
  })

  useEffect(() => {
    getInfo()
  }, [])

  const getInfo = () => {
    if (isTeam) {
      fetchRest(
        {
          url: `/team/${teamId}`,
          method: 'GET',

          isFullPageLoader: false,
        },
        'aboutForm'
      )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          if (res.data.data.teamDetails.teamTitle) {
            setaboutTitle({
              value: res.data.data.teamDetails.teamTitle,
              isValid: true,
              isTouched: false,
              errorMsg: 'Please enter your title',
            })
          }
          if (res.data.data.teamDetails.teamDescription) {
            setaboutContent({
              value: res.data.data.teamDetails.teamDescription,
              isValid: true,
              isTouched: false,
              errorMsg: 'Please enter your info',
            })
          }
        })
        .catch()
    }
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    if (aboutContent.isValid && aboutTitle.isValid) {
      const { accessToken } = getUserTokens()
      if (!isTeam) {
        fetchRest(
          {
            url: 'editProfile',
            method: 'PUT',
            data: {
              title: aboutTitle.value ? aboutTitle.value : undefined,
              info: aboutContent.value ? aboutContent.value : undefined,
              accessToken,
            },
            isFullPageLoader: false,
          },
          'aboutForm'
        )
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((res: any) => {
            closePopup()
            setUser({ ...userDetails, title: aboutTitle.value, info: aboutContent.value })
          })
          .catch()
      } else {
        fetchRest(
          {
            url: `/team/${teamId}/edit`,
            method: 'PUT',
            data: {
              teamTitle: aboutTitle.value ? aboutTitle.value : undefined,
              teamDescription: aboutContent.value ? aboutContent.value : undefined,
            },
            isFullPageLoader: false,
          },
          'aboutForm'
        )
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((res: any) => {
            closePopup()
            setUser({ ...userDetails, title: aboutTitle.value, info: aboutContent.value })
          })
          .catch()
      }
    }
  }

  useEffect(() => {
    if (!isTeam) {
      if (userDetails.title) {
        setaboutTitle({
          value: userDetails.title,
          isValid: true,
          isTouched: false,
          errorMsg: 'Please enter your title',
        })
      }
      if (userDetails.info) {
        setaboutContent({
          value: userDetails.info,
          isValid: true,
          isTouched: false,
          errorMsg: 'Please enter your info',
        })
      }
    }
  }, [userDetails])

  return (
    <>
      <NoEventsLoader isLoading={sectionLoading && sectionLoading.aboutForm}>
        <form onSubmit={onSubmit}>
          <div className={styles['list-card']}>
            <div className={styles.label}>
              <img src={aboutIcon} alt="About icon" className={styles.divImg} />
              <div>{t('userDetails.about')}</div>
            </div>
            <div className={styles.marginTop}>
              <div className={styles.fullField}>
                {isTeam ? (
                  <>
                    <div className={styles.heading2}>Team title</div>
                    <div className={styles.subHeading}>
                      Enter a single sentence description of your team
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.heading2}>{t('userDetails.your-title')}</div>
                    <div className={styles.subHeading}>{t('userDetails.title-desc')}</div>
                  </>
                )}
                <div>
                  <InputWrapper
                    type="text"
                    valueObj={aboutTitle}
                    placeholder="For example, Professional UX teacher..."
                    onChange={(e: any) => {
                      // handleChange(e, 'title')
                      setaboutTitle({
                        isTouched: true,
                        value: e.value,
                        isValid: true,
                        errorMsg: e.errorMsg,
                      })
                    }}
                    className={styles.text_field}
                  />
                </div>
              </div>
              <div className={styles.fullField}>
                {isTeam ? (
                  <>
                    <div className={styles.heading2}>Team description</div>
                    <div className={styles.subHeading}>Use this space to describe your team</div>
                  </>
                ) : (
                  <>
                    <div className={styles.heading2}>{t('userDetails.edit')}</div>
                    <div className={styles.subHeading}>{t('userDetails.general-info')}</div>
                  </>
                )}
                <textarea
                  className={styles.textareaStyles}
                  onChange={(e) => {
                    let value = e.target.value
                    setaboutContent((prev) => {
                      return {
                        ...prev,
                        isTouched: true,
                        isValid: !value || (value && value.length <= 5000) ? true : false,
                        errorMsg:
                          !value || (value && value.length <= 5000)
                            ? ''
                            : t('common.moreThan5000CharactersNotAllowed'),
                        value: value,
                      }
                    })
                  }}
                  value={aboutContent.value}
                ></textarea>
                <div className={styles.text_area_help_text}>
                  {!aboutContent.isValid ? (
                    <div className="error">{aboutContent.errorMsg}</div>
                  ) : (
                    <div></div>
                  )}
                  <div>
                    {charactersLeft({
                      currentLength:
                        aboutContent.value && aboutContent.value.length
                          ? aboutContent.value.length
                          : 0,
                    })}
                    {t('common.charactersLeft')}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.button}>
              <Button
                text="Done"
                type="submit"
                className={styles.buttonClass}
                ButtonImg={() => {
                  return (
                    <>
                      {sectionLoading && sectionLoading.aboutForm ? (
                        <Loader className="form_loader" size={20} thickness={2} />
                      ) : (
                        <>
                          <img src={tick} alt="tick icon" className={styles.divImg} />
                        </>
                      )}
                    </>
                  )
                }}
              />
            </div>
          </div>
        </form>
      </NoEventsLoader>
    </>
  )
}

export const ExpertiseForm: React.FC<{
  closePopup: () => void
}> = ({ closePopup }) => {
  const { fetchRest, sectionLoading } = useFetch({ expertiseForm: false })
  const { t } = useTranslation()
  const { userDetails, setUser } = useUserDetailsContext()
  const [category, setcategory] = useState<Array<CategoryType>>([])
  const [areaOfExpertise, setareaOfExpertise] = useState<{
    value: any
    isValid: boolean
    isTouched: boolean
    errorMsg: string
  }>({
    value: [],
    isValid: false,
    isTouched: false,
    errorMsg: 'Please select your area of expertise',
  })

  useEffect(() => {
    fetchRest({
      url: '/category',
      method: 'GET',
    }).then((res: any) => {
      if (res && res.data && res.data.data) {
        setcategory(
          res.data.data.map((each: any) => {
            return {
              categoryId: each.categoryId,
              name: each.name,
              description: each.description,
              imageUrl: each.imageUrl,
            }
          })
        )
      } else {
        setcategory([])
      }
    })

    if (userDetails.teacherSkill) {
      let data: any[] = []
      if (userDetails.teacherAreaOfExpertise && userDetails.teacherAreaOfExpertise.length) {
        userDetails.teacherAreaOfExpertise.forEach((each: any) => {
          data.push(each.category)
        })
      }
      setareaOfExpertise({
        value: data,
        isValid: true,
        isTouched: true,
        errorMsg: '',
      })
    }
  }, [])

  const closeIconFunc = (e: any, key: string) => {
    let propertyToAccess: string = ''
    if (key === 'areaOfExpertise') {
      propertyToAccess = 'categoryId'
    }
    setareaOfExpertise((prev: any) => {
      return {
        ...prev,
        value: areaOfExpertise.value.filter((each: any) => {
          return each[propertyToAccess] !== e[propertyToAccess]
        }),
      }
    })
  }

  const autoCompleteOnChange = (newValue: any, key: string) => {
    if (key === 'areaOfExpertise') {
      var valid = validateInput(schemaAreaOfExpertise, newValue)
      let data = [...areaOfExpertise.value, ...newValue]

      setareaOfExpertise((prev: any) => {
        return {
          ...prev,
          isValid: valid.isValid,
          errorMsg: valid.errorMsg,
          value: Object.values(
            data.reduce((r, o) => {
              r[o.categoryId] = o
              return r
            }, {})
          ),
        }
      })
    }
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    if (areaOfExpertise.isValid) {
      const { accessToken } = getUserTokens()
      let data: any[] = []
      areaOfExpertise.value.forEach((each: any) => {
        data.push(each.categoryId)
      })
      fetchRest(
        {
          url: 'editProfile',
          method: 'PUT',
          data: {
            areaOfExpertise: data,
            accessToken,
          },
          isFullPageLoader: false,
        },
        'expertiseForm'
      )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          closePopup()
          let expertise: any[] = []
          areaOfExpertise.value.forEach((each: any) => {
            expertise.push({ category: each })
          })
          setUser({
            ...userDetails,
            teacherAreaOfExpertise: expertise,
          })
        })
        .catch()
    }
  }

  return (
    <>
      <NoEventsLoader isLoading={sectionLoading && sectionLoading.expertiseForm}>
        <form onSubmit={onSubmit}>
          <div className={styles['list-card']}>
            <div className={styles.headingWrapperPopup}>
              <div className={styles.label}>
                <img src={interests} alt="interests" className={styles.divImg} />
                <div>{t('userDetails.expertise')}</div>
              </div>
            </div>
            <div className={styles.marginBtm}>
              <div className={styles.heading2}>Enter your Area of Expertise</div>
              <div className={styles.subHeading}>
                {t('userDetails.removeAreaOfExpertiseByDeletingTags')}
              </div>
              <div className={styles.autocompletePosition}>
                <div className="autocomplete-wrapper search">
                  <Autocomplete
                    popupIcon={<img src={Search} alt="search" />}
                    multiple
                    onChange={(event, newValue) => {
                      autoCompleteOnChange(newValue, 'areaOfExpertise')
                    }}
                    handleHomeEndKeys
                    options={category}
                    getOptionLabel={(option) => {
                      return option.name
                    }}
                    ListboxProps={{
                      style: {
                        maxHeight: '250px',
                        borderRadius: 0,
                      },
                    }}
                    disableCloseOnSelect={true}
                    renderOption={(option: any) => <AutocompleteOption option={option.name} />}
                    style={{ width: '100%', position: 'relative' }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" placeholder="Start Typing..." />
                    )}
                  />
                </div>
                {areaOfExpertise.isTouched && !areaOfExpertise.isValid ? (
                  <div className={styles.error}>{areaOfExpertise.errorMsg}</div>
                ) : (
                  ''
                )}
                {areaOfExpertise.value && areaOfExpertise.value.length ? (
                  <div className={styles.multiSelectWrapperSkills}>
                    {areaOfExpertise.value.map((each: any) => {
                      return (
                        <>
                          <MultipleSelect
                            textContent={each.name}
                            closeIconFunc={() => {
                              closeIconFunc(each, 'areaOfExpertise')
                            }}
                          />
                        </>
                      )
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className={styles.button}>
              <Button
                text="Done"
                className={styles.buttonClass}
                ButtonImg={() => {
                  return (
                    <>
                      {sectionLoading && sectionLoading.expertiseForm ? (
                        <Loader className="form_loader" size={20} thickness={2} />
                      ) : (
                        <>
                          <img src={tick} alt="tick icon" className={styles.divImg} />
                        </>
                      )}
                    </>
                  )
                }}
                type="submit"
              />
            </div>
          </div>
        </form>
      </NoEventsLoader>
    </>
  )
}

export const SkillsForm: React.FC<{
  closePopup: () => void
}> = ({ closePopup }) => {
  const { fetchRest, sectionLoading } = useFetch({ skillsForm: false })
  const { t } = useTranslation()
  const { userDetails, setUser } = useUserDetailsContext()
  const [subCategory, setSubCategory] = useState<Array<SubcategoryType>>([])
  const [skills, setskills] = useState<{
    value: any
    isValid: boolean
    isTouched: boolean
    errorMsg: string
  }>({
    value: [],
    isValid: false,
    isTouched: false,
    errorMsg: 'Please select your skills',
  })

  useEffect(() => {
    fetchRest({
      url: '/subCategory',
      method: 'GET',
    }).then((res: any) => {
      if (res && res.data && res.data.data) {
        setSubCategory(
          res.data.data.map((each: any) => {
            return {
              subCategoryId: each.subCategoryId,
              name: each.name,
              description: each.description,
              imageUrl: each.imageUrl,
            }
          })
        )
      } else {
        setSubCategory([])
      }
    })

    if (userDetails.teacherSkill) {
      let data: any[] = []
      if (userDetails.teacherSkill && userDetails.teacherSkill.length) {
        userDetails.teacherSkill.forEach((each: any) => {
          data.push(each.subCategory)
        })
      }
      setskills({
        value: data,
        isValid: true,
        isTouched: true,
        errorMsg: '',
      })
    }
  }, [])

  const closeIconFunc = (e: any, key: string) => {
    let propertyToAccess: string = ''
    if (key === 'skills') {
      propertyToAccess = 'subCategoryId'
    }
    setskills((prev: any) => {
      return {
        ...prev,
        value: skills.value.filter((each: any) => {
          return each[propertyToAccess] !== e[propertyToAccess]
        }),
      }
    })
  }

  const autoCompleteOnChange = (newValue: any, key: string) => {
    if (key === 'skills') {
      var valid = validateInput(schemaSkills, newValue)
      let data = [...skills.value, ...newValue]

      setskills((prev: any) => {
        return {
          ...prev,
          isValid: valid.isValid,
          errorMsg: valid.errorMsg,
          value: Object.values(
            data.reduce((r, o) => {
              r[o.subCategoryId] = o
              return r
            }, {})
          ),
        }
      })
    }
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    if (skills.isValid) {
      const { accessToken } = getUserTokens()
      let data: any[] = []
      skills.value.forEach((each: any) => {
        data.push(each.subCategoryId)
      })
      fetchRest(
        {
          url: 'editProfile',
          method: 'PUT',
          data: {
            skills: data,
            accessToken,
          },
          isFullPageLoader: false,
        },
        'skillsForm'
      )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          closePopup()
          let teacherSkill: any[] = []
          skills.value.forEach((each: any) => {
            teacherSkill.push({ subCategory: each })
          })
          setUser({
            ...userDetails,
            teacherSkill: teacherSkill,
          })
        })
        .catch()
    }
  }

  return (
    <>
      <NoEventsLoader isLoading={sectionLoading && sectionLoading.skillsForm}>
        <form onSubmit={onSubmit}>
          <div className={styles['list-card']}>
            <div className={styles.headingWrapperPopup}>
              <div className={styles.label}>
                <img src={interests} alt="interests" className={styles.divImg} />
                <div>{t('userDetails.skills')}</div>
              </div>
            </div>
            <div className={styles.marginBtm}>
              <div className={styles.heading2}>{t('userDetails.enter-skills')}</div>
              <div className={styles.subHeading}>{t('userDetails.removeSkillsByDeletingTags')}</div>
              <div className={styles.autocompletePosition}>
                <div className={styles.marginBtm}>
                  <div className="autocomplete-wrapper search">
                    <Autocomplete
                      popupIcon={<img src={Search} alt="search" />}
                      multiple
                      onChange={(event, newValue) => {
                        autoCompleteOnChange(newValue, 'skills')
                      }}
                      handleHomeEndKeys
                      options={subCategory}
                      getOptionLabel={(option) => {
                        return option.name
                      }}
                      disableCloseOnSelect={true}
                      ListboxProps={{
                        style: {
                          maxHeight: '250px',
                          borderRadius: 0,
                        },
                      }}
                      renderOption={(option: any) => <AutocompleteOption option={option.name} />}
                      style={{ maxWidth: '715px', width: '100%', position: 'relative' }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" placeholder="Start typing..." />
                      )}
                    />
                  </div>
                </div>
                {skills.isTouched && !skills.isValid ? (
                  <div className={styles.error}>{skills.errorMsg}</div>
                ) : (
                  ''
                )}
                {skills.value && skills.value.length ? (
                  <div className={styles.multiSelectWrapperSkills}>
                    {skills.value.map((each: any) => {
                      return (
                        <>
                          <MultipleSelect
                            textContent={each.name}
                            closeIconFunc={() => {
                              closeIconFunc(each, 'skills')
                            }}
                          />
                        </>
                      )
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className={styles.button}>
              <Button
                text="Done"
                className={styles.buttonClass}
                ButtonImg={() => {
                  return (
                    <>
                      {sectionLoading && sectionLoading.skillsForm ? (
                        <Loader className="form_loader" size={20} thickness={2} />
                      ) : (
                        <>
                          <img src={tick} alt="tick icon" className={styles.divImg} />
                        </>
                      )}
                    </>
                  )
                }}
                type="submit"
              />
            </div>
          </div>
        </form>
      </NoEventsLoader>
    </>
  )
}

export const HourForm: React.FC<{
  readonly closePopup: () => void
}> = ({ closePopup }) => {
  const { fetchRest, sectionLoading } = useFetch({ hourForm: false })
  const { t } = useTranslation()
  const { userDetails, setUser } = useUserDetailsContext()
  const [hourlyRate, setHourlyRate] = useState<{
    value: string
    isValid: boolean
    isTouched: boolean
    errorMsg: string
  }>({
    value: '0',
    isValid: false,
    isTouched: false,
    errorMsg: 'Please enter Hourly Rate',
  })

  useEffect(() => {
    if (userDetails.hourlyRate) {
      setHourlyRate({
        value: userDetails.hourlyRate,
        isValid: true,
        isTouched: false,
        errorMsg: '',
      })
    }
  }, [userDetails])

  const hourlyRateChange = (e: any) => {
    if (isNaN(Number(e.value)) || Number(e.value) > 9999) {
      setHourlyRate((prev) => ({
        ...prev,
        isTouched: true,
      }))
    } else {
      setHourlyRate({
        isTouched: true,
        value: e.value,
        isValid: e.isValid,
        errorMsg: e.errorMsg,
      })
    }
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    setHourlyRate((prev: any) => {
      return {
        ...prev,
        isTouched: true,
      }
    })
    if (hourlyRate.isValid) {
      const { accessToken } = getUserTokens()
      fetchRest(
        {
          url: 'editProfile',
          method: 'PUT',
          data: {
            hourlyRate: hourlyRate.value,
            accessToken,
          },
          isFullPageLoader: false,
        },
        'hourForm'
      )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          closePopup()
          setUser({ ...userDetails, hourlyRate: hourlyRate.value })
        })
        .catch()
    }
  }

  const onKeyDownCost = (e: any) => {
    if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
      e.preventDefault()
    }
  }

  return (
    <>
      <NoEventsLoader isLoading={sectionLoading && sectionLoading.hourForm}>
        <form onSubmit={onSubmit}>
          <div className={styles['list-cardCost']}>
            <div className={styles.headingWrapperPopupCost}>
              <div className={styles.label}>
                <img src={cost} alt="interests" className={styles.divImg} />
                <div>{t('userDetails.cost')}</div>
              </div>
            </div>
            <div className={styles.marginBtm}>
              <div className={styles.headingCost}>{t('userDetails.cost')}</div>
              <div className={styles.subHeadingRate}>
                {t('requestDetails.profileRate')}
                {hourlyRate.value}
                {t('requestDetails.perHour')}
              </div>
              <div className={styles.cost}>
                <div className={styles.wrapperLeft}>
                  <div className={styles.smallField}>
                    <InputWrapper
                      type="text"
                      onChange={() => {}}
                      disabled
                      valueObj={{ value: '$', isTouched: false, isValid: true, errorMsg: '' }}
                      className={styles.dolar}
                    />
                  </div>
                  <div className={styles.halfField}>
                    <InputWrapper
                      type="number"
                      onKeyDown={onKeyDownCost}
                      valueObj={hourlyRate}
                      onChange={hourlyRateChange}
                      schema={costSchema}
                      validateOnChange={true}
                      validateOnBlur={true}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.borderLine}>
                <div>
                  <div className={styles.headingCost}>{t('userDetails.service-fees')}</div>
                  <div className={styles.subHeadingCost}>{t('userDetails.service-charge')}</div>
                </div>
                <div className={styles.dolar}>
                  ${hourlyRate.value ? getServiceFee(Number(hourlyRate.value)) : 0}
                </div>
              </div>
              <div className={styles.borderLine}>
                <div>
                  <div className={styles.headingCost}>{t('userDetails.your-fees')}</div>
                  <div className={styles.subHeadingCost}>{t('userDetails.your-charge')}</div>
                </div>
                <div className={`${styles.dolar} ${styles.color}`}>
                  ${hourlyRate.value ? getUserFee(Number(hourlyRate.value)) : 0}
                </div>
              </div>
            </div>
            <div className={styles.buttonLeft}>
              <Button
                text="Done"
                className={styles.buttonClass}
                ButtonImg={() => {
                  return (
                    <>
                      {sectionLoading && sectionLoading.hourForm ? (
                        <Loader className="form_loader" size={20} thickness={2} />
                      ) : (
                        <>
                          <img src={tick} alt="tick icon" className={styles.divImg} />
                        </>
                      )}
                    </>
                  )
                }}
                type="submit"
              />
            </div>
          </div>
        </form>
      </NoEventsLoader>
    </>
  )
}

export const UserProfileInformation: React.FC<{
  isEducation?: boolean
  isChat?: boolean
  userData?: any
  isTeam?: boolean
}> = ({ isChat, isEducation = false, userData = undefined, isTeam = false }) => {
  const { userDetails } = useUserDetailsContext()
  const { t } = useTranslation()
  const [userInfo, setUserInfo] = useState<any>({})

  const [dialogs, setDialogs] = useState<Popups>({
    about: false,
    areaOfExpertise: false,
    hour: false,
    skills: false,
  })

  const openPopup = (popupName: keyof Popups) => {
    setDialogs((prev) => ({ ...prev, [popupName]: true }))
  }

  const closePopup = (popupName: keyof Popups) => {
    setDialogs((prev) => ({ ...prev, [popupName]: false }))
  }

  useEffect(() => {
    if (!isTeam) {
      if (!isChat) {
        setUserInfo(userDetails)
      }
    }
  }, [userDetails, isChat])

  useEffect(() => {
    if (!isTeam) {
      if (isChat) {
        setUserInfo(userData)
      }
    }
  }, [userData, isChat])

  return (
    <>
      <div className={styles.contentWrapper}>
        <div className={styles.left}>
          <div className={styles['list-card']}>
            <div className={styles.headingWrapper}>
              <div className={styles.label}>
                <img src={aboutIcon} alt="About icon" className={styles.divImg} />
                <div>{t('userDetails.about')}</div>
              </div>
              {!isChat || isTeam ? (
                <div className={styles.pointer}>
                  <img
                    src={editIcon}
                    alt="edit"
                    onClick={() => {
                      openPopup('about')
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            {isTeam ? (
              <div className={styles.heading}>{userInfo.teamTitle}</div>
            ) : (
              <div className={styles.heading}>{userInfo.title}</div>
            )}
            <div className={styles.aboutContent}>
              <div className={styles.margin}>
                <>
                  {isTeam ? (
                    <>
                      {userInfo.teamDescription ? (
                        userInfo.teamDescription
                      ) : userInfo.teamTitle ? (
                        ''
                      ) : (
                        <EmptySection subText={'Tell people a little about your team'} />
                      )}
                    </>
                  ) : (
                    <>
                      {userInfo.info ? (
                        userInfo.info
                      ) : userInfo.title ? (
                        ''
                      ) : (
                        <EmptySection
                          subText={
                            'Tell people a little about yourself and give perspective students confidence that you are the right mentor for them'
                          }
                        />
                      )}
                    </>
                  )}
                </>
              </div>
            </div>
          </div>

          {!isTeam ? (
            !isChat ? (
              <div className={`${styles['list-card']} ${styles.noBottomPadding}`}>
                <div className={styles.headingWrapper}>
                  <div className={styles.labelSkills}>
                    <img src={interests} alt="interests" className={styles.divImg} />
                    <div>{t('userDetails.expertise')}</div>
                  </div>
                  {!isChat ? (
                    <div className={styles.pointer}>
                      <img
                        src={editIcon}
                        alt="edit"
                        onClick={() => {
                          openPopup('areaOfExpertise')
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div>
                  {userInfo.teacherAreaOfExpertise && userInfo.teacherAreaOfExpertise.length ? (
                    <div className={styles.multiSelectWrapper}>
                      {userInfo.teacherAreaOfExpertise.map((each: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            {each.category.name ? (
                              <MultipleSelect textContent={each.category.name} />
                            ) : (
                              ''
                            )}
                          </React.Fragment>
                        )
                      })}
                    </div>
                  ) : (
                    <>
                      <EmptySection subText="Complete this section to receive learning requests that match your experience" />
                    </>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {!isTeam ? (
            <div className={`${styles['list-card']} ${styles.noBottomPadding}`}>
              <div className={styles.headingWrapper}>
                <div className={styles.labelSkills}>
                  <img src={interests} alt="interests" className={styles.divImg} />
                  <div>{t('userDetails.skills')}</div>
                </div>
                {!isChat ? (
                  <div className={styles.pointer}>
                    <img
                      src={editIcon}
                      alt="edit"
                      onClick={() => {
                        openPopup('skills')
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {userInfo.teacherSkill && userInfo.teacherSkill.length ? (
                <div className={styles.multiSelectWrapper}>
                  {userInfo.teacherSkill.map((each: any) => {
                    return (
                      <>
                        {each.subCategory.name ? (
                          <MultipleSelect textContent={each.subCategory.name} />
                        ) : (
                          ''
                        )}
                      </>
                    )
                  })}
                </div>
              ) : (
                <>
                  <EmptySection subText="Complete this section to receive learning requests that match your skills" />
                </>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
        {!isTeam ? (
          <div className={styles.right}>
            <div className={styles.hourRate}>
              <div className={styles.headingWrapper}>
                <div className={styles.label}>
                  <img src={cost} alt="interests" className={styles.divImg} />
                  <div>{t('userDetails.cost')}</div>
                </div>
                {!isChat ? (
                  <div className={styles.pointer}>
                    <img
                      src={editIcon}
                      alt="edit"
                      onClick={() => {
                        openPopup('hour')
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className={styles.costPerHour}>${userInfo.hourlyRate}/hr</div>
              <div className={styles.costContent}>{t('financialSettings.price')}</div>
            </div>
            <div className={styles.LanguagesKnown}>
              <div className={styles.LanguageHeadingWrapper}>
                <div className={styles.label}>
                  <img src={languages} alt="interests" className={styles.divImg} />
                  <div>{t('financialSettings.languages')}</div>
                </div>
                {/* {!isChat ? (
                  <div className={styles.languagePointer}>
                    <img src={plusIcon} alt="edit -" className={styles.pointerPlus} />
                  </div>
                ) : (
                  <></>
                )} */}
              </div>

              <div className={styles.subHeadingWrapper}>
                <div className={styles.labelInner}>{t('financialSettings.english')}</div>
                <div className={styles.inner}>{t('financialSettings.fluent')}</div>

                {/* {!isChat ? <img src={editIcon} alt="edit" /> : <></>} */}
              </div>
              {/* <div className={styles.subHeadingWrapper}>
                <div className={styles.labelInner}>Italian</div>
                <div className={styles.inner}>{t('financialSettings.basic')}</div>
                {!isChat ? <img src={editIcon} alt="edit" /> : <></>}
              </div> */}
            </div>
            {isEducation ? (
              <>
                <div className={styles.hourRate}>
                  <div className={styles.headingWrapper}>
                    <div className={styles.label}>
                      <img src={education} alt="interests" className={styles.divImg} />
                      <div>{t('financialSettings.education')}</div>
                    </div>
                    {!isChat ? (
                      <div>
                        <img src={plusIcon} alt="edit" className={styles.pointerPlus} />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className={styles.educationWrapper}>
                    <div className={styles.innerEducation}>
                      <div></div>
                    </div>
                    {!isChat ? (
                      <div>
                        <img src={editIcon} alt="edit" />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className={styles.aboutContentEducation}></div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      <DialogWrapper
        onClose={() => {
          closePopup('areaOfExpertise')
        }}
        open={dialogs.areaOfExpertise}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <ExpertiseForm
          closePopup={() => {
            closePopup('areaOfExpertise')
          }}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          closePopup('skills')
        }}
        open={dialogs.skills}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <SkillsForm
          closePopup={() => {
            closePopup('skills')
          }}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          closePopup('about')
        }}
        open={dialogs.about}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <AboutForm
          closePopup={() => {
            closePopup('about')
          }}
          isTeam={isTeam}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          closePopup('hour')
        }}
        open={dialogs.hour}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <HourForm
          closePopup={() => {
            closePopup('hour')
          }}
        />
      </DialogWrapper>
    </>
  )
}
