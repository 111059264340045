/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useUserDetailsContext } from '../use-user-details-context'
import { useUserUnreadInfoContext } from '../user-unread-info-provider/UserUnreadInfoProvider'

export const UserStatusContext = createContext({
  userStatus: { status: 'OFFLINE' },
  connectedUsers: [],
  connectedUsersStatus: {},
  currentlyConnectedUser: undefined,
  userMessageAction: false,
  messengerEnabled: false,
  changeUserStatus: (value: any) => {
    return 0 as number
  },
  resetUserStatus: () => {
    return 0 as number
  },
  fetchUserStatus: () => {
    return 0 as number
  },
  updateConnectedUsers: (userIds: any) => {
    return 0 as number
  },
  updateCurrentlyConnectedUser: (userId: string | null) => {
    return 0 as number
  },
  updateUserMessageAction: (state: boolean) => {
    return 0 as number
  },
})

export const UserStatusProvider: React.FC<{}> = ({ children }) => {
  // const URL = 'ws://localhost:8000/ws/'; // -- test url
  const localUrl = 'wss://demo.divami.com:8000/ws'
  const URL = process.env.REACT_APP_WEBSOCKET_URL ? process.env.REACT_APP_WEBSOCKET_URL :  localUrl
  // const URL = 'wss://jump.ontelo.io:8000/ws'
  // const URL = 'wss://2a15xq9c0k.execute-api.us-east-1.amazonaws.com/dev'
  const socket = useRef<WebSocket | null>(null)
  const [isConnected, setIsConnected] = useState(false)

  const { userDetails } = useUserDetailsContext()
  const { unreadInfo, setUnreadInfoVal, removeUnreadInfoVal } = useUserUnreadInfoContext()

  const [userStatus, setUserStatus] = useState<any>({ status: 'OFFLINE' })
  const [connectedUsers, setConnectedUsers] = useState<any>([])
  const [connectedUsersStatus, setConnectedUsersStatus] = useState<any>({})
  const [currentlyConnectedUser, setCurrentlyConnectedUser] = useState<any>(undefined)
  const [userMessageAction, setUserMessageAction] = useState(false)
  const [messengerEnabled, setMessengerEnabled] = useState(false)

  const fetchUserStatus = () => {
    return userStatus
  }

  const changeUserStatus = (value: any) => {
    setUserStatus({ status: value })
    return 0
  }

  const resetUserStatus = () => {
    setUserStatus({ status: 'OFFLINE' })
    return 0
  }

  const updateConnectedUsers = (userIds: any) => {
    setConnectedUsers(userIds)
    return 0
  }

  const updateCurrentlyConnectedUser = (userId: string | null) => {
    setCurrentlyConnectedUser(userId)
    return 0
  }

  const updateUserMessageAction = (state: boolean) => {
    setUserMessageAction(state)
    return 0
  }

  const userLeftChannel = () => {
    setMessengerEnabled(true)
  }

  const onSocketOpen = useCallback(() => {
    setIsConnected(true)
    socket.current?.send(
      JSON.stringify({
        action: 'connect',
      })
    )
  }, [])

  const onSocketClose = useCallback(() => {
    setIsConnected(false)
  }, [])

  const onSocketMessage = useCallback((dataStr) => {
    const obj = JSON.parse(dataStr)
    const data = JSON.parse(obj.data)
    if (data && typeof data === 'object' && data.hasOwnProperty('action')) {
      switch (data.action) {
        case 'connection_id':
          console.log(data)
          break
        case 'status_map':
          setConnectedUsersStatus(data.data.connectedUsers)
          break
        case 'user_left_channel':
          userLeftChannel()
          break
        case 'new_unread':
          setUnreadInfoVal(data.userId)
          break
        case 'read_message':
          removeUnreadInfoVal(data.userId)
          break
        default:
          break
      }
    }
  }, [])

  const registerUser = useCallback((userId, activityStatus) => {
    const func = () => {
      socket.current?.send(
        JSON.stringify({
          action: 'registerStatus',
          userId,
          activityStatus,
        })
      )
    }
    if (socket.current?.readyState === WebSocket.CONNECTING) {
      setTimeout(() => {
        func()
      }, 1000)
    } else {
      func()
    }
  }, [])

  const getStatus = useCallback(() => {
    socket.current?.send(
      JSON.stringify({
        action: 'getStatus',
      })
    )
  }, [])

  const registerConnectedUsers = useCallback((userIds) => {
    socket.current?.send(
      JSON.stringify({
        action: 'registerConnectedUsers',
        userIds,
      })
    )
  }, [])

  const connectedUser = (connectedWithUserId: string) => {
    socket.current?.send(
      JSON.stringify({
        action: 'connectedChannel',
        connectedWithUserId,
      })
    )
  }

  const startedConversation = useCallback(() => {
    socket.current?.send(
      JSON.stringify({
        action: 'startedConversation',
      })
    )
  }, [])

  const onConnect = useCallback(() => {
    if (socket.current?.readyState !== WebSocket.OPEN) {
      socket.current = new WebSocket(URL)
      socket.current.addEventListener('open', onSocketOpen)
      socket.current.addEventListener('close', onSocketClose)
      socket.current.addEventListener('message', (event) => {
        onSocketMessage(event.data)
      })
    }
  }, [])

  const onDisconnect = useCallback(() => {
    if (isConnected) {
      socket.current?.close()
      setIsConnected(false)
    }
  }, [isConnected])

  useEffect(() => {
    if (isConnected && connectedUsers && connectedUsers.length) {
      registerConnectedUsers(connectedUsers)
    }
  }, [connectedUsers, isConnected])

  useEffect(() => {
    if (userDetails.isLogged && userDetails.userId) {
      onConnect()
    }
    return () => {
      socket.current?.close()
      setIsConnected(false)
    }
  }, [userDetails])

  useEffect(() => {
    if (isConnected && userDetails.isLogged && userDetails.userId) {
      changeUserStatus('ONLINE')
      registerUser(userDetails.userId, 'ONLINE')
    }
  }, [isConnected, userDetails])

  useEffect(() => {
    if (!userDetails.isLogged && isConnected) {
      socket.current?.close()
      setIsConnected(false)
    }
  }, [userDetails])

  useEffect(() => {
    // -- connection got change
    if (currentlyConnectedUser !== undefined) {
      connectedUser(currentlyConnectedUser)
      setMessengerEnabled(true)
    }
  }, [currentlyConnectedUser])

  useEffect(() => {
    if (messengerEnabled && userMessageAction) {
      startedConversation()
      setUserMessageAction(false)
      setMessengerEnabled(false)
    }
  }, [userMessageAction, messengerEnabled])

  const setDataFunction = {
    userStatus,
    connectedUsers,
    userMessageAction,
    connectedUsersStatus,
    currentlyConnectedUser,
    messengerEnabled,
    resetUserStatus,
    fetchUserStatus,
    changeUserStatus,
    updateConnectedUsers,
    updateCurrentlyConnectedUser,
    updateUserMessageAction,
  }

  return <UserStatusContext.Provider value={setDataFunction}>{children}</UserStatusContext.Provider>
}

export const useUserStatusContext = () => {
  const context = useContext(UserStatusContext)

  if (!context) {
    throw new Error('useUserStatusContext must be implemented after UserDetailsProvider')
  }

  return context
}
