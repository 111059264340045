/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-plusplus */
/* eslint-disable no-console */

import { appConfig } from '../Config'
// eslint-disable-next-line no-unused-vars
const Chime = require('aws-sdk/clients/chime')

export const createMemberArn = (userId: any) => `${appConfig.appInstanceArn}/user/${userId}`

const appInstanceUserArnHeader = 'x-amz-chime-bearer'

let chime: any = null

// Setup Chime Client lazily
async function chimeClient() {
  if (chime == null) {
    chime = new Chime()
  }
  return chime
}

async function getMessagingSessionEndpoint() {
  //console.log('APICALL::getMessagingSessionEndpoint()');
  const request = (await chimeClient()).getMessagingSessionEndpoint()
  const response = await request.promise()
  return response
}
/**
 * Function to send channel message
 * @param {channelArn} string Channel Arn
 * @param {messageContent} string Message content
 * @param {member} string Chime channel member
 * @param {options{}} object Additional attributes for the request object.
 * @returns {object} sendMessage object;
 */
async function sendChannelMessage(
  channelArn: any,
  messageContent: any,
  persistence: any,
  member: any,
  options: any = null
) {
  //console.log('APICALL::sendChannelMessage()');
  const params: any = {
    ChannelArn: channelArn,
    Content: messageContent,
    Persistence: persistence, // Allowed types are PERSISTENT and NON_PERSISTENT
    Type: 'STANDARD', // Allowed types are STANDARD and CONTROL
  }
  if (options && options.Metadata) {
    params.Metadata = options.Metadata
  }

  const request = (await chimeClient()).sendChannelMessage(params)
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(member.userId)
  })
  const response = await request.promise()
  const sentMessage = {
    response: response,
    CreatedTimestamp: new Date(),
    Sender: { Arn: createMemberArn(member.userId), Name: member.username },
  }
  return sentMessage
}

async function listChannelMessages(channelArn: any, userId: any, nextToken = null) {
  //console.log('APICALL::listChannelMessages()');
  const params = {
    ChannelArn: channelArn,
    NextToken: nextToken,
  }

  const request = (await chimeClient()).listChannelMessages(params)
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(userId)
  })
  const response = await request.promise()
  const messageList = response.ChannelMessages
  messageList.sort(function (a: any, b: any) {
    // eslint-disable-next-line no-nested-ternary
    return a.CreatedTimestamp < b.CreatedTimestamp
      ? -1
      : a.CreatedTimestamp > b.CreatedTimestamp
      ? 1
      : 0
  })

  const messages = []
  for (let i = 0; i < messageList.length; i++) {
    const message = messageList[i]
    messages.push(message)
  }
  return { Messages: messages, NextToken: response.NextToken }
}

async function listAppInstanceUsers(appInstanceArn: any, userId: any, nextToken = null) {
  //console.log('APICALL::listAppInstanceUsers()');
  const params = {
    AppInstanceArn: appInstanceArn,
    NextToken: nextToken,
  }

  const request = (await chimeClient()).listAppInstanceUsers(params)
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(userId)
  })
  const response = await request.promise()
  return response.AppInstanceUsers
}

async function createChannelMembership(channelArn: any, memberArn: any, userId: any) {
  //console.log('APICALL::createChannelMembership()');

  const params = {
    ChannelArn: channelArn,
    MemberArn: memberArn,
    Type: 'DEFAULT', // OPTIONS ARE: DEFAULT and HIDDEN
  }

  const request = (await chimeClient()).createChannelMembership(params)
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(userId)
  })
  const response = await request.promise()
  return response.Member
}

async function batchCreateChannelMembership(channelArn: any, memberArn: any[], userId: any) {
  //console.log('APICALL::createChannelMembership()');

  const params = {
    ChannelArn: channelArn,
    MemberArn: memberArn,
    Type: 'DEFAULT', // OPTIONS ARE: DEFAULT and HIDDEN
  }

  const request = (await chimeClient()).batchCreateChannelMembership(params)
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(userId)
  })
  const response = await request.promise()
  return response.Member
}

async function deleteChannelMembership(channelArn: any, memberArn: any, userId: any) {
  //console.log('APICALL::deleteChannelMembership()');

  const params = {
    ChannelArn: channelArn,
    MemberArn: memberArn,
  }

  const request = (await chimeClient()).deleteChannelMembership(params)
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(userId)
  })
  const response = await request.promise()
  return response
}

async function createChannelBan(channelArn: any, memberArn: any, userId: any) {
  //console.log('APICALL::deleteChannelMembership()');

  const params = {
    ChannelArn: channelArn,
    MemberArn: memberArn,
  }

  const request = (await chimeClient()).createChannelBan(params)
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(userId)
  })
  const response = await request.promise()
  return response
}

async function deleteChannelBan(channelArn: any, memberArn: any, userId: any) {
  //console.log('deleteChannelBan called');

  const params = {
    ChannelArn: channelArn,
    MemberArn: memberArn,
  }

  const request = (await chimeClient()).deleteChannelBan(params)
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(userId)
  })
  const response = await request.promise()
  return response
}

async function listChannelBans(channelArn: any, maxResults: any, nextToken: any, userId: any) {
  //console.log('listChannelBans called');

  const params = {
    ChannelArn: channelArn,
    MaxResults: maxResults,
    NextToken: nextToken,
  }

  const request = (await chimeClient()).listChannelBans(params)
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(userId)
  })
  const response = await request.promise()
  //console.log('listChannelBans response', response);
  return response
}

async function listChannelMemberships(channelArn: any, userId: any) {
  //console.log('listChannelMemberships called');
  const params = {
    ChannelArn: channelArn,
  }

  const request = (await chimeClient()).listChannelMemberships(params)
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(userId)
  })
  const response = await request.promise()
  // //console.log(response);
  return response.ChannelMemberships
}

async function createChannel(
  appInstanceArn: any,
  metadata: any,
  name: any,
  mode: any,
  privacy: any,
  userId: any
) {
  //console.log('createChannel called');
  const params = {
    AppInstanceArn: appInstanceArn,
    Metadata: metadata,
    Name: name,
    Mode: mode,
    Privacy: privacy,
  }

  const request = (await chimeClient()).createChannel(params)
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(userId)
  })
  const response = await request.promise()
  return response.ChannelArn
}

async function createChannelModerator(channelArn: any, channelModeratorArn: any, userId: any) {
  //console.log('createChannelModerator called');
  const params = {
    ChannelArn: channelArn,
    ChannelModeratorArn: createMemberArn(channelModeratorArn),
  }

  const request = (await chimeClient()).createChannelModerator(params)
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(userId)
  })
  const response = await request.promise()
  return response.ChannelArn
}

async function createRoom() {
  //console.log("Create room called");
  const params = {
    Name: 'New Room 1',
  }
  const request = (await chimeClient()).createRoom(params)
  const response = await request.promise()
  console.log(response)
}

async function describeChannel(channelArn: any, userId: any) {
  //console.log('describeChannel called');

  const params = {
    ChannelArn: channelArn,
  }

  const request = (await chimeClient()).describeChannel(params)
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(userId)
  })
  const response = await request.promise()
  return response.Channel
}

async function updateChannel(channelArn: any, name: any, mode: any, metadata: any, userId: any) {
  const params = {
    ChannelArn: channelArn,
    Name: name,
    Mode: mode,
    Metadata: metadata,
  }

  const request = (await chimeClient()).updateChannel(params)
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(userId)
  })
  const response = await request.promise()
  //console.log('response', response);
  return response
}

async function listChannelMembershipsForAppInstanceUser(userId: any) {
  //console.log('listChannelMembershipsForAppInstanceUser called');

  const request = (await chimeClient()).listChannelMembershipsForAppInstanceUser()
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(userId)
  })
  const response = await request.promise()
  const channels = response.ChannelMemberships
  return channels
}

async function listChannels(appInstanceArn: any, userId: any, maxResults?: any, nextToken?: any) {
  //console.log('listChannels called');
  const params: {
    AppInstanceArn: any
    MaxResults?: any
    NextToken?: any
  } = {
    AppInstanceArn: appInstanceArn,
  }
  if (Boolean(maxResults)) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    params['MaxResults'] = maxResults
  }
  if (Boolean(nextToken)) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    params['NextToken'] = nextToken
  }

  const request = (await chimeClient()).listChannels(params)
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(userId)
  })
  const response = await request.promise()
  const channels = response.Channels
  return channels
}

async function listChannelsForAppInstanceUser(userId: any) {
  //console.log('listChannelsForAppInstanceUser called');

  const request = (await chimeClient()).listChannelsForAppInstanceUser()
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(userId)
  })
  const response = await request.promise()
  const channels = response.Channels
  //console.log('channels', channels);
  return channels
}

async function deleteChannel(channelArn: any, userId: any) {
  //console.log('deleteChannel called');

  const params = {
    ChannelArn: channelArn,
  }

  const request = (await chimeClient()).deleteChannel(params)
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(userId)
  })
  await request.promise()
}

async function listChannelModerators(channelArn: any, userId: any) {
  //console.log('listChannelModerators called');
  const params = {
    ChannelArn: channelArn,
  }

  const request = (await chimeClient()).listChannelModerators(params)
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(userId)
  })
  const response = await request.promise()
  return response ? response.ChannelModerators : null
}

async function updateChannelMessage(
  channelArn: any,
  messageId: any,
  content: any,
  metadata: any,
  userId: any
) {
  //console.log('updateChannelMessage called');
  const params = {
    ChannelArn: channelArn,
    MessageId: messageId,
    Content: content,
    Metadata: metadata,
  }

  const request = (await chimeClient()).updateChannelMessage(params)
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(userId)
  })

  const response = await request.promise()
  return response
}

async function redactChannelMessage(channelArn: any, messageId: any, userId: any) {
  //console.log('redactChannelMessage called');
  const params = {
    ChannelArn: channelArn,
    MessageId: messageId,
  }

  const request = (await chimeClient()).redactChannelMessage(params)
  request.on('build', function () {
    request.httpRequest.headers[appInstanceUserArnHeader] = createMemberArn(userId)
  })

  const response = await request.promise()
  //console.log('response', response);
  return response
}

async function createMeeting(name: any, userId: any, channelArn: any) {
  const response = await fetch(
    `${appConfig.apiGatewayInvokeUrl}create?name=${encodeURIComponent(
      name
    )}&userId=${encodeURIComponent(userId)}&channel=${encodeURIComponent(channelArn)}`,
    {
      method: 'POST',
    }
  )
  const data = await response.json()

  if (data.error) {
    throw new Error(`Server error: ${data.error}`)
  }

  return data
}

async function createAttendee(name: any, userId: any, channelArn: any, meeting: any) {
  const response = await fetch(
    `${appConfig.apiGatewayInvokeUrl}join?name=${encodeURIComponent(
      name
    )}&userId=${encodeURIComponent(userId)}&channel=${encodeURIComponent(
      channelArn
    )}&meeting=${encodeURIComponent(meeting)}`,
    {
      method: 'POST',
    }
  )
  const data = await response.json()

  if (data.error) {
    throw new Error(`Server error: ${data.error}`)
  }

  return data
}

function createGetAttendeeCallback() {
  return async (chimeAttendeeId: any, externalUserId: any) => {
    return {
      name: externalUserId,
    }
  }
}

async function endMeeting(meetingId: any) {
  const res = await fetch(
    `${appConfig.apiGatewayInvokeUrl}end?meetingId=${encodeURIComponent(meetingId)}`,
    {
      method: 'POST',
    }
  )

  if (!res.ok) {
    throw new Error('Server error ending meeting')
  }
}

export {
  sendChannelMessage,
  listChannelMessages,
  createChannelMembership,
  listChannelMemberships,
  deleteChannelMembership,
  createChannelBan,
  deleteChannelBan,
  listChannelBans,
  createChannel,
  describeChannel,
  updateChannel,
  listChannels,
  listChannelsForAppInstanceUser,
  deleteChannel,
  listChannelModerators,
  updateChannelMessage,
  redactChannelMessage,
  getMessagingSessionEndpoint,
  listChannelMembershipsForAppInstanceUser,
  listAppInstanceUsers,
  createMeeting,
  createAttendee,
  createGetAttendeeCallback,
  endMeeting,
  createRoom,
  batchCreateChannelMembership,
  createChannelModerator,
}
