import { Accordion, AccordionDetails, debounce } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { AccordionWrapper } from '../../common/accordion-wrapper/AccordionWrapper'
import { useFetch } from '../../common/https'
import { Loader } from '../../common/Loader/Loader'
import { LoadingWrapper } from '../../common/loading-wrapper/LoadingWrapper'
import { Search } from '../../common/search/Search'
import { Footer } from '../../components/footer/Footer'
import { Header } from '../../components/header/Header'
import { SummaryWrapper } from '../../components/summary-wrapper/SummaryWrapper'
import { TabItem } from '../../components/tab-item/TabItem'
import { TabsWrapper } from '../../components/tabs-wrapper/TabsWrapper'
import styles from './HelpCenter.module.scss'

export const HelpCenter: React.FC = () => {
  const { push } = useHistory()
  const { search } = useLocation()

  const [defaultState, setDefaultState] = useState<number>(-1)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [accordionData, setAccordionData] = useState<ReadonlyArray<any>>([])
  const { fetchRest, sectionLoading } = useFetch({ helpCenter: true })
  const [value, setValue] = useState<string>('')

  const fetchData = (searchValue?: string) => {
    let url = ''
    if (defaultState === 0) {
      url = '/query/1'
    } else if (defaultState === 1) {
      url = '/query/2'
    } else if (defaultState === 2) {
      url = '/query/3'
    }
    fetchRest(
      {
        url,
        method: 'GET',
        queryParams: searchValue ? { search: searchValue } : undefined,
      },
      'helpCenter'
    ).then((res) => {
      if (res && res.data && res.data.data) {
        setAccordionData(res.data.data)
      } else {
        setAccordionData([])
      }
    })
  }

  const debouncedSave = useCallback(
    debounce((nextValue: string) => {
      fetchData(nextValue)
    }, 1000),
    [defaultState]
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onchangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: nextValue } = e.target
    setValue(nextValue)
    debouncedSave(nextValue)
  }

  useEffect(() => {
    if (defaultState !== -1) {
      fetchData()
    }
  }, [defaultState])

  const onTabChange = (activeTab: number) => {
    setValue('')
    setDefaultState(activeTab)
    switch (activeTab) {
      case 1:
        push('/help-center?tab=teachers')
        break
      case 2:
        push('/help-center?tab=teams')
        break
      default:
        push('/help-center?tab=learners')
    }
  }
  const tabHeaders: ReadonlyArray<{
    key: string
    TabItemWrapper: React.FC<{ isActive: boolean }>
  }> = [
    {
      key: 'For learners',
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Learners" />
      },
    },
    {
      key: 'For teachers',
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Teachers" />
      },
    },
    {
      key: 'Teams',
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Teams" />
      },
    },
  ]

  useEffect(() => {
    let searchParams = new URLSearchParams(search)
    let defaultTab: number
    let searchTab = searchParams.get('tab')
    if (searchTab === 'teachers') {
      defaultTab = 1
    } else if (searchTab === 'teams') {
      defaultTab = 2
    } else {
      defaultTab = 0
    }
    setDefaultState(defaultTab)
  }, [])

  return (
    <>
      <Header inHelpCenter={true} />
      <div className={`${styles.help_center} accordion-wrapper`}>
        <div className={styles.tab_container}>
          <TabsWrapper
            tabHeaders={tabHeaders}
            activeTabValue={defaultState}
            onTabChange={onTabChange}
          />
        </div>
        <div className={styles.wrapper}>
          <Search
            type="text"
            title="Help center"
            placeholder="Search requests..."
            onChange={onchangeText}
            value={value}
            autoFocus
            autoComplete="off"
          />
          <div className={styles.accordionSection}>
            <LoadingWrapper
              isLoading={sectionLoading && sectionLoading.helpCenter}
              LoaderComponent={() => <Loader />}
            >
              <AccordionWrapper expandedPanel={[]}>
                {({ expanded, onChange }) => (
                  <>
                    {accordionData.map((item) => {
                      return (
                        <Accordion
                          key={item.queryId}
                          className={styles.accordion}
                          onChange={(_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
                            onChange({
                              panel: item.queryId,
                              isExpanded,
                            })
                          }}
                          expanded={expanded.includes(item.queryId) ? true : false}
                        >
                          <SummaryWrapper
                            expanded={expanded.includes(item.queryId) ? true : false}
                            Summary={() => {
                              return (
                                <div className={styles.accordionHeaderSection}>
                                  <span>{item.query}</span>
                                </div>
                              )
                            }}
                          />
                          <AccordionDetails className={styles.accordionDetails}>
                            <div className={styles.para}>{item.answer}</div>
                          </AccordionDetails>
                        </Accordion>
                      )
                    })}
                  </>
                )}
              </AccordionWrapper>
            </LoadingWrapper>
          </div>
        </div>
        <div className={styles.footer_wrapper} data-testid="footer-section">
          <Footer />
        </div>
      </div>
    </>
  )
}
