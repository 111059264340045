/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Button } from '../../common/button/Button'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { CreateTeam } from '../create-team-form/CreateTeam'
import styles from './MyTeams.module.scss'
import JoinTeam from '../../assets/images/svg/joinTeam.svg'
import plus from '../../assets/images/svg/white-plus.svg'
import teamIcon from '../../assets/images/svg/TeamIconColor.svg'
import { TeamDetails } from '../team-details/TeamDetail'
import { useTranslation } from 'react-i18next'
import { useFetch } from '../../common/https'
import { Loader } from '../../common/Loader/Loader'
import Pagination from '@material-ui/lab/Pagination'

export const MyTeams: React.FC<{ readonly callback: any; readonly joinRequest?: () => void }> = ({
  callback,
  joinRequest = () => {},
}) => {
  const [createTeam, setCreateTeam] = useState<boolean>(false)
  const [joinTeam, setJoinTeam] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0)
  const { fetchRest, sectionLoading } = useFetch({ getAllTeams: false })
  const { t } = useTranslation()
  const [myTeams, setMyTeams] = useState<ReadonlyArray<any>>([])
  const [paginationState, setPaginationState] = useState<{
    readonly perPage: number
    readonly page: number
  }>({
    perPage: 5,
    page: 1,
  })

  const changeHandler = (event: any, value: any) => {
    setPaginationState((prev) => ({ ...prev, page: value }))
    getAllTeams({ page: value, perPage: paginationState.perPage })
  }
  const getAllTeams = (obj: { readonly page?: number; readonly perPage?: number }) => {
    const { page = paginationState.page, perPage = paginationState.perPage } = obj
    fetchRest(
      {
        url: `/teams`,
        method: 'GET',
        isFullPageLoader: false,
        queryParams: {
          page,
          perPage,
        },
      },
      'getAllTeams'
    )
      .then((res: any) => {
        setMyTeams(res.data.data)
        setTotalCount(res.data.count)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getAllTeams({})
  }, [])
  // api call on useEffect to return team ids for teams the user is a part of, store them in an array, and using those teamIds get the details of the teams and store them in another array

  return (
    <>
      <div>
        <div className={styles.section}>
          <div className={`${styles.row} ${styles.margins} ${styles.heading}`}>
            <div className={styles.marginLeft}> {t('teams.teamsCount')} </div>
            <div className={totalCount === 0 ? styles.count : styles.countBlue}>
              &nbsp;{totalCount}
            </div>
          </div>
          <div className={`${styles.row} ${styles.paddingTop}`}>
            <div className={styles.newTeam}>
              <Button
                text="Create new team"
                className={styles.buttonStyles}
                onClick={() => {
                  setCreateTeam(true)
                }}
                ButtonImg={() => {
                  return <img className={styles.divImg} src={plus} alt="" />
                }}
              />
            </div>
            <Button
              text="Join Team"
              className={styles.buttonGrey}
              onClick={() => {
                setJoinTeam(true)
              }}
              ButtonImg={() => {
                return <img className={styles.divImg} src={JoinTeam} alt="" />
              }}
            />
          </div>
        </div>
        <div className={styles.section}>
          {sectionLoading && sectionLoading.getAllTeams ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              {myTeams.length ? (
                myTeams.map((each: any, key: number) => {
                  return (
                    <TeamDetails
                      teamInfo={each}
                      key={key}
                      callBackFunction={callback}
                      fetchList={() => getAllTeams({})}
                    />
                  )
                })
              ) : (
                <div className={styles.empty}>
                  <div>
                    <img src={teamIcon} alt="team icon" />
                    <div className={` ${styles.heading} ${styles.width} ${styles.textCenter}`}>
                      Your account is not connected to any team yet
                    </div>
                    <div className={`${styles.desc} ${styles.textCenter}`}>
                      Join existing team or create new one.
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {totalCount > 5 ? (
            <div className={styles.pagination}>
              <Pagination
                count={Math.ceil(totalCount / paginationState.perPage)}
                onChange={changeHandler}
                page={paginationState.page}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <DialogWrapper
        onClose={() => {
          setCreateTeam(false)
        }}
        open={createTeam}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <CreateTeam
          callBackFunction={() => {
            setCreateTeam(false)
            getAllTeams({})
          }}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setJoinTeam(false)
        }}
        open={joinTeam}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <CreateTeam
          join={true}
          callBackFunction={() => {
            joinRequest()
            setJoinTeam(false)
          }}
        />
      </DialogWrapper>
    </>
  )
}
