/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useState } from 'react'

export const UserInfoContext = createContext({
  userData: [] as any,
  getUserData: (userId: string) => {},
  setUser: (value: any) => {},
})

export const UserInfoProvider: React.FC<{}> = ({ children }) => {
  const [userData, setUserData] = useState<any>([])

  const getUserData = (userId: string) => {
    return userData.find((each: any) => each.userId === userId)
  }

  const setUser = (value: any) => {
    setUserData((prev: any) => {
      return [...prev, value]
    })
  }

  const setDataFunction = {
    userData,
    getUserData,
    setUser,
  }

  return <UserInfoContext.Provider value={setDataFunction}>{children}</UserInfoContext.Provider>
}
