import React from 'react'
import { useTranslation } from 'react-i18next'
import fileIcon from '../../assets/images/svg/Group.svg'
import warn from '../../assets/images/svg/warn.svg'
import '../../common.scss'
import { Loader } from '../../common/Loader/Loader'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import { Button } from '../../common/button/Button'
import styles from './CourseDetails.module.scss'

export const CourseDetails: React.FC<{
  selectedCourse: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }
  readonly fromTeams?: boolean
  readonly isRequestSubmitting?: boolean
  onSubmitRequest(): void
}> = ({ selectedCourse, onSubmitRequest, isRequestSubmitting = false, fromTeams = false }) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className={styles.box}>
        <div
          className={styles['box-title']}
          dangerouslySetInnerHTML={{ __html: selectedCourse.title }}
        ></div>
        <div className={styles['box-time']}>{selectedCourse.duration} hours session</div>
      </div>

      <div className={styles.box}>
        <div className={styles['box-subheading']}>
          <img src={fileIcon} alt="file icon" className={styles['box-img']} />
          <div>{t('sessionDetails.courseOutline')}</div>
        </div>
        <div
          className={styles['box-content']}
          dangerouslySetInnerHTML={{ __html: selectedCourse.outline }}
        ></div>
      </div>

      <div className={styles.box}>
        <div className={styles['box-subheading']}>
          <img src={fileIcon} alt="file icon" className={styles['box-img']} />
          <div>{t('sessionDetails.preRequisiteKnow')}</div>
        </div>
        <div
          className={styles['box-content']}
          dangerouslySetInnerHTML={{ __html: selectedCourse.prerequisite }}
        ></div>
      </div>

      <div className={styles.box}>
        <div className={styles['box-subheading']}>
          <img src={fileIcon} alt="file icon" className={styles['box-img']} />
          <div>Learning Outcomes</div>
        </div>
        {selectedCourse.outcome ? (
          <div
            className={styles['box-content']}
            dangerouslySetInnerHTML={{
              __html: selectedCourse.outcome,
            }}
          ></div>
        ) : (
          <div className={styles['box-content']}>None</div>
        )}
      </div>

      <div className={styles.box}>
        <div className={styles['box-subheading']}>
          <img src={fileIcon} alt="file icon" className={styles['box-img']} />
          <div>Software/Hardware requirements</div>
        </div>
        <div className={styles['box-content']}>
          {selectedCourse.requirements ? (
            <div
              className={styles['box-content']}
              dangerouslySetInnerHTML={{
                __html: selectedCourse.requirements,
              }}
            ></div>
          ) : (
            <div className={styles['box-content']}>{'No special requirements'}</div>
          )}
        </div>
      </div>

      {fromTeams ? (
        <></>
      ) : (
        <div className={styles.box}>
          <div className={styles['box-btn']}>
            <NoEventsLoader isLoading={isRequestSubmitting}>
              <Button
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onClick={(_e: any) => {
                  onSubmitRequest()
                }}
                className={styles['btn-color']}
                text={t('sessionDetails.btn-text')}
                ButtonImg={() => {
                  return (
                    <>
                      {isRequestSubmitting ? (
                        <Loader className="form_loader" size={20} thickness={2} />
                      ) : (
                        <>
                          <img src={warn} alt="apply-now" className={styles['box-img']} />
                        </>
                      )}
                    </>
                  )
                }}
              />
            </NoEventsLoader>
          </div>
        </div>
      )}
    </div>
  )
}
