/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import styles from './GetPaid.module.scss'
import cost from '../../assets/images/svg/HourRate.svg'
import whiteTick from '../../assets/images/svg/whiteTick.svg'
import addNewIcon from '../../assets/images/svg/add-new.svg'
import { Autocomplete } from '@material-ui/lab'
import { FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core'
import { AutocompleteOption } from '../../common/autocomplete-option/AutocompleteOption'
import { Button } from '../../common/button/Button'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { AddNew } from '../add-new-payment-method/AddNew'
import { useFetch } from '../../common/https'
import { useStripePaymentContext } from '../../common/use-stripe-payment-context'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { validateInput } from '../../common/ValidateFields'
import { schemaCardId } from '../../common/schema/Schema'
import { useElements } from '@stripe/react-stripe-js'
import { Loader } from '../../common/Loader/Loader'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { UsePopupContext } from '../../common/use-popup-context'
import Done from '../../assets/images/png/done.png'
import repeatRequest from '../../assets/images/svg/repeatRequest.svg'

interface PaymentOptions {
  name: string
  cardNo: string
  validUntil: string
  cvc: number
  displayNumber: string
}
export const GetPaid: React.FC<{
  readonly availableFund: number
  readonly proposalId?: string
  readonly proposal: any
  readonly onClosePopup: () => void
  readonly statsFunction?: () => void
}> = ({ availableFund, onClosePopup, proposalId, proposal, statsFunction = () => {} }) => {
  const { fetchRest, sectionLoading } = useFetch({ loader: false, pay: false })
  const { confirm, confirmCard } = useStripePaymentContext()

  const elements = useElements()

  const [paymentMethods, setPaymentMethods] = useState<any>({
    method: { value: [], isValid: true, isTouched: false, errorMsg: '' },
  })
  const { userDetails } = useUserDetailsContext()
  const [paymentList, setPaymentList] = useState<Array<PaymentOptions>>([])
  const [clientSecret, setClientSecret] = useState('')
  const [addNew, setAddNew] = useState<boolean>(false)

  const { close, setData: setPopupData } = UsePopupContext()

  const popUp = (success: boolean) => {
    let headerContent: string = ''
    let content: string = ''

    if (success) {
      headerContent = 'Payment Successfull'
      content = 'Payment completed successfully'
    } else {
      headerContent = 'Payment Failed'
      content = 'Please provide/update payment method'
    }

    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return (
            <img
              src={success ? Done : repeatRequest}
              alt="popup icon"
              className={styles.widthPopup}
            />
          )
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.green}
                    text={'Got It'}
                    onClick={() => {
                      close()
                      onClosePopup()
                      statsFunction()
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button
                    text={'OK'}
                    onClick={(e) => {
                      close()
                    }}
                    className={styles.green}
                  ></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  const autoCompleteOnChange = (value: any) => {
    var valid = validateInput(schemaCardId, value)
    setPaymentMethods((prev: any) => {
      return {
        ...prev,
        method: {
          value: value,
          isValid: valid.isValid,
          isTouched: true,
          errorMsg: valid.errorMsg,
        },
      }
    })
  }

  const [formState, setFormState] = useState<{
    readonly value: string
  }>({
    value: 'Card',
  })

  const radioOptions: ReadonlyArray<{
    readonly value: number | string
    readonly label: string
  }> = [
    {
      value: 'Card',
      label: 'Saved payment methods',
    },
    // {
    //   value: 'other',
    //   label: 'Other payment methods',
    // },
  ]

  useEffect(() => {
    getCardDetails()
  }, [])

  const onReasonChange = (event: any) => {
    event.persist()
    if (event && event.target && event.target.value) {
      setFormState({ value: event.target.value })
    }
  }

  const onSubmit = (e: any) => {
    if (formState.value === 'Card') {
      fetchRest(
        {
          url: '/createPaymentIntent',
          method: 'POST',
          data: {
            paymentMethod: paymentMethods.method.value.id,
            customer: userDetails.stripeCustomerIdUK,
            proposalId: proposalId,
            stripeAccount: 'UK',
          },
        },
        'pay'
      )
        .then(async (res: any) => {
          setClientSecret(res.data.secret)

          const status = await confirmCard(res.data.secret)

          if (status.status === 'succeeded') {
            popUp(true)
          }
        })
        .catch((err: any) => {
          popUp(false)
        })
    } else {
      confirm(elements!).then((res: any) => {
        if (res.status === 'succeeded') {
          popUp(true)
        }
      })
    }
  }

  const getCardDetails = () => {
    fetchRest(
      {
        url: `/getCustomerCards?stripeCustomerId=${
          userDetails.stripeCustomerIdUK
        }&stripeAccount=${'UK'}`,
        method: 'GET',
      },
      'loader'
    )
      .then((res: any) => {
        setPaymentList(
          res.data.data.data.map((each: any) => {
            return { displayNumber: each.last4, ...each }
          })
        )
      })
      .catch((err: any) => {})
  }

  return (
    <>
      {sectionLoading && sectionLoading.loader ? (
        <div className={styles.whiteBackground}>
          <Loader />
        </div>
      ) : (
        <div className={styles.whiteBackground}>
          <div className={styles.heading}>
            <img src={cost} alt="cost" className={styles.divImg} />
            {/* <div>{t('get-paid.getPaid')}</div> */}
            <div>Pay</div>
          </div>
          <div style={{ padding: '0px 15px' }}>Your payment amount is ${availableFund}</div>
          <div className="green_radio_buttons">
            <div className="green_radio_buttons">
              <RadioGroup
                onChange={onReasonChange}
                value={formState.value}
                name="radio-buttons-group"
              >
                {radioOptions.map((each, index: number) => {
                  return (
                    <FormControlLabel
                      className={styles.radioLabel}
                      value={each.value}
                      control={<Radio size={'medium'} disableRipple />}
                      label={each.label}
                      key={index}
                      classes={{
                        label: styles.label,
                      }}
                    />
                  )
                })}
                <>
                  <div className={styles.withdrawalMethod}>
                    {/* <div className={styles.boldText}>{t('get-paid.specify-method')}</div> */}
                    <div className={styles.boldText}>Specify payment method</div>
                    {/* <div className={styles.smallText}>{t('get-paid.select')}</div> */}
                    <div className={styles.smallText}>Select already specified payment method</div>
                    <div className={styles.marginTop}>
                      <div className="autocomplete-wrapper">
                        <Autocomplete
                          value={paymentMethods.method.value}
                          onChange={(event, newValue) => {
                            autoCompleteOnChange(newValue)
                          }}
                          handleHomeEndKeys
                          options={paymentList}
                          getOptionLabel={(option) => {
                            return option.last4 ? '****' + option.last4 : ''
                          }}
                          renderOption={(option: any) => (
                            <AutocompleteOption option={'****' + option.last4} />
                          )}
                          ListboxProps={{
                            style: {
                              maxHeight: '147px',
                            },
                          }}
                          style={{ maxWidth: '715px', width: '100%', position: 'relative' }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Select payment method"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.buttonWrapper}>
                    <Button
                      // text="Get paid now"
                      text="Pay"
                      ButtonImg={() => {
                        return (
                          <>
                            {sectionLoading && sectionLoading.pay ? (
                              <Loader className="form_loader" size={20} thickness={2} />
                            ) : (
                              <>
                                <img src={whiteTick} alt="tick icon" className={styles.divImg} />
                              </>
                            )}
                          </>
                        )
                      }}
                      disabled={sectionLoading && sectionLoading.pay}
                      className={styles.button}
                      onClick={onSubmit}
                    />
                    <Button
                      text="Add new method"
                      ButtonImg={() => {
                        return (
                          <>
                            <img src={addNewIcon} alt="add new" className={styles.divImg} />
                          </>
                        )
                      }}
                      className={styles.greyButton}
                      onClick={() => {
                        setAddNew(true)
                      }}
                    />
                  </div>
                </>
                {/* )}
                </> */}
              </RadioGroup>
            </div>
          </div>
        </div>
      )}

      <DialogWrapper
        onClose={() => {
          setAddNew(false)
        }}
        open={addNew}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <AddNew
          countryCode={proposal.owner.stripeAccountId ? proposal.owner.countryCode : 'UK'}
          onClose={() => {
            setAddNew(false)
            getCardDetails()
          }}
        />
      </DialogWrapper>
    </>
  )
}
