import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import facebook from '../../assets/images/svg/facebook.svg'
import instagram from '../../assets/images/svg/instagram.svg'
import linkedIn from '../../assets/images/svg/linkedin.svg'
import logo from '../../assets/images/svg/ontelo-logo.svg'
import twitter from '../../assets/images/svg/twitter.svg'
import styles from './Footer.module.scss'

export const Footer: React.FC = () => {
  const { t } = useTranslation()

  const year = new Date().getFullYear()

  const socialMedia = [
    {
      logo: instagram,
      text: 'instagram-logo',
      to: 'https://instagram.com/ontelo.io',
    },
    {
      logo: linkedIn,
      text: 'linkedIn-logo',
      to: 'https://www.linkedin.com/company/ontelo/about/?viewAsMember=true',
    },
    {
      logo: facebook,
      text: 'facebook-logo',
      to: 'https://www.facebook.com/Ontelo-108555041488622',
    },
    {
      logo: twitter,
      text: 'twitter-logo',
      to: 'https://twitter.com/ontelo_universe',
    },
  ]
  return (
    <div className={styles.footer}>
      <div className={styles.footer__logo}>
        <img
          src={logo}
          className={styles['ontelo-logo']}
          alt="ontelo-logo"
          data-testid="logo_footer"
        />
      </div>
      <div>
        <div className={styles.footer__email}>{t('footer.infoOntelo')}</div>
        <div className={styles.footer__section}>
          {/* <div className={styles['footer__contact-info']}>
          <div>{t('footer.uk')}</div>
          <div>{t('footer.uae')}</div>
          <div>{t('footer.southAfrica')}</div>
        </div> */}
          {/* <div className={styles['links-div']}>
            <Link to="/privacy-policy" target="_blank" rel="noreferrer">
            <span className={styles.links}> Privacy policy</span>
            </Link>
            </div>
            <div className={styles['links-div']}>
            <Link to="/terms-and-conditions" target="_blank" rel="noreferrer">
            <span className={styles.links}>Terms & Conditions</span>
            </Link>
          </div> */}

          <div className={styles['links-div']}>
            <Link to="/help-center?tab=learners" target="_blank" rel="noreferrer">
              <span className={styles.links}>Help center</span>
            </Link>
          </div>
        </div>
      </div>

      <div className={styles['footer__social-media']}>
        <div className={styles.footer__rights}>
          ontelo® | The Knowledge Marketplace © {year} All Rights Reserved.
        </div>
        <div className={styles['footer__social-media-icon']}>
          {socialMedia.map((each, index) => (
            <React.Fragment key={index}>
              <a href={each.to} target="_blank" rel="noreferrer">
                <img src={each.logo} alt={each.text} />
              </a>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}
