/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import chatConnectGray from '../../assets/images/svg/chat-connect-gray.svg'
import PopUPIcon from '../../assets/images/svg/popup-icon.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import star from '../../assets/images/svg/star.svg'
import tick from '../../assets/images/svg/whiteTick.svg'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { Loader } from '../../common/Loader/Loader'
import { useFetch } from '../../common/https'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import { UsePopupContext } from '../../common/use-popup-context'
import { charactersLeft } from '../../common/utils'
import { Button } from '../../common/button/Button'
import styles from './ConnectPopup.module.scss'
import { NameInitials } from '../name-initials/NameInitials'

export const ConnectPopup: React.FC<{
  readonly item: any
  onClose(): void
  onSuccess?(): void
  onError?(): void
}> = ({ item, onClose, onSuccess = () => {} }) => {
  const elem = Array.from({ length: 4 }, (_, index) => index + 1)
  const { t } = useTranslation()
  const [message, setMessage] = useState({
    isValid: false,
    description: '',
    isTouched: false,
    errorMsg: '',
  })
  const { fetchRest, sectionLoading } = useFetch({ connectPopup: false })
  const { close, setData: setPopupData } = UsePopupContext()

  const openPopup = (success: boolean) => {
    let content: string = ''
    let headerContent: string = ''
    if (success) {
      headerContent = 'Success'
      content = 'Your message has been sent Successfully!'
    } else {
      headerContent = 'Something went wrong'
      content =
        'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? PopUPIcon : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.white}
                    text={'Got It'}
                    onClick={() => {
                      close()
                      onSuccess()
                      onClose()
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button
                    text={'Go back!'}
                    onClick={(e) => {
                      close()
                    }}
                    className={styles.white}
                  ></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  const connect = (e: any) => {
    e.preventDefault()
    setMessage((prev: any) => {
      return {
        ...prev,
        isTouched: true,
      }
    })
    if (message.description.trim().length === 0) {
      setMessage((prev: any) => {
        return {
          ...prev,
          errorMsg: 'Please enter message',
          isValid: false,
        }
      })
    } else if (message.description.length > 5000) {
      setMessage((prev: any) => {
        return {
          ...prev,
          errorMsg: 'Please enter less than 5000 characters',
          isValid: false,
        }
      })
    } else {
      setMessage((prev: any) => {
        return {
          ...prev,
          isValid: true,
        }
      })
      fetchRest(
        {
          url: '/connectToRespondent',
          method: 'POST',
          data: {
            message: message.description,
            responseId: item.responseId,
            requestId: item.requestId,
          },
        },
        'connectPopup'
      )
        .then((res: any) => {
          openPopup(true)
        })
        .catch((err: any) => {
          openPopup(false)
        })
    }
  }
  return (
    <>
      <NoEventsLoader isLoading={sectionLoading && sectionLoading.connectPopup}>
        <form onSubmit={connect}>
          <div className={styles['list-card']}>
            <div className={styles['respondent__align-items']}>
              <img
                src={chatConnectGray}
                alt="connect"
                className={styles['respondent__align-icon']}
              />
              <div className={styles['respondent__align-subject']}>{t('connectPopup.connect')}</div>
            </div>
            <div className={styles['review-card__profile']}>
              {item.user.imageUrl.length > 0 ? (
                <img src={item.user.imageUrl} alt="user icon" className={styles.userImage} />
              ) : (
                <>
                  <NameInitials
                    firstName={item.user.firstName}
                    lastName={item.user.lastName}
                    className={styles.icon}
                  />
                </>
              )}
              <div className={styles['review-card__star-rating']}>
                <div className={styles['review-card__username']}>{item.user.firstName}</div>
                <div className={styles['review-card__rating']}>
                  {elem.map((each) => {
                    return (
                      <img
                        src={star}
                        alt="star icons"
                        key={'star' + each}
                        className={styles['review-card__rating-icons']}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
            <div className={styles['edit-title']}>{t('connectPopup.message')}</div>
            <div>
              <textarea
                className={styles['textarea-styles']}
                value={message.description}
                onChange={(e) => {
                  let value = e.target.value
                  setMessage((prev: any) => {
                    return { ...prev, description: value }
                  })
                  // setCharacterCount(5000 - value.length)
                }}
              ></textarea>
              <div className={styles.errorPosition}>
                {!message.isValid ? <div className={styles.error}>{message.errorMsg}</div> : ''}
              </div>
            </div>
            <div className={styles['character-count']}>
              {charactersLeft({
                currentLength:
                  message.description && message.description.length
                    ? message.description.length
                    : 0,
              })}
              {t('common.charactersLeft')}
            </div>
            <div className={styles['box-btn']}>
              <Button
                ButtonImg={() => {
                  return (
                    <>
                      {sectionLoading && sectionLoading.connectPopup ? (
                        <Loader className="form_loader" size={20} thickness={2} />
                      ) : (
                        <>
                          <img src={tick} alt="apply-now" className={styles['btn-img']} />
                        </>
                      )}
                    </>
                  )
                }}
                type="submit"
                className={styles['btn-color']}
                text="Connect"
              />
            </div>
          </div>
        </form>
      </NoEventsLoader>
    </>
  )
}
