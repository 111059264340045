import React from 'react'
import { Header } from '../../components/header/Header'
import { ReviewSection } from '../../components/review-section/ReviewSection'
import { SignUpForm } from '../sign-up-form/SignUpForm'
import styles from './SignUp.module.scss'

export const SignUp: React.FC = () => {
  return (
    <>
      <div className={styles.wrapperSignUp}>
        <div className={styles.section}>
          <Header isSignUp={true} />
          <div className={styles.leftWrapper}>
            <SignUpForm />
          </div>
        </div>
        <div className={`${styles.section} ${styles.mobile}`} data-testid="review">
          <div className={styles.right}>
            <ReviewSection isSignUp={true} />
          </div>
        </div>
      </div>
    </>
  )
}
