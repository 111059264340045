/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useFetch } from '../../common/https'
import settings from '../../assets/images/svg/settings.svg'
import { FormControlLabel, Radio, RadioGroup, Switch } from '@material-ui/core'
import Done from '../../assets/images/png/done.png'
import pinkStar from '../../assets/images/svg/pinkStar.svg'
import star from '../../assets/images/svg/star.svg'
import teamIcon from '../../assets/images/svg/TeamIconColor.svg'
import tick from '../../assets/images/svg/whiteTick.svg'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { Button } from '../../common/button/Button'
import { Loader } from '../../common/Loader/Loader'
import { UsePopupContext } from '../../common/use-popup-context'
import styles from './UserRights.module.scss'

export const UserRights: React.FC<{
  readonly team: any
  readonly closePopup: any
}> = ({ team, closePopup }) => {
  const { fetchRest, sectionLoading } = useFetch({ cred: false })

  const [formState, setFormState] = useState<{
    readonly value: string
  }>({
    value: team.memberRole,
  })

  const [rights, setRights] = useState([
    {
      value: 'Have acces to finances',
      isChecked: true,
    },
    {
      value: 'Can edit company settings',
      isChecked: true,
    },
    {
      value: 'Can manage users',
      isChecked: true,
    },
    {
      value: 'Can edit users sessions',
      isChecked: true,
    },
  ])

  useEffect(() => {
    if (team.memberRole === 'Admin') {
      fetchRest(
        {
          method: 'GET',
          url: `getTeamAdminCredentials/${team.teamId}/${team.memberId}`,
        },
        'cred'
      )
        .then((res: any) => {
          if (res && res.data) {
            setRights([
              {
                value: 'Have acces to finances',
                isChecked: res.data.credentials.canAccessFinances,
              },
              {
                value: 'Can edit company settings',
                isChecked: res.data.credentials.canEditCompanySetting,
              },
              {
                value: 'Can manage users',
                isChecked: res.data.credentials.canManageUser,
              },
              {
                value: 'Can edit users sessions',
                isChecked: res.data.credentials.canEditSession,
              },
            ])
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [team])

  const { close, setData: setPopupData } = UsePopupContext()

  const popUp = (type?: string) => {
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={type === 'SuperAdmin' ? teamIcon : Done} alt="popup icon" />
        }}
        title={
          type === 'SuperAdmin'
            ? ' Are you sure you want to assign a Super Admin ? '
            : type === 'Member'
            ? 'Super admin assigned!'
            : 'Update User Rights'
        }
        Buttons={() => {
          return (
            <>
              {type === 'SuperAdmin' ? (
                <>
                  <Button
                    text={'Yes'}
                    onClick={() => {
                      close()
                      updateRights()
                    }}
                    className={styles.red}
                  ></Button>
                  <Button
                    text={'No'}
                    onClick={() => {
                      close()
                    }}
                    className={styles.white}
                  ></Button>
                </>
              ) : (
                <Button
                  className={styles.white}
                  text={'Got it'}
                  onClick={() => {
                    close()
                    closePopup()
                  }}
                ></Button>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>
                {type === 'SuperAdmin'
                  ? 'Doing this will make you a member'
                  : type === 'Member'
                  ? 'You are now a member of this team'
                  : 'User rights successfully updated'}
              </p>
            </div>
          )
        }}
      />
    )
  }

  const radioOptions: ReadonlyArray<{
    readonly value: number | string
    readonly label: string
  }> = [
    {
      value: 'Member',
      label: 'Team member',
    },
    {
      value: 'SuperAdmin',
      label: 'Super Administrator',
    },
    {
      value: 'Admin',
      label: 'Administrator',
    },
  ]

  const onReasonChange = (event: any) => {
    event.persist()
    if (event && event.target && event.target.value) {
      setFormState({ value: event.target.value })
    }
  }

  const onChecked = (index: any) => {
    let arr = rights
    setRights([])
    arr.forEach((each: any, i: any) => {
      if (i !== index) {
        setRights((prev: any) => {
          return [...prev, each]
        })
      } else {
        setRights((prev: any) => {
          return [...prev, { value: each.value, isChecked: !each.isChecked }]
        })
      }
    })
  }

  const updateRights = () => {
    fetchRest(
      {
        method: 'POST',
        url: `team/${team.teamId}/admin/${team.memberId}/change-credentials`,
        data:
          formState.value === 'Member'
            ? {
                isMember: true,
              }
            : formState.value === 'SuperAdmin'
            ? {
                isSuperAdmin: true,
              }
            : {
                isAdmin: true,
                canAccessFinances: rights[0]?.isChecked,
                canEditSession: rights[3]?.isChecked,
                canEditCompanySetting: rights[1]?.isChecked,
                canManageUser: rights[2]?.isChecked,
              },
      },
      'loader'
    )
      .then((res) => {
        popUp()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.imgWrapper}>
          <>
            {team?.memberInfo.imageUrl ? (
              <img className={styles.userImg} src={team?.memberInfo.imageUrl} alt="profile" />
            ) : (
              <>
                <div className={`${styles.imgWrapperIcon}`}>
                  <div className={styles.align}>
                    {team?.memberInfo.firstName && team?.memberInfo.lastName
                      ? `${team?.memberInfo.firstName
                          .toUpperCase()
                          .charAt(0)}${team?.memberInfo.lastName.toUpperCase().charAt(0)}`
                      : ''}
                  </div>
                </div>
              </>
            )}
          </>
        </div>
        <div className={styles.userInfoWrapper}>
          <div className={styles.name}>{team?.memberInfo.firstName}</div>
          <div className={styles.feedbackContent}>
            <div className={styles.rating}>
              {Array(Number(0))
                .fill('')
                .map((x, i) => {
                  return <img src={pinkStar} alt="rating" key={i} className={styles.star} />
                })}
              {Array(5 - Number(0))
                .fill('')
                .map((x, i) => {
                  return <img src={star} alt="rating" key={i} className={styles.star} />
                })}
            </div>
          </div>
        </div>
      </div>
      {sectionLoading && sectionLoading.cred ? (
        <Loader />
      ) : (
        <div className={`${styles.wrapper} ${styles.pLeft}`}>
          <div className={styles.manageRights}>
            <img src={settings} alt="settings" className={styles['manageRights-img']} />
            <div className={styles['manageRights-title']}>Manage rights</div>
          </div>
          <div className={'radio-rights'}>
            <div className="green_radio_buttons">
              <RadioGroup
                onChange={onReasonChange}
                value={formState.value}
                name="radio-buttons-group"
              >
                {radioOptions.map((each, index: number) => {
                  return (
                    <FormControlLabel
                      className={styles.radioLabel}
                      value={each.value}
                      control={<Radio size={'medium'} disableRipple />}
                      label={each.label}
                      key={index}
                      classes={{
                        label: styles.label,
                      }}
                    />
                  )
                })}
                {/* @TODO : slider should open based on each selection, under the ui of the checkbox, current implementation is wrong */}
                <>
                  {formState.value === 'Member' || formState.value === 'SuperAdmin' ? (
                    <></>
                  ) : (
                    <>
                      {rights.map((each, index) => {
                        return (
                          <div className={styles.switchWrapper} key={index}>
                            <div className={styles.borders}>
                              <div className={styles.headingCheckBox}>{each.value}</div>
                              <Switch
                                checked={each.isChecked}
                                onChange={() => {
                                  onChecked(index)
                                }}
                                color="primary"
                              />
                            </div>
                          </div>
                        )
                      })}
                    </>
                  )}
                </>
              </RadioGroup>
            </div>
          </div>
          <Button
            text={'Save'}
            ButtonImg={() => {
              return (
                <>
                  {sectionLoading && sectionLoading.loader ? (
                    <Loader size={20} thickness={2} className={styles.marginBottom} />
                  ) : (
                    <img src={tick} alt="user icon" className={styles.user_img} />
                  )}
                </>
              )
            }}
            onClick={() => {
              if (formState.value === 'SuperAdmin') {
                popUp('SuperAdmin')
              } else {
                updateRights()
              }
            }}
            className={styles.invite_btn}
          />
        </div>
      )}
    </>
  )
}
