/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { useFetch } from '../../common/https'
import { Loader } from '../../common/Loader/Loader'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { Button } from '../../common/button/Button'
import tick from '../../assets/images/svg/whiteTick.svg'
import edit from '../../assets/images/svg/editButton.svg'
import editIcon from '../../assets/images/svg/editIcon.svg'
import PopUPIcon from '../../assets/images/svg/popup-icon.svg'
import deleteTeam from '../../assets/images/svg/deleteTeamPopup.svg'
import LeaveTeam from '../../assets/images/svg/LeaveTeamPopup.svg'
import RepeatRequest from '../../assets/images/svg/delete-request.svg'
import styles from './TeamProfile.module.scss'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import deleteMenu from '../../assets/images/svg/delete-menu.svg'
import leave from '../../assets/images/svg/leaveTeam.svg'
import { TabItem } from '../tab-item/TabItem'
import { TabWrapperProps, TabsWrapper } from '../tabs-wrapper/TabsWrapper'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import { ProposalList } from '../proposal-list/ProposalList'
import { RequestLearning } from '../request-learning/RequestLearning'
import { RequestList } from '../request-list/RequestList'
import { InformationTab } from '../teams-tab-information/TeamsTabInformation'
import { MembersList, TeamMembers } from '../teams-tab-members/TeamsTabMembers'
import { SessionList } from '../session-list/SessionList'
import { TeamInviteCode } from '../team-invite-code/TeamInviteCode'
import { LoadingWrapper } from '../../common/loading-wrapper/LoadingWrapper'
import { SessionCard } from '../session-card/SessionCard'
import grayStar from '../../assets/images/svg/gray-star.svg'
import { CourseDetails } from '../course-details/CourseDetails'
import { TeamRecommendCourse } from '../recommend-course/TeamRecommendCourse'
import { Archive } from '../teams-tab-archive/TeamsTabArchive'
import { DropDown } from '../../common/drop-down/DropDown'
import dotMenu from '../../assets/images/svg/dots-menu.svg'
import { DeleteTeam } from '../delete-team/DeleteTeam'
import { UsePopupContext } from '../../common/use-popup-context'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { Pagination } from '@material-ui/lab'
import { NameInitials } from '../name-initials/NameInitials'
import Moment from 'react-moment'
import { EmptySection } from '../../common/empty-section/EmptySection'
import { useUploadContext } from '../../common/use-upload-context'

const RecommendedCourses: React.FC<{ readonly teamId?: string; readonly setReload: any }> = ({
  teamId = '',
  setReload,
}) => {
  const { fetchRest, sectionLoading } = useFetch({ recommendations: false })
  const [selectedCourse, setSelectedCourse] = useState<any>()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [userRecommendation, setUserRecommendation] = useState<
    ReadonlyArray<{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any
    }>
  >([])
  const { t } = useTranslation()
  const [paginationState, setPaginationState] = useState<{
    readonly perPage: number
    readonly page: number
  }>({
    perPage: 3,
    page: 1,
  })
  const { close, setData: setPopupData } = UsePopupContext()
  const [count, setCount] = useState<number>(0)
  const changeHandler = (event: any, value: any) => {
    setPaginationState((prev) => ({ ...prev, page: value }))
    getUserRecommendations({ page: value, perPage: paginationState.perPage })
  }
  const getUserRecommendations = (obj: { readonly page?: number; readonly perPage?: number }) => {
    const { page = paginationState.page, perPage = paginationState.perPage } = obj
    fetchRest(
      {
        url: `team/${teamId}/get-recommended-courses-from-members`,
        method: 'GET',
        queryParams: {
          page: page,
          perPage: perPage,
        },
      },
      'recommendations'
    )
      .then((res: any) => {
        if (res && res.data && res.data.data) {
          setUserRecommendation(res.data.data)
          setCount(res.data.count)
        } else {
          setUserRecommendation([])
        }
      })
      .catch()
  }
  const submitRequest = () => {
    fetchRest(
      {
        url: '/userSubmitRequest',
        method: 'POST',
        data: {
          description: selectedCourse.title,
          courseId: selectedCourse.courseId,
        },
        queryParams: teamId ? { teamId } : {},
      },
      'submitRequest'
    )
      .then((_res) => {
        popup(true)
      })
      .catch((err) => {
        popup(false)
      })
  }
  const popup = (success: boolean) => {
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? PopUPIcon : RepeatRequest} alt="popup icon" />
        }}
        title={
          success
            ? 'Great, you’ve submitted your learning request!'
            : 'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
        }
        Buttons={() => {
          return (
            <>
              <Button
                className={styles.white}
                text={'Got It'}
                onClick={() => {
                  close()
                  setIsDialogOpen(false)
                  setReload()
                }}
              ></Button>
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>
                {success
                  ? 'Now sit tight, one of the ontelo experts will be in touch with you soon'
                  : ''}
              </p>
            </div>
          )
        }}
      />
    )
  }
  useEffect(() => {
    getUserRecommendations({})
  }, [])

  return (
    <div className={`${styles.element} ${styles.recommend}`}>
      <div className={styles['review-card__align-items']}>
        <img src={grayStar} alt="star" className={styles['review-card__time-icon']} />
        <div className={styles['time-subject']}>{t('requestDetails.recommendations')}</div>
      </div>
      {count ? (
        <>
          <LoadingWrapper
            isLoading={sectionLoading && sectionLoading.recommendations}
            LoaderComponent={Loader}
          >
            {userRecommendation.map((item) => {
              return (
                <div key={item.courseId}>
                  <SessionCard
                    imageUrl={item.imageUrl}
                    header={item.courseInfo.title}
                    timer={item.courseInfo.duration}
                    onCardClick={() => {
                      setSelectedCourse(item.courseInfo)
                      setIsDialogOpen(true)
                    }}
                  />
                  <div className={styles['review-card__align-items']}>
                    <div className={styles['sender-subject']}>
                      Recommended by - {item?.recommenderInfo?.firstName}
                    </div>
                  </div>
                </div>
              )
            })}
          </LoadingWrapper>
          {count > 3 ? (
            <div className={styles.pagination_wrapper}>
              <Pagination
                page={paginationState.page}
                onChange={changeHandler}
                count={Math.ceil(count / paginationState.perPage)}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <EmptySection subText={'No recommended courses!'} />
        </>
      )}
      <DialogWrapper
        Title={(titleProps) => {
          return <DialogHeader {...titleProps} />
        }}
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false)
        }}
        isFullScreen={false}
      >
        <CourseDetails
          isRequestSubmitting={sectionLoading && sectionLoading.submitRequest}
          selectedCourse={selectedCourse}
          onSubmitRequest={submitRequest}
        />
      </DialogWrapper>
    </div>
  )
}

const ActivityListItem: React.FC<{ readonly data?: any }> = ({ data }) => {
  return (
    <div className={`${styles.wrapper} ${styles.padding} ${styles.marginBtm}`}>
      <div className={styles.activityDetails}>
        <div>
          {data?.notificationFromInfo?.imageUrl ? (
            <img
              src={data?.notificationFromInfo?.imageUrl}
              alt="notification"
              className={styles.icon}
            />
          ) : (
            <NameInitials
              firstName={data?.notificationFromInfo?.firstName}
              lastName={data?.notificationFromInfo?.lastName}
              className={styles.icon}
            />
          )}
        </div>
        <div>
          <div className={styles.userName}>
            {data?.notificationFromInfo?.firstName} {data?.notificationFromInfo?.lastName}
          </div>
          <div className={styles.desc}>
            <span className={styles.bold}>{data?.notificationTitle}</span>
          </div>
        </div>
      </div>
      <div className={styles.date}>
        <Moment date={data?.notificationOn} format="DD MMM" interval={0} />,
        <Moment date={data?.notificationOn} format="HH:mm" interval={0} />
      </div>
    </div>
  )
}

export const TeamTitleForm: React.FC<{
  readonly closePopup: () => void
  readonly isTeam?: boolean
  readonly teamInfo?: any
}> = ({ closePopup, isTeam, teamInfo }) => {
  const { fetchRest, sectionLoading } = useFetch({ aboutform: false })
  const [aboutName, setaboutName] = useState<{
    value: string
    isValid: boolean
    isTouched: boolean
    errorMsg: string
  }>({
    value: teamInfo.teamDetails.teamName,
    isValid: true,
    isTouched: false,
    errorMsg: 'Please enter your Title',
  })

  const onSubmit = (e: any) => {
    e.preventDefault()
    if (aboutName.isValid) {
      fetchRest(
        {
          url: `/team/${teamInfo.teamId}/edit`,
          method: 'PUT',
          data: {
            teamName: aboutName.value ? aboutName.value : undefined,
          },
          isFullPageLoader: false,
        },
        'aboutForm'
      )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          closePopup()
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <>
      <NoEventsLoader isLoading={sectionLoading && sectionLoading.aboutForm}>
        <form onSubmit={onSubmit}>
          <div className={styles['list-card']}>
            <div className={styles.marginTop}>
              <div className={styles.fullField}>
                <div className={styles.heading2}>Team Name</div>
                <div className={styles.subHeading}>Please enter your team name</div>
                <div>
                  <InputWrapper
                    type="text"
                    valueObj={aboutName}
                    onChange={(e: any) => {
                      // handleChange(e, 'title')
                      setaboutName({
                        isTouched: true,
                        value: e.value,
                        isValid: true,
                        errorMsg: e.errorMsg,
                      })
                    }}
                    className={styles.text_field}
                  />
                </div>
              </div>
            </div>
            <div className={styles.button}>
              <Button
                text="Save"
                type="submit"
                className={styles.buttonClass}
                ButtonImg={() => {
                  return (
                    <>
                      {sectionLoading && sectionLoading.aboutForm ? (
                        <Loader className="form_loader" size={20} thickness={2} />
                      ) : (
                        <>
                          <img src={tick} alt="tick icon" className={styles.divImg} />
                        </>
                      )}
                    </>
                  )
                }}
              />
            </div>
          </div>
        </form>
      </NoEventsLoader>
    </>
  )
}

export const TeamsProfile: React.FC<{
  readonly isTeam?: boolean
  readonly callback?: any
}> = ({ isTeam = true, callback = () => {} }) => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const { pathname } = useLocation()
  const { search } = useLocation()
  const { fetchRest, sectionLoading } = useFetch({ teamDetails: false })
  const teamId = pathname.substring(pathname.lastIndexOf('/') + 1)
  const [defaultState, setDefaultState] = useState<number>(-1)
  const [isActiveTab, setIsActiveTab] = useState('Information')
  const [teamData, setTeamData] = useState<any>({})
  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const { close, setData: setPopupData } = UsePopupContext()
  const [openDeleteTeamDialog, setOpenDeleteTeamDialog] = useState<boolean>(false)
  const [count, setCount] = useState<{
    requestCount: number
    proposalCount: number
    approvedProposalCount: number
    sessionCount: number
    activityCount: number
  }>({
    requestCount: 0,
    proposalCount: 0,
    approvedProposalCount: 0,
    sessionCount: 0,
    activityCount: 0,
  })
  const { userDetails } = useUserDetailsContext()

  const teamsAdditionalTabs: ReadonlyArray<TabWrapperProps> = [
    {
      key: 'Information',
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Information" className={styles.tab} />
      },
    },
    {
      key: 'Activity',
      TabItemWrapper: ({ isActive }) => {
        return (
          <TabItem
            isActive={isActive}
            text="Activity"
            count={count?.activityCount}
            className={styles.tab}
          />
        )
      },
    },
    {
      key: 'Members',
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Members" className={styles.tab} />
      },
    },
    {
      key: 'Request Learning',
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Request Learning" className={styles.tab} />
      },
    },
    {
      key: 'Request',
      TabItemWrapper: ({ isActive }) => {
        return (
          <TabItem
            isActive={isActive}
            text="Request"
            className={styles.tab}
            count={count?.requestCount}
          />
        )
      },
    },
    {
      key: 'Proposal',
      TabItemWrapper: ({ isActive }) => {
        return (
          <TabItem
            isActive={isActive}
            text="Proposal"
            count={count?.proposalCount}
            className={styles.tab}
          />
        )
      },
    },
    {
      key: 'Approval',
      TabItemWrapper: ({ isActive }) => {
        return (
          <TabItem
            isActive={isActive}
            text="Approval"
            className={styles.tab}
            count={count?.approvedProposalCount}
          />
        )
      },
    },
    {
      key: 'Session',
      TabItemWrapper: ({ isActive }) => {
        return (
          <TabItem
            isActive={isActive}
            text="Session"
            count={count?.sessionCount}
            className={styles.tab}
          />
        )
      },
    },
    {
      key: 'Archive',
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Archive" count={0} className={styles.tab} />
      },
    },
  ]

  const teamTabs: ReadonlyArray<TabWrapperProps> = [
    {
      key: 'Information',
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Information" className={styles.tab} />
      },
    },
    {
      key: 'Activity',
      TabItemWrapper: ({ isActive }) => {
        return (
          <TabItem
            isActive={isActive}
            text="Activity"
            count={count?.activityCount}
            className={styles.tab}
          />
        )
      },
    },

    {
      key: 'Request Learning',
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Request Learning" className={styles.tab} />
      },
    },
    {
      key: 'Request',
      TabItemWrapper: ({ isActive }) => {
        return (
          <TabItem
            isActive={isActive}
            text="Request"
            className={styles.tab}
            count={count?.requestCount}
          />
        )
      },
    },
    {
      key: 'Proposal',
      TabItemWrapper: ({ isActive }) => {
        return (
          <TabItem
            isActive={isActive}
            text="Proposal"
            count={count?.proposalCount}
            className={styles.tab}
          />
        )
      },
    },
    {
      key: 'Approval',
      TabItemWrapper: ({ isActive }) => {
        return (
          <TabItem
            isActive={isActive}
            text="Approval"
            className={styles.tab}
            count={count?.approvedProposalCount}
          />
        )
      },
    },
    {
      key: 'Session',
      TabItemWrapper: ({ isActive }) => {
        return (
          <TabItem
            isActive={isActive}
            text="Session"
            count={count?.sessionCount}
            className={styles.tab}
          />
        )
      },
    },
    {
      key: 'Archive',
      TabItemWrapper: ({ isActive }) => {
        return <TabItem isActive={isActive} text="Archive" count={0} className={styles.tab} />
      },
    },
  ]

  const teamStats = () => {
    fetchRest({
      url: `team/${teamId}/team-detail-stats`,
      method: 'GET',
    }).then((res: any) => {
      setCount((prev: any) => {
        return {
          requestCount: res.data.requestCount,
          proposalCount: res.data.proposalCount,
          approvedProposalCount: res.data.approvedProposalCount,
          sessionCount: res.data.sessionCount,
          activityCount: res.data.activityCount,
        }
      })
    })
  }
  const onTabChangeTeams = (activeTab: number) => {
    if (memberRole === 'Admin' || memberRole === 'SuperAdmin') {
      switch (activeTab) {
        case 1:
          push(`/teams/${teamId}?tab=Activity`)
          setIsActiveTab('Activity')
          markAsRead()
          break
        case 2:
          push(`/teams/${teamId}?tab=Members`)
          setIsActiveTab('Members')
          break
        case 3:
          push(`/teams/${teamId}?tab=RequestLearning`)
          setIsActiveTab('RequestLearning')
          break
        case 4:
          push(`/teams/${teamId}?tab=Requests`)
          setIsActiveTab('Requests')
          break
        case 5:
          push(`/teams/${teamId}?tab=Proposals`)
          setIsActiveTab('Proposals')
          break
        case 6:
          push(`/teams/${teamId}?tab=Approvals`)
          setIsActiveTab('Approvals')
          break
        case 7:
          push(`/teams/${teamId}?tab=Sessions`)
          setIsActiveTab('Sessions')
          break
        case 8:
          push(`/teams/${teamId}?tab=Archive`)
          setIsActiveTab('Archive')
          break
        default:
          push(`/teams/${teamId}?tab=Information`)
          setIsActiveTab('Information')
      }
    } else {
      switch (activeTab) {
        case 1:
          push(`/teams/${teamId}?tab=Activity`)
          setIsActiveTab('Activity')
          markAsRead()
          break
        case 2:
          push(`/teams/${teamId}?tab=RequestLearning`)
          setIsActiveTab('RequestLearning')
          break
        case 3:
          push(`/teams/${teamId}?tab=Requests`)
          setIsActiveTab('Requests')
          break
        case 4:
          push(`/teams/${teamId}?tab=Proposals`)
          setIsActiveTab('Proposals')
          break
        case 5:
          push(`/teams/${teamId}?tab=Approvals`)
          setIsActiveTab('Approvals')
          break
        case 6:
          push(`/teams/${teamId}?tab=Sessions`)
          setIsActiveTab('Sessions')
          break
        case 7:
          push(`/teams/${teamId}?tab=Archive`)
          setIsActiveTab('Archive')
          break
        default:
          push(`/teams/${teamId}?tab=Information`)
          setIsActiveTab('Information')
      }
    }
  }

  const [activityList, setActivityList] = useState<any>([])
  const [openPopup, setOpenPopup] = useState(false)
  const [openEditNamePopup, setOpenEditNamePopup] = useState(false)
  const [notificationIdArray, setNotificationIdArray] = useState<any>([])
  const [notificationCount, setNotificationCount] = useState<number>(0)
  const [reloadRequestList, setReloadRequestList] = useState<boolean>(false)

  const [paginationState, setPaginationState] = useState<{
    page: number
    perPage: number
  }>({
    perPage: 5,
    page: 1,
  })
  const fetchNotifications = (obj: { readonly page?: number; readonly perPage?: number }) => {
    const { page = paginationState.page, perPage = paginationState.perPage } = obj
    fetchRest({
      url: `/notifications`,
      method: 'GET',
      queryParams: {
        teamId,
        perPage,
        page,
      },
    }).then((res: any) => {
      setActivityList(res.data.data)
      setNotificationCount(res.data.count)
      setNotificationIdArray([])
      res.data.data.forEach((each: any) => {
        setNotificationIdArray((prev: any) => {
          return [...prev, each.notificationId]
        })
      })
    })
  }
  const markAsRead = () => {
    fetchRest({
      url: `/readNotification`,
      method: 'PUT',
      data: {
        notificationIds: notificationIdArray,
      },
    }).then((res: any) => {
      console.log(res.data.data)
    })
  }
  const [editImageLoader, setEditImageLoader] = useState<boolean>(false)
  const { uploadFile } = useUploadContext()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const imageHandler = (e: any) => {
    setEditImageLoader(true)
    if (
      e?.target?.files?.[0] &&
      e.target.files[0]?.type?.indexOf('image') !== -1 &&
      e.target.files[0]?.size / (1024 * 1024) <= 3
    ) {
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = async () => {
        if (reader.readyState === 2) {
          setSelectedImage(reader.result as string)
        }
        const multipartFormData = new FormData()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        multipartFormData.append('picture', reader.result as any)
        const file = reader.result as any
        const contentType = file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
        const fileBuffer = Buffer.from(
          file.replace(/^data:image\/[a-zA-Z+]+;base64,/, ''),
          'base64'
        )
        const uploadResult: any = await uploadFile({
          S3_BUCKET: process.env.REACT_APP_TEAM_ASSET_S3 || 'ontelo-dev-team-assets',
          file: fileBuffer,
          key: `${teamId}/team_profile_picture`,
          ACL: 'public-read',
          contentType,
        })
        if (uploadResult.status === 'success') {
          setTeamData((prev: any) => {
            return { ...prev, teamDetails: { ...prev.teamDetails, teamImage: reader.result } }
          })
        }
        // fetchRest(
        //   {
        //     url: `/team/${teamId}/edit`,
        //     method: 'PUT',
        //     data: {
        //       teamImage: reader.result as string,
        //     },
        //     isFullPageLoader: false,
        //   },
        //   'teamDetails'
        // )
        //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
        //   .then((res: any) => {
        //     setTeamData((prev: any) => {
        //       return { ...prev, teamDetails: { ...prev.teamDetails, teamImage: reader.result } }
        //     })
        //   })
        //   .catch((err) => {
        //     console.log(err)
        //   })
      }
    }
  }

  const fetchTeamDetails = () => {
    fetchRest(
      {
        method: 'GET',
        url: `team/${teamId}`,
      },
      'teamDetails'
    )
      .then((res) => {
        setTeamData(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const [memberRole, setMemberRole] = useState('')
  const fetchUserDetails = () => {
    fetchRest(
      {
        url: `team/${teamId}/current-user-info`,
        method: 'GET',
        isFullPageLoader: false,
      },
      'userDetails'
    )
      .then((res) => {
        setMemberRole(res.data.data.memberRole)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const listArrayIsAdmin = [
    {
      listContent: (
        <>
          <div className={styles.elementDropDown}>
            <img src={leave} alt="person icon" className={styles.elementImage} />
            <div>Leave team</div>
          </div>
        </>
      ),
      value: 'Leave Team',
      onClick: (_e: any) => {
        openPopupDialog(false, 'Leave')
      },
    },
    {
      listContent: (
        <>
          <div className={styles.elementDropDown}>
            <img src={deleteMenu} alt="person icon" className={styles.elementImage} />
            <div>Delete team</div>
          </div>
        </>
      ),
      value: 'Delete team',
      onClick: (_e: any) => {
        setOpenDeleteTeamDialog(true)
      },
    },
  ]

  const leaveTeam = () => {
    fetchRest(
      {
        url: `/team/${teamId}/leave-team`,
        method: 'GET',
        isFullPageLoader: true,
      },
      'leaveTeam'
    )
      .then((res: any) => {
        openPopupDialog(true, 'Leave')
      })
      .catch((err: any) => {
        openPopupDialog(false, 'Leave')

        console.log(err)
      })
  }

  const listArray = [
    {
      listContent: (
        <>
          <div className={styles.elementDropDown}>
            <img src={leave} alt="person icon" className={styles.elementImage} />
            <div>Leave team</div>
          </div>
        </>
      ),
      value: 'Leave Team',
      onClick: (_e: any) => {
        leaveTeam()
      },
    },
  ]

  const openPopupDialog = (success: boolean, type?: string) => {
    let headerContent: string = ''
    let content: string = ''
    if (type === 'Leave') {
      if (success) {
        headerContent = t('teams.createTeam.leaveTeam')
        content = t('teams.createTeam.leaveTeamContent')
      }
      if (success === false) {
        headerContent = 'Failed!'
        content = 'Could not leave the team,try again!'
      }
      if (memberRole === 'SuperAdmin') {
        headerContent = t('teams.createTeam.cannotLeave')
        content = t('teams.createTeam.cannotLeaveContent')
      }
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return (
            <img
              src={
                success
                  ? type === 'Leave'
                    ? LeaveTeam
                    : type === 'Delete'
                    ? deleteTeam
                    : RepeatRequest
                  : RepeatRequest
              }
              alt="popup icon"
            />
          )
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.green}
                    text={'Got It'}
                    onClick={() => {
                      close()
                      push(`/teams?tab=MyTeams`)
                      callback('myTeams')
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button
                    text={'Close'}
                    onClick={(e) => {
                      close()
                      if (memberRole === 'SuperAdmin') {
                        push(`/teams/${teamData.teamId}?tab=Members`)
                      }
                    }}
                    className={styles.white}
                  ></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  const changeHandler = (event: any, value: any) => {
    setPaginationState((prev) => ({ ...prev, page: value }))
    fetchNotifications({ page: value, perPage: paginationState.perPage })
  }

  useEffect(() => {
    teamStats()
    fetchTeamDetails()
    fetchNotifications({})
    fetchUserDetails()
    setReloadRequestList(false)
  }, [])
  useEffect(() => {
    let searchParams = new URLSearchParams(search)
    let defaultTab: number = -1
    let searchTab = searchParams.get('tab') ? searchParams.get('tab') : 'Information'
    if (searchTab) {
      setIsActiveTab(searchTab)
    }
    if (memberRole === 'SuperAdmin' || memberRole === 'Admin') {
      if (searchTab === 'Information') {
        defaultTab = 0
      } else if (searchTab === 'Activity') {
        defaultTab = 1
      } else if (searchTab === 'Members') {
        defaultTab = 2
      } else if (searchTab === 'RequestLearning') {
        defaultTab = 3
      } else if (searchTab === 'Requests') {
        defaultTab = 4
      } else if (searchTab === 'Proposals') {
        defaultTab = 5
      } else if (searchTab === 'Approvals') {
        defaultTab = 6
      } else if (searchTab === 'Sessions') {
        defaultTab = 7
      } else if (searchTab === 'Archive') {
        defaultTab = 8
      }
    } else {
      if (searchTab === 'Information') {
        defaultTab = 0
      } else if (searchTab === 'Activity') {
        defaultTab = 1
      } else if (searchTab === 'RequestLearning') {
        defaultTab = 2
      } else if (searchTab === 'Requests') {
        defaultTab = 3
      } else if (searchTab === 'Proposals') {
        defaultTab = 4
      } else if (searchTab === 'Approvals') {
        defaultTab = 5
      } else if (searchTab === 'Sessions') {
        defaultTab = 6
      } else if (searchTab === 'Archive') {
        defaultTab = 7
      }
    }
    setDefaultState(defaultTab)
  }, [search, memberRole])
  return (
    <>
      <div className={styles.background}>
        <div className={styles.center}>
          <div className={styles.wrapper}>
            <div className={styles.flexBody}>
              <div className={styles.imgWrapper}>
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  id="image"
                  style={{ display: 'none' }}
                  onChange={imageHandler}
                />
                <>
                  {sectionLoading && sectionLoading.teamDetails ? (
                    <Loader />
                  ) : // <></>
                  teamData.teamDetails ? (
                    teamData?.teamDetails?.teamImage ? (
                      <img
                        className={styles.userImg}
                        src={teamData?.teamDetails.teamImage}
                        alt="profile"
                      />
                    ) : (
                      <>
                        <div className={`${styles.imgWrapperIcon}`}>
                          <div className={styles.align}>
                            {teamData?.teamDetails.teamName
                              ? `${teamData?.teamDetails.teamName.toUpperCase().charAt(0)}`
                              : ''}
                          </div>
                        </div>
                      </>
                    )
                  ) : (
                    <>
                      <div className={`${styles.imgWrapperIcon}`}>
                        <div className={styles.align}>
                          {teamData.teamName ? `${teamData.teamName.toUpperCase().charAt(0)}` : ''}
                        </div>
                      </div>
                    </>
                  )}
                </>
                <label htmlFor="image">
                  <NoEventsLoader
                    isLoading={sectionLoading && sectionLoading.teamDetails && editImageLoader}
                  >
                    <img className={styles.editImgUploader} src={edit} alt="edit icon" />
                  </NoEventsLoader>
                </label>
              </div>
              <div className={styles.userInfoWrapper}>
                <div className={styles.name}>{teamData?.teamDetails?.teamName}</div>
                {/* <div className={styles.}>{teamData.teamDescription}</div> */}
              </div>
            </div>
            <div className={styles.editTeamName}>
              <img
                src={editIcon}
                alt="edit"
                onClick={() => {
                  setOpenEditNamePopup(true)
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.center}>
          <div className={styles.tabsWrapper}>
            <TabsWrapper
              tabHeaders={
                memberRole === 'SuperAdmin' || memberRole === 'Admin'
                  ? teamsAdditionalTabs
                  : teamTabs
              }
              activeTabValue={defaultState}
              onTabChange={onTabChangeTeams}
            />
            <div className={styles.dropDown}>
              <DropDown
                menuProps={{
                  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                  transformOrigin: { vertical: 'top', horizontal: 'right' },
                }}
                ListArray={memberRole === 'SuperAdmin' ? listArrayIsAdmin : listArray}
              >
                <div className={styles['dropdown-menu']}>
                  <img src={dotMenu} alt="menu" className={styles['dotted-menu']} />
                </div>
              </DropDown>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.background}>
        <div className={styles.center}>
          {isActiveTab === 'Information' ? (
            <InformationTab
              invitationKey={teamData.invitationKey}
              callBack={fetchTeamDetails}
              teamData={teamData}
            />
          ) : (
            <></>
          )}
          {isActiveTab === 'Activity' ? (
            <>
              <div className={styles['membersList-container']}>
                <div className={styles.left}>
                  {activityList.length ? (
                    activityList.map((each: any, key: number) => {
                      return (
                        <>
                          <ActivityListItem key={key} data={each} />
                        </>
                      )
                    })
                  ) : (
                    <></>
                  )}
                  {notificationCount > 5 ? (
                    <div className={styles.pagination_wrapper}>
                      <Pagination
                        page={paginationState.page}
                        onChange={changeHandler}
                        count={Math.ceil(notificationCount / paginationState.perPage)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className={styles.right}>
                  <TeamInviteCode
                    inviteCode={teamData.invitationKey}
                    isAdmin={false}
                    callBack={fetchTeamDetails}
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {isActiveTab === 'Members' ? (
            <TeamMembers
              invitationKey={teamData.invitationKey}
              isAdmin={memberRole === 'SuperAdmin' || memberRole === 'Admin' ? true : false}
              callBack={fetchTeamDetails}
              memberRole={memberRole}
            />
          ) : (
            <></>
          )}
          {isActiveTab === 'RequestLearning' ? (
            <div className={styles['membersList-container']}>
              <div className={styles.left}>
                <RequestLearning teamId={teamId} statsFunction={teamStats} />
              </div>
              <div className={styles.right}>
                <TeamInviteCode
                  inviteCode={teamData.invitationKey}
                  isAdmin={false}
                  callBack={fetchTeamDetails}
                />
                <TeamRecommendCourse teamId={teamId} />
              </div>
            </div>
          ) : (
            <></>
          )}
          {isActiveTab === 'Requests' ? (
            <div className={styles['membersList-container']}>
              <div className={styles.left}>
                <RequestList
                  isTeam={true}
                  teamId={teamId}
                  reloadRequest={reloadRequestList}
                  statsFunction={teamStats}
                />
              </div>
              <div className={styles.right}>
                <TeamInviteCode
                  inviteCode={teamData.invitationKey}
                  isAdmin={false}
                  callBack={fetchTeamDetails}
                />
                <RecommendedCourses
                  teamId={teamId}
                  setReload={() => {
                    setReloadRequestList(true)
                    teamStats()
                  }}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          {isActiveTab === 'Proposals' ? (
            <div className={styles['membersList-container']}>
              <div className={styles.left}>
                <ProposalList
                  teamId={teamId}
                  forApproval={true}
                  statsFunction={teamStats}
                  memberRole={memberRole}
                />
              </div>
              <div className={styles.right}>
                <TeamInviteCode
                  inviteCode={teamData.invitationKey}
                  isAdmin={false}
                  callBack={fetchTeamDetails}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          {isActiveTab === 'Approvals' ? (
            <>
              <div className={styles['membersList-container']}>
                <div className={styles.left}>
                  <ProposalList teamId={teamId} forApproval={false} />
                </div>
                <div className={styles.right}>
                  <TeamInviteCode
                    inviteCode={teamData.invitationKey}
                    isAdmin={false}
                    callBack={fetchTeamDetails}
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {isActiveTab === 'Sessions' ? (
            <div className={styles['membersList-container']}>
              <div className={styles.left}>
                <SessionList teamId={teamId} />
              </div>
              <div className={styles.right}>
                <TeamInviteCode
                  inviteCode={teamData.invitationKey}
                  isAdmin={false}
                  callBack={fetchTeamDetails}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          {isActiveTab === 'Archive' ? (
            <>
              <Archive teamId={teamId} />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <DialogWrapper
        onClose={() => {
          setOpenPopup(false)
        }}
        open={openPopup}
        isFullScreen={false}
        Title={DialogHeader}
      ></DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setOpenEditNamePopup(false)
        }}
        open={openEditNamePopup}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <TeamTitleForm
          closePopup={() => {
            setOpenEditNamePopup(false)
            fetchTeamDetails()
          }}
          isTeam={isTeam}
          teamInfo={teamData}
        />
      </DialogWrapper>
      <DialogWrapper
        Title={DialogHeader}
        open={openDeleteTeamDialog}
        onClose={() => {
          setOpenDeleteTeamDialog(false)
        }}
        isFullScreen={false}
        fullHeightDialog={false}
      >
        <DeleteTeam
          teamInfo={teamData}
          closePopup={() => {
            setOpenDeleteTeamDialog(false)
            callback('myTeams')
          }}
        />
      </DialogWrapper>
    </>
  )
}

export const TeamProfile: React.FC<{ readonly callback: any }> = ({ callback }) => {
  return (
    <>
      <TeamsProfile callback={callback} />
    </>
  )
}
