
/**
 * Do we still need this ?
 * @TODO: Remove the file if not needed !
 */
/* eslint-disable no-use-before-define */
import React, { createContext, useContext, useState } from 'react'
import { listChannelMemberships } from '../../api/ChimeAPI';
import { useUserDetailsContext } from '../../../../common/use-user-details-context'
const OneToOneState = createContext();
const OneToOneProvider = ({ children }) => {
  const { userDetails } = useUserDetailsContext()
  const [otherPerson, setotherPerson] = useState("One to one");
  const getMembershipData = async (channeldata) => {
    const member = {
      userId: localStorage.getItem('identityId'),
      username: userDetails.userId
    }
    const res = await listChannelMemberships(channeldata.ChannelArn, member.userId);
    if (res[0].Member.Name === member.username)
      //setotherPerson(res[1].Member.Name);
      return res[1].Member.Name
    else
      //setotherPerson(res[0].Member.Name)
      return res[0].Member.Name
  }
  const OneToOneValues = {
    otherPerson,
    setotherPerson,
    getMembershipData
  }
  return (
    <OneToOneState.Provider value={OneToOneValues}>
      {children}
    </OneToOneState.Provider>
  )
}
const OneToOneStateService = () => {
  const context = useContext(OneToOneState);

  if (!context) {
    throw new Error('error');
  }

  return context;
};
export { OneToOneStateService, OneToOneProvider }
