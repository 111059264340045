import * as yup from 'yup'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateInput = (schema: yup.AnySchema, value: any) => {
  if (schema) {
    try {
      schema.validateSyncAt('value', { value: value })
      return { errorMsg: '', isValid: true }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const localError: any = error
      return { errorMsg: localError.errors[0], isValid: false }
    }
  }
  return { errorMsg: '', isValid: true }
}
