/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import dotMenu from '../../assets/images/svg/dots-menu.svg'
import closeIcon from '../../assets/images/svg/gray-close.svg'
import repeatRequest from '../../assets/images/svg/repeatRequest.svg'
import editMenu from '../../assets/images/svg/menu-edit.svg'
import timeIcon from '../../assets/images/svg/time-icon.svg'
import redClock from '../../assets/images/svg/red-clock.svg'
import { DropDown, ListArrayItemProps } from '../../common/drop-down/DropDown'
import { NameInitials } from '../name-initials/NameInitials'
import tick from '../../assets/images/svg/gray_tick.svg'
import styles from './SessionListCard.module.scss'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { ProposalDetails } from '../proposal-details/ProposalDetails'
import Moment from 'react-moment'
import { CreateProposal } from '../create-proposal/CreateProposal'
import { WithdrawResponse } from '../withdraw-response/WithdrawResponse'
import { FinishSession } from '../finish-session/FinishSession'
import warningIcon from '../../assets/images/svg/warningIcon.svg'
import moment from 'moment'
import { UsePopupContext } from '../../common/use-popup-context'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { Button } from '../../common/button/Button'
export const SessionListCard: React.FC<{
  data: any
  readonly inStudentDashboard?: boolean
  readonly fetchProposalList?: () => void
  readonly teamId?: string
}> = ({ data, inStudentDashboard = false, fetchProposalList = () => {}, teamId }) => {
  const [openDispute, setOpenDispute] = useState<boolean>(false)
  const [isSessionFinished, setIsSessionFinished] = useState<boolean>(false)
  const [onClickCancel, setOnClickCancel] = useState<boolean>(false)
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const [createPopup, setCreatePopup] = useState(false)
  const now = new Date()
  const sessionEndTime = moment(data?.proposal?.proposalDetail.sessionDate).add(
    data.proposal.proposalDetail.sessionTimeInHour,
    'hours'
  )
  const currentTime = moment(now).format('YYYY-MM-DD HH:MM:SS')
  const sessionEndDate = moment(sessionEndTime).format('YYYY-MM-DD HH:MM:SS')
  const timeOfSession: string = data.sessionIn
  const { close, setData: setPopupData } = UsePopupContext()
  const popup = (message: string, heading: string) => {
    let headerContent: string = heading
    let content: string = message
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={repeatRequest} alt="popup icon" className={styles.widthPopup} />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              <>
                <Button
                  className={styles.green}
                  text={'Got It'}
                  onClick={() => {
                    close()
                    // onClose()
                  }}
                ></Button>
              </>
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  const timeDiff = (time: string) => {
    let x = new Date(time).getTime() - new Date().getTime()
    if (Math.floor(x / (1000 * 60 * 60)) <= -1) {
      return true
    } else {
      return false
    }
  }
  const menuList: ReadonlyArray<ListArrayItemProps> = [
    {
      listContent: (
        <>
          <div className={styles.element}>
            <img src={tick} alt="tick icon" className={styles.elementImage} />
            <div>Finish Session</div>
          </div>
        </>
      ),
      value: 'finish',
      onClick: (_e: any) => {
        // if (currentTime > sessionEndDate) {
        setIsSessionFinished(true)
        // } else {
        //   popup('You cannot finish this session now', 'Your session is not yet completed')
        //   // alert('Cant open')
        // }
      },
    },
    {
      listContent: (
        <>
          <div className={styles.element}>
            <img src={editMenu} alt="edit icon" className={styles.elementImage} />
            <div>Edit Session</div>
          </div>
        </>
      ),
      value: 'Edit',
      onClick: (_e: any) => {
        setCreatePopup(true)
      },
    },
    {
      listContent: (
        <>
          <div className={styles.element}>
            <img src={closeIcon} alt="close" className={styles.elementImage} />
            <div>Cancel Session</div>
          </div>
        </>
      ),
      value: 'Cancel Session',
      onClick: (_e: any) => {
        if (timeOfSession.substring(3) > '1 day') {
          setOnClickCancel(true)
        } else {
          popup(
            'Cannot cancel this session',
            'This session cannot be canceled as it is within next 24hours'
          )
          // popUp('Btn', false)
        }
      },
    },
  ]
  const menuListStudent: ReadonlyArray<ListArrayItemProps> = [
    {
      listContent: (
        <>
          <div className={styles.element}>
            <img src={tick} alt="tick icon" className={styles.elementImage} />
            <div>Finish Session</div>
          </div>
        </>
      ),
      value: 'finish',
      onClick: (_e: any) => {
        // if (currentTime > sessionEndDate) {
        setIsSessionFinished(true)
        // } else {
        //   // popUp('fin', false)
        //   popup('Cannot finish this session', 'This session is not yet finished')
        // }
      },
    },
    {
      listContent: (
        <>
          <div className={styles.element}>
            <img src={closeIcon} alt="close icon" className={styles.elementImage} />
            <div>Cancel Session</div>
          </div>
        </>
      ),
      value: 'Cancel',
      onClick: (_e: any) => {
        if (timeOfSession.substring(3) > '1 day') {
          setOnClickCancel(true)
        } else {
          popup('You cannot cancel the session now!', 'Less than 24 hours to your session!')
          // popUp('Btn', false)
        }
      },
    },
  ]
  const menuListDispute: ReadonlyArray<ListArrayItemProps> = [
    {
      listContent: (
        <>
          <div className={styles.element}>
            <img src={warningIcon} alt="warning icon" className={styles.elementImage} />
            <div>Open a Dispute</div>
          </div>
        </>
      ),
      value: 'OpenDispute',
      onClick: (_e: any) => {
        setOpenDispute(true)
      },
    },
  ]

  return (
    <>
      <div onClick={() => {}} className={styles['list-card']}>
        <div className={styles['list-card-header']}>
          <div className={styles['d-flex']}>
            <div
              className={
                data.status === 'Scheduled'
                  ? `${styles['review-card__align-items']} ${styles['green-label']} ${styles.zeroMinWidth}`
                  : data.status === 'Dispute'
                  ? `${styles['review-card__align-items']} ${styles['red-label']} ${styles.zeroMinWidth}`
                  : `${styles['review-card__align-items']} ${styles['blue-label']} ${styles.zeroMinWidth}`
              }
            >
              <div className={styles['review-card__time-subject']}>{data.status}</div>
            </div>
            {data.status !== 'Dispute' ? (
              <div
                className={
                  timeDiff(data.proposal.proposalDetail.sessionDate)
                    ? `${styles['review-card__align-items']} ${styles['red-patch']}`
                    : styles['review-card__align-items']
                }
              >
                <img
                  src={timeDiff(data.proposal.proposalDetail.sessionDate) ? redClock : timeIcon}
                  alt="time icon"
                  className={styles['review-card__time-icon']}
                />
                <div className={styles['review-card__time-subject']}>{data.sessionIn}</div>
              </div>
            ) : (
              <></>
            )}
          </div>
          {data.status !== 'Dispute' &&
          (data.status === 'Delivered'
            ? !inStudentDashboard
              ? data.traineeCompletionDate && !data.trainerCompletionDate
                ? true
                : false
              : false
            : true) ? (
            <DropDown
              menuProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                transformOrigin: { vertical: 'top', horizontal: 'right' },
              }}
              ListArray={
                inStudentDashboard
                  ? data.status === 'Scheduled'
                    ? data.traineeCompletionDate
                      ? timeDiff(data.proposal.proposalDetail.sessionDate)
                        ? [
                            ...menuListStudent,
                            {
                              listContent: (
                                <>
                                  <div className={styles.element}>
                                    <img
                                      src={warningIcon}
                                      alt="warning icon"
                                      className={styles.elementImage}
                                    />
                                    <div>Open a Dispute</div>
                                  </div>
                                </>
                              ),
                              value: 'OpenDispute',
                              onClick: (_e: any) => {
                                setOpenDispute(true)
                              },
                            },
                          ]
                        : menuListStudent
                      : data.trainerCompletionDate
                      ? [
                          ...menuListDispute,
                          {
                            listContent: (
                              <>
                                <div className={styles.element}>
                                  <img src={tick} alt="tick icon" className={styles.elementImage} />
                                  <div>Finish Session</div>
                                </div>
                              </>
                            ),
                            value: 'finish',
                            onClick: (_e: any) => {
                              setIsSessionFinished(true)
                            },
                          },
                        ]
                      : timeDiff(data.proposal.proposalDetail.sessionDate)
                      ? [
                          ...menuListStudent,
                          {
                            listContent: (
                              <>
                                <div className={styles.element}>
                                  <img
                                    src={warningIcon}
                                    alt="warning icon"
                                    className={styles.elementImage}
                                  />
                                  <div>Open a Dispute</div>
                                </div>
                              </>
                            ),
                            value: 'OpenDispute',
                            onClick: (_e: any) => {
                              setOpenDispute(true)
                            },
                          },
                        ]
                      : menuListStudent
                    : menuListDispute
                  : data.status === 'Scheduled'
                  ? data.trainerCompletionDate
                    ? menuListDispute
                    : data.traineeCompletionDate
                    ? [
                        ...menuListDispute,
                        {
                          listContent: (
                            <>
                              <div className={styles.element}>
                                <img src={tick} alt="tick icon" className={styles.elementImage} />
                                <div>Finish Session</div>
                              </div>
                            </>
                          ),
                          value: 'finish',
                          onClick: (_e: any) => {
                            setIsSessionFinished(true)
                          },
                        },
                      ]
                    : timeDiff(data.proposal.proposalDetail.sessionDate)
                    ? [
                        ...menuList,
                        {
                          listContent: (
                            <>
                              <div className={styles.element}>
                                <img
                                  src={warningIcon}
                                  alt="warning icon"
                                  className={styles.elementImage}
                                />
                                <div>Open a Dispute</div>
                              </div>
                            </>
                          ),
                          value: 'OpenDispute',
                          onClick: (_e: any) => {
                            setOpenDispute(true)
                          },
                        },
                      ]
                    : menuList
                  : data.traineeCompletionDate && !data.trainerCompletionDate
                  ? [
                      {
                        listContent: (
                          <>
                            <div className={styles.element}>
                              <img src={tick} alt="tick icon" className={styles.elementImage} />
                              <div>Finish Session</div>
                            </div>
                          </>
                        ),
                        value: 'finish',
                        onClick: (_e: any) => {
                          // if (currentTime > sessionEndDate) {
                          setIsSessionFinished(true)
                          // } else {
                          //   // popUp('fin', false)
                          //   popup('Cannot finish this session', 'This session is not yet finished')
                          // }
                        },
                      },
                    ]
                  : []
              }
            >
              <div className={styles['dropdown-menu']}>
                <img src={dotMenu} alt="menu" className={styles['dotted-menu']} />
              </div>
            </DropDown>
          ) : (
            <></>
          )}
        </div>
        <div
          className={styles['dotted-menu']}
          onClick={() => {
            setIsPopupOpen(true)
          }}
        >
          <div className={styles['list-card-title']}>
            {data.proposal.proposalDetail.title}
            <div className={styles['list-card-content']}>
              <Moment
                date={data?.proposal?.proposalDetail.sessionDate}
                format="DD MMM"
                interval={0}
              />
              ,
              <Moment
                date={data?.proposal?.proposalDetail.sessionDate}
                format="HH:mm"
                interval={0}
              />
              -
              <Moment
                date={data?.proposal?.proposalDetail.sessionDate}
                format="HH:mm"
                interval={0}
                add={{ hours: data?.proposal?.proposalDetail.sessionTimeInHour }}
              />
            </div>
          </div>

          <div className={styles['review-card__profile']}>
            {!inStudentDashboard ? (
              <div>
                {data.proposal.request.user.imageUrl ? (
                  <img
                    src={data.proposal.request.user.imageUrl}
                    alt="owner"
                    className={styles.icon}
                  />
                ) : (
                  <NameInitials
                    firstName={data.proposal.request.user.firstName}
                    lastName={data.proposal.request.user.lastName}
                    className={styles.icon}
                  />
                )}
              </div>
            ) : (
              <div>
                {data.proposal.owner.imageUrl ? (
                  <img src={data.proposal.owner.imageUrl} alt="owner" className={styles.icon} />
                ) : (
                  <NameInitials
                    firstName={data.proposal.owner.firstName}
                    lastName={data.proposal.owner.lastName}
                    className={styles.icon}
                  />
                )}
              </div>
            )}
            <div className={styles['review-card__star-rating']}>
              <div className={styles['review-card__username']}>
                {!inStudentDashboard
                  ? `${
                      data.proposal.request.user.firstName
                    } ${data.proposal.request.user.lastName.charAt(0)}${'.'}`
                  : `${data.proposal.owner.firstName} ${data.proposal.owner.lastName.charAt(
                      0
                    )}${'.'}`}
              </div>
              <div className={styles['review-card__rating']}>
                {!inStudentDashboard ? 'Attendee' : 'Mentor'}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogWrapper
        onClose={() => {
          setIsPopupOpen(false)
        }}
        open={isPopupOpen}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <ProposalDetails
          objectReceived={data}
          isAttendee={teamId ? true : inStudentDashboard}
          onClose={() => {
            setIsPopupOpen(false)
            fetchProposalList()
          }}
          isSession={true}
          teamId={teamId ? teamId : undefined}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setCreatePopup(false)
        }}
        open={createPopup}
        Title={DialogHeader}
      >
        <CreateProposal
          isEdit={true}
          isSession={true}
          data={{ sessionId: data.sessionId, ...data.proposal }}
          createProposal={() => {
            setCreatePopup(false)
            fetchProposalList()
          }}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setOnClickCancel(false)
        }}
        open={onClickCancel}
        fullHeightDialog={true}
        Title={DialogHeader}
      >
        <WithdrawResponse
          responseId={data?.sessionId}
          inProposal={false}
          onSuccess={() => {
            setOnClickCancel(false)
            fetchProposalList()
          }}
          isSession={true}
          isAttendee={inStudentDashboard}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setIsSessionFinished(false)
          fetchProposalList()
        }}
        open={isSessionFinished}
        Title={DialogHeader}
      >
        <FinishSession
          data={data}
          isAttendee={inStudentDashboard}
          finishSession={() => {
            setIsSessionFinished(false)
            fetchProposalList()
          }}
          teamId={teamId ? teamId : undefined}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setOpenDispute(false)
        }}
        open={openDispute}
        Title={DialogHeader}
      >
        <WithdrawResponse
          responseId={data.sessionId}
          onSuccess={() => {
            setOpenDispute(false)
            fetchProposalList()
          }}
          isSession={false}
          isAttendee={inStudentDashboard}
          openDispute={openDispute}
        />
      </DialogWrapper>
    </>
  )
}
