/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import deleteMenu from '../../assets/images/svg/delete-menu.svg'
import grayEye from '../../assets/images/svg/eye-gray.svg'
import grayClose from '../../assets/images/svg/gray-close.svg'
import editMenu from '../../assets/images/svg/menu-edit.svg'
import respond from '../../assets/images/svg/respond.svg'
import sortFilter from '../../assets/images/svg/sort-icon.svg'
import star from '../../assets/images/svg/star.svg'
import timeIcon from '../../assets/images/svg/time-icon.svg'
import tick from '../../assets/images/svg/whiteTick.svg'
import { Button } from '../../common/button/Button'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import createProposal from '../../assets/images/svg/create_proposal_gray.svg'
import chat from '../../assets/images/svg/chat-connect-gray.svg'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { DropDown } from '../../common/drop-down/DropDown'
import { EmptySection } from '../../common/empty-section/EmptySection'
import { useFetch } from '../../common/https'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import { Loader } from '../../common/Loader/Loader'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import { costSchema } from '../../common/schema/Schema'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { charactersLeft, onKeyDown } from '../../common/utils'
import { WithdrawResponse } from '../../components/withdraw-response/WithdrawResponse'
import { NameInitials } from '../name-initials/NameInitials'
import { RespondentCard } from '../respondent-card/RespondentCard'
import styles from './RequestDetails.module.scss'
import { CreateProposal } from '../create-proposal/CreateProposal'
import { LoadingWrapper } from '../../common/loading-wrapper/LoadingWrapper'
import { useHistory } from 'react-router'
import { ElementsWrapper } from '../../common/elements-wrapper/ElementsWrapper'
import { BankDetailsForm } from '../bank-details-form/BankDetailsForm'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { UsePopupContext } from '../../common/use-popup-context'
import Done from '../../assets/images/png/done.png'
import {
  TeacherChatUserProfile,
  StudentChatUserProfile,
} from '../chat-user-profile/ChatUserProfile'

export const RequestDetails: React.FC<{
  readonly data: any
  readonly openPopup: (value?: string | undefined) => void
  readonly setisOpenDialog: React.Dispatch<React.SetStateAction<boolean>>
  readonly setisEdit: React.Dispatch<React.SetStateAction<boolean>>
  readonly isTeacher?: boolean
  readonly callBack?: (value: boolean) => void
  readonly isDeleted?: boolean
  readonly countries?: any
  readonly statsFunction?: () => void
}> = ({
  data,
  openPopup,
  setisOpenDialog,
  setisEdit,
  statsFunction = () => {},
  isTeacher = false,
  callBack,
  countries,
  isDeleted = false,
}) => {
  const { t } = useTranslation()
  const { fetchRest, sectionLoading } = useFetch({ requestDetails: false, respondent: false })
  const { userDetails } = useUserDetailsContext()
  const [respondentsData, setRespondentsData] = useState([])
  const [filterTag, setFilterTag] = useState('By date')
  const [withdrawOpen, setWithdrawOpen] = useState(false)
  const [onCreateProposal, setOnCreateProposal] = useState(false)
  const { push } = useHistory()
  const [profile, setProfile] = useState(false)
  const [profileDetails, setProfileDetails] = useState({
    isTeacher: false,
    userId: '',
  })

  const setProfileInfo = (userId: string, teacher: boolean) => {
    setProfileDetails({
      isTeacher: teacher,
      userId: userId,
    })
    setProfile(true)
  }
  const [addNewMethod, setAddNewMethod] = useState<boolean>(false)
  const [responseData, setResponseData] = useState<{
    [key: string]: {
      value: string
      isTouched: boolean
      isValid: boolean
      errorMsg: string
    }
  }>({
    description: {
      value: '',
      isValid: false,
      isTouched: false,
      errorMsg: 'Please introduce yourself',
    },
    hourlyRate: {
      value: '0',
      isTouched: false,
      isValid: false,
      errorMsg: 'Please enter the amount',
    },
  })
  const elem = Array.from({ length: 4 }, (_, index) => index + 1)

  const getRequestRespondents = (filterValue?: { orderBy: string; sort: string }) => {
    if (!isTeacher) {
      fetchRest(
        {
          url: `requestRespondents/${data.requestId}`,
          // url: 'requestRespondents/a1b9ba52-5e22-485a-a952-83b77f59d498',
          method: 'GET',
          queryParams: filterValue,
        },
        'respondent'
      )
        .then((res) => {
          if (res && res.data) {
            setRespondentsData(res.data.data)
          } else {
            setRespondentsData([])
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  const listFilter = [
    {
      listContent: (
        <>
          <div className={styles.element}>
            <div>By date</div>
          </div>
        </>
      ),
      value: 'By date',
      onClick: (_e: any) => {
        setFilterTag('By date')
        getRequestRespondents({ orderBy: 'dateCreated', sort: 'DESC' })
      },
    },
    {
      listContent: (
        <>
          <div className={styles.element}>
            <div>By Price</div>
          </div>
        </>
      ),
      value: 'By Price',
      onClick: (_e: any) => {
        setFilterTag('By Price')
        getRequestRespondents({ orderBy: 'hourlyRate', sort: 'DESC' })
      },
    },
    {
      listContent: (
        <>
          <div className={styles.element}>
            <div>By Rating</div>
          </div>
        </>
      ),
      value: 'By Rating',
      onClick: (_e: any) => {
        setFilterTag('By Rating')
      },
    },
  ]

  const hourlyRateChange = (e: any) => {
    if (isNaN(Number(e.value)) || Number(e.value) > 9999) {
      setResponseData((prev) => ({
        ...prev,
        hourlyRate: {
          ...prev.hourlyRate,
          isTouched: true,
        },
      }))
    } else {
      setResponseData((prev) => ({
        ...prev,
        hourlyRate: {
          isTouched: true,
          value: e.value,
          isValid: e.isValid,
          errorMsg: e.errorMsg,
        },
      }))
    }
  }

  const onSubmit = (event: any) => {
    event.preventDefault()
    if (
      responseData.description.isValid &&
      responseData.hourlyRate.isValid &&
      responseData.description.value.trim()
    ) {
      fetchRest(
        {
          url: 'respondToRequest',
          method: 'POST',
          data: {
            requestId: data.requestId,
            description: responseData.description.value,
            hourlyRate: responseData.hourlyRate.value,
          },
          isFullPageLoader: false,
        },
        'requestDetails'
      )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          if (res.status) {
            if (callBack) {
              callBack(true)
            }
            setisOpenDialog(false)
          }
        })
        .catch()
    } else {
      setResponseData((prev: any) => {
        return {
          ...prev,
          description: {
            value: prev.description.value,
            isTouched: true,
            isValid: false,
            errorMsg: prev.description.errorMsg,
          },
        }
      })
    }
  }

  const { close, setData: setPopupData } = UsePopupContext()

  const [url, setUrl] = useState('')

  const popUp = (success: boolean) => {
    let headerContent: string = ''
    let content: string = ''

    if (success) {
      headerContent = 'Onboarding process'
      content = 'Please complete the onboarding process before creating the proposal'
    }

    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={Done} alt="popup icon" className={styles.widthPopup} />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.green}
                    text={'Click here'}
                    onClick={() => {
                      if (userDetails.isTeacher) {
                        fetchRest({
                          url: '/accountOnBoardingCreateLink',
                          method: 'POST',
                          data: {
                            accountId: userDetails.stripeAccountId,
                            stripeAccount: 'UK',
                          },
                          isFullPageLoader: true,
                        })
                          .then((each: any) => {
                            window.location.href = each.data.data.url
                          })
                          .catch((err) => console.log(err))
                      }
                    }}
                  />
                </>
              ) : (
                <>
                  <Button
                    text={'OK'}
                    onClick={(e) => {
                      close()
                    }}
                    className={styles.green}
                  ></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  const onWithdraw = () => {
    setWithdrawOpen(true)
  }

  const onWithdrawSuccess = () => {
    setWithdrawOpen(false)
    if (callBack) {
      callBack(true)
    }
  }

  const onKeyDownCost = (e: any) => {
    if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
      e.preventDefault()
    }
  }

  useEffect(() => {
    getRequestRespondents()
  }, [])

  useEffect(() => {
    setResponseData((prev) => ({
      ...prev,
      hourlyRate: { value: userDetails.hourlyRate, isTouched: false, isValid: true, errorMsg: '' },
    }))
  }, [userDetails])
  return (
    <div>
      <NoEventsLoader isLoading={isDeleted}>
        <div className={styles['list-card']}>
          <div className={styles['list-card-header']}>
            <div className={styles['d-flex']}>
              {data?.response && data?.response?.length > 0 ? (
                <>
                  {data.response && data.response[0] && data.response[0].isAcknowledged ? (
                    <>
                      <div className={styles['request-connect']}>
                        <img src={tick} alt="tick icon" className={styles.divImg} />
                        Connected
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles['request-respond']}>
                        <img src={tick} alt="tick icon" className={styles.divImg} />
                        {t('requestDetails.responded')}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
              <div className={styles['review-card__align-items']}>
                <img src={timeIcon} alt="time icon" className={styles['review-card__time-icon']} />
                <div className={styles['review-card__time-subject']}>Exp in {data.expiresIn}</div>
              </div>
              {isTeacher ? (
                <> </>
              ) : (
                <div className={`${styles.eye} ${styles['review-card__align-items']}`}>
                  <img src={grayEye} alt="eye" className={styles['review-card__time-icon']} />
                  <div className={styles['review-card__time-subject']}> {data.views}</div>
                </div>
              )}
            </div>
          </div>
          <div className={styles['list-card-title']}>
            <span
              dangerouslySetInnerHTML={{
                __html:
                  data?.category != null
                    ? data?.category.name
                    : data?.course != null
                    ? data?.course.title
                    : data?.subCategory != null
                    ? data?.subCategory.name
                    : '',
              }}
            ></span>
          </div>
          <div
            className={styles['list-card-content']}
            dangerouslySetInnerHTML={{ __html: data.description }}
          ></div>
          {isTeacher ? (
            <div
              className={styles['review-card__profile']}
              onClick={() => setProfileInfo(data.user.userId, false)}
            >
              {data.user.imageUrl.length > 0 ? (
                <img src={data.user.imageUrl} alt="user" className={styles.userImage} />
              ) : (
                <div className={styles.icon}>
                  <div className={styles.backgroundImg}>
                    {data.user.firstName && data.user.lastName
                      ? `${data.user.firstName.toUpperCase().charAt(0)}${data.user.lastName
                          .toUpperCase()
                          .charAt(0)}`
                      : ''}
                  </div>
                </div>
              )}
              <div className={styles['review-card__star-rating']}>
                <div className={styles['review-card__username']}>{data.user.firstName}</div>
                <div className={styles['review-card__rating']}>
                  {elem.map((item) => {
                    return (
                      <img
                        src={star}
                        alt="star icons"
                        key={'star' + item}
                        className={styles['review-card__rating-icons']}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles['list-card-footer']}>
              {data.courseId ? (
                <></>
              ) : (
                <Button
                  className={styles['btn-cancel']}
                  text="Edit"
                  onClick={() => {
                    setisEdit(true)
                    setisOpenDialog(true)
                  }}
                  ButtonImg={() => {
                    return <img src={editMenu} alt="edit" className={styles['btn-img']} />
                  }}
                ></Button>
              )}
              <Button
                className={styles['btn-cancel']}
                text="Delete"
                onClick={() => {
                  openPopup()
                }}
                ButtonImg={() => {
                  return (
                    <>
                      {isDeleted ? (
                        <Loader className="form_loader" size={20} thickness={2} />
                      ) : (
                        <>
                          <img src={deleteMenu} alt="delete" className={styles['btn-img']} />
                        </>
                      )}
                    </>
                  )
                }}
              ></Button>
            </div>
          )}
        </div>
      </NoEventsLoader>

      {isTeacher ? (
        <>
          {data.response && data.response.length === 0 ? (
            <>
              <NoEventsLoader isLoading={sectionLoading && sectionLoading.requestDetails}>
                <form onSubmit={onSubmit}>
                  <div className={styles['request-section']}>
                    <div className={styles['list-title-common']}>
                      <img src={respond} alt="respond icon" />
                      {t('requestDetails.response')}
                    </div>
                    <div className={styles['edit-title']}>Cover letter</div>
                    <div className={styles['edit-title-gray']}>{t('requestDetails.introduce')}</div>
                    <div className={styles.text_area_container}>
                      <textarea
                        className={styles['textarea-styles']}
                        value={responseData.description.value}
                        onChange={(e) => {
                          let value = e.target.value
                          let errorMsg: string = ''
                          if (!value.length) {
                            errorMsg = 'Please introduce yourself'
                          } else if (value.length > 5000) {
                            errorMsg = t('common.moreThan5000CharactersNotAllowed')
                          }
                          setResponseData((prev: any) => {
                            return {
                              ...prev,
                              description: {
                                value: value,
                                isValid: value.length > 0 && value.length <= 5000 ? true : false,
                                isTouched: true,
                                errorMsg: errorMsg,
                              },
                            }
                          })
                        }}
                      ></textarea>
                      {!responseData.description.isValid && responseData.description.isTouched ? (
                        <div className={styles.error}>{responseData.description.errorMsg}</div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className={styles['character-count']}>
                      {charactersLeft({
                        currentLength:
                          responseData.description.value && responseData.description.value.length
                            ? responseData.description.value.length
                            : 0,
                      })}
                      {t('common.charactersLeft')}
                    </div>
                    <div className={styles.headingCost}>{t('requestDetails.hourlyRate')}</div>
                    <div className={styles.subHeadingRate}>
                      {t('requestDetails.profileRate')}
                      {responseData.hourlyRate.value}
                      {t('requestDetails.perHour')}
                    </div>
                    <div className={styles.cost}>
                      <div className={styles.wrapperLeft}>
                        <div className={styles.smallField}>
                          <InputWrapper
                            type="text"
                            onChange={() => {}}
                            disabled
                            valueObj={{ value: '$', isTouched: false, isValid: true, errorMsg: '' }}
                            className={styles.dolar}
                          />
                        </div>
                        <div className={styles.halfField}>
                          <InputWrapper
                            type="number"
                            valueObj={responseData.hourlyRate}
                            onChange={hourlyRateChange}
                            schema={costSchema}
                            validateOnChange={true}
                            validateOnBlur={true}
                            onKeyDown={onKeyDownCost}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.buttonLeft}>
                      <Button
                        text="Respond"
                        type="submit"
                        className={styles.buttonClass}
                        ButtonImg={() => {
                          return (
                            <>
                              {sectionLoading && sectionLoading.requestDetails ? (
                                <Loader className="form_loader" size={20} thickness={2} />
                              ) : (
                                <>
                                  <img src={tick} alt="tick icon" className={styles.divImg} />
                                </>
                              )}
                            </>
                          )
                        }}
                      />
                    </div>
                  </div>
                </form>
              </NoEventsLoader>
            </>
          ) : (
            <div className={styles['request-section']}>
              <div className={styles['list-title-common']}>
                <img src={respond} alt="respond icon" />
                {t('requestDetails.responded')}
              </div>
              <div className={styles['list-card-title']}>${data.response[0].hourlyRate}/hr</div>
              <div className={styles['list-card-content']}>{data.response[0].description}</div>
              <div
                className={styles['review-card__profile']}
                onClick={() => setProfileInfo(data.response[0].user.userId, true)}
              >
                {data?.response?.[0]?.user?.imageUrl?.length > 0 ? (
                  <img
                    src={data.response[0].user.imageUrl}
                    alt="user"
                    className={styles.userImage}
                  />
                ) : (
                  <NameInitials
                    className={styles.initials_container}
                    firstName={
                      data?.response?.[0]?.user?.firstName ? data.response[0].user.firstName : ''
                    }
                    lastName={
                      data?.response?.[0]?.user?.lastName ? data.response[0].user.lastName : ''
                    }
                  />
                )}
                <div className={styles['review-card__star-rating']}>
                  <div className={styles['review-card__username']}>
                    {data?.response?.[0]?.user?.firstName}
                  </div>
                  {data?.response?.[0]?.createdOn.length > 0 ? (
                    <div className={styles['review-card__timer']}>
                      {data?.response?.[0]?.createdOn} {t('requestDetails.ago')}
                    </div>
                  ) : (
                    <div className={styles['review-card__timer']}>Just now</div>
                  )}
                </div>
              </div>
              <div className={styles.btnWrapper}>
                {data.proposal && data.proposal.length > 0 ? (
                  <></>
                ) : (
                  <div className={styles.buttonLeft1}>
                    <Button
                      text="Withdraw response"
                      className={styles.buttonWithdraw}
                      ButtonImg={() => {
                        return (
                          <>
                            <img src={grayClose} alt="close icon" className={styles.divImg} />
                          </>
                        )
                      }}
                      onClick={onWithdraw}
                    />
                  </div>
                )}
                <>
                  {data.response[0].isAcknowledged ? (
                    <>
                      <div className={styles.buttonLeft1}>
                        <Button
                          text="Create Proposal"
                          className={styles.buttonWithdraw}
                          ButtonImg={() => {
                            return (
                              <>
                                <img
                                  src={createProposal}
                                  alt="create proposal icon"
                                  className={styles.divImg}
                                />
                              </>
                            )
                          }}
                          onClick={() => {
                            let supported = false
                            if (countries.length > 0) {
                              countries.forEach((each: any) => {
                                if (each === userDetails.countryCode) {
                                  supported = true
                                }
                              })
                            }
                            if (
                              supported &&
                              userDetails.account.capabilities.transfers === 'inactive'
                            ) {
                              popUp(true)
                            } else if (
                              userDetails.account &&
                              userDetails.account.external_accounts &&
                              userDetails.account.external_accounts.total_count <= 0
                            ) {
                              setAddNewMethod(true)
                            } else {
                              if (data?.proposal?.length > 0) {
                                openPopup('PROPOSAL_EXIST')
                              } else {
                                setOnCreateProposal(true)
                              }
                            }
                          }}
                        />
                      </div>
                      <div className={styles.buttonLeft1}>
                        <Button
                          text="Open Messages"
                          className={styles.buttonWithdraw}
                          ButtonImg={() => {
                            return (
                              <>
                                <img
                                  src={chat}
                                  alt="Open Messages icon"
                                  className={styles.divImg}
                                />
                              </>
                            )
                          }}
                          onClick={() => {
                            isTeacher ? push('/chat?type=mentor') : push('/chat?type=learn')
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className={styles['request-section']}>
          <div className={styles['list-title']}>
            {t('requestDetails.responses')}
            <span className={styles['color-blue']}>&nbsp;{respondentsData.length}</span>
          </div>
          {respondentsData.length === 0 && !(sectionLoading && sectionLoading.respondent) ? (
            <>
              <EmptySection subText="You have yet to receive any responses" />
            </>
          ) : (
            <>
              <DropDown
                menuProps={{
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  transformOrigin: { vertical: 'top', horizontal: 'left' },
                }}
                ListArray={listFilter}
              >
                <div className={styles['list-filter']}>
                  <img src={sortFilter} alt="sort" /> {filterTag}
                </div>
              </DropDown>
            </>
          )}
        </div>
      )}
      <LoadingWrapper
        isLoading={sectionLoading && sectionLoading.respondent}
        LoaderComponent={Loader}
      >
        {respondentsData.map((item: any) => {
          return (
            <div key={item.requestId}>
              <RespondentCard item={item} />
            </div>
          )
        })}
      </LoadingWrapper>
      <DialogWrapper
        onClose={() => {
          setWithdrawOpen(false)
        }}
        open={withdrawOpen}
        Title={DialogHeader}
      >
        <WithdrawResponse
          responseId={data?.response?.[0]?.responseId ? data?.response?.[0]?.responseId : ''}
          onSuccess={onWithdrawSuccess}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setOnCreateProposal(false)
        }}
        open={onCreateProposal}
        Title={DialogHeader}
      >
        <CreateProposal
          isEdit={false}
          statsFunction={statsFunction}
          createProposal={() => {
            setOnCreateProposal(false)
            if (callBack) callBack(true)
            setisOpenDialog(false)
          }}
          data={isTeacher && data.proposal.length > 0 ? data.proposal[0] : data}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setAddNewMethod(false)
        }}
        open={addNewMethod}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <ElementsWrapper>
          <BankDetailsForm
            popupClose={() => {
              setAddNewMethod(false)
              if (callBack) {
                callBack(true)
              }
            }}
          />
        </ElementsWrapper>
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setProfile(false)
        }}
        open={profile}
        Title={DialogHeader}
      >
        {profileDetails.isTeacher ? (
          <TeacherChatUserProfile isChat={false} userId={profileDetails.userId} />
        ) : (
          <StudentChatUserProfile isChat={false} userId={profileDetails.userId} />
        )}
      </DialogWrapper>
    </div>
  )
}
