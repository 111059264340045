import React from 'react'
import { useTranslation } from 'react-i18next'
import EmptySectionImage from '../../assets/images/png/empty_section.png'
import styles from './EmptySection.module.scss'

interface EmptySectionProps {
  readonly imageSource?: string
  readonly text?: string
  readonly subText: string
  readonly Button?: React.FC
}

export const EmptySection: React.FC<EmptySectionProps> = (props) => {
  const { t } = useTranslation()
  const { imageSource = EmptySectionImage, text = t('common.emptyHere'), subText, Button } = props
  return (
    <div className={styles.empty_container}>
      <img src={imageSource} alt="empty" />
      <div className={styles.empty_text}>{text}</div>
      <div className={styles.sub_text}>{subText}</div>
      <div className={styles.marginTop}>{Button ? <Button /> : <></>}</div>
    </div>
  )
}
