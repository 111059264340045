/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, MouseEventHandler, useEffect, useState } from 'react'
import { validateInput } from '../ValidateFields'
import styles from './InputWrapper.module.scss'

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  readonly validateOnBlur?: boolean
  readonly validateOnChange?: boolean
  readonly inputIcon?: string
  readonly onChange: any
  readonly schema?: any
  readonly onBlur?: any
  readonly iconClick?: MouseEventHandler<HTMLImageElement> | undefined
  readonly valueObj: {
    value: string
    isTouched: boolean
    isValid: boolean
    errorMsg: string
  }
  readonly value?: any
}

export const InputWrapper: React.FC<InputProps> = ({
  onChange,
  onBlur,
  value,
  type,
  className,
  validateOnBlur,
  validateOnChange,
  inputIcon,
  iconClick = () => {},
  schema,
  valueObj,
  ...rest
}) => {
  const [localValue, setLocalValue] = useState({
    value: valueObj.value,
    errorMsg: valueObj.errorMsg,
    isValid: valueObj.isValid,
    isTouched: valueObj.isTouched,
  })

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    let input: string = type === 'number' ? localValue.value : e.target.value
    const re = /^\d+(\.\d{1,2})?$/
    if (type === 'number' && (e.target.value === '' || re.test(e.target.value))) {
      input = e.target.value
      if (input.length > 1) {
        input = input.replace(/^0+/, '')
      }
    }
    let inputState: { errorMsg: string; isValid: boolean } = { errorMsg: '', isValid: true }
    if (validateOnChange && schema) {
      inputState = validateInput(schema, input)
    }
    setLocalValue({ value: input, isTouched: true, ...inputState })
    onChange({ value: input, ...inputState })
  }

  const onBlurHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value
    let inputState: { errorMsg: string; isValid: boolean } = { errorMsg: '', isValid: true }
    if (validateOnBlur && schema) {
      inputState = validateInput(schema, input)
      setLocalValue((prev) => ({ ...prev, isTouched: true, ...inputState }))
    }
    setLocalValue((prev) => ({ ...prev, isTouched: true }))
    if (onBlur) {
      onBlur({ value: input, ...inputState })
    }
  }

  useEffect(() => {
    let inputState: { errorMsg: string; isValid: boolean } = validateInput(schema, valueObj.value)
    setLocalValue({ ...valueObj, ...inputState })
  }, [valueObj])

  return (
    <div className={styles['input-wrapper']}>
      <input
        onChange={onChangeHandler}
        value={localValue.value}
        onBlur={onBlurHandler}
        autoComplete="new-password"
        type={type}
        {...rest}
        className={
          className ? `${styles['common-input-style']} ${className}` : styles['common-input-style']
        }
      />
      {inputIcon ? (
        <img
          src={inputIcon}
          alt="input icon"
          onClick={iconClick}
          className={styles['input-icon']}
        />
      ) : (
        ''
      )}
      {!localValue.isValid && localValue.isTouched ? (
        <div className={styles['error-msg']}>{localValue.errorMsg}</div>
      ) : (
        ''
      )}
    </div>
  )
}
