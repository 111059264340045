import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import React, { useEffect, useState } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { LoaderProvider } from './common/loader-provider/LoaderProvider'
import { PopupProvider } from './common/popup-provider/PopupProvider'
import { UploadProvider } from './common/upload-provider/UploadProvider'
import { UserDetailsProvider } from './common/user-details-provider/UserDetailsProvider'
import { UserInfoProvider } from './common/user-info-provider/UserInfoProvider'
import { UserStatusProvider } from './common/user-status-provider/UserStatusProvider'
import { UserUnreadInfoProvider } from './common/user-unread-info-provider/UserUnreadInfoProvider'
import {
  StudentChatUserProfile,
  TeacherChatUserProfile,
} from './components/chat-user-profile/ChatUserProfile'
import { ProtectedChatWindow } from './components/chat-window/ChatWindow'
import { MessagingProvider } from './components/chat-window/providers/ChatMessagesProvider'
import { OneToOneProvider } from './components/chat-window/providers/OneToOneProvider'
import { ScrollTop } from './components/scrollTop/ScrollTop'
import { ForgetPassword } from './pages/forget-password/ForgetPassword'
import { HelpCenter } from './pages/help-center/HelpCenter'
import { Login } from './pages/login/Login'
import { SignUp } from './pages/sign-up/SignUp'
import { ProtectedStudentDashboard } from './pages/student-dashboard/StudentDashboard'
import { ProtectedTeacherDashboard } from './pages/teacher-dashboard/TeacherDashboard'
import { ProtectedTeamsDashboard } from './pages/teams-dashboard/TeamsDashboard'
import { ProtectedUserProfile } from './pages/user-profile/UserProfile'

const routes: ReadonlyArray<{
  readonly path: string
  readonly component: React.FC
  readonly exact?: boolean
}> = [
  {
    path: '/sign-up',
    component: SignUp,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/learn',
    component: ProtectedStudentDashboard,
  },
  {
    path: '/mentor',
    component: ProtectedTeacherDashboard,
  },
  {
    path: '/user-profile',
    component: ProtectedUserProfile,
  },
  {
    path: '/chat',
    component: ProtectedChatWindow,
  },
  {
    path: '/teacher',
    component: TeacherChatUserProfile,
  },
  {
    path: '/teams',
    component: ProtectedTeamsDashboard,
  },
  {
    path: '/student',
    component: StudentChatUserProfile,
  },
  {
    path: '/forgot-password',
    component: ForgetPassword,
  },
  {
    path: '/help-center',
    component: HelpCenter,
  },
  {
    path: '/',
    component: Login,
  },
]

export const App: React.FC = () => {
  const pathname = window.location.pathname
  const baseRedirectionUrl = 'https://demo.divami.com/ontelo-static/'

  const baseUrl = process.env.REACT_APP_LANDING_REDIRECT_URL
    ? process.env.REACT_APP_LANDING_REDIRECT_URL
    : baseRedirectionUrl

  const [width, setWidth] = useState(() => {
    if (typeof window !== 'undefined') return window.innerWidth
    else return 1200
  })
  const updateDimensions = () => {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateDimensions)
      return () => window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  useEffect(() => {
    if (
      window.innerWidth <= 768 &&
      pathname !== '/ontelo/sign-up' &&
      pathname !== '/ontelo/login'
    ) {
      window.location.href = `${baseUrl}desktop-warning`
    }
  }, [window.innerWidth, width])

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <LoaderProvider>
          <PopupProvider>
            {/* <MessagingProvider>
              <OneToOneProvider> */}
            <BrowserRouter
              basename={
                process.env.REACT_APP_BASE_NAME ? process.env.REACT_APP_BASE_NAME : '/ontelo/'
              }
            >
              <UserDetailsProvider>
                <UserInfoProvider>
                  <UploadProvider>
                    <UserUnreadInfoProvider>
                      <UserStatusProvider>
                        <MessagingProvider>
                          <OneToOneProvider>
                            <ScrollTop />
                            <Switch>
                              <Route
                                path="/"
                                exact={true}
                                component={() => <Redirect to="/learn?tab=requestLearning" />}
                              />
                              {routes.map((each, index) => (
                                <Route key={index} {...each} />
                              ))}
                              <Route path="*" component={() => <Redirect to="/login" />} />
                            </Switch>
                          </OneToOneProvider>
                        </MessagingProvider>
                      </UserStatusProvider>
                    </UserUnreadInfoProvider>
                  </UploadProvider>
                </UserInfoProvider>
              </UserDetailsProvider>
            </BrowserRouter>
            {/* </OneToOneProvider>
            </MessagingProvider> */}
          </PopupProvider>
        </LoaderProvider>
      </MuiPickersUtilsProvider>
    </>
  )
}
