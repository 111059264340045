/* eslint-disable @typescript-eslint/no-explicit-any */
import Pagination from '@material-ui/lab/Pagination'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import grayStar from '../../assets/images/svg/gray-star.svg'
import PopUpIcon from '../../assets/images/svg/popup-icon.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import sortFilter from '../../assets/images/svg/sort-icon.svg'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { Loader } from '../../common/Loader/Loader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { DropDown } from '../../common/drop-down/DropDown'
import { useFetch } from '../../common/https'
import { LoadingWrapper } from '../../common/loading-wrapper/LoadingWrapper'
import { RequestListCard } from '../../common/request-list-card/RequestListCard'
import { UsePopupContext } from '../../common/use-popup-context'
import { Button } from '../../common/button/Button'
import { CourseDetails } from '../course-details/CourseDetails'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { EmptySection } from '../../common/empty-section/EmptySection'
import { SessionCard } from '../session-card/SessionCard'
import styles from './RequestList.module.scss'

export const RequestList: React.FC<{
  readonly isTeam?: boolean
  readonly teamId?: string
  readonly reloadRequest?: boolean
  readonly statsFunction?: () => void
}> = ({ isTeam, teamId, reloadRequest = false, statsFunction = () => {} }) => {
  const { t } = useTranslation()
  const { fetchRest, sectionLoading } = useFetch({
    recommendations: true,
    requestList: true,
    submitRequest: false,
  })
  const { close, setData: setPopupData } = UsePopupContext()

  const [count, setCount] = useState(0)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [requestList, setRequestList] = useState<ReadonlyArray<any>>([])
  const [filterTag, setFilterTag] = useState('By date')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [userRecommendation, setUserRecommendation] = useState<
    ReadonlyArray<{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any
    }>
  >([])
  const [paginationState, setPaginationState] = useState<{
    readonly perPage: number
    readonly page: number
  }>({
    perPage: 3,
    page: 1,
  })

  const [selectedCourse, setSelectedCourse] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }>({})
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const { push } = useHistory()
  const listFilter = [
    {
      listContent: (
        <>
          <div className={styles.element}>
            <div>By date</div>
          </div>
        </>
      ),
      value: 'By date',
      onClick: (_e: any) => {
        setFilterTag('By date')
        getRequestList({ orderBy: 'dateCreated', sort: 'DESC', page: 1, perPage: 3 })
      },
    },
    {
      listContent: (
        <>
          <div className={styles.element}>
            <div>Expires soon</div>
          </div>
        </>
      ),
      value: 'Expires soon',
      onClick: (_e: any) => {
        setFilterTag('Expires soon')
        getRequestList({ orderBy: 'dateCreated', sort: 'ASC', page: 1, perPage: 3 })
      },
    },
    {
      listContent: (
        <>
          <div className={styles.element}>
            <div>Answers qnt</div>
          </div>
        </>
      ),
      value: 'Answers qnt',
      onClick: (_e: any) => {
        setFilterTag('Answers qnt')
        getRequestList({ orderBy: 'responseCount', sort: 'ASC', page: 1, perPage: 3 })
      },
    },
  ]

  const getRequestList = (filterValue?: {
    orderBy?: string
    sort?: string
    page?: number
    perPage?: number
  }) => {
    if (filterValue === undefined) {
      filterValue = {
        perPage: paginationState.perPage,
        page: paginationState.page,
      }
    }
    fetchRest(
      {
        url: 'userRequest',
        method: 'GET',
        queryParams: teamId ? { ...filterValue, teamId: teamId } : filterValue,
      },
      'requestList'
    )
      .then((res) => {
        if (res && res.data) {
          setRequestList(res.data.data)
          setCount(res.data.count)
        } else {
          setRequestList([])
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const getUserRecommendations = () => {
    fetchRest({ url: 'recommendedCourse', method: 'GET' }, 'recommendations')
      .then((res: any) => {
        if (res && res.data && res.data.data) {
          setUserRecommendation(res.data.data)
        } else {
          setUserRecommendation([])
        }
      })
      .catch()
  }

  const onCallBack = (value: boolean) => {
    if (value) {
      getRequestList()
      statsFunction()
    }
  }
  useEffect(() => {
    getRequestList()
  }, [reloadRequest])
  const onChangeHandler = (event: any, value: any) => {
    setPaginationState((prev) => ({ ...prev, page: value }))
    getRequestList({
      page: value,
      perPage: paginationState.perPage,
    })
  }

  const closeDialog = () => {
    setIsDialogOpen(false)
    setTimeout(() => {
      setSelectedCourse({})
    }, 200)
  }
  const openPopup = (success: boolean) => {
    let content: string = ''
    let headerContent: string = ''
    if (success) {
      headerContent = 'Great, you’ve submitted your learning request!'
      content = 'Now sit tight, one of the ontelo experts will be in touch with you soon'
    } else {
      headerContent = 'Something went wrong'
      content =
        'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? PopUpIcon : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.white}
                    text={'Got It'}
                    onClick={() => {
                      close()
                      setIsDialogOpen(false)
                      getRequestList()
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button
                    text={'Cancel'}
                    className={styles['cancel-btn']}
                    onClick={() => {
                      close()
                    }}
                  ></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  const onSubmitRequest = () => {
    // Do API Call here
    fetchRest(
      {
        url: '/userSubmitRequest',
        method: 'POST',
        data: {
          description: selectedCourse.title,
          courseId: selectedCourse.courseId,
        },
      },
      'submitRequest'
    )
      .then((_res) => {
        openPopup(true)
        statsFunction()
      })
      .catch((err) => {
        openPopup(false)
      })
  }

  useEffect(() => {
    if (!teamId) {
      getUserRecommendations()
    }
  }, [])
  useEffect(() => {
    getRequestList()
  }, [reloadRequest])
  return (
    <>
      <div className={styles.wrapper}>
        <div
          className={
            isTeam ? `${styles['request-section']} ${styles.mTop}` : styles['request-section']
          }
        >
          <div className={styles['list-title']}>
            {t('requestDetails.activeRequest')}
            <span className={styles['color-blue']}>&nbsp;{count}</span>
          </div>
          {count ? (
            <DropDown
              menuProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                transformOrigin: { vertical: 'top', horizontal: 'left' },
              }}
              ListArray={listFilter}
            >
              <div className={styles['list-filter']}>
                <img src={sortFilter} alt="sort" /> {filterTag}
              </div>
            </DropDown>
          ) : (
            <></>
          )}
        </div>

        <div className={styles['request-lists']}>
          <div className={styles['request-lists-left']}>
            <LoadingWrapper
              isLoading={sectionLoading && sectionLoading.requestList}
              LoaderComponent={Loader}
            >
              {requestList && requestList.length ? (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                requestList.map((item: any) => {
                  return (
                    <div key={item.requestId}>
                      <RequestListCard data={item} callback={onCallBack} />
                    </div>
                  )
                })
              ) : (
                <> </>
              )}
              {count ? (
                <>
                  {Math.ceil(count / paginationState.perPage) > 1 ? (
                    <div className={styles.pagination}>
                      <Pagination
                        count={Math.ceil(count / paginationState.perPage)}
                        onChange={onChangeHandler}
                        page={paginationState.page}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <EmptySection
                    subText="you have no requests pending - want to learn something, find your mentor "
                    Button={() => {
                      return (
                        <Button
                          className={` ${styles.padding} ${styles.white}`}
                          text="Find your mentor"
                          onClick={(e: any) => push('/learn?tab=requestLearning')}
                        />
                      )
                    }}
                  />
                </>
              )}
            </LoadingWrapper>
          </div>
          {isTeam ? (
            <></>
          ) : (
            <div className={styles['request-lists-right']}>
              <div className={styles['review-card__align-items']}>
                <img src={grayStar} alt="star" className={styles['review-card__time-icon']} />
                <div className={styles['time-subject']}>{t('requestDetails.recommendations')}</div>
              </div>
              <LoadingWrapper
                isLoading={sectionLoading && sectionLoading.recommendations}
                LoaderComponent={Loader}
              >
                {userRecommendation.map((item) => {
                  return (
                    <SessionCard
                      imageUrl={item.imageUrl}
                      key={item.courseId}
                      header={item.title}
                      timer={item.duration}
                      onCardClick={() => {
                        setSelectedCourse(item)
                        setIsDialogOpen(true)
                      }}
                    />
                  )
                })}
              </LoadingWrapper>
            </div>
          )}
        </div>
      </div>
      <DialogWrapper
        Title={(titleProps) => {
          return <DialogHeader {...titleProps} />
        }}
        open={isDialogOpen}
        onClose={closeDialog}
        isFullScreen={false}
      >
        <CourseDetails
          isRequestSubmitting={sectionLoading && sectionLoading.submitRequest}
          selectedCourse={selectedCourse}
          onSubmitRequest={onSubmitRequest}
        />
      </DialogWrapper>
    </>
  )
}
