/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Search } from '../../common/search/Search'
import { NameInitials } from '../name-initials/NameInitials'
import styles from './TeamsTabArchive.module.scss'
import pinkStar from '../../assets/images/svg/pinkStar.svg'
import star from '../../assets/images/svg/star.svg'
import { DropDown, ListArrayItemProps } from '../../common/drop-down/DropDown'
import sortIcon from '../../assets/images/svg/sort-icon.svg'
import { useTranslation } from 'react-i18next'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { ProposalDetails } from '../proposal-details/ProposalDetails'
import { useFetch } from '../../common/https'
import { EmptySection } from '../../common/empty-section/EmptySection'
import { Loader } from '../../common/Loader/Loader'
import Moment from 'react-moment'
import { Pagination } from '@material-ui/lab'

const ListForSessions: React.FC<{ data: any }> = ({ data }) => {
  const [popupOpen, setPopupOpen] = useState<boolean>(false)
  const price =
    Number(data.proposal.proposalDetail.hourlyRate) *
    Number(data.proposal.proposalDetail.sessionTimeInHour)
  const handleClose = () => {
    setPopupOpen(false)
  }
  return (
    <div className={`${styles.dFlex} ${styles.border}`}>
      <div
        className={styles.userDetails}
        onClick={() => {
          setPopupOpen(true)
        }}
      >
        <div className={styles.icon}>
          {data?.proposal?.owner.imageUrl ? (
            <img src={data?.proposal?.owner.imageUrl} alt="owner" />
          ) : (
            <NameInitials
              firstName={data?.proposal?.owner.firstName}
              lastName={data?.proposal?.owner.lastName}
            />
          )}
        </div>
        <div>
          <div className={styles.name}>
            {data?.proposal?.owner.firstName}
            {''}
            {data?.proposal?.owner.lastName}
          </div>
          <div className={styles.desc}>Creator</div>
          {/* yet to  be tested */}
        </div>
      </div>
      <div
        className={styles.courseDetails}
        onClick={() => {
          setPopupOpen(true)
        }}
      >
        <div className={styles.courseTitle}>{data?.proposal?.proposalDetail.title}</div>
        <div className={styles.desc}>
          <Moment date={data?.proposal?.proposalDetail.sessionDate} format="DD MMM" interval={0} />
          ,
          <Moment date={data?.proposal?.proposalDetail.sessionDate} format="HH:mm" interval={0} /> -
          <Moment
            date={data?.proposal?.proposalDetail.sessionDate}
            format="HH:mm"
            interval={0}
            add={{
              hours: data?.proposal?.proposalDetail.sessionTimeInHour,
            }}
          />
          , costed ${price}
        </div>
      </div>
      <div
        onClick={() => {
          setPopupOpen(true)
        }}
      >
        {data?.reviews?.length ? (
          <div className={styles.rating}>
            {Array(Number(0))
              .fill('')
              .map((x, i) => {
                return <img src={pinkStar} alt="rating" key={i} className={styles.star} />
              })}
            {Array(5 - Number(0))
              .fill('')
              .map((x, i) => {
                return <img src={star} alt="rating" key={i} className={styles.star} />
              })}
          </div>
        ) : (
          <></>
        )}
        <div className={styles.desc}>
          Completed on
          <Moment
            date={data?.proposal?.proposalDetail.sessionDate}
            format="HH:mm"
            interval={0}
            add={{
              hours: data?.proposal?.proposalDetail.sessionTimeInHour,
            }}
          />
        </div>
      </div>
      <DialogWrapper
        Title={DialogHeader}
        open={popupOpen}
        onClose={handleClose}
        isFullScreen={false}
        fullHeightDialog={false}
      >
        <ProposalDetails
          objectReceived={data}
          inArchive={true}
          isAttendee={true}
          teamId={data.proposal.request.teamId}
          onClose={() => {
            setPopupOpen(false)
          }}
          isSession={true}
        />
      </DialogWrapper>
    </div>
  )
}
export const Archive: React.FC<{ readonly teamId: string }> = ({ teamId }) => {
  const [countCompletedSession, setCountCompletedSession] = useState<number>(10)
  const [countCancelledSession, setCountCancelledSession] = useState<number>(10)
  const [paginationStateCompleted, setPaginationStateCompleted] = useState<{
    page: number
    perPage: number
  }>({
    perPage: 10,
    page: 1,
  })
  const [paginationStateCancelled, setPaginationStateCancelled] = useState<{
    page: number
    perPage: number
  }>({
    perPage: 10,
    page: 1,
  })

  const { fetchRest, sectionLoading } = useFetch({
    completedSession: false,
    cancelledSession: false,
  })
  const { t } = useTranslation()
  const [cancelledSessionList, setCancelledSessionList] = useState([])
  const [completedSessionsList, setCompletedSessionsList] = useState([])
  const [dropdownTag, setDropdownTag] = useState({
    index: 0,
    value: 'dateCreated',
    label: 'By Date',
    sort: 'DESC',
  })

  const setSort = (variantIndex: number) => {
    if (variantIndex === 1) {
      setDropdownTag({ index: 1, value: 'dateCreated', label: 'Expires Soon', sort: 'ASC' })
    } else {
      setDropdownTag({ index: 0, value: 'dateCreated', label: 'By Date', sort: 'DESC' })
    }
  }
  const listDropDown: ReadonlyArray<ListArrayItemProps> = [
    {
      listContent: (
        <>
          <div className={styles.element}>
            <div>{t('teacher_dashboard.byDate')}</div>
          </div>
        </>
      ),
      value: 'dateCreated',
      onClick: (_e) => {
        setSort(0)
      },
    },
    {
      listContent: (
        <>
          <div className={styles.element}>
            <div>{t('teacher_dashboard.expiresSoon')}</div>
          </div>
        </>
      ),
      value: 'expiresSoon',
      onClick: (_e) => {
        setSort(1)
      },
    },
  ]
  useEffect(() => {
    fetchCompletedSessions({})
    fetchCancelledSessions({})
    setCountCompletedSession(completedSessionsList.length)
    setCountCancelledSession(cancelledSessionList.length)
  }, [])
  const changeHandlerCancelled = (event: any, value: any) => {
    setPaginationStateCancelled((prev) => ({ ...prev, page: value }))
    fetchCancelledSessions({ page: value, perPage: paginationStateCancelled.perPage })
  }
  const changeHandlerCompleted = (event: any, value: any) => {
    setPaginationStateCompleted((prev) => ({ ...prev, page: value }))
    fetchCompletedSessions({ page: value, perPage: paginationStateCompleted.perPage })
  }
  const fetchCompletedSessions = (obj: {
    readonly page?: number
    readonly perPage?: number
    readonly status?: string
  }) => {
    const {
      page = paginationStateCompleted.page,
      perPage = paginationStateCompleted.perPage,
      status = dropdownTag.value,
    } = obj
    fetchRest(
      {
        url: `/session/team`,
        method: 'GET',
        queryParams: {
          status: 'Delivered',
          page,
          perPage,
          teamId,
        },
      },
      'completedSession'
    ).then((res: any) => {
      setCompletedSessionsList(res.data.data)
      setCountCompletedSession(res.data.count)
    })
  }
  const fetchCancelledSessions = (obj: {
    readonly page?: number
    readonly perPage?: number
    readonly status?: string
  }) => {
    const {
      page = paginationStateCancelled.page,
      perPage = paginationStateCancelled.perPage,
      status = dropdownTag.value,
    } = obj
    fetchRest(
      {
        url: `/session/team`,
        method: 'GET',
        queryParams: {
          status: 'Cancelled',
          page,
          perPage,
          teamId,
        },
      },
      'cancelledSession'
    ).then((res: any) => {
      setCancelledSessionList(res.data.data)
      setCountCancelledSession(res.data.count)
    })
  }
  return (
    <div>
      <div className={styles.contentWrapper}>
        <div className={styles.heading}>Completed sessions : {countCompletedSession}</div>
        <div className={styles.search}>
          <Search
            type={'text'}
            placeholder={'Search proposal or attendee name'}
            onChange={() => {}}
          />
        </div>
        <div className={styles.dropdown_container}>
          <DropDown
            menuProps={{
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              transformOrigin: { vertical: 'top', horizontal: 'left' },
            }}
            ListArray={listDropDown}
            selectedItem={dropdownTag.index}
          >
            <div className={styles['list-filter']}>
              <img src={sortIcon} alt="sort" /> {dropdownTag.label}
            </div>
          </DropDown>
        </div>

        <div className={styles.padding}>
          {sectionLoading && sectionLoading.completedSession ? (
            <Loader />
          ) : (
            <>
              {completedSessionsList.length ? (
                // to avoid repetations in array
                Array.from(new Set(completedSessionsList)).map((each: any) => {
                  return (
                    <>
                      <ListForSessions data={each} />
                    </>
                  )
                })
              ) : (
                <EmptySection subText={'No sessions completed yet!'} />
              )}
            </>
          )}
          {countCompletedSession > 8 ? (
            <Pagination
              page={paginationStateCompleted.page}
              onChange={changeHandlerCompleted}
              count={Math.ceil(countCompletedSession / paginationStateCompleted.perPage)}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.heading}>Cancelled sessions : {countCancelledSession}</div>
        <div className={styles.search}>
          <Search
            type={'text'}
            placeholder={'Search proposal or attendee name'}
            onChange={() => {}}
          />
        </div>
        <div className={styles.padding}>
          {sectionLoading && sectionLoading.cancelledSession ? (
            <Loader />
          ) : (
            <>
              {cancelledSessionList.length ? (
                Array.from(new Set(cancelledSessionList)).map((each: any) => {
                  return (
                    <>
                      <ListForSessions data={each} />
                    </>
                  )
                })
              ) : (
                <EmptySection subText={'No sessions are cancelled yet'} />
              )}
              {countCancelledSession > 5 ? (
                <Pagination
                  page={paginationStateCancelled.page}
                  onChange={changeHandlerCancelled}
                  count={Math.ceil(countCompletedSession / paginationStateCancelled.perPage)}
                />
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
