import React from 'react'
import styles from './AlertPopupWrapper.module.scss'

export const AlertPopupWrapper: React.FC<{
  Img?: React.FC
  title: string
  Content: React.FC
  Buttons?: React.FC
}> = ({ Img, title, Content, Buttons }) => {
  return (
    <div className={styles['popup-alert__container']}>
      <div>{Img ? <Img /> : ''}</div>
      <div className={styles['popup-alert__title']}>{title}</div>
      <div className={styles['popup-alert__content']}>
        <Content />
      </div>
      {Buttons ? <Buttons /> : ''}
    </div>
  )
}
