/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import archive from '../../assets/images/svg/archive.svg'
import createProposal from '../../assets/images/svg/create_proposal_gray.svg'
// import archive from '../../assets/images/svg/archive.svg'
import deleteMenu from '../../assets/images/svg/delete-menu.svg'
import deleteRequestIcon from '../../assets/images/svg/delete-request.svg'
import chat from '../../assets/images/svg/chat-connect-gray.svg'
import Done from '../../assets/images/png/done.png'

import dotMenu from '../../assets/images/svg/dots-menu.svg'
import grayEye from '../../assets/images/svg/eye-gray.svg'
import grayClose from '../../assets/images/svg/gray-close.svg'
import editMenu from '../../assets/images/svg/menu-edit.svg'
import star from '../../assets/images/svg/star.svg'
import timeIcon from '../../assets/images/svg/time-icon.svg'
import tick from '../../assets/images/svg/whiteTick.svg'
import { CreateProposal } from '../../components/create-proposal/CreateProposal'
import { EditRequest } from '../../components/edit-request/EditRequest'
import PopUpIcon from '../../assets/images/svg/popup-icon.svg'

import { NameInitials } from '../../components/name-initials/NameInitials'
import { RequestDetails } from '../../components/request-details/RequestDetails'
import { WithdrawResponse } from '../../components/withdraw-response/WithdrawResponse'
import { AlertPopupWrapper } from '../alert-popup-wrapper/AlertPopupWrapper'
import { Button } from '../button/Button'
import { DialogHeader } from '../dialog-header/DialogHeader'
import { DialogWrapper } from '../dialog-wrapper/DialogWrapper'
import { DropDown, ListArrayItemProps } from '../drop-down/DropDown'
import { useFetch } from '../https'
import { Loader } from '../Loader/Loader'
import { UsePopupContext } from '../use-popup-context'
import styles from './RequestListCard.module.scss'
import { useHistory } from 'react-router'
import { useUserDetailsContext } from '../use-user-details-context'
import { BankDetailsForm } from '../../components/bank-details-form/BankDetailsForm'
import { ElementsWrapper } from '../elements-wrapper/ElementsWrapper'

export const RequestListCard: React.FC<{
  data: any
  callback?: (value: boolean) => void
  isTeacher?: boolean
  detailPopup?: boolean
  actionMenu?: boolean
  resetProposalStatus?: () => void
  statsFunction?: () => void
}> = ({
  data,
  callback,
  isTeacher = false,
  detailPopup = true,
  actionMenu = true,
  statsFunction = () => {},
  resetProposalStatus = () => {},
}) => {
  const { push } = useHistory()

  const [isOpenDialog, setisOpenDialog] = useState(false)
  const [withDrawReasonDialog, setWithDrawReasonDialog] = useState(false)
  const [isEdit, setisEdit] = useState(false)
  const { close, setData: setPopupData } = UsePopupContext()
  const { t } = useTranslation()
  const { userDetails, countries } = useUserDetailsContext()
  const [createProposalDialog, setCreateProposalDialog] = useState<boolean>(false)
  const { fetchRest, sectionLoading } = useFetch({ delete: false })
  const elem = Array.from({ length: 4 }, (_, index) => index + 1)
  const [listArray, setlistArray] = useState<ReadonlyArray<ListArrayItemProps>>([])
  const [addNewMethod, setAddNewMethod] = useState<boolean>(false)
  const deleteRequest = () => {
    fetchRest(
      {
        method: 'DELETE',
        url: '/deleteRequest',
        data: {
          requestId: data?.requestId,
        },
      },
      'delete'
    )
      .then((res) => {
        if (res?.data?.status === 'success') {
          openPopup('delete')
          if (callback) {
            callback(true)
            statsFunction()
          }
        }
      })
      .catch((response) => {
        if (response?.response?.data?.error?.errorType) {
          openPopup(response.response.data.error.errorType, response.response.data.error.message)
        } else {
          openPopup(
            'FAILED_TO_DELETE_REQUEST',
            'Failed to delete request, please try again after sometime.'
          )
        }
      })
  }

  const openPopup = (value?: string, errorMessage: string = '') => {
    let headerContent: string = ''
    let content: string = ''
    if (value === 'delete') {
      headerContent = t('requestCard.delete-popup-head')
      content = t('requestCard.delete-popup-content')
    } else if (value === 'edit') {
      headerContent = t('requestCard.edit-popup-head')
      content = t('requestCard.edit-popup-head')
    } else if (value === 'PROPOSAL_EXIST') {
      headerContent = t('requestCard.proposal-exists-head')
      content = errorMessage ? errorMessage : 'Cannot create as proposal already exists'
    } else if (value === 'SESSION_EXIST') {
      headerContent = 'Session Exists'
      content = errorMessage ? errorMessage : 'Cannot create as session already exists'
    } else if (value === 'FAILED_TO_DELETE_REQUEST') {
      headerContent = 'Failed to delete request'
      content = errorMessage
    } else {
      headerContent = t('requestCard.delete-request-head')
      content = t('requestCard.delete-request-content')
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return (
            <img
              src={
                value === 'PROPOSAL_EXIST' || value === 'edit' || value === 'SESSION_EXIST'
                  ? PopUpIcon
                  : deleteRequestIcon
              }
              alt="popup icon"
            />
          )
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {value === 'delete' ||
              value === 'edit' ||
              value === 'FAILED_TO_DELETE_REQUEST' ||
              value === 'PROPOSAL_EXIST' ||
              value === 'SESSION_EXIST' ? (
                <>
                  <Button className={styles.green} text={'Got It'} onClick={close}></Button>
                </>
              ) : (
                <>
                  <Button
                    text={'Delete'}
                    onClick={(e) => {
                      deleteRequest()
                      close()
                    }}
                    className={styles.white}
                  ></Button>
                  <Button text={'Cancel'} className={styles['cancel-btn']} onClick={close}></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  const onEditRequest = (value: boolean) => {
    if (value) {
      setisOpenDialog(false)
      setisEdit(false)
      openPopup('edit')
      if (callback) {
        callback(value)
      }
    }
  }

  const popUp = (success: boolean) => {
    let headerContent: string = ''
    let content: string = ''

    if (success) {
      headerContent = 'Onboarding process'
      content = 'Please complete the onboarding process before creating the proposal'
    }

    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={Done} alt="popup icon" className={styles.widthPopup} />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.green}
                    text={'Click here'}
                    onClick={() => {
                      if (userDetails.isTeacher) {
                        fetchRest({
                          url: '/accountOnBoardingCreateLink',
                          method: 'POST',
                          data: {
                            accountId: userDetails.stripeAccountId,
                            stripeAccount: 'UK',
                          },
                          isFullPageLoader: true,
                        })
                          .then((each: any) => {
                            window.location.href = each.data.data.url
                          })
                          .catch((err) => console.log(err))
                      }
                    }}
                  />
                </>
              ) : (
                <>
                  <Button
                    text={'OK'}
                    onClick={(e) => {
                      close()
                    }}
                    className={styles.green}
                  ></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  const editAction: ListArrayItemProps = {
    listContent: (
      <>
        <div className={styles.element}>
          <img src={editMenu} alt="edit icon" className={styles.elementImage} />
          <div>Edit</div>
        </div>
      </>
    ),
    value: 'Edit',
    onClick: (_e: any) => {
      setisEdit(true)
      setisOpenDialog(true)
    },
  }

  const onViewRequest = () => {
    if (isTeacher) {
      fetchRest({
        method: 'GET',
        url: '/viewRequest',
        params: [data.requestId],
      })
        .then()
        .catch()
    }
  }

  const withdrawMenu = {
    listContent: (
      <>
        <div className={styles.element}>
          <img src={grayClose} alt="close icon" className={styles.elementImage} />
          <div>Withdraw response</div>
        </div>
      </>
    ),
    value: 'Withdraw response',
    onClick: (_e: any) => {
      setWithDrawReasonDialog(true)
    },
  }

  const openMessageMenu = {
    listContent: (
      <>
        <div className={styles.element}>
          <img src={chat} alt="chat icon" className={styles.elementImage} />
          <div>Open Messages</div>
        </div>
      </>
    ),
    value: 'open Messages',
    onClick: (_e: any) => {
      isTeacher ? push('/chat?type=mentor') : push('/chat?type=learn')
    },
  }

  const createProposalMenu = {
    listContent: (
      <>
        <div className={styles.element}>
          <img src={createProposal} alt="create proposal icon" className={styles.elementImage} />
          <div>Create Proposal</div>
        </div>
      </>
    ),
    value: 'Create Proposal',
    onClick: (_e: any) => {
      let supported = false
      if (countries.length > 0) {
        countries.forEach((each: any) => {
          if (each === userDetails.countryCode) {
            supported = true
          }
        })
      }
      if (supported && userDetails.account.capabilities.transfers === 'inactive') {
        popUp(true)
      } else if (
        userDetails.account &&
        userDetails.account.external_accounts &&
        userDetails.account.external_accounts.total_count <= 0
      ) {
        setAddNewMethod(true)
      } else {
        if (data?.proposal?.length > 0) {
          openPopup('PROPOSAL_EXIST')
        } else {
          setCreateProposalDialog(true)
        }
      }
    },
  }

  useEffect(() => {
    if (isTeacher) {
      if (data.response && data.response[0] && data.response[0].isAcknowledged) {
        if (data.proposal && data.proposal.length > 0) {
          setlistArray([createProposalMenu, openMessageMenu])
        } else {
          setlistArray([withdrawMenu, createProposalMenu, openMessageMenu])
        }
      } else {
        setlistArray([withdrawMenu])
      }
    } else {
      setlistArray([
        {
          listContent: (
            <>
              <div className={styles.element}>
                <img src={deleteMenu} alt="delete icon" className={styles.elementImage} />
                <div>Delete</div>
              </div>
            </>
          ),
          value: 'Delete',
          onClick: (_e: any) => {
            openPopup()
          },
        },
      ])
    }
  }, [isTeacher, data])

  return (
    <>
      <div onClick={onViewRequest} className={styles['list-card']}>
        <div className={styles['list-card-header']}>
          <div className={styles['d-flex']}>
            {data?.response && data?.response?.length > 0 ? (
              <>
                {data.response && data.response[0] && data.response[0].isAcknowledged ? (
                  <>
                    <div className={styles['request-connect']}>
                      <img src={tick} alt="tick icon" className={styles.divImg} />
                      Connected
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles['request-respond']}>
                      <img src={tick} alt="tick icon" className={styles.divImg} />
                      {t('requestDetails.responded')}
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
            <div className={styles['review-card__align-items']}>
              <img src={timeIcon} alt="time icon" className={styles['review-card__time-icon']} />
              <div className={styles['review-card__time-subject']}>
                {t('requestListCard.exp')} {data?.expiresIn}
              </div>
            </div>
            {isTeacher ? (
              <></>
            ) : (
              <div className={`${styles.eye} ${styles['review-card__align-items']}`}>
                <img src={grayEye} alt="eye" className={styles['review-card__time-icon']} />
                <div className={styles['review-card__time-subject']}> {data?.views}</div>
              </div>
            )}
          </div>
          {actionMenu ? (
            <DropDown
              menuProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                transformOrigin: { vertical: 'top', horizontal: 'right' },
              }}
              ListArray={
                isTeacher
                  ? data?.response?.length > 0
                    ? listArray
                    : []
                  : data.courseId
                  ? listArray
                  : [{ ...editAction }, ...listArray]
              }
            >
              <div className={styles['dropdown-menu']}>
                <img src={dotMenu} alt="menu" className={styles['dotted-menu']} />
              </div>
            </DropDown>
          ) : (
            <></>
          )}
        </div>
        <div
          className={styles['dotted-menu']}
          onClick={() => {
            if (detailPopup) {
              setisOpenDialog(true)
            }
          }}
        >
          <div className={styles['list-card-title']}>
            <span
              className={styles.hideContent}
              dangerouslySetInnerHTML={{
                __html:
                  data?.category != null
                    ? data?.category.name
                    : data?.course != null
                    ? data?.course.title
                    : data?.subCategory != null
                    ? data?.subCategory.name
                    : '',
              }}
            ></span>
            <div
              className={styles['list-card-content']}
              dangerouslySetInnerHTML={{ __html: data.description }}
            ></div>
          </div>
          {!isTeacher ? (
            <div className={styles['list-card-footer']}>
              <div className={styles['responds-wrapper']}>
                <div className={styles['responds-count']}>
                  {data?.responseCount != null ? data?.responseCount : 0}
                </div>
                <div className={styles['responds-text']}>
                  {t('requestListCard.receivedResponse')}
                </div>
                {/* <div></div> */}
              </div>
              <div>
                {sectionLoading && sectionLoading.delete ? (
                  <Loader className="form_loader" size={20} thickness={2} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <div className={styles['review-card__profile']}>
              {data?.user?.imageUrl.length > 0 ? (
                <img
                  src={data.user.imageUrl}
                  alt="user"
                  className={styles.userImage}
                  style={{ width: '40px', height: '40px' }}
                />
              ) : (
                <NameInitials
                  firstName={data?.user?.firstName}
                  lastName={data?.user?.lastName}
                  className={styles.icon}
                />
              )}
              <div className={styles['review-card__star-rating']}>
                <div className={styles['review-card__username']}>{data?.user?.firstName}</div>
                <div className={styles['review-card__rating']}>
                  {elem.map((item) => {
                    return (
                      <img
                        src={star}
                        alt="star icons"
                        key={'star' + item}
                        className={styles['review-card__rating-icons']}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <DialogWrapper
        onClose={() => {
          setisOpenDialog(false)
          setisEdit(false)
        }}
        open={isOpenDialog}
        isFullScreen={!isEdit || isTeacher ? true : false}
        Title={DialogHeader}
      >
        {!isEdit ? (
          <RequestDetails
            statsFunction={statsFunction}
            data={data}
            openPopup={openPopup}
            setisOpenDialog={setisOpenDialog}
            setisEdit={setisEdit}
            isTeacher={isTeacher}
            countries={countries}
            callBack={callback}
            isDeleted={sectionLoading && sectionLoading.delete}
          />
        ) : (
          <EditRequest data={data} onEditRequest={onEditRequest}></EditRequest>
        )}
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setWithDrawReasonDialog(false)
        }}
        open={withDrawReasonDialog}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <WithdrawResponse
          responseId={data?.response?.[0]?.responseId ? data?.response?.[0]?.responseId : ''}
          onSuccess={() => {
            if (callback) {
              callback(true)
            }
          }}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setCreateProposalDialog(false)
        }}
        open={createProposalDialog}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <CreateProposal
          isEdit={false}
          createProposal={() => {
            setCreateProposalDialog(false)
          }}
          statsFunction={statsFunction}
          data={data}
          resetProposalStatus={() => {
            resetProposalStatus()
          }}
          // statsFunction={statsFunction}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setAddNewMethod(false)
        }}
        open={addNewMethod}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <ElementsWrapper>
          <BankDetailsForm
            popupClose={() => {
              setAddNewMethod(false)
              if (callback) {
                callback(true)
              }
            }}
          />
        </ElementsWrapper>
      </DialogWrapper>
    </>
  )
}
