/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import addNew from '../../assets/images/svg/add-new.svg'
import { Button } from '../../common/button/Button'
import styles from './AddNew.module.scss'
import { UsePopupContext } from '../../common/use-popup-context'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import PopUPIcon from '../../assets/images/svg/popup-icon.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import { useTranslation } from 'react-i18next'
import { useElements, CardElement } from '@stripe/react-stripe-js'
import whiteTick from '../../assets/images/svg/whiteTick.svg'
import stripePowered from '../../assets/images/png/stripe-powered.png'

import { useStripePaymentContext } from '../../common/use-stripe-payment-context'
import { useFetch } from '../../common/https'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { Loader } from '../../common/Loader/Loader'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import { nameSchema } from '../../common/schema/Schema'
import { StripeCardNumberElement } from '@stripe/stripe-js'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'

export const AddNew: React.FC<{
  readonly onClose: () => void
  countryCode?: string
}> = ({ onClose, countryCode }) => {
  const { t } = useTranslation()

  const { userDetails } = useUserDetailsContext()
  const { fetchRest, sectionLoading } = useFetch({ addCard: false })
  const elements: any = useElements()
  const { createToken } = useStripePaymentContext()

  const [cardDetails, setCardDetails] = useState({
    cardHolderName: {
      value: '',
      isValid: false,
      isTouched: false,
      errorMsg: "Please enter the cardHolder's name",
    },
  })

  const { close, setData: setPopupData } = UsePopupContext()
  const openPopup = (success: boolean, message?: string) => {
    let content: string = ''
    let headerContent: string = ''
    if (success) {
      headerContent = 'Success'
      content = 'Your Payment Method has been added Successfully!'
    } else {
      headerContent = 'Something went wrong'
      content = message
        ? message
        : 'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? PopUPIcon : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.white}
                    text={'Got It'}
                    onClick={() => {
                      close()
                      // onSuccess()
                      onClose()
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button
                    text={'Go back!'}
                    onClick={(e) => {
                      close()
                      // onClose()
                    }}
                    className={styles.white}
                  ></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  const handleChange = (_e: any, key: string) => {
    setCardDetails((prev: any) => {
      return {
        ...prev,
        [key]: { value: _e.value, isValid: _e.isValid, isTouched: true, errorMsg: _e.errorMsg },
      }
    })
  }

  const addCustomerCard = (response: any) => {
    if (response != null && response.token) {
      fetchRest(
        {
          url: '/addCard',
          method: 'POST',
          data: {
            source: response.token.id,
            stripeCustomerId: userDetails.stripeCustomerIdUK,
            stripeAccount: 'UK',
          },
        },
        'addCard'
      )
        .then((res: any) => {
          openPopup(true)
        })
        .catch((err: any) => {
          openPopup(false)
        })
    } else {
      openPopup(false, response.message)
    }
  }

  const onSubmit = async () => {
    if (cardDetails.cardHolderName.isTouched && cardDetails.cardHolderName.isValid) {
      const cardElement = elements.getElement(CardElement) as StripeCardNumberElement
      const response = await createToken(cardElement, cardDetails.cardHolderName.value)
      addCustomerCard(response)
    }
  }

  return (
    <div className={styles.whiteBackground}>
      <div className={styles.heading}>
        <img src={addNew} alt="add new method" className={styles.divImg} />
        <div>{t('get-paid.add-new.newMethod')}</div>
      </div>
      <div className={styles.padding}>
        <div className={styles.boldText}>{t('get-paid.add-new.cardholderName')}</div>
        <div className={styles.inputField}>
          <InputWrapper
            valueObj={cardDetails.cardHolderName}
            onChange={(_e: any) => {
              handleChange(_e, 'cardHolderName')
            }}
            schema={nameSchema}
            validateOnBlur={true}
            validateOnChange={true}
          />
        </div>
        <div className={styles.paymentTypes}>
          <NoEventsLoader isLoading={sectionLoading && sectionLoading.addCard}>
            <div className="cardElement">
              <div className={styles.boldText}>Card details</div>
              <CardElement />
            </div>
            <div className={styles.dflex}>
              <Button
                text="Add"
                ButtonImg={() => {
                  return (
                    <>
                      {sectionLoading && sectionLoading.addCard ? (
                        <Loader className="form_loader" size={20} thickness={2} />
                      ) : (
                        <>
                          <img src={whiteTick} alt="White tick" className={styles.divImg} />
                        </>
                      )}
                    </>
                  )
                }}
                className={styles.button}
                onClick={onSubmit}
              />
              <img src={stripePowered} alt="stripe" />
            </div>
          </NoEventsLoader>
        </div>
      </div>
    </div>
  )
}
