/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Checkbox, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { AutocompleteOption } from '../../common/autocomplete-option/AutocompleteOption'
import { Button } from '../../common/button/Button'
import { useFetch } from '../../common/https'
import { UsePopupContext } from '../../common/use-popup-context'
import { charactersLeft } from '../../common/utils'
import styles from './DeleteTeam.module.scss'
import deleteIcon from '../../assets/images/svg/deleteTeamPopup.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import { useHistory } from 'react-router'
import { Loader } from '../../common/Loader/Loader'
interface ReasonForDelete {
  value: string
}
export const DeleteTeam: React.FC<{ readonly teamInfo?: any; readonly closePopup?: any }> = ({
  teamInfo = {},
  closePopup = () => {},
}) => {
  const { t } = useTranslation()
  const [reasonArray, setReasonArray] = useState<Array<ReasonForDelete>>([
    { value: t('teams.deleteTeam.reason1') },
    { value: t('teams.deleteTeam.reason2') },
    { value: t('teams.deleteTeam.reason3') },
    { value: t('teams.deleteTeam.other') },
  ])
  const [reason, setReason] = useState({
    reason: { value: '', isTouched: false, isValid: false, errorMsg: 'Please enter a reason' },
    moreInfo: { value: '', isTouched: false, isValid: false, errorMsg: 'Please enter info' },
    experience: { value: '', isTouched: false, isValid: false, errorMsg: 'Please enter info' },
  })
  const [checked1, setChecked1] = useState<boolean>(true)
  const [checked2, setChecked2] = useState<boolean>(true)
  const { fetchRest, sectionLoading } = useFetch({ deleteTeam: false })
  const { close, setData: setPopupData } = UsePopupContext()
  const { push } = useHistory()

  const onSubmit = (e: any) => {
    e.preventDefault()
    setReason((prev: any) => {
      let blockPrev = { ...prev }
      Object.keys(blockPrev).forEach((each) => {
        blockPrev = { ...blockPrev, [each]: { ...blockPrev[each], isTouched: true } }
      })
      return blockPrev
    })
    if (reason.reason.value === 'Other(Please specify)' && reason.moreInfo.value === '') {
      setReason((prev: any) => {
        return {
          ...prev,
          moreInfo: { ...prev.moreInfo, isValid: false },
        }
      })
    } else {
      setReason((prev: any) => {
        return {
          ...prev,
          moreInfo: { ...prev.moreInfo, isValid: true },
        }
      })
    }
    if (checked1 && checked2) {
      if (reason.reason.isValid && reason.experience.isValid && reason.moreInfo.isValid) {
        //   api call to delete team
        fetchRest(
          {
            url: `team/${teamInfo.teamId}/delete`,
            method: 'PUT',
            data: {
              reasonForDeletion: reason.reason.value,
              moreInfoRegardingDeletion: reason?.moreInfo?.value ? reason.moreInfo.value : '',
              overallExperienceWithOntelo: reason.experience.value,
              acceptedAgreement1: checked1,
              acceptedAgreement2: checked2,
            },
          },
          'deleteTeam'
        )
          .then((res: any) => {
            openPopup(true)
          })
          .catch((err: any) => {
            openPopup(false)
          })
      } else {
        console.log('failed')
      }
    } else {
      console.log('checkbox not ticked')
    }
  }
  const openPopup = (success: boolean) => {
    let content: string = ''
    let headerContent: string = ''
    if (success) {
      headerContent = 'This team is now deleted'
      content = `Deleted team "${teamInfo?.teamDetails?.teamName}"`
    } else {
      headerContent = 'Something went wrong'
      content =
        'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? deleteIcon : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.white}
                    text={'Got It'}
                    onClick={() => {
                      close()
                      closePopup()
                      push('/teams')
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button text={'Cancel'} className={styles['cancel-btn']} onClick={close}></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }
  return (
    <div className={styles.outerWrapper}>
      <form onSubmit={onSubmit}>
        <div className={styles.teamDetails}>Delete "{teamInfo?.teamDetails?.teamName}" Team ?</div>
        <div className={styles['list-card-title']}>{t('teams.deleteTeam.reason')}</div>
        <div className={styles['list-card-dropdown']}>
          <div className="autocomplete-wrapper">
            <Autocomplete
              value={reason.reason}
              onChange={(event, newValue) => {
                if (newValue) {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  setReason((prev: any) => {
                    return {
                      ...prev,
                      reason: {
                        value: newValue.value,
                        isValid: newValue.value ? true : false,
                        errorMsg: '',
                        isTouched: true,
                      },
                    }
                  })
                }
              }}
              handleHomeEndKeys
              options={reasonArray}
              getOptionLabel={(option) => {
                return option.value
              }}
              renderOption={(option) => <AutocompleteOption option={option.value} />}
              ListboxProps={{
                style: {
                  maxHeight: '147px',
                },
              }}
              style={{
                width: '100%',
                position: 'relative',
                border: 'none !important',
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Please enter the reason here.."
                />
              )}
            />
          </div>
          <div className={styles.errorText}>
            {reason.reason.isTouched && reason.reason.value === ''
              ? '*Please enter a reason first'
              : ''}
          </div>
        </div>
        <div className={styles['list-card-title']}>{t('teams.deleteTeam.moreInfo')}</div>
        <div>
          <textarea
            className={styles['textarea-styles']}
            value={reason.moreInfo.value}
            onChange={(e: any) => {
              let value = e.target.value
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              setReason((prev: any) => {
                return {
                  ...prev,
                  moreInfo: { value: value, isTouched: true, isValid: true, errorMsg: '' },
                }
              })
            }}
          ></textarea>
          <div className={styles.errorText}>
            {reason.moreInfo.isTouched &&
            reason.reason.value === 'Other(Please specify)' &&
            reason.moreInfo.value === ''
              ? '*Please describe the reason'
              : ''}
          </div>
          <div className={styles['character-count']}>
            {charactersLeft({
              currentLength:
                reason?.moreInfo?.value?.length > 0 ? reason?.moreInfo?.value?.length : 0,
            })}
            {t('common.charactersLeft')}
          </div>
        </div>
        <div className={styles['list-card-title']}>{t('teams.deleteTeam.experience')}</div>
        <div>
          <textarea
            className={styles['textarea-styles']}
            value={reason.experience.value}
            onChange={(e: any) => {
              let value = e.target.value
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              setReason((prev: any) => {
                return {
                  ...prev,
                  experience: { value: value, isTouched: true, isValid: true, errorMsg: '' },
                }
              })
            }}
          ></textarea>
          <div className={styles.errorText}>
            {reason.experience.isTouched && reason.experience.value === ''
              ? '*Please describe your experience'
              : ''}
          </div>
          <div className={styles['character-count']}>
            {charactersLeft({
              currentLength:
                reason?.experience?.value?.length > 0 ? reason?.experience?.value?.length : 0,
            })}
            {t('common.charactersLeft')}
          </div>
        </div>
        <div className={styles.dFlex}>
          <Checkbox
            checked={checked1}
            onChange={() => {
              setChecked1(!checked1)
            }}
            color="primary"
          />

          <div className={styles.label}>
            <div>
              You understand that the team will remain active until all scheduled events have been
              delivered
            </div>
          </div>
        </div>
        <div className={styles.dFlex}>
          <Checkbox
            checked={checked2}
            onChange={() => {
              setChecked2(!checked2)
            }}
            color="primary"
          />
          <div className={styles.label}>
            You understand that no training requests can be raised once a request to delete is
            raised
          </div>
        </div>
        <div className={styles.errorText}>
          {!checked1 || !checked2 ? '*Please check these boxes' : ''}
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            text={'Delete Team'}
            className={styles.button}
            ButtonImg={() => {
              return (
                <>
                  {sectionLoading && sectionLoading.deleteTeam ? (
                    <Loader className={styles.form_loader} size={20} thickness={2} />
                  ) : (
                    <></>
                  )}
                </>
              )
            }}
          />
        </div>
      </form>
    </div>
  )
}
