/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import styles from './FinishSession.module.scss'
import tick from '../../assets/images/svg/gray_tick.svg'
import { Button } from '../../common/button/Button'
import whiteTick from '../../assets/images/svg/whiteTick.svg'
import sessionFinish from '../../assets/images/svg/sessionFinish.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import { Rating } from '@material-ui/lab'
import { styled } from '@material-ui/core'
import { NameInitials } from '../name-initials/NameInitials'
import Grade from '@material-ui/icons/Grade'
import { charactersLeft } from '../../common/utils'
import { useTranslation } from 'react-i18next'
import { useFetch } from '../../common/https'
import repeatRequest from '../../assets/images/svg/repeatRequest.svg'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { UsePopupContext } from '../../common/use-popup-context'
import { Loader } from '../../common/Loader/Loader'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
})

export const FinishSession: React.FC<{
  readonly data?: any
  readonly isAttendee?: boolean
  finishSession: () => void
  readonly teamId?: string
}> = ({ data = {}, isAttendee = false, finishSession, teamId }) => {
  const { fetchRest, sectionLoading } = useFetch({ done: false })
  // const elem = Array.from({ length: 4 }, (_, index) => index + 1)
  const { t } = useTranslation()
  const { close, setData: setPopupData } = UsePopupContext()
  const [communicationRating, setCommunicationRating] = useState(5)
  const [otherRating, setOtherRating] = useState(5)
  const [aboutContent, setAboutContent] = useState({
    isValid: false,
    description: '',
    isTouched: false,
    errorMsg: '',
  })
  const popUp = (success: boolean) => {
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? sessionFinish : repeatRequest} alt="popup icon" />
        }}
        title={
          success
            ? 'Sessions finished'
            : 'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
        }
        Buttons={() => {
          return (
            <>
              <Button
                className={styles.white}
                text={'Got It'}
                onClick={() => {
                  close()
                  finishSession()
                }}
              ></Button>
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>
                {success
                  ? 'Please wait for the other party to also finish the session.'
                  : 'Please try again'}
              </p>
            </div>
          )
        }}
      />
    )
  }
  const onSubmit = () => {
    if (aboutContent.isValid) {
      fetchRest(
        {
          url: `finishSession/${isAttendee || teamId ? 'student' : 'teacher'}/${data.sessionId}`,
          method: 'PUT',
          isFullPageLoader: false,
          data: {
            reviewSummary: aboutContent.description,
            // reviewRating: communicationRating,
            communicationRating,
            otherRating,
          },
        },
        'done'
      )
        .then((res) => {
          popUp(true)
        })
        .catch((error) => {
          if (error?.response?.data?.error?.errorStatus) {
            errorProposalPopup(error.response.data.error?.message)
          } else {
            popUp(false)
          }
        })
    } else {
      setAboutContent({
        isValid: false,
        description: '',
        isTouched: true,
        errorMsg: 'Feedback is required',
      })
    }
  }

  const errorProposalPopup = (errorMessage: string = '') => {
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img className={styles.popupImage} src={RepeatRequest} alt="popup icon" />
        }}
        title={`${t('common.failed')}!`}
        Buttons={() => {
          return (
            <>
              <Button
                className={styles.proposal_cancel_btn}
                text={t('common.cancel')}
                onClick={() => {
                  close()
                }}
              ></Button>
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{errorMessage}</p>
            </div>
          )
        }}
      />
    )
  }

  return (
    <>
      <NoEventsLoader isLoading={sectionLoading && sectionLoading.done}>
        <div className={styles.wrapperClass}>
          <div className={styles.heading}>
            <img src={tick} alt="finish session" className={styles.divImg} />
            <div>Finish Session</div>
          </div>
          <div className={styles.boldFont}>Please rate {isAttendee ? 'mentor' : 'attendee'}</div>
          <div className={styles.thinFont}>
            Was your {isAttendee ? 'learning' : 'teaching'} experience with this{' '}
            {isAttendee ? 'teacher' : 'student'} great ?
          </div>
          <div className={styles.user}>
            <div className={styles.divImg}>
              {isAttendee ? (
                <>
                  <div className={styles.icon}>
                    {data?.proposal?.owner.imageUrl ? (
                      <img
                        src={data?.proposal?.owner.imageUrl}
                        className={styles.icon}
                        alt="owner"
                      />
                    ) : (
                      <>
                        <NameInitials
                          firstName={data?.proposal?.owner.firstName}
                          lastName={data?.proposal?.owner.lastName}
                          className={styles.radius}
                        />
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.icon}>
                    {data?.proposal?.request?.user?.imageUrl ? (
                      <img
                        src={data?.proposal?.request?.user?.imageUrl}
                        className={styles.icon}
                        alt="user"
                      />
                    ) : (
                      <>
                        <NameInitials
                          firstName={data?.proposal?.request?.user?.firstName}
                          lastName={data?.proposal?.request?.user?.lastName}
                          className={styles.radius}
                        />
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className={styles.info}>
              <div className={styles.name}>
                {isAttendee ? (
                  <>
                    {data?.proposal?.owner?.firstName} {data?.proposal?.owner?.lastName.charAt(0)} .
                  </>
                ) : (
                  <>
                    {data?.proposal?.request.user?.firstName}
                    {data?.proposal?.request.user?.lastName.charAt(0)}.
                  </>
                )}
              </div>
              <div className={styles.profile}>{isAttendee ? 'mentor' : 'Attendee'}</div>
            </div>
          </div>
          <div className={styles.borderBtm}>
            <div className={styles.ratings}>
              <div className={styles.topic}>{isAttendee ? 'Knowledge' : 'Participation'}</div>
              <div className={styles.starImg}>
                <StyledRating
                  name="color"
                  onClick={(e: any) => {
                    setCommunicationRating(e.target.value)
                  }}
                  defaultValue={communicationRating}
                  getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                  precision={1}
                  size={'medium'}
                  icon={<Grade fontSize="inherit" style={{ color: '#e54dc0' }} />}
                  emptyIcon={<Grade fontSize="inherit" style={{ color: '#F0F1F3' }} />}
                />
              </div>
            </div>
            <div className={styles.ratings}>
              <div className={styles.topic}>{isAttendee ? 'Teaching style' : 'Enthusiasm'}</div>
              <div className={styles.starImg}>
                <StyledRating
                  name="customized-color"
                  onClick={(e: any) => {
                    setOtherRating(e.target.value)
                  }}
                  defaultValue={otherRating}
                  getLabelText={(value1) => `${value1} Star${value1 !== 1 ? 's' : ''}`}
                  precision={1}
                  size={'medium'}
                  icon={<Grade fontSize="inherit" style={{ color: '#e54dc0' }} />}
                  emptyIcon={<Grade fontSize="inherit" style={{ color: '#F0F1F3' }} />}
                />
              </div>
            </div>
          </div>
          <div className={styles.marginTop}>
            <div className={styles.boldFont}>Leave Feedback</div>
            <div className={styles.thinFont}>Share a few words</div>
            <div>
              <textarea
                className={styles.textareaStyles}
                onChange={(e) => {
                  let value = e.target.value
                  setAboutContent({
                    isTouched: true,
                    isValid: !value || (value && value.length <= 5000) ? true : false,
                    errorMsg:
                      !value || (value && value.length <= 5000)
                        ? ''
                        : t('common.moreThan5000CharactersNotAllowed'),
                    description: value,
                  })
                }}
                value={aboutContent.description}
              ></textarea>
              {!aboutContent.isValid && aboutContent.isTouched ? (
                <div className={styles.error}>{aboutContent.errorMsg}</div>
              ) : (
                <></>
              )}
              <div className={styles['character-count']}>
                {charactersLeft({
                  currentLength:
                    aboutContent.description && aboutContent.description.length
                      ? aboutContent.description.length
                      : 0,
                })}
                {t('common.charactersLeft')}
              </div>
            </div>
          </div>
          <div className={styles.btnWrapper}>
            <Button
              text="Done"
              ButtonImg={() => {
                return (
                  <>
                    {sectionLoading && sectionLoading.done ? (
                      <Loader className="form_loader" size={20} thickness={2} />
                    ) : (
                      <>
                        <img src={whiteTick} alt="Done" className={styles.divImg} />
                      </>
                    )}
                  </>
                )
              }}
              className={styles.white}
              onClick={() => {
                onSubmit()
              }}
            />
          </div>
        </div>
      </NoEventsLoader>
    </>
  )
}
