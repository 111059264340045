/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */

import { v4 as uuid } from 'uuid'
import AWS from 'aws-sdk'
import {
  LogLevel,
  ConsoleLogger,
  DefaultMessagingSession,
  MessagingSessionConfiguration,
} from 'amazon-chime-sdk-js'

import { getMessagingSessionEndpoint, createMemberArn } from '../api/ChimeAPI'

export class MessagingService {
  private _session: any

  sessionId: string

  private _logger: ConsoleLogger

  private _messageUpdateCallbacks: any[]

  private _endpoint: any

  constructor() {
    this._session = null
    this.sessionId = uuid()
    this._logger = new ConsoleLogger('SDK Chat Demo', LogLevel.INFO)
    this._messageUpdateCallbacks = []
  }

  messageObserver = {
    messagingSessionDidStart: () => {
      console.log('Messaging Connection started!')
    },
    messagingSessionDidStartConnecting: (reconnecting: any) => {
      console.log('Messaging Connection connecting')
    },
    messagingSessionDidStop: (event: any) => {
      console.log('Messaging Connection received DidStop event')
    },
    messagingSessionDidReceiveMessage: (message: any) => {
      console.log('Messaging Connection received message')
      this.publishMessageUpdate(message)
    },
  }

  setMessagingEndpoint(member: any) {
    getMessagingSessionEndpoint()
      .then(async (response) => {
        this._endpoint = response?.Endpoint?.Url

        const sessionConfig = new MessagingSessionConfiguration(
          createMemberArn(member.userId),
          this.sessionId,
          this._endpoint,
          new AWS.Chime(),
          AWS
        )

        this._session = new DefaultMessagingSession(sessionConfig, this._logger)

        this._session.addObserver(this.messageObserver)
        this._session.start()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  connect(member: any) {
    this.setMessagingEndpoint(member)
  }

  close() {
    try {
      this._session.stop()
    } catch (err) {
      console.error('Failed to stop Messaging Session.')
    }
  }

  subscribeToMessageUpdate(callback: any) {
    this._messageUpdateCallbacks.push(callback)
  }

  unsubscribeFromMessageUpdate(callback: any) {
    const index = this._messageUpdateCallbacks.indexOf(callback)
    if (index !== -1) {
      this._messageUpdateCallbacks.splice(index, 1)
    }
  }

  publishMessageUpdate(message: any) {
    for (let i = 0; i < this._messageUpdateCallbacks.length; i += 1) {
      const callback = this._messageUpdateCallbacks[i]
      callback(message)
    }
  }
}
