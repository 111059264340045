/* eslint-disable @typescript-eslint/no-explicit-any */
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'
import { StripePaymentProvider } from '../stripe-payment-provider/StripePaymentProvider'

export const ElementsWrapper: React.FC<{}> = ({ children }) => {
  const [stripePromise, setStripePromise] = useState<any>(null)

  useEffect(() => {
    setStripePromise(
      loadStripe(
        process.env.REACT_APP_STRIPE_KEY_UK
          ? process.env.REACT_APP_STRIPE_KEY_UK
          : 'pk_test_51KTS58Aya1OWy4NKqQb6JFFM19nM2WvjSmpTrcowwJLgLNCtLasuzwuQNpsAzVhrRvlirNpSLch2zrNC2EmPKNVj00UvN2FaiC'
      )
    )
  }, [])

  return (
    <>
      <Elements stripe={stripePromise}>
        <StripePaymentProvider>{children}</StripePaymentProvider>
      </Elements>
    </>
  )
}
