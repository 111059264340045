import { Icon, InputAdornment } from '@material-ui/core'
import { DatePicker, DatePickerProps } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import React from 'react'
import calendarBlue from '../../assets/images/svg/calendar-blue.svg'
import { format } from '../Constants'
import styles from './DatePicker.module.scss'

interface PickerProps extends DatePickerProps {
  value: Date
  onChange: (newValue: MaterialUiPickersDate) => void
}

export const DatePickerComponent: React.FC<PickerProps> = ({
  value,
  onChange,
  minDate,
  InputProps = {},
  ...props
}) => {
  return (
    <div className="date-picker">
      <DatePicker
        autoOk
        disableToolbar
        minDate={minDate}
        className={styles.datePicker}
        variant={props.variant ? props.variant : 'inline'}
        format={format}
        value={value}
        placeholder={props.placeholder ? props.placeholder : 'Select date...'}
        onChange={onChange}
        InputProps={{
          ...InputProps,
          endAdornment: InputProps?.endAdornment ? (
            InputProps?.endAdornment
          ) : (
            <InputAdornment position={'end'}>
              <Icon>
                <img src={calendarBlue} alt="calendar" />
              </Icon>
            </InputAdornment>
          ),
        }}
      />
    </div>
  )
}
