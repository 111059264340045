/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { NameInitials } from '../name-initials/NameInitials'
import styles from './TeamMembersList.module.scss'

export const TeamMembersList: React.FC<{
  readonly teamInfo: any
}> = ({ teamInfo }) => {
  return (
    <div>
      {teamInfo.memberStatus === 'Active' ? (
        <div className={styles.members}>
          <div className={styles['membersList-profile']}>
            {teamInfo.memberInfo.imageUrl ? (
              <img src={teamInfo.memberInfo.imageUrl} alt="owner" className={styles.user_Img} />
            ) : (
              <NameInitials
                firstName={teamInfo.memberInfo.firstName}
                lastName={teamInfo.memberInfo.lastName}
                className={styles.user_Img}
              />
            )}
            <div className={styles['membersList-userDetails']}>
              <div className={styles['membersList-username']}>{teamInfo.memberInfo.firstName}</div>
              <div
                className={
                  teamInfo.memberRole === 'Admin' || teamInfo.memberRole === 'SuperAdmin'
                    ? `${styles['membersList-role']} ${styles.pink}`
                    : styles['membersList-role']
                }
              >
                {teamInfo.memberRole}
              </div>
            </div>
          </div>
          <div className={styles.sessions}>
            <div>Attended</div>
            <div>
              <i>
                <span className={styles.blue}>{0}</span> sessions
              </i>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
