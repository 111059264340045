/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFetch } from '../../common/https'
import { Loader } from '../../common/Loader/Loader'
import ProtectedRoute from '../../common/protected-route/ProtectedRoute'
import { useUserDetailsContext } from '../../common/use-user-details-context'
import { Footer } from '../../components/footer/Footer'
import { Header } from '../../components/header/Header'
import { ProposalList } from '../../components/proposal-list/ProposalList'
import { SessionList } from '../../components/session-list/SessionList'
import { TabItem } from '../../components/tab-item/TabItem'
import { TabsWrapper, TabWrapperProps } from '../../components/tabs-wrapper/TabsWrapper'
import { TeacherRequestList } from '../teacher-request-list/TeacherRequestList'
import { UnregisteredTeacherDashboard } from '../unregistered-teacher-dashboard/UnregisteredTeacherDashboard'
import styles from './TeacherDashboard.module.scss'

const TeacherDashboard = () => {
  const { push } = useHistory()
  const { fetchRest } = useFetch()
  const { search } = useLocation()
  const { userDetails } = useUserDetailsContext()
  const { t } = useTranslation()

  const [initialTab, setInitialTab] = useState<number>(-1)
  const [tabCountValues, setTabCountValues] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }>({
    requestCount: 0,
    proposalCount: 0,
    sessionCount: 0,
  })
  const [tabHeaders, setTabHeaders] = useState<ReadonlyArray<TabWrapperProps>>([])
  const getTabHeaders: (obj: { [key: string]: any }) => ReadonlyArray<TabWrapperProps> = (
    countValues
  ) => {
    return [
      {
        key: 'findRequest',
        TabItemWrapper: ({ isActive }) => {
          return (
            <TabItem
              isActive={isActive}
              count={countValues.requestCount}
              text={t('teacher_dashboard.findRequest')}
            />
          )
        },
      },
      {
        key: 'Proposal',
        TabItemWrapper: ({ isActive }) => {
          return (
            <TabItem
              isActive={isActive}
              count={countValues.proposalCount}
              text={t('teacher_dashboard.proposal')}
            />
          )
        },
      },
      {
        key: 'Sessions',
        TabItemWrapper: ({ isActive }) => {
          return (
            <TabItem
              isActive={isActive}
              count={countValues.sessionCount}
              text={t('teacher_dashboard.sessions')}
            />
          )
        },
      },
    ]
  }

  const fetchStatus = () => {
    fetchRest({
      url: '/teacherStats',
      method: 'GET',
    })
      .then((res) => {
        if (res && res.data) {
          setTabCountValues(res.data)
        }
      })
      .catch()
  }
  const onSubTabChange = (activeTab: number) => {
    setInitialTab(activeTab)
    switch (activeTab) {
      case 1:
        push('/mentor?tab=proposal')
        break
      case 2:
        push('/mentor?tab=session')
        break
      default:
        push('/mentor?tab=findRequest')
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(search)
    const tab = queryParams.get('tab')
    if (tab === 'findRequest') {
      setInitialTab(0)
    } else if (tab === 'proposal') {
      setInitialTab(1)
    } else if (tab === 'session') {
      setInitialTab(2)
    } else {
      setInitialTab(0)
    }
    fetchStatus()
  }, [search])
  useEffect(() => {
    setTabHeaders(getTabHeaders(tabCountValues))
  }, [tabCountValues])
  return (
    <>
      <Header />
      {userDetails && userDetails.isTeacher ? (
        <div className={styles.tabHeaders}>
          <div className={styles.tab_container}>
            <TabsWrapper
              tabHeaders={tabHeaders}
              activeTabValue={initialTab}
              onTabChange={onSubTabChange}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={styles.container}>
        {userDetails && userDetails.isLoader ? (
          userDetails.isTeacher ? (
            <>
              <>{initialTab === 0 ? <TeacherRequestList statsFunction={fetchStatus} /> : <></>}</>
              <>{initialTab === 1 ? <ProposalList statsFunction={fetchStatus} /> : <></>}</>
              <>{initialTab === 2 ? <SessionList statsFunction={fetchStatus} /> : <></>}</>
            </>
          ) : (
            <UnregisteredTeacherDashboard />
          )
        ) : (
          <>
            <Loader />
          </>
        )}
      </div>
      <div className={styles.wrapper}>
        <Footer />
      </div>
    </>
  )
}

export const ProtectedTeacherDashboard: React.FC = () => {
  return (
    <ProtectedRoute>
      <TeacherDashboard />
    </ProtectedRoute>
  )
}
