/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import eye from '../../assets/images/svg/eye.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import thumbsUp from '../../assets/images/svg/thumbsUp.svg'
import tick from '../../assets/images/svg/warn.svg'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { Button } from '../../common/button/Button'
import { useFetch } from '../../common/https'
import { InputWrapper } from '../../common/input-wrapper/InputWrapper'
import { Loader } from '../../common/Loader/Loader'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import { codeSchema, passwordConfirmSchema, passwordSchema } from '../../common/schema/Schema'
import { UsePopupContext } from '../../common/use-popup-context'
import { onKeyDownCost } from '../../common/utils'
import styles from './SMSVerification.module.scss'

export const SMSVerification: React.FC<{
  readonly email: string | null
  readonly destinationEmail: string | null
}> = ({ email, destinationEmail }) => {
  const { t } = useTranslation()
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false)
  const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState<boolean>(false)
  const { close, setData: setPopupData } = UsePopupContext()
  const [counter, setCounter] = useState(480)
  const { fetchRest, sectionLoading } = useFetch({ forgotPass: false, confirmPass: false })
  const [data, setData] = useState({
    code: {
      value: '',
      isValid: false,
      isTouched: false,
      errorMsg: 'Enter the 6 digit code received',
    },
    password: {
      value: '',
      isValid: false,
      isTouched: false,
      errorMsg: 'Please enter the New Password',
    },
    reEnterPass: {
      value: '',
      isValid: false,
      isTouched: false,
      errorMsg: 'Please Re-enter the New Password',
    },
  })
  const [flag, setFlag] = useState(0)
  const { push } = useHistory()
  const resend = (e: any) => {
    clicked()
    resendCode(e)
  }
  const handleChange = (e: any, key: string) => {
    setData((prev) => {
      return {
        ...prev,
        [key]: { ...e, isTouched: true },
      }
    })
  }

  const clicked = () => {
    if (counter <= 0) {
      setCounter(479)
    }
  }
  const closePopup = () => {
    close()
    push('/login')
  }
  const openPopup = (success: boolean, headerMsg?: string, contentMsg?: string) => {
    let content: string = ''
    let headerContent: string = ''

    if (success) {
      headerContent = headerMsg ? headerMsg : 'Password updated'
      content = contentMsg ? contentMsg : 'Password is successfully updated!'
    } else {
      headerContent = headerMsg ? headerMsg : 'Something went wrong'
      content = contentMsg
        ? contentMsg
        : 'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? thumbsUp : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.white}
                    text={'Got It'}
                    onClick={() => closePopup()}
                  ></Button>
                </>
              ) : (
                <>
                  <Button text={'Cancel'} className={styles['cancel-btn']} onClick={close}></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  const submitForm = (e: any) => {
    e.preventDefault()
    setData((prev: any) => {
      let blockPrev = { ...prev }
      Object.keys(blockPrev).forEach((each) => {
        blockPrev = { ...blockPrev, [each]: { ...blockPrev[each], isTouched: true } }
      })
      return blockPrev
    })
    if (
      data.password.value &&
      data.reEnterPass.value &&
      data.password.value !== data.reEnterPass.value
    ) {
      setFlag(1)
    } else {
      setFlag(0)
      if (data.code.isValid && data.password.isValid && data.reEnterPass.isValid && flag === 0) {
        fetchRest(
          {
            url: '/confirmPassword',
            method: 'POST',
            data: {
              confirmationCode: data.code.value,
              password: data.password.value,
              email: email,
            },
            isFullPageLoader: false,
          },
          'confirmPass'
        )
          .then((res) => {
            openPopup(true)
          })
          .catch((err) => {
            const error = err?.response?.data?.error?.message?.error?.code
            if (error === 'CodeMismatchException') {
              openPopup(false, 'Whoops', 'That is an invalid code')
            } else {
              openPopup(false)
            }
          })
      }
    }
  }
  const resendCode = (e: any) => {
    setCounter(480)
    fetchRest(
      {
        url: '/forgotPassword',
        method: 'POST',
        data: {
          email,
        },
        isFullPageLoader: false,
      },
      'forgotPass'
    )
      .then((res) => {
        //   openPopup(true)
      })
      .catch((err) => {
        //   openPopup(false)
      })
  }
  useEffect(() => {
    const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])
  return (
    <>
      <NoEventsLoader
        isLoading={sectionLoading && (sectionLoading.forgotPass || sectionLoading.confirmPass)}
      >
        <form onSubmit={submitForm}>
          <div className={styles.form}>
            <div className={styles.title}>
              {t('resendPass.enter-code')}
              <span>{destinationEmail}</span>
            </div>
            <div className={styles.field}>
              <InputWrapper
                type="text"
                onKeyDown={onKeyDownCost}
                valueObj={data.code}
                onChange={(e: any) => {
                  handleChange(e, 'code')
                }}
                schema={codeSchema}
                validateOnBlur
                validateOnChange
                placeholder={t('resendPass.placeholder')}
              />
            </div>
            <div className={styles.field}>
              <InputWrapper
                type={isPasswordShown ? 'text' : 'password'}
                valueObj={data.password}
                placeholder="Enter new Password"
                onChange={(e: any) => {
                  handleChange(e, 'password')
                }}
                inputIcon={eye}
                iconClick={() => setIsPasswordShown(!isPasswordShown)}
                schema={passwordSchema}
                validateOnBlur
                validateOnChange
                className={styles.paddingRight}
              />
            </div>

            <div>
              <InputWrapper
                type={isConfirmPasswordShown ? 'text' : 'password'}
                valueObj={data.reEnterPass}
                onChange={(e: any) => {
                  handleChange(e, 'reEnterPass')
                }}
                inputIcon={eye}
                iconClick={() => setIsConfirmPasswordShown(!isConfirmPasswordShown)}
                placeholder="Re-Enter New Password"
                schema={passwordConfirmSchema}
                validateOnBlur
                validateOnChange
                className={styles.paddingRight}
              />
            </div>
            <div>
              <div>
                {flag === 1 ? (
                  <div className={styles.errorMsg}>{t('connectPopup.passwordsDontMatch')}</div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className={styles.wrapper}>
              <Button
                text="Done"
                className={styles.button}
                ButtonImg={() => {
                  return (
                    <>
                      {sectionLoading && sectionLoading.confirmPass ? (
                        <Loader className="form_loader" size={20} thickness={2} />
                      ) : (
                        <>
                          <img src={tick} alt="tick" className={styles.imgBtn} />
                        </>
                      )}
                    </>
                  )
                }}
                type="submit"
              />
              <Button
                text={
                  counter > 0 ? `Resend in ${Math.floor(counter / 60)}:${counter % 60}` : 'Resend'
                }
                disabled={counter > 0 ? true : false}
                ButtonImg={() => {
                  return (
                    <>
                      {sectionLoading && sectionLoading.forgotPass ? (
                        <Loader className="form_loader" size={20} thickness={2} />
                      ) : (
                        <></>
                      )}
                    </>
                  )
                }}
                onClick={(e: any) => {
                  resend(e)
                }}
                className={counter > 0 ? styles.resend : styles.button}
              />
            </div>
          </div>
        </form>
      </NoEventsLoader>
    </>
  )
}
