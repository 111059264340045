import { AccordionSummary, AccordionSummaryProps } from '@material-ui/core'
import React from 'react'
import minus from '../../assets/images/svg/minus.svg'
import plus from '../../assets/images/svg/plus.svg'
import styles from './SummaryWrapper.module.scss'
interface SummaryWrapperProps extends AccordionSummaryProps {
  readonly Summary: React.FC
  readonly expanded: boolean
}
export const SummaryWrapper: React.FC<SummaryWrapperProps> = ({
  Summary,
  expanded,
  className,
  ...rest
}) => {
  return (
    <AccordionSummary
      {...rest}
      className={className ? `${className} ${styles.accordionHeader}` : styles.accordionHeader}
      expandIcon={
        expanded ? <img src={minus} alt="expand icon" /> : <img src={plus} alt="expand icon" />
      }
    >
      <Summary />
    </AccordionSummary>
  )
}
