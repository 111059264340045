/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { Autocomplete, Pagination } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TeacherRegistration from '../../assets/images/png/teacher_registration.png'
import Search from '../../assets/images/svg/search.svg'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { AutocompleteOption } from '../../common/autocomplete-option/AutocompleteOption'
import { Loader } from '../../common/Loader/Loader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { useFetch } from '../../common/https'
import { LoadingWrapper } from '../../common/loading-wrapper/LoadingWrapper'
import { RequestListCard } from '../../common/request-list-card/RequestListCard'
import { UsePopupContext } from '../../common/use-popup-context'
import { WelcomeWizard } from '../../components/welcome-wizard/WelcomeWizard'
import { Button } from '../../common/button/Button'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { EmptySection } from '../../common/empty-section/EmptySection'
import styles from './UnregisteredTeacherDashboard.module.scss'
import { ElementsWrapper } from '../../common/elements-wrapper/ElementsWrapper'

export const UnregisteredTeacherDashboard = () => {
  const { t } = useTranslation()
  const { fetchRest, sectionLoading } = useFetch({ requests: true })
  const { close, setData: setPopupData } = UsePopupContext()

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [data, setData] = useState<ReadonlyArray<{ [key: string]: any }>>([])
  const [count, setCount] = useState<number>(0)
  const [searchItems, setSearchItems] = useState<ReadonlyArray<{ [key: string]: any }>>([])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [options, setOptions] = useState<ReadonlyArray<{ [key: string]: any }>>([])
  const [paginationState, setPaginationState] = useState<{
    readonly perPage: number
    readonly page: number
  }>({
    perPage: 9,
    page: 1,
  })

  const fetchAndSetOptions = () => {
    fetchRest({ method: 'GET', url: 'requestCategoryAndSubCategory' })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res) => {
        if (res && res.data && res.data.data) {
          setOptions(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            res.data.data.map((each: any) => ({
              ...each,
              id: each.categoryId ? each.categoryId : each.subCategoryId,
            }))
          )
        }
      })
      .catch()
  }

  const getRequestListBody = () => {
    const categories =
      searchItems && searchItems.length
        ? searchItems.filter((each) => each.categoryId).map((each) => each.categoryId)
        : []
    const subCategories =
      searchItems && searchItems.length
        ? searchItems.filter((each) => each.subCategoryId).map((each) => each.subCategoryId)
        : []

    return { categories, subCategories }
  }

  const fetchAndSetData = () => {
    fetchRest(
      {
        method: 'PUT',
        url: 'trainerRequestList',
        queryParams: {
          sort: 'DESC',
          page: paginationState.page,
        },
        data: getRequestListBody(),
      },
      'requests'
    )
      .then((res) => {
        if (res && res.data && res.data.data) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const massagedData = res.data.data.map((each: any) => {
            let subject: string = ''
            if (each?.category?.name) {
              subject = each.category.name
            }
            if (each?.subCategory?.name) {
              subject = each.subCategory.name
            }
            if (each?.course?.name) {
              subject = each.course.name
            }
            return { ...each, subject }
          })
          setData(massagedData)
          setCount(res.data.count)
        }
      })
      .catch()
  }

  const openRegisterForm = () => {
    setIsDialogOpen(true)
  }

  const handleClose = () => {
    setIsDialogOpen(false)
  }

  const openRegisterPopup = () => {
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={TeacherRegistration} alt="popup icon" className={styles.imgWidth} />
        }}
        title={t('teacher_dashboard.youAreNotRegisteredAsATeacherYet')}
        Buttons={() => {
          return (
            <>
              <Button
                className={styles.register_button}
                text={t('teacher_dashboard.registerAsATeacher')}
                onClick={() => {
                  openRegisterForm()
                  close()
                }}
              ></Button>
              <Button
                text={t('common.close')}
                className={styles['cancel-btn']}
                onClick={close}
              ></Button>
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{t('teacher_dashboard.toViewRequestPleaseRegisterAsATeacher')}</p>
            </div>
          )
        }}
      />
    )
  }
  useEffect(() => {
    fetchAndSetOptions()
  }, [])

  useEffect(() => {
    fetchAndSetData()
  }, [paginationState, searchItems])

  return (
    <>
      <div className={styles.section}>
        <div className={styles.register_section}>
          <div className={styles.register_container}>
            <img
              className={styles.teacher_image}
              src={TeacherRegistration}
              alt="register_teacher"
            />
            <div className={styles.register_heading}>
              {t('teacher_dashboard.youAreNotRegisteredAsATeacherYet')}
            </div>
            <div className={styles.register_text}>
              {t('teacher_dashboard.registerNowAndStartEarning')}
            </div>
            <div className={styles.register_text}>
              {t('teacher_dashboard.learnMoreAboutTeacherAccount')}
            </div>
          </div>
          <div>
            <Button
              className={styles.register_button}
              text={t('teacher_dashboard.registerAsATeacher')}
              onClick={openRegisterForm}
            ></Button>
          </div>
        </div>
      </div>
      <div className={`${styles.section} ${styles.search_section}`}>
        <div className={styles.search_heading}>
          {t('teacher_dashboard.recentLearningRequests')}:
          <span className={styles.request_count}>{count}</span>
        </div>
        <div className="autocomplete-wrapper autocomplete_disable_icon_animation">
          <Autocomplete
            popupIcon={<img src={Search} alt="search_icon" />}
            placeholder={t('teacher_dashboard.searchRequestsIEDesignOrFigma')}
            value={searchItems as any[]}
            multiple
            onChange={(_event, newValue) => {
              setPaginationState((prev) => ({ ...prev, page: 1 }))
              setSearchItems(newValue)
            }}
            getOptionSelected={(option) =>
              searchItems.find((each) => each.id === option.id) ? true : false
            }
            ChipProps={{
              deleteIcon: <CloseOutlined />,
            }}
            classes={{ tag: 'autocomplete-custom-tag' }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderOption={(option: any) => (
              <AutocompleteOption
                option={
                  <>
                    {option.name}
                    <span className={styles.option_count}>
                      {option.requestCount ? option.requestCount : 0}
                    </span>
                  </>
                }
              />
            )}
            ListboxProps={{
              style: {
                maxHeight: '147px',
              },
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            options={options as any[]}
            getOptionLabel={(option) => {
              return option.name
            }}
            style={{ width: '100%', position: 'relative' }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('teacher_dashboard.searchRequestsIEDesignOrFigma')}
                variant="outlined"
              />
            )}
          />
        </div>
      </div>
      <div className={styles.section}>
        <LoadingWrapper
          isLoading={sectionLoading && sectionLoading.requests}
          LoaderComponent={() => (
            <div className={styles.loader_wrapper}>
              <Loader />
            </div>
          )}
        >
          <div
            className={`${styles.request_container} ${
              data && data.length && data.length % 3 ? styles.request_container_after : ''
            }`}
          >
            {data && data.length ? (
              <>
                {data.map((each) => {
                  return (
                    <React.Fragment key={each.requestId}>
                      <div className={styles.request_card} onClick={openRegisterPopup}>
                        <RequestListCard
                          data={each}
                          actionMenu={false}
                          callback={() => {}}
                          isTeacher={true}
                          detailPopup={false}
                        />
                      </div>
                    </React.Fragment>
                  )
                })}
              </>
            ) : (
              <>
                <EmptySection subText={t('teacher_dashboard.youGotNoRequestsYet')} />
              </>
            )}
          </div>
          {data && data.length ? (
            <div className={styles.pagination_wrapper}>
              <Pagination
                page={paginationState.page}
                onChange={(event, value) => {
                  setPaginationState((prev) => ({ ...prev, page: value }))
                }}
                count={Math.ceil(count / paginationState.perPage)}
              />
            </div>
          ) : (
            <></>
          )}
        </LoadingWrapper>
      </div>
      <DialogWrapper
        Title={(titleProps) => {
          return <DialogHeader {...titleProps} />
        }}
        open={isDialogOpen}
        onClose={handleClose}
        isFullScreen={false}
      >
        <ElementsWrapper>
          <WelcomeWizard closePopup={handleClose} isTeachersForm={true} />
        </ElementsWrapper>
      </DialogWrapper>
    </>
  )
}
