import axios from 'axios'
import { useState } from 'react'
import { useLoaderContext } from './use-loader-context'
import { getUserTokens, UnknownObject } from './utils'

const baseUrlDev = 'http://127.0.0.1:3000/dev/'

const baseUrl = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : baseUrlDev

export const axiosInstance = axios.create({
  baseURL: baseUrl,
})

/**
 * @param  url - URL without params - Ex:- /user
 * @param  params - params that needs to be attached to URL - Ex:- [1,3]
 * @returns URL padded with params i.e /user/1/3
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addParamsToUrl = (url: string, params: any[]) => {
  let urlWithParams = url
  if (Array.isArray(params)) {
    params.forEach((param) => {
      urlWithParams = `${urlWithParams}/${param}`
    })
  }
  return urlWithParams
}

export const useFetch = (sections?: { [key: string]: boolean }) => {
  const { hideLoader, showLoader } = useLoaderContext()

  const [sectionLoading, setSectionLoading] = useState(sections)

  const setLoadingOfSection = (sectionName: string, loading: boolean) => {
    setSectionLoading((prev) => ({
      ...prev,
      [sectionName]: loading,
    }))
  }

  const fetchRest = async (
    request: {
      url: string
      method: 'GET' | 'POST' | 'DELETE' | 'PUT'
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params?: any[]
      queryParams?: UnknownObject
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data?: any
      isFullUrl?: boolean
      headers?: UnknownObject
      isFullPageLoader?: boolean
    },
    sectionName?: string
  ) => {
    const {
      url,
      method = 'GET',
      params = [],
      queryParams = {},
      data,
      headers = {},
      isFullPageLoader,
    } = request
    const { idToken = '' } = getUserTokens()
    const headersWithAuthorization = idToken
      ? { ...headers, Authorization: `Bearer ${idToken}` }
      : headers
    let urlWithParams = params ? addParamsToUrl(url, params) : url
    if (sectionName) {
      setLoadingOfSection(sectionName, true)
    }
    if (isFullPageLoader) {
      showLoader()
    }
    try {
      const response = await axiosInstance.request({
        method,
        url: urlWithParams,
        params: queryParams,
        data: method !== 'GET' ? data : undefined,
        headers: headersWithAuthorization,
      })
      if (sectionName) {
        setLoadingOfSection(sectionName, false)
      }
      if (isFullPageLoader) {
        hideLoader()
      }
      if (response.data) {
        return await Promise.resolve(response)
      }
      return await Promise.reject(response)
    } catch (err) {
      if (sectionName) {
        setLoadingOfSection(sectionName, false)
      }
      if (isFullPageLoader) {
        hideLoader()
      }
      return Promise.reject(err)
    }
  }

  return { fetchRest, sectionLoading }
}
