import React from 'react'
import styles from './NoEventsLoader.module.scss'

export const NoEventsLoader: React.FC<{
  readonly isLoading: boolean | undefined
}> = ({ isLoading, children }) => {
  return (
    <>
      <div className={isLoading ? styles.disable_action : ''}>{children}</div>
    </>
  )
}
