import { Menu, MenuItem, MenuItemProps, MenuProps } from '@material-ui/core'
import React, { useState } from 'react'
import styles from './DropDown.module.scss'
export interface ListArrayItemProps extends MenuItemProps {
  listContent: React.ReactNode
  button?: true
}

interface MenuPropsExtended extends Partial<MenuProps> {}

export const DropDown: React.FC<{
  readonly ListArray: readonly ListArrayItemProps[]
  readonly menuProps?: MenuPropsExtended
  readonly selectedItem?: number
}> = ({ ListArray, children, menuProps, selectedItem = -1 }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <div onClick={handleClick}>{children}</div>
      <>
        <Menu
          open={anchorEl ? true : false}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          onClose={handleClose}
          classes={{
            paper: styles.menuChanges,
          }}
          {...menuProps}
        >
          {ListArray.map(
            ({ listContent, children: localChildren, onClick, value, ...rest }, index) => {
              return (
                <MenuItem
                  onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                    if (onClick) {
                      onClick(e)
                    }
                    handleClose()
                  }}
                  selected={selectedItem !== -1 && selectedItem === index ? true : false}
                  key={index}
                  {...rest}
                >
                  {listContent}
                </MenuItem>
              )
            }
          )}
        </Menu>
      </>
    </>
  )
}
