/* eslint-disable @typescript-eslint/no-explicit-any */
export const deleteCookie = (cookieName: string) => {
  document.cookie = `${cookieName} =;Domain=ontelo.io; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

export const removeUserTokens = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('idToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('identityId')
  localStorage.removeItem('accessKeyId')
  localStorage.removeItem('secretAccessKey')
  localStorage.removeItem('sessionToken')
  localStorage.removeItem('sessionExpiration')
  deleteCookie('accessToken')
}

export const getUserTokens = () => {
  const accessToken = localStorage.getItem('accessToken')
  const idToken = localStorage.getItem('idToken')
  const refreshToken = localStorage.getItem('refreshToken')
  const identityId = localStorage.getItem('identityId')
  const accessKeyId = localStorage.getItem('accessKeyId')
  const secretAccessKey = localStorage.getItem('secretAccessKey')
  const sessionToken = localStorage.getItem('sessionToken')
  const sessionExpiration = localStorage.getItem('sessionExpiration')
  return {
    accessToken: accessToken ? accessToken : '',
    idToken: idToken ? idToken : '',
    refreshToken: refreshToken ? refreshToken : '',
    identityId: identityId ? identityId : '',
    accessKeyId: accessKeyId ? accessKeyId : '',
    secretAccessKey: secretAccessKey ? secretAccessKey : '',
    sessionToken: sessionToken ? sessionToken : '',
    sessionExpiration: sessionExpiration ? sessionExpiration : '',
  }
}

export const getTextWithEllipsis = (text: string, subStringLength: number) => {
  const textLength = text.length
  if (subStringLength > textLength) {
    return text
  }
  const subString = text.substring(0, subStringLength + 1)
  const startIndexOfLastWord = subString.lastIndexOf(' ')
  const subStringWithEllipsis = `${subString.substring(0, startIndexOfLastWord)}...`
  return subStringWithEllipsis
}

export const getServiceFeePercent = () => {
  return Number(process.env.REACT_APP_ONTELO_SERVICE_FEE)
    ? Number(process.env.REACT_APP_ONTELO_SERVICE_FEE)
    : 10
}

export const getServiceFee = (hourlyRate: number) => {
  const serviceFeePercent = getServiceFeePercent()
  return (hourlyRate * serviceFeePercent) / 100
}

export const getUserFee = (hourlyRate: number) => {
  const serviceFeePercent = 100 - getServiceFeePercent()
  return (hourlyRate * serviceFeePercent) / 100
}

export const charactersLeft = ({
  currentLength,
  maxLength = 5000,
}: {
  readonly maxLength?: number
  readonly currentLength: number
}) => {
  if (currentLength) {
    return currentLength < maxLength ? maxLength - currentLength : 0
  }
  return maxLength
}
export const onKeyDownCost = (e: any) => {
  if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
    e.preventDefault()
  }
}

/**Use this function for all the input fields with type number to accept positive numbers which includes floating point values as well */
export const onKeyDown = (e: any) => {
  const regex = new RegExp(/^[1-9]\d*(\.\d+)?$/)
  if (!regex.test(e.target.value)) {
    e.preventDefault()
  }
}
export interface UnknownObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}
