/* eslint-disable @typescript-eslint/no-explicit-any */
import { debounce } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EmptySection } from '../../common/empty-section/EmptySection'
import { useFetch } from '../../common/https'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import { Loader } from '../../common/Loader/Loader'
import PopUpIcon from '../../assets/images/svg/popup-icon.svg'
import { LoadingWrapper } from '../../common/loading-wrapper/LoadingWrapper'
import { Search } from '../../common/search/Search'
import sortFilter from '../../assets/images/svg/sort-request.svg'
import { SessionListCard } from '../session-list-card/SessionListCard'
import styles from './SessionList.module.scss'
import grayStar from '../../assets/images/svg/gray-star.svg'
import { SessionCard } from '../session-card/SessionCard'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { CourseDetails } from '../course-details/CourseDetails'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { Button } from '../../common/button/Button'
import { UsePopupContext } from '../../common/use-popup-context'
import { DropDown, ListArrayItemProps } from '../../common/drop-down/DropDown'

export const SessionList: React.FC<{
  readonly inStudentDashboard?: boolean
  readonly statsFunction?: () => void
  readonly teamId?: string
}> = ({ inStudentDashboard = false, teamId = '', statsFunction = () => {} }) => {
  const { t } = useTranslation()
  const { fetchRest, sectionLoading } = useFetch({ sessionList: false })
  const [dropdownTag, setDropdownTag] = useState({
    index: 0,
    value: 'All',
    label: 'Show all',
  })
  const [count, setCount] = useState(0)
  const [sessions, setSessions] = useState([])
  const [paginationState, setPaginationState] = useState<{
    readonly perPage: number
    readonly page: number
  }>({
    perPage: 9,
    page: 1,
  })
  const [searchItems, setSearchItems] = useState<string>('')
  const [userRecommendation, setUserRecommendation] = useState<
    ReadonlyArray<{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any
    }>
  >([])

  const { close, setData: setPopupData } = UsePopupContext()
  const [selectedCourse, setSelectedCourse] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }>({})
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const caller = inStudentDashboard || teamId ? 'mentor' : 'attendee'

  const setSort = (variantIndex: number) => {
    if (variantIndex === 1) {
      setDropdownTag({ index: 1, value: 'Scheduled', label: 'Scheduled' })
    } else if (variantIndex === 2) {
      setDropdownTag({ index: 2, value: 'Delivered', label: 'Delivered' })
    } else if (variantIndex === 3) {
      setDropdownTag({ index: 3, value: 'Dispute', label: 'Dispute' })
    } else {
      setDropdownTag({ index: 0, value: 'All', label: 'Show all' })
    }
  }

  const listDropDown: ReadonlyArray<ListArrayItemProps> = [
    {
      listContent: (
        <>
          <div className={styles.element}>
            <div>Show all</div>
          </div>
        </>
      ),
      value: 'All',
      onClick: (_e) => {
        setSort(0)
        fetchSessionList({ status: 'All' })
      },
    },
    {
      listContent: (
        <>
          <div className={styles.element}>
            <div>Scheduled</div>
          </div>
        </>
      ),
      value: 'Scheduled',
      onClick: (_e) => {
        setSort(1)
        fetchSessionList({ status: 'Scheduled' })
      },
    },
    {
      listContent: (
        <>
          <div className={styles.element}>
            <div>Delivered</div>
          </div>
        </>
      ),
      value: 'Delivered',
      onClick: (_e) => {
        setSort(2)
        fetchSessionList({ status: 'Delivered' })
      },
    },
    {
      listContent: (
        <>
          <div className={styles.element}>
            <div>Dispute</div>
          </div>
        </>
      ),
      value: 'Dispute',
      onClick: (_e) => {
        setSort(3)
        fetchSessionList({ status: 'Dispute' })
      },
    },
  ]

  const getUserRecommendations = () => {
    fetchRest({ url: 'recommendedCourse', method: 'GET' }, 'recommendations')
      .then((res: any) => {
        if (res && res.data && res.data.data) {
          setUserRecommendation(res.data.data)
        } else {
          setUserRecommendation([])
        }
      })
      .catch()
  }

  const closeDialog = () => {
    setIsDialogOpen(false)
    setTimeout(() => {
      setSelectedCourse({})
    }, 200)
  }

  const fetchSessionList = (obj: {
    readonly search?: string
    readonly page?: number
    readonly perPage?: number
    readonly status?: string
  }) => {
    const {
      search = searchItems ? searchItems : undefined,
      page = paginationState.page,
      perPage = paginationState.perPage,
      status = dropdownTag.value,
    } = obj
    fetchRest(
      {
        url: `/session/${inStudentDashboard ? 'student' : teamId ? 'team' : 'teacher'}`,
        method: 'GET',
        queryParams: teamId
          ? {
              search,
              page,
              perPage,
              teamId,
              status,
            }
          : {
              search,
              page,
              perPage,
              status,
            },
        isFullPageLoader: false,
      },
      'sessionList'
    )
      .then((res) => {
        if (res && res.data && res.data.data) {
          setSessions(res.data.data)
          setCount(res.data.count)
        } else {
          setSessions([])
        }
      })
      .catch()
  }

  const changeHandler = (event: any, value: any) => {
    setPaginationState((prev) => ({ ...prev, page: value }))
    fetchSessionList({ page: value, perPage: paginationState.perPage })
  }

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: nextValue } = e.target
    setPaginationState((prev) => ({ ...prev, page: 1 }))
    setSearchItems(nextValue)
    debouncedSave(nextValue)
  }

  const debouncedSave = useCallback(
    debounce((nextValue: string) => {
      fetchSessionList({ search: nextValue, page: 1 })
    }, 1000),
    []
  )

  const openPopup = (success: boolean) => {
    let content: string = ''
    let headerContent: string = ''
    if (success) {
      headerContent = 'Great, you’ve submitted your learning request!'
      content = 'Now sit tight, one of the ontelo experts will be in touch with you soon'
    } else {
      headerContent = 'Something went wrong'
      content =
        'Unfortunately, a server error prevented your request from being completed. ontelo might be undergoing maintenance or your connection may have timed out. Please refresh the page or try again later.'
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img src={success ? PopUpIcon : RepeatRequest} alt="popup icon" />
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {success ? (
                <>
                  <Button
                    className={styles.white}
                    text={'Got It'}
                    onClick={() => {
                      close()
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button
                    text={'Cancel'}
                    className={styles['cancel-btn']}
                    onClick={() => {
                      close()
                    }}
                  ></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  const onSubmitRequest = () => {
    // Do API Call here
    fetchRest(
      {
        url: '/userSubmitRequest',
        method: 'POST',
        data: {
          description: selectedCourse.title,
          courseId: selectedCourse.courseId,
        },
      },
      'submitRequest'
    )
      .then((_res) => {
        openPopup(true)
      })
      .catch((err) => {
        openPopup(false)
      })
  }

  useEffect(() => {
    fetchSessionList({})
    if (!teamId) {
      getUserRecommendations()
    }
  }, [])

  return (
    <>
      <div className={styles.rowWrapper}>
        <div
          className={teamId ? `${styles.proposalWrapper} ${styles.mTop}` : styles.proposalWrapper}
        >
          <div className={`${styles.section} ${styles.search_section}`}>
            <div className={styles.search_heading}>
              {t('teacher_dashboard.activeSession')}:
              <span className={styles.request_count}>&nbsp;{count}</span>
            </div>
            <div>
              <Search
                type="text"
                placeholder={`Search session or ${caller} name...`}
                onChange={onChangeSearch}
                value={searchItems}
                autoFocus
                autoComplete="off"
              />
            </div>
            <div className={styles['list-card-bottom']}>
              <div className={styles['d-flex']}>
                <div className={styles.dropdown_container}>
                  <DropDown
                    menuProps={{
                      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                      transformOrigin: { vertical: 'top', horizontal: 'left' },
                    }}
                    ListArray={listDropDown}
                    selectedItem={dropdownTag.index}
                  >
                    <div className={styles['list-filter']}>
                      <img src={sortFilter} alt="sort" /> {dropdownTag.label}
                    </div>
                  </DropDown>
                </div>
              </div>
            </div>
          </div>

          <div className={styles['d-flex']}>
            <div className={`${styles.section} ${styles.proposal_list} ${styles.marginBtm}`}>
              <LoadingWrapper
                isLoading={sectionLoading && sectionLoading.sessionList}
                LoaderComponent={() => <Loader />}
              >
                {sessions.length ? (
                  sessions.map((each, index) => {
                    return (
                      <div key={index}>
                        <SessionListCard
                          data={each}
                          inStudentDashboard={teamId ? true : inStudentDashboard}
                          fetchProposalList={() => {
                            fetchSessionList({})
                            statsFunction()
                          }}
                          teamId={teamId ? teamId : undefined}
                        />
                      </div>
                    )
                  })
                ) : (
                  <div className={styles.center}>
                    <EmptySection subText="No Sessions here!" />
                  </div>
                )}
                {count ? (
                  <div className={styles.pagination}>
                    <Pagination
                      count={Math.ceil(count / paginationState.perPage)}
                      onChange={changeHandler}
                      page={paginationState.page}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </LoadingWrapper>
            </div>
            {inStudentDashboard ? (
              <div className={styles['request-lists-right']}>
                <div className={styles['review-card__align-items']}>
                  <img src={grayStar} alt="star" className={styles['review-card__time-icon']} />
                  <div className={styles['time-subject']}>
                    {t('requestDetails.recommendations')}
                  </div>
                </div>
                <LoadingWrapper
                  isLoading={sectionLoading && sectionLoading.recommendations}
                  LoaderComponent={Loader}
                >
                  {userRecommendation.map((item) => {
                    return (
                      <SessionCard
                        imageUrl={item.imageUrl}
                        key={item.courseId}
                        header={item.title}
                        timer={item.duration}
                        onCardClick={() => {
                          setSelectedCourse(item)
                          setIsDialogOpen(true)
                        }}
                      />
                    )
                  })}
                </LoadingWrapper>
              </div>
            ) : (
              <> </>
            )}
          </div>
        </div>
      </div>
      <DialogWrapper
        Title={(titleProps) => {
          return <DialogHeader {...titleProps} />
        }}
        open={isDialogOpen}
        onClose={closeDialog}
        isFullScreen={false}
      >
        <CourseDetails
          isRequestSubmitting={sectionLoading && sectionLoading.submitRequest}
          selectedCourse={selectedCourse}
          onSubmitRequest={onSubmitRequest}
        />
      </DialogWrapper>
    </>
  )
}
