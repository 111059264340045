/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import styles from './RecommendCourseForm.module.scss'
import star from '../../assets/images/svg/gray2-star.svg'
import jointeam from '../../assets/images/svg/joinTeam.svg'
import { Button } from '../../common/button/Button'
import whiteTick from '../../assets/images/svg/whiteTick.svg'
import { Autocomplete } from '@material-ui/lab'
import search from '../../assets/images/svg/search.svg'
import { AutocompleteOption } from '../../common/autocomplete-option/AutocompleteOption'
import { debounce, TextField } from '@material-ui/core'
import { useFetch } from '../../common/https'
import { RecommendCourseAccordion } from '../accordion-recommend/RecommendCourseAccordion'
import { validateInput } from '../../common/ValidateFields'
import { schemaAreaOfExpertise } from '../../common/schema/Schema'
import { Search } from '../../common/search/Search'
import { EmailList } from '../email-list/EmailList'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { UsePopupContext } from '../../common/use-popup-context'
import teamIcon from '../../assets/images/svg/TeamIconColor.svg'
import { Loader } from '../../common/Loader/Loader'

interface CategoryType {
  categoryId: string
  description?: string
  imageUrl?: string
  name: string
}

interface StudentType {
  studentId: string
  description?: string
  imageUrl?: string
  name: string
}

export const RecommendCourseForm: React.FC<{ readonly teamId: string; readonly closePopup: any }> =
  ({ teamId, closePopup }) => {
    const [data, setData] = useState({
      category: { value: null, isValid: false, errorMsg: '', isTouched: false },
      attendee: { value: [], isValid: false, errorMsg: '', isTouched: false },
    })
    const [studentAvailable, setStudentAvailable] = useState<boolean>(false)
    const [membersList, setMembersList] = useState([])
    const [top, setTop] = useState(false)
    const [searchItems, setSearchItems] = useState<any>([])
    const [searchValue, setSearchValue] = useState('')
    const { fetchRest, sectionLoading } = useFetch({ recommend: false })
    const [course, setCourse] = useState('')
    const [category, setCategory] = useState<Array<CategoryType>>([])
    const [accordionIsTouched, setAccordionIsTouched] = useState<boolean>(false)
    const [studentIsTouched, setStudentIsTouched] = useState<boolean>(false)
    const { close, setData: setPopupData } = UsePopupContext()
    const [categoryId, setCategoryId] = useState<string>('')
    const [subCategoryId, setSubCategoryId] = useState<string>('')
    const [memberIdArray, setMemberIdArray] = useState<any>([])
    const removeUser = (index: any) => {
      let arr = searchItems
      setSearchItems([])
      arr.forEach((each: any, i: any) => {
        if (i !== index) {
          setSearchItems((prev: any) => {
            return [...prev, each]
          })
        }
      })
    }
    const autoCompleteOnChange = (newValue: any, key: string) => {
      if (key === 'attendee') {
      } else if (key === 'category') {
        setAccordionIsTouched(true)
        var valid = validateInput(schemaAreaOfExpertise, newValue)
        setData((prev: any) => {
          return {
            ...prev,
            [key]: {
              value: newValue,
              isValid: valid.isValid,
              isTouched: true,
              errorMsg: valid.errorMsg,
            },
          }
        })
      }
    }
    const fetchCategoryOrSoftware = () => {
      fetchRest({
        url: '/category',
        method: 'GET',
      }).then((res: any) => {
        if (res && res.data && res.data.data) {
          setCategory(
            res.data.data.map((each: any) => {
              return {
                categoryId: each.categoryId,
                name: each.name,
                description: each.description,
                imageUrl: each.imageUrl,
              }
            })
          )
        } else {
          setCategory([])
        }
      })
    }
    const onChangeSearch = (e: any) => {
      if (e.target.value.length > 0) {
        setStudentAvailable(true)
      } else {
        setStudentAvailable(false)
        setTop(false)
      }
      const value = e.target.value
      setSearchValue(value)
      debouncedSave(value)
    }

    const debouncedSave = useCallback(
      debounce((nextValue: React.SetStateAction<string>) => {
        fetchRest({
          method: 'GET',
          url: `/user-list?email=${nextValue}`,
        })
          .then((res) => {
            setMembersList(res.data.data)
          })
          .catch()
      }, 200),
      []
    )
    const onClickHandler = (item: any) => {
      setStudentIsTouched(true)
      setSearchItems((prev: any) => {
        return [...prev, item]
      })
      setTop(false)
      setStudentAvailable(false)
      setSearchValue('')
      // because we already selected a student
    }

    const handleSetCourse = (ele: any, child: any, item: any) => {
      setCourse(ele?.courseId)
      setSubCategoryId(child?.subCategoryId)
      setCategoryId(item.categoryId)
    }

    const popup = (success: boolean, contentFromApi?: string) => {
      let content: string = ''
      let headerContent: string = ''
      if (success) {
        content = 'The listed team members received the course recommended by you'
        headerContent = 'Recommendation sent!'
      } else {
        content = 'Could not send recommendation'
        headerContent = contentFromApi ? contentFromApi : ''
      }
      setPopupData(
        <AlertPopupWrapper
          Img={() => {
            return <img src={teamIcon} alt="popup icon" />
          }}
          title={headerContent}
          Buttons={() => {
            return (
              <>
                {
                  <>
                    <Button
                      className={styles.white}
                      text={'Got It'}
                      onClick={() => {
                        close()
                        closePopup()
                      }}
                    ></Button>
                  </>
                }
              </>
            )
          }}
          Content={() => {
            return (
              <div>
                <p>{content}</p>
              </div>
            )
          }}
        />
      )
    }
    const onSubmit = (e: any) => {
      setStudentIsTouched(true)
      setAccordionIsTouched(true)
      if (course && searchItems.length > 0) {
        // api to add to recommendation for course
        fetchRest(
          {
            url: `team/${teamId}/recommend-courses-to-team-member`,
            method: 'POST',
            data: {
              courseId: course,
              memberIds: Array.from(new Set(memberIdArray)),
              subCategoryId: subCategoryId,
              categoryId: categoryId,
            },
          },
          'recommend'
        )
          .then((res: any) => {
            popup(true)
          })
          .catch((err: any) => {
            const error = err?.response?.data?.error?.message
            if (error !== '') {
              popup(false, 'User is not a part of this team')
            } else {
              popup(false)
            }
          })
      }
    }
    useEffect(() => {
      searchItems.map((each: any) => {
        return (
          <>
            {setMemberIdArray((prev: any) => {
              return [...prev, each.userId]
            })}
          </>
        )
      })
    }, [searchItems])
    useEffect(() => {
      fetchCategoryOrSoftware()
    }, [])
    return (
      <div>
        <div className={`${styles.element} ${styles.elementBtm}`}>
          <div className={`${styles.title} ${styles.padding}`}>
            <img src={star} className={styles.divImg} alt="star" /> <div>Specify course</div>
          </div>
          <div className={styles.padding}>
            <div className={styles.field_label}>Category & Software</div>
            <div className={styles.field_label_text}>Specify category or exact Software </div>
            <div className={styles.field_wrapper}>
              <div className="autocomplete-wrapper search">
                <Autocomplete
                  popupIcon={<img src={search} alt="search" />}
                  value={data.category.value}
                  onChange={(event, newValue) => {
                    autoCompleteOnChange(newValue, 'category')
                  }}
                  handleHomeEndKeys
                  options={category}
                  getOptionLabel={(option) => {
                    return option.name
                  }}
                  renderOption={(option: any) => <AutocompleteOption option={option.name} />}
                  ListboxProps={{
                    style: {
                      maxHeight: '147px',
                    },
                  }}
                  style={{ width: '100%', position: 'relative' }}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
              </div>
            </div>
            <div className={styles.field_wrapper}></div>
          </div>
          <div>
            {data.category.value !== '' ? (
              <>
                <RecommendCourseAccordion
                  selectedCategory={data.category.value ? data.category.value : {}}
                  setSelected={handleSetCourse}
                  teamId={teamId}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={styles.element}>
          <div className={`${styles.title} ${styles.padding}`}>
            <img src={jointeam} className={styles.divImg} alt="star" /> <div>Team Members</div>
          </div>
          <div className={styles.padding}>
            <div className={styles.field_label}>Specify Member</div>
            <div className={styles.field_label_text}>Select one or more Team Members</div>
          </div>
          <div className={styles.field_wrapper}>
            <div>
              <Search
                type={'text'}
                placeholder={'Start typing email id'}
                onChange={onChangeSearch}
                value={searchValue}
                className={styles.backgroundImg}
                autoComplete="off"
              />
              {studentAvailable && membersList.length > 0 ? (
                <ul className={styles.list}>
                  {membersList.map((item: any, index: number) => {
                    return (
                      <>
                        <li key={index} onClick={() => onClickHandler(item)}>
                          <div>{item.email}</div>
                        </li>
                      </>
                    )
                  })}
                </ul>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className={styles.padding}>
            {searchItems.map((each: any, index: any) => {
              return <EmailList each={each} removeUser={removeUser} index={index} key={index} />
            })}
          </div>
          <div className={styles.errorMsg}>
            {!course && accordionIsTouched
              ? searchItems.length < 1 && studentIsTouched
                ? '*Please enter Student email and course Name'
                : '*Please select a course'
              : searchItems.length < 1 && studentIsTouched
              ? 'Please enter student email'
              : ''}
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              text="Send Recommendation"
              className={styles.button}
              ButtonImg={() => {
                return (
                  <>
                    {sectionLoading && sectionLoading.recommend ? (
                      <Loader size={20} thickness={2} className={styles.divImg} />
                    ) : (
                      <img src={whiteTick} className={styles.divImg} alt="tick" />
                    )}
                  </>
                )
              }}
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    )
  }
