/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import attention from '../../assets/images/svg/attention.svg'
import closeIcon from '../../assets/images/svg/closeGrey.svg'
import warn from '../../assets/images/svg/warningIcon.svg'
import RepeatRequest from '../../assets/images/svg/repeatRequest.svg'
import feedback from '../../assets/images/svg/feedback.svg'
import connection from '../../assets/images/svg/connection.svg'
import pencil from '../../assets/images/svg/editGrey.svg'
import tick from '../../assets/images/svg/whiteTick.svg'
import file from '../../assets/images/svg/fileIcon.svg'
import checkboxBlue from '../../assets/images/svg/checkbox-blue.svg'
import fileIcon from '../../assets/images/svg/Group.svg'
import pinkStar from '../../assets/images/svg/pinkStar.svg'
import star from '../../assets/images/svg/star.svg'
import Grade from '@material-ui/icons/Grade'
import groupIcon from '../../assets/images/svg/groupIcon.svg'
import question from '../../assets/images/svg/question.svg'
import finance from '../../assets/images/svg/finance.svg'
import proposals from '../../assets/images/svg/proposals.svg'
import timeIcon from '../../assets/images/svg/time-icon.svg'
import { Button } from '../../common/button/Button'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { CreateProposal } from '../create-proposal/CreateProposal'
import { NameInitials } from '../name-initials/NameInitials'
import styles from './ProposalDetails.module.scss'
import Moment from 'react-moment'
import { useTranslation } from 'react-i18next'
import { useFetch } from '../../common/https'
import { AlertPopupWrapper } from '../../common/alert-popup-wrapper/AlertPopupWrapper'
import { UsePopupContext } from '../../common/use-popup-context'
import deleteRequestIcon from '../../assets/images/svg/delete-request.svg'
import checkbox from '../../assets/images/svg/checkbox.svg'
import Done from '../../assets/images/png/done.png'
import repeatRequest from '../../assets/images/svg/repeatRequest.svg'
import { WithdrawResponse } from '../withdraw-response/WithdrawResponse'
import { NoEventsLoader } from '../../common/no-events-loader/NoEventsLoader'
import { Loader } from '../../common/Loader/Loader'
import { Rating } from '@material-ui/lab'
import { styled } from '@material-ui/core'
import { FinishSession } from '../finish-session/FinishSession'
import redClock from '../../assets/images/svg/red-clock.svg'

import moment from 'moment'
import { GetPaid } from '../get-paid/GetPaid'
import { ElementsWrapper } from '../../common/elements-wrapper/ElementsWrapper'
import { useUserDetailsContext } from '../../common/use-user-details-context'

import {
  TeacherChatUserProfile,
  StudentChatUserProfile,
} from '../chat-user-profile/ChatUserProfile'
const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
})
export const ProposalDetails: React.FC<{
  readonly inArchive?: boolean
  readonly objectReceived: any
  readonly isAttendee: boolean
  readonly onEdit?: () => void
  readonly onClose: () => void
  readonly isSession?: boolean
  readonly fromChat?: boolean
  readonly teamId?: string
  readonly forApproval?: boolean
  readonly memberRole?: string
  readonly teamStats?: () => void
}> = ({
  objectReceived,
  inArchive = false,
  isAttendee,
  teamStats = () => {},
  onClose,
  isSession = false,
  fromChat = false,
  teamId = '',
  forApproval = false,
  memberRole,
}) => {
  const [editDialog, setEditDialog] = useState(false)
  const [isSessionFinished, setIsSessionFinished] = useState<boolean>(false)
  const { t } = useTranslation()
  const { fetchRest, sectionLoading } = useFetch({ accept: false, approve: false })
  const [getPaid, setGetPaid] = useState<boolean>(false)

  const { countries } = useUserDetailsContext()

  const [profile, setProfile] = useState(false)
  const [profileDetails, setProfileDetails] = useState({
    isTeacher: false,
    userId: '',
  })

  const setProfileInfo = (userId: string, isTeacher: boolean) => {
    setProfileDetails({
      isTeacher: isTeacher,
      userId: userId,
    })
    setProfile(true)
  }

  const price =
    Number(
      isSession
        ? objectReceived.proposal.proposalDetail.hourlyRate
        : objectReceived.proposalDetail.hourlyRate
    ) *
    Number(
      isSession
        ? objectReceived.proposal.proposalDetail.sessionTimeInHour
        : objectReceived.proposalDetail.sessionTimeInHour
    )
  const priceString = `Accept and pay $ ${
    Number(
      isSession
        ? objectReceived.proposal.proposalDetail.hourlyRate
        : objectReceived.proposalDetail.hourlyRate
    ) *
    Number(
      isSession
        ? objectReceived.proposal.proposalDetail.sessionTimeInHour
        : objectReceived.proposalDetail.sessionTimeInHour
    )
  } `

  const now = new Date()
  const sessionEndTime = moment(
    isSession
      ? objectReceived?.proposal?.proposalDetail.sessionDate
      : objectReceived.proposalDetail.sessionDate
  ).add(
    isSession
      ? objectReceived.proposal.proposalDetail.sessionTimeInHour
      : objectReceived.proposalDetail.sessionTimeInHour,
    'hours'
  )
  const currentTime = moment(now).format('YYYY-MM-DD HH:MM:SS')
  const sessionEndDate = moment(sessionEndTime).format('YYYY-MM-DD HH:MM:SS')
  const { close, setData: setPopupData } = UsePopupContext()
  const [onClickCancel, setOnClickCancel] = useState<boolean>(false)
  const [isDisputeOpen, setIsDisputeOpen] = useState<boolean>(false)
  const cancelBtn: string = isSession ? 'Cancel Session' : 'Cancel Proposal'

  const popUp = (key: string, success: boolean) => {
    let headerContent: string = ''
    let content: string = ''
    if (key === 'accept' && success) {
      headerContent = t('requestDetails.accepted')
      content = ''
    } else if (key === 'accept' && !success) {
      headerContent = t('requestDetails.failed-to-accept')
      content = ''
    } else if (key === 'approve') {
      if (success) {
        headerContent = t('requestDetails.approved')
        content = t('requestDetails.approvedContent')
      } else {
        headerContent = t('requestDetails.cannotapprove')
        content = ''
      }
    } else if (key === 'reject' && success) {
      headerContent = t('requestDetails.ignored')
      content = t('requestDetails.ignoredContent')
    } else if (key === 'reject' && !success) {
      headerContent = t('requestDetails.failed-to-ignore')
      content = ''
    } else if (key === 'cancel' && success) {
      headerContent = t('requestDetails.withdrawn')
      content = t('requestDetails.withdrawnContent')
    } else if (key === 'payment' && success) {
      headerContent = 'Payment option not available'
      content = 'Mentor country doesnt accept online payments. Please do offline payments.'
    } else if (key === 'Btn' && !success) {
      headerContent = 'Less than 24 hours to your session!'
      content = ' You cannot cancel the session now! '
    } else if (key === 'Btn-ongoing' && !success) {
      headerContent = 'Session is ongoing'
      content = ' You cannot cancel the session now! '
    } else if (key === 'fin' && !success) {
      headerContent = 'Your session is not yet completed'
      content = ' You cannot finish the session now! '
    } else {
      headerContent = t('requestDetails.withdrawn')
      content = t('requestDetails.failed-to-withdraw')
    }
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return (
            <img
              src={
                success
                  ? key === 'accept' || key === 'approve' || key === 'payment'
                    ? Done
                    : deleteRequestIcon
                  : repeatRequest
              }
              alt="popup"
              className={styles.widthPopup}
            />
          )
        }}
        title={headerContent}
        Buttons={() => {
          return (
            <>
              {(key === 'accept' || key === 'reject' || key === 'cancel' || key === 'approve') &&
              success ? (
                <>
                  <Button
                    className={styles.green}
                    text={'Got It'}
                    onClick={() => {
                      close()
                      onClose()
                      // if (teamId) {
                      //   teamStats()
                      // }
                    }}
                  ></Button>
                </>
              ) : (
                <>
                  <Button
                    text={'OK'}
                    onClick={(e) => {
                      close()
                    }}
                    className={styles.green}
                  ></Button>
                </>
              )}
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{content}</p>
            </div>
          )
        }}
      />
    )
  }

  const timeDiff = (time: string) => {
    let x = new Date(time).getTime() - new Date().getTime()
    if (Math.floor(x / (1000 * 60 * 60)) <= -1) {
      return true
    } else {
      return false
    }
  }
  const acceptProposal = () => {
    fetchRest(
      {
        url: `/acceptProposal/${objectReceived.proposalId}`,
        method: 'PUT',
        isFullPageLoader: true,
      },
      'accept'
    )
      .then((res) => {
        popUp('accept', true)
      })
      .catch((err) => {
        if (err?.response?.data?.error?.errorStatus) {
          errorProposalPopup(err.response.data.error?.message)
        } else {
          popUp('accept', false)
        }
      })
  }

  const paymentStatusInfo = (status: string, sessionStatus: string) => {
    let statusTitle = '--'
    let statusInfo = '--'
    if (sessionStatus !== 'Dispute') {
      if (objectReceived.proposal.paymentStatus === 'Completed') {
        let supported = false
        if (countries.length > 0) {
          countries.forEach((each: any) => {
            if (each === objectReceived.proposal.owner.countryCode) {
              supported = true
            }
          })
        }
        statusTitle = 'Paid'
        if (supported) {
          statusInfo = isAttendee
            ? 'Funds are paid to counter party'
            : 'Funds should be in your account shortly'
        } else {
          statusInfo = isAttendee
            ? 'Funds are paid to counter party'
            : 'Payment will be made direct to your bank account upon receipt of an invoice from you. Please email your invoice to accounts@ontelo.io'
        }
      } else {
        switch (status) {
          case 'In-Escrow':
            statusTitle = 'In escrow'
            statusInfo = isAttendee
              ? 'Payment will be forwarded to counter party when both parties will finish the session'
              : 'Payment will be made direct to your bank account upon receipt of an invoice from you. Please email your invoice to accounts@ontelo.io'
            break
          case 'In-Review':
            statusTitle = 'In review'
            statusInfo = isAttendee
              ? 'Funds are on a way to counter party. This process usually takes a couple of working days'
              : 'Funds are on a way to you. This process usually takes a couple of working days'
            break
        }
      }
    } else {
      statusTitle = 'In escrow'
      statusInfo = 'Money are frozen in the escrow until dispute will be resolved'
    }

    return (
      <>
        <div className={`${styles.statusFin} ${styles[status]}`}>{statusTitle}</div>
        <div className={styles.statusAbout}>{statusInfo}</div>
      </>
    )
  }
  const openDispute = () => {
    return (
      <Button
        text="Open a dispute"
        className={`${styles.commonBtn} ${styles.largeBtn}`}
        ButtonImg={() => {
          return <img src={warn} className={styles.btnImg} alt="warn" />
        }}
        onClick={() => {
          setIsDisputeOpen(true)
        }}
      />
    )
  }
  const errorProposalPopup = (errorMessage: string = '') => {
    setPopupData(
      <AlertPopupWrapper
        Img={() => {
          return <img className={styles.popupImage} src={RepeatRequest} alt="popup" />
        }}
        title={`${t('common.failed')}!`}
        Buttons={() => {
          return (
            <>
              <Button
                className={styles.proposal_cancel_btn}
                text={t('common.cancel')}
                onClick={() => {
                  close()
                }}
              ></Button>
            </>
          )
        }}
        Content={() => {
          return (
            <div>
              <p>{errorMessage}</p>
            </div>
          )
        }}
      />
    )
  }
  const approveProposal = () => {
    fetchRest(
      {
        url: `/approveProposal/${objectReceived.proposalId}`,
        method: 'GET',
      },
      'approve'
    )
      .then((res: any) => {
        popUp('approve', true)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }
  return (
    <>
      <div className={styles.outerDiv}>
        <div className={styles.element}>
          {isSession ? (
            <div className={styles['d-flex']}>
              <div
                className={
                  objectReceived.status === 'Scheduled'
                    ? `${styles['review-card__align-items']} ${styles['green-label']} ${styles.zeroMinWidth}`
                    : objectReceived.status === 'Dispute'
                    ? `${styles['review-card__align-items']} ${styles['red-label']} ${styles.zeroMinWidth}`
                    : `${styles['review-card__align-items']} ${styles['blue-label']} ${styles.zeroMinWidth}`
                }
              >
                <div className={styles['review-card__time-subject']}>{objectReceived.status}</div>
              </div>
              {objectReceived.status !== 'Dispute' ? (
                inArchive ? (
                  <></>
                ) : (
                  <div
                    className={
                      timeDiff(objectReceived.proposal.proposalDetail.sessionDate)
                        ? `${styles['review-card__align-items']} ${styles['red-patch']}`
                        : `${styles['review-card__align-items']} ${styles.gray}`
                    }
                  >
                    <img
                      src={
                        timeDiff(objectReceived.proposal.proposalDetail.sessionDate)
                          ? redClock
                          : timeIcon
                      }
                      alt="time"
                      className={styles['review-card__time-icon']}
                    />
                    <div
                      className={
                        !isSession
                          ? `${styles['review-card__time-subject']} ${styles.widthProposal}`
                          : styles['review-card__time-subject']
                      }
                    >
                      {objectReceived.sessionIn}
                    </div>
                  </div>
                )
              ) : (
                <></>
              )}
            </div>
          ) : (
            <>
              <div className={styles['d-flex']}>
                {objectReceived.status !== 'Dispute' ? (
                  <div className={` ${styles['review-card__align-items']} ${styles.gray}`}>
                    <img src={timeIcon} alt="time" className={styles['review-card__time-icon']} />
                    <div className={styles['review-card__time-subject']}>
                      {t('requestDetails.posted')} {objectReceived.createdOn}
                      {t('requestDetails.ago')}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}

          <div className={styles.courseTitle}>
            {isSession
              ? objectReceived?.proposal?.proposalDetail.title
              : objectReceived.proposalDetail.title}
          </div>
          <div className={styles.dates}>
            <Moment
              date={
                isSession
                  ? objectReceived?.proposal?.proposalDetail.sessionDate
                  : objectReceived.proposalDetail.sessionDate
              }
              format="DD MMM"
              interval={0}
            />
            ,
            <Moment
              date={
                isSession
                  ? objectReceived?.proposal?.proposalDetail.sessionDate
                  : objectReceived.proposalDetail.sessionDate
              }
              format="HH:mm"
              interval={0}
            />
            -
            <Moment
              date={
                isSession
                  ? objectReceived?.proposal?.proposalDetail.sessionDate
                  : objectReceived.proposalDetail.sessionDate
              }
              format="HH:mm"
              interval={0}
              add={{
                hours: isSession
                  ? objectReceived?.proposal?.proposalDetail.sessionTimeInHour
                  : objectReceived.proposalDetail.sessionTimeInHour,
              }}
            />
          </div>
          <div
            className={`${styles.owner} ${styles.cursor}`}
            onClick={() => {
              if (isAttendee) {
                setProfileInfo(
                  isSession ? objectReceived?.proposal?.ownerId : objectReceived?.ownerId,
                  true
                )
              } else {
                setProfileInfo(
                  isSession
                    ? objectReceived?.proposal?.request.userId
                    : objectReceived?.request.userId,
                  false
                )
              }
            }}
          >
            {isAttendee ? (
              <>
                <div className={styles.icon}>
                  {(
                    isSession
                      ? objectReceived?.proposal?.owner.imageUrl
                      : objectReceived.owner.imageUrl
                  ) ? (
                    <img
                      src={
                        isSession
                          ? objectReceived?.proposal?.owner.imageUrl
                          : objectReceived.owner.imageUrl
                      }
                      className={styles.icon}
                      alt="owner"
                    />
                  ) : (
                    <>
                      <NameInitials
                        firstName={
                          isSession
                            ? objectReceived?.proposal?.owner.firstName
                            : objectReceived.owner.firstName
                        }
                        lastName={
                          isSession
                            ? objectReceived?.proposal?.owner.lastName
                            : objectReceived.owner.lastName
                        }
                        className={styles.radius}
                      />
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className={styles.icon}>
                  {(
                    isSession
                      ? objectReceived?.proposal?.request?.user?.imageUrl
                      : objectReceived.request.user.imageUrl
                  ) ? (
                    <img
                      src={
                        isSession
                          ? objectReceived?.proposal?.request?.user?.imageUrl
                          : objectReceived.request.user.imageUrl
                      }
                      className={styles.icon}
                      alt="user"
                    />
                  ) : (
                    <>
                      <NameInitials
                        firstName={
                          isSession
                            ? objectReceived?.proposal?.request?.user?.firstName
                            : objectReceived.request.user.firstName
                        }
                        lastName={
                          isSession
                            ? objectReceived?.proposal?.request?.user?.lastName
                            : objectReceived.request.user.lastName
                        }
                        className={styles.radius}
                      />
                    </>
                  )}
                </div>
              </>
            )}
            <div>
              <div className={styles.fullName}>
                {isAttendee ? (
                  <>
                    {isSession
                      ? objectReceived?.proposal?.owner?.firstName
                      : objectReceived.owner.firstName}{' '}
                    {isSession
                      ? objectReceived?.proposal?.owner?.lastName.charAt(0)
                      : objectReceived.owner.lastName.charAt(0)}
                    .
                  </>
                ) : (
                  <>
                    {isSession
                      ? objectReceived?.proposal?.request.user?.firstName
                      : objectReceived.request.user.firstName}{' '}
                    {isSession
                      ? objectReceived?.proposal?.request.user?.lastName.charAt(0)
                      : objectReceived.request.user.lastName.charAt(0)}
                    .
                  </>
                )}
              </div>
              <div className={styles.title}>{isAttendee ? 'Mentor' : 'Attendee'}</div>
            </div>
          </div>
          <NoEventsLoader isLoading={sectionLoading && sectionLoading.accept}>
            {isSession ? (
              <>
                {objectReceived.status === 'Scheduled' ? (
                  <div className={styles.buttons}>
                    {(isAttendee && objectReceived.traineeCompletionDate) ||
                    (!isAttendee && objectReceived.trainerCompletionDate) ||
                    (!isAttendee && objectReceived.traineeCompletionDate) ? (
                      // openDispute()
                      <></>
                    ) : (
                      <>
                        <Button
                          text="Finish session"
                          ButtonImg={() => {
                            return <img src={tick} className={styles.btnImg} alt="edit" />
                          }}
                          className={`${styles.white} ${styles.mediumBtn}`}
                          onClick={() => {
                            if ((isSession && currentTime > sessionEndDate) || !isSession) {
                              setIsSessionFinished(true)
                            } else {
                              popUp('fin', false)
                            }
                          }}
                        />
                        {isAttendee || teamId ? (
                          <></>
                        ) : (
                          <>
                            <Button
                              text="Edit"
                              ButtonImg={() => {
                                return <img src={pencil} className={styles.btnImg} alt="edit" />
                              }}
                              className={`${styles.commonBtn} ${styles.smallBtn}`}
                              onClick={() => setEditDialog(true)}
                            />
                          </>
                        )}
                        <Button
                          text="Cancel session"
                          ButtonImg={() => {
                            return <img src={closeIcon} className={styles.btnImg} alt="close" />
                          }}
                          className={`${styles.commonBtn} ${styles.cancelBtn}`}
                          onClick={() => {
                            if (
                              (isSession &&
                                new Date(
                                  objectReceived.proposal.proposalDetail.sessionDate
                                ).getDate() -
                                  new Date().getDate() >
                                  1) ||
                              !isSession
                            ) {
                              setOnClickCancel(true)
                            } else {
                              if (
                                new Date(
                                  objectReceived.proposal.proposalDetail.sessionDate
                                ).getDate() -
                                  new Date().getDate() <
                                0
                              ) {
                                popUp('Btn-ongoing', false)
                              } else {
                                popUp('Btn', false)
                              }
                            }
                          }}
                        />
                      </>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {isAttendee || teamId ? (
                  <div className={styles.buttons}>
                    {forApproval ? (
                      <>
                        <Button
                          text="Approve"
                          disabled={memberRole === 'Member' ? true : false}
                          ButtonImg={() => {
                            return (
                              <>
                                {sectionLoading && sectionLoading.approve ? (
                                  <>
                                    <Loader size={20} thickness={2} className={styles.btnImg} />
                                  </>
                                ) : (
                                  <img
                                    src={tick}
                                    className={
                                      memberRole === 'Member' ? styles.disable : styles.btnImg
                                    }
                                    alt="edit"
                                  />
                                )}
                              </>
                            )
                          }}
                          className={
                            memberRole === 'Member'
                              ? `${styles['disable-button']} ${styles.white} ${styles.mediumBtn}`
                              : `${styles.white} ${styles.mediumBtn}`
                          }
                          onClick={() => {
                            if (teamId && forApproval) {
                              approveProposal()
                            } else {
                              setGetPaid(true)
                            }
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Button
                          text={priceString}
                          disabled={
                            teamId && (memberRole === 'Admin' || memberRole === 'SuperAdmin')
                              ? true
                              : false
                          }
                          ButtonImg={() => {
                            return (
                              <>
                                {sectionLoading && sectionLoading.accept ? (
                                  <Loader className={styles.form_loader} size={20} thickness={2} />
                                ) : (
                                  <img src={tick} className={styles.btnImg} alt="edit" />
                                )}
                              </>
                            )
                          }}
                          className={`${styles.white} ${styles.acceptButton}`}
                          onClick={() => {
                            if (teamId && forApproval) {
                              approveProposal()
                            } else {
                              setGetPaid(true)
                            }
                          }}
                        />
                      </>
                    )}
                    <Button
                      text="Reject Proposal"
                      disabled={forApproval && memberRole === 'Member' ? true : false}
                      ButtonImg={() => {
                        return (
                          <>
                            {sectionLoading && sectionLoading.reject ? (
                              <Loader className={styles.form_loader} size={20} thickness={2} />
                            ) : (
                              <img
                                src={closeIcon}
                                className={` ${styles.btnImg} ${
                                  forApproval && memberRole === 'Member' ? styles.disabled : ''
                                }`}
                                alt="close"
                              />
                            )}
                          </>
                        )
                      }}
                      className={
                        memberRole === 'Member'
                          ? `${styles['disable-button']} ${styles.commonBtn} ${styles.largeBtn}`
                          : `${styles.commonBtn} ${styles.largeBtn}`
                      }
                      onClick={() => {
                        setOnClickCancel(true)
                      }}
                    />
                  </div>
                ) : (
                  <div className={styles.buttons}>
                    <Button
                      text="Edit"
                      ButtonImg={() => {
                        return <img src={pencil} className={styles.btnImg} alt="edit" />
                      }}
                      className={`${styles.commonBtn} ${styles.smallBtn}`}
                      onClick={() => setEditDialog(true)}
                    />
                    <Button
                      text={cancelBtn}
                      ButtonImg={() => {
                        return <img src={closeIcon} className={styles.btnImg} alt="close" />
                      }}
                      className={`${styles.commonBtn} ${styles.largeBtn}`}
                      onClick={() => {
                        setOnClickCancel(true)
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </NoEventsLoader>
        </div>
        <>
          {!isSession ? (
            <div className={styles.element}>
              <>
                <div className={styles.heading}>
                  <img className={styles.headingIcon} src={proposals} alt="proposal" />
                  <div className={styles.subHeading}>{t('requestDetails.proposal-status')}</div>
                </div>
                <div className={`${styles.status} ${styles[objectReceived.status]}`}>
                  {objectReceived.status}
                  {objectReceived.status === 'Pending' ? '...' : ''}
                </div>
                <div className={styles.statusAbout}>
                  {objectReceived.status === 'Pending'
                    ? `Waiting for ${
                        isAttendee ? (teamId ? 'Admin' : 'you') : 'counter party'
                      } to ${teamId ? 'approve' : 'accept'} proposal`
                    : ''}
                </div>
              </>
            </div>
          ) : (
            <>
              <div className={`${styles['d-flex']} ${styles.spaceBetween}`}>
                <div className={`${styles.element} ${styles.widthElement}`}>
                  <div className={styles.heading}>
                    <img className={styles.headingIcon} src={proposals} alt="proposal" />
                    <div className={styles.subHeading}>{t('sessionDetails.sessionStatus')}</div>
                  </div>
                  <div>
                    {objectReceived.status === 'Scheduled' ? (
                      <div className={styles.sessionStatus}>{objectReceived.status}</div>
                    ) : objectReceived.status === 'Delivered' ? (
                      <>
                        <div className={styles.blue}>{objectReceived.status}</div>
                        <div className={styles.sessionDetails}>
                          {objectReceived.traineeCompletionDate &&
                          objectReceived.trainerCompletionDate
                            ? 'Session has been completed by both parties'
                            : isAttendee
                            ? 'Session has been completed by you'
                            : 'Session has been completed by ' +
                              objectReceived?.proposal?.request.user.firstName +
                              ' ' +
                              objectReceived?.proposal?.request.user.lastName}
                        </div>
                        <div className={`${styles.wrapper} ${styles.border} `}>
                          <div>
                            <div className={styles.fullNameBold}>
                              {isSession
                                ? objectReceived?.proposal?.request.user.firstName
                                : objectReceived?.request.user.firstName}{' '}
                              {isSession
                                ? objectReceived?.proposal?.request.user.lastName
                                : objectReceived?.request.user.lastName}
                            </div>
                            {objectReceived.traineeCompletionDate ? (
                              <div className={styles.sessionDetails}>
                                Session was completed on{' '}
                                <Moment
                                  date={objectReceived.traineeCompletionDate}
                                  format="DD MMM"
                                  interval={0}
                                />
                                ,
                                <Moment
                                  date={objectReceived.traineeCompletionDate}
                                  format="HH:mm"
                                  interval={0}
                                />
                                {'   '}
                                <img
                                  src={checkboxBlue}
                                  alt="checkbox"
                                  className={styles.imgCheckbox}
                                />
                              </div>
                            ) : (
                              <div className={styles.sessionDetails}>waiting...</div>
                            )}
                          </div>
                          <div>
                            {objectReceived.status === 'delivered' ? (
                              <img src={checkbox} className={styles.checkbox} alt="checkbox" />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className={`${styles.wrapper} ${styles.border} `}>
                          <div>
                            <div className={styles.fullNameBold}>
                              {isSession
                                ? objectReceived?.proposal?.owner.firstName
                                : objectReceived?.owner.firstName}
                              {isSession
                                ? objectReceived?.proposal?.owner.lastName
                                : objectReceived?.owner.lastName}
                            </div>
                            {objectReceived.trainerCompletionDate ? (
                              <div className={styles.sessionDetails}>
                                Session was completed on{' '}
                                <Moment
                                  date={objectReceived.trainerCompletionDate}
                                  format="DD MMM"
                                  interval={0}
                                />
                                ,
                                <Moment
                                  date={objectReceived.trainerCompletionDate}
                                  format="HH:mm"
                                  interval={0}
                                />
                                {'   '}
                                <img
                                  src={checkboxBlue}
                                  alt="checkbox"
                                  className={styles.imgCheckbox}
                                />
                              </div>
                            ) : (
                              <div className={styles.sessionDetails}>waiting...</div>
                            )}
                          </div>
                          <div>
                            {objectReceived.status === 'scheduled' ? (
                              <img src={checkbox} className={styles.checkbox} alt="checkbox" />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={styles.red}>In {objectReceived.status}</div>
                        <div className={`${styles.fullNameBold} ${styles.paddingTop}`}>
                          {objectReceived?.sessionDispute?.disputeRaisedBy ===
                          objectReceived.proposal.owner.userId
                            ? ` ${
                                objectReceived.proposal.owner.firstName
                              } ${objectReceived.proposal.owner.lastName.charAt(0)}`
                            : ` ${
                                objectReceived.proposal.request.user.firstName
                              } ${objectReceived.proposal.request.user.lastName.charAt(0)}`}
                          {'.'}
                        </div>
                        <div className={styles.sessionDetails}>
                          Started a dispute on
                          <Moment
                            date={objectReceived?.sessionDispute?.disputeRaisedAt}
                            format="DD MMM"
                            interval={0}
                          />
                          ,
                          <Moment
                            date={objectReceived?.sessionDispute?.disputeRaisedAt}
                            format="HH:mm"
                            interval={0}
                          />
                        </div>
                        <div className={`${styles.sessionDetails} ${styles.marginTop}`}>
                          ontelo team is solving the issue
                        </div>
                      </>
                    )}
                  </div>
                  <div className={styles.statusAbout}>
                    {objectReceived.status === 'Scheduled'
                      ? isAttendee && objectReceived.traineeCompletionDate !== null
                        ? 'Session has been completed by you'
                        : !isAttendee && objectReceived.trainerCompletionDate !== null
                        ? 'Session has been completed by you'
                        : 'Session is ongoing'
                      : ''}
                  </div>
                  {objectReceived.status === 'Scheduled' &&
                  (objectReceived.traineeCompletionDate !== null ||
                    objectReceived.trainerCompletionDate !== null) ? (
                    <>
                      <div className={`${styles.wrapper} ${styles.border} `}>
                        <div>
                          <div className={`${styles.fullNameBold} ${styles.paddingTop}`}>
                            {isSession
                              ? objectReceived?.proposal?.request.user.firstName
                              : objectReceived?.request.user.firstName}{' '}
                            {isSession
                              ? objectReceived?.proposal?.request.user.lastName
                              : objectReceived?.request.user.lastName}
                          </div>
                          {objectReceived.traineeCompletionDate ? (
                            <div className={styles.sessionDetails}>
                              Session was completed on{' '}
                              <Moment
                                date={objectReceived.traineeCompletionDate}
                                format="DD MMM"
                                interval={0}
                              />
                              ,
                              <Moment
                                date={objectReceived.traineeCompletionDate}
                                format="HH:mm"
                                interval={0}
                              />
                              {'   '}
                              <img
                                src={checkboxBlue}
                                alt="checkbox"
                                className={styles.imgCheckbox}
                              />
                            </div>
                          ) : (
                            <div className={styles.sessionDetails}>waiting...</div>
                          )}
                        </div>
                        <div>
                          {objectReceived.status === 'delivered' ? (
                            <img src={checkbox} className={styles.checkbox} alt="checkbox" />
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className={`${styles.wrapper} ${styles.border} `}>
                        <div>
                          <div className={styles.fullNameBold}>
                            {isSession
                              ? objectReceived?.proposal?.owner.firstName
                              : objectReceived?.owner.firstName}{' '}
                            {isSession
                              ? objectReceived?.proposal?.owner.lastName
                              : objectReceived?.owner.lastName}
                          </div>
                          {objectReceived.trainerCompletionDate ? (
                            <div className={styles.sessionDetails}>
                              Session was completed on{' '}
                              <Moment
                                date={objectReceived.trainerCompletionDate}
                                format="DD MMM"
                                interval={0}
                              />
                              ,
                              <Moment
                                date={objectReceived.trainerCompletionDate}
                                format="HH:mm"
                                interval={0}
                              />
                              {'   '}
                              <img
                                src={checkboxBlue}
                                alt="checkbox"
                                className={styles.imgCheckbox}
                              />
                            </div>
                          ) : (
                            <div className={styles.sessionDetails}>waiting...</div>
                          )}
                        </div>
                        <div>
                          {objectReceived.status === 'scheduled' ? (
                            <img src={checkbox} className={styles.checkbox} alt="checkbox" />
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className={`${styles.element} ${styles.widthElement} ${styles.dFlex}`}>
                  <div className="">
                    <div className={styles.heading}>
                      <img className={styles.headingIcon} src={finance} alt="finance" />
                      <div className={styles.subHeading}>{t('sessionDetails.paymentStatus')}</div>
                    </div>
                    <>
                      {paymentStatusInfo(
                        objectReceived.paymentStatusToTrainer,
                        objectReceived.status
                      )}
                    </>
                  </div>
                  {objectReceived.status === 'Scheduled' ? (
                    <>
                      {(isAttendee && objectReceived.traineeCompletionDate) ||
                      (!isAttendee && objectReceived.trainerCompletionDate) ||
                      (!isAttendee && objectReceived.traineeCompletionDate) ? (
                        <>{openDispute()}</>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : objectReceived.status === 'Delivered' && !inArchive ? (
                    <>{openDispute()}</>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          )}
        </>

        <>
          {(
            isSession
              ? objectReceived?.proposal?.proposalDetail.sessionDescription
              : objectReceived?.proposalDetail.sessionDescription
          ) ? (
            <div className={styles.element}>
              <div className={styles.heading}>
                <img className={styles.headingIcon} src={fileIcon} alt="file" />
                <div className={styles.subHeading}>
                  {isSession || fromChat
                    ? t('createProposal.sessionDescription')
                    : t('sessionDetails.courseOutline')}
                </div>
              </div>
              <div className={styles.sessionDetails}>
                {isSession
                  ? objectReceived?.proposal?.proposalDetail.sessionDescription
                  : objectReceived?.proposalDetail.sessionDescription}
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
        {isSession || !isAttendee ? (
          <>
            {/* prerk */}
            <>
              {(
                isSession
                  ? objectReceived?.proposal?.proposalDetail.preRequisiteKnowledge
                  : objectReceived?.proposalDetail.preRequisiteKnowledge
              ) ? (
                <div className={styles.element}>
                  <div className={styles.heading}>
                    <img className={styles.headingIcon} src={fileIcon} alt="file" />
                    <div className={styles.subHeading}>{t('sessionDetails.preRequisiteKnow')}</div>
                  </div>
                  <div className={styles.sessionDetails}>
                    {isSession
                      ? objectReceived?.proposal?.proposalDetail.preRequisiteKnowledge
                      : objectReceived?.proposalDetail.preRequisiteKnowledge}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
            <>
              {(
                isSession
                  ? objectReceived?.proposal?.proposalDetail.learningOutcome
                  : objectReceived?.proposalDetail.learningOutcome
              ) ? (
                <div className={styles.element}>
                  <div className={styles.heading}>
                    <img className={styles.headingIcon} src={fileIcon} alt="file" />
                    <div className={styles.subHeading}>{t('sessionDetails.learningOutcomes')}</div>
                  </div>
                  <div className={styles.sessionDetails}>
                    {isSession
                      ? objectReceived?.proposal?.proposalDetail.learningOutcome
                      : objectReceived?.proposalDetail.learningOutcome}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
            <>
              {(
                isSession
                  ? objectReceived?.proposal?.proposalDetail.learningRequirement
                  : objectReceived?.proposalDetail.learningRequirement
              ) ? (
                <div className={styles.element}>
                  <div className={styles.heading}>
                    <img className={styles.headingIcon} src={fileIcon} alt="file" />
                    <div className={styles.subHeading}>
                      {t('sessionDetails.softwareHardwareReq')}
                    </div>
                  </div>
                  <div className={styles.sessionDetails}>
                    {isSession
                      ? objectReceived?.proposal?.proposalDetail.learningRequirement
                      : objectReceived?.proposalDetail.learningRequirement}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          </>
        ) : (
          <></>
        )}

        <div className={styles.elementSummary}>
          <div className={` ${styles.heading} ${styles.padding}`}>
            <img className={styles.headingIcon} src={groupIcon} alt="group" />
            <div className={styles.subHeading}>{t('requestDetails.attendees')}</div>
          </div>
          <div>
            <div
              className={`${styles.row} ${styles.border} ${styles.cursor}`}
              onClick={() =>
                setProfileInfo(
                  isSession ? objectReceived?.proposal?.ownerId : objectReceived?.ownerId,
                  true
                )
              }
            >
              <div className={styles.icon}>
                {(
                  isSession
                    ? objectReceived?.proposal?.owner.imageUrl
                    : objectReceived?.owner.imageUrl
                ) ? (
                  <img
                    src={
                      isSession
                        ? objectReceived?.proposal?.owner.imageUrl
                        : objectReceived?.owner.imageUrl
                    }
                    className={styles.attendeeImage}
                    alt="attendee"
                  />
                ) : (
                  <NameInitials
                    firstName={
                      isSession
                        ? objectReceived?.proposal?.owner.firstName
                        : objectReceived?.owner.firstName
                    }
                    lastName={
                      isSession
                        ? objectReceived?.proposal?.owner.lastName
                        : objectReceived?.owner.lastName
                    }
                    className={styles.radius}
                  />
                )}
              </div>
              <div className={styles.width}>
                <div className={styles.fullName}>
                  {isSession
                    ? objectReceived?.proposal?.owner.firstName
                    : objectReceived?.owner.firstName}{' '}
                  {isSession
                    ? objectReceived?.proposal?.owner.lastName.charAt(0)
                    : objectReceived?.owner.lastName.charAt(0)}
                  .
                </div>
                <div className={styles.title}>{t('requestDetails.creator')}</div>
              </div>
            </div>
            <div
              className={`${styles.row} ${styles.border} ${styles.cursor}`}
              onClick={() =>
                setProfileInfo(
                  isSession
                    ? objectReceived?.proposal?.request.user.userId
                    : objectReceived?.request.user.userId,
                  false
                )
              }
            >
              <div className={styles.icon}>
                {(
                  isSession
                    ? objectReceived?.proposal?.request.user.imageUrl
                    : objectReceived?.request.user.imageUrl
                ) ? (
                  <img
                    src={
                      isSession
                        ? objectReceived?.proposal?.request.user.imageUrl
                        : objectReceived?.request.user.imageUrl
                    }
                    className={styles.attendeeImage}
                    alt="attendee"
                  />
                ) : (
                  <NameInitials
                    firstName={
                      isSession
                        ? objectReceived?.proposal?.request.user.firstName
                        : objectReceived?.request.user.firstName
                    }
                    lastName={
                      isSession
                        ? objectReceived?.proposal?.request.user.lastName
                        : objectReceived?.request.user.lastName
                    }
                    className={styles.radius}
                  />
                )}
              </div>
              <div className={styles.width}>
                <div className={styles.fullName}>
                  {isSession
                    ? objectReceived?.proposal?.request.user.firstName
                    : objectReceived?.request.user.firstName}{' '}
                  {isSession
                    ? objectReceived?.proposal?.request.user.lastName.charAt(0)
                    : objectReceived?.request.user.lastName.charAt(0)}
                  .
                </div>
                <div className={styles.title}>Attendee</div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.element} ${styles.mTop}`}>
          <div className={styles.heading}>
            <img className={styles.headingIcon} src={connection} alt="connection" />
            <div className={styles.subHeading}>{t('requestDetails.connectionMethod')}</div>
          </div>
          <div className={styles.connection}>
            {isSession
              ? objectReceived?.proposal?.proposalDetail.connectionMode
              : objectReceived?.proposalDetail.connectionMode}
            {isSession ? (
              <>
                <div className={` ${styles.linkDiv} ${styles.sessionDetails}`}>
                  <div className={styles.marginRight}>Room Link </div>
                  <a
                    href="{{objectReceived.proposal.proposalDetail.connectionLink}}"
                    target="_blank"
                    className={styles.link}
                  >
                    {objectReceived.proposal.proposalDetail.connectionLink}
                  </a>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className={styles.connectionFlex}>
            <img
              src={attention}
              className={`${styles.divImg} ${styles.maxWidth}`}
              alt="attention"
            />
            <div className={styles.sessionDetails}>{t('requestDetails.session-details')}</div>
          </div>
        </div>
        <div className={styles.elementSummary}>
          <div className={styles.headingSummary}>
            <img className={styles.headingIcon} src={file} alt="file" />
            <div className={styles.subHeading}>{t('requestDetails.summary')}</div>
          </div>
          <div className={styles.details}>
            <div className={styles.subHeadingSummary}>{t('requestDetails.session-price')}</div>
            <div className={styles.value}>${price}</div>
          </div>
          <div className={styles.details}>
            <div className={styles.subHeadingSummary}>{t('requestDetails.session-length')}</div>
            <div className={styles.value}>
              {isSession
                ? objectReceived?.proposal?.proposalDetail.sessionTimeInHour
                : objectReceived?.proposalDetail.sessionTimeInHour}
              {isSession
                ? objectReceived?.proposal?.proposalDetail.sessionTimeInHour &&
                  objectReceived?.proposal?.proposalDetail.sessionTimeInHour > 1
                  ? 'hours'
                  : 'hour'
                : objectReceived?.proposalDetail.sessionTimeInHour &&
                  objectReceived?.proposalDetail.sessionTimeInHour > 1
                ? 'hours'
                : 'hour'}
            </div>
          </div>
          <div className={styles.details}>
            <div className={styles.subHeadingSummary}>{t('requestDetails.session-date')}</div>
            <div className={styles.value}>
              <Moment
                date={
                  isSession
                    ? objectReceived?.proposal?.proposalDetail.sessionDate
                    : objectReceived?.proposalDetail.sessionDate
                }
                format="DD MMM"
                interval={0}
              />
              ,
              <Moment
                date={
                  isSession
                    ? objectReceived?.proposal?.proposalDetail.sessionDate
                    : objectReceived?.proposalDetail.sessionDate
                }
                format="HH:mm"
                interval={0}
              />
              -
              <Moment
                date={
                  isSession
                    ? objectReceived?.proposal?.proposalDetail.sessionDate
                    : objectReceived?.proposalDetail.sessionDate
                }
                format="HH:mm"
                interval={0}
                add={{
                  hours: isSession
                    ? objectReceived?.proposal?.proposalDetail.sessionTimeInHour
                    : objectReceived?.proposalDetail.sessionTimeInHour,
                }}
              />
            </div>
          </div>
        </div>

        {isSession && objectReceived.reviews.length > 0 ? (
          <div className={`${styles.elementSummary} ${styles.marginBtm}`}>
            <div className={` ${styles.heading} ${styles.padding}`}>
              <img className={styles.headingIcon} src={feedback} alt="feedback" />
              <div className={styles.subHeading}>Feedback</div>
            </div>
            {isAttendee ? (
              <>
                <div className={styles.padding}>
                  <StyledRating
                    name="color"
                    defaultValue={
                      objectReceived.reviews[0] &&
                      objectReceived.reviews[0].userId === objectReceived.proposal?.request.userId
                        ? objectReceived.reviews[0].reviewRating
                        : objectReceived.reviews[1] &&
                          objectReceived.reviews[1].userId ===
                            objectReceived.proposal?.request.userId
                        ? objectReceived.reviews[1].reviewRating
                        : 0
                    }
                    getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                    precision={1}
                    size={'medium'}
                    icon={<Grade fontSize="inherit" style={{ color: '#e54dc0' }} />}
                    emptyIcon={<Grade fontSize="inherit" style={{ color: '#F0F1F3' }} />}
                    readOnly
                  />
                  <div
                    className={
                      (objectReceived.reviews[0] &&
                        objectReceived.reviews[0].userId ===
                          objectReceived.proposal?.request.userId) ||
                      (objectReceived.reviews[1] &&
                        objectReceived.reviews[1].userId ===
                          objectReceived.proposal?.request.userId)
                        ? `${styles.sessionDetails}`
                        : `${styles.sessionDetails} ${styles.opacity}`
                    }
                  >
                    {objectReceived.reviews[0].userId === objectReceived.proposal?.request.userId
                      ? objectReceived.reviews[0].reviewSummary
                      : objectReceived.reviews[1] &&
                        objectReceived.reviews[1].userId === objectReceived.proposal?.request.userId
                      ? objectReceived.reviews[1].reviewSummary
                      : 'Other party has not leave a review yet...'}
                  </div>
                </div>
                <div className={`${styles.row}`}>
                  <div className={styles.icon}>
                    {(
                      isSession
                        ? objectReceived?.proposal?.owner.imageUrl
                        : objectReceived?.owner.imageUrl
                    ) ? (
                      <img
                        src={
                          isSession
                            ? objectReceived?.proposal?.owner.imageUrl
                            : objectReceived?.owner.imageUrl
                        }
                        className={styles.attendeeImage}
                        alt="attendee"
                      />
                    ) : (
                      <NameInitials
                        firstName={
                          isSession
                            ? objectReceived?.proposal?.owner.firstName
                            : objectReceived?.owner.firstName
                        }
                        lastName={
                          isSession
                            ? objectReceived?.proposal?.owner.lastName
                            : objectReceived?.owner.lastName
                        }
                        className={styles.radius}
                      />
                    )}
                  </div>
                  <div className={styles.width}>
                    <div className={styles.fullName}>
                      {isSession
                        ? objectReceived?.proposal?.owner.firstName
                        : objectReceived?.owner.firstName}{' '}
                      {isSession
                        ? objectReceived?.proposal?.owner.lastName.charAt(0)
                        : objectReceived?.owner.lastName.charAt(0)}
                      .
                    </div>
                    <div className={styles.title}>Mentor</div>
                  </div>
                </div>
                <div className={`${styles.padding} ${styles.mTop}`}>
                  <StyledRating
                    name="color"
                    defaultValue={
                      objectReceived.reviews[0] &&
                      objectReceived.reviews[0].userId === objectReceived.proposal.ownerId
                        ? objectReceived.reviews[0].reviewRating
                        : objectReceived.reviews[1] &&
                          objectReceived.reviews[1].userId === objectReceived.proposal.ownerId
                        ? objectReceived.reviews[1].reviewRating
                        : 0
                    }
                    getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                    precision={1}
                    size={'medium'}
                    icon={<Grade fontSize="inherit" style={{ color: '#e54dc0' }} />}
                    emptyIcon={<Grade fontSize="inherit" style={{ color: '#F0F1F3' }} />}
                    readOnly
                  />
                  <div
                    className={
                      (objectReceived.reviews[0] &&
                        objectReceived.reviews[0].userId === objectReceived.proposal?.ownerId) ||
                      (objectReceived.reviews[1] &&
                        objectReceived.reviews[1].userId === objectReceived.proposal?.ownerId)
                        ? `${styles.sessionDetails}`
                        : `${styles.sessionDetails} ${styles.opacity}`
                    }
                  >
                    {objectReceived.reviews[0] &&
                    objectReceived.reviews[0].userId === objectReceived.proposal.ownerId
                      ? objectReceived.reviews[0].reviewSummary
                      : objectReceived.reviews[1] &&
                        objectReceived.reviews[1].userId === objectReceived.proposal.ownerId
                      ? objectReceived.reviews[1].reviewSummary
                      : 'Other party has not leave a review yet...'}
                  </div>
                </div>
                <div className={`${styles.row}`}>
                  <div className={styles.icon}>
                    {(
                      isSession
                        ? objectReceived?.proposal?.request.user.imageUrl
                        : objectReceived?.request.user.imageUrl
                    ) ? (
                      <img
                        src={
                          isSession
                            ? objectReceived?.proposal?.request.user.imageUrl
                            : objectReceived?.request.user.imageUrl
                        }
                        className={styles.attendeeImage}
                        alt="attendee"
                      />
                    ) : (
                      <NameInitials
                        firstName={
                          isSession
                            ? objectReceived?.proposal?.request.user.firstName
                            : objectReceived?.request.user.firstName
                        }
                        lastName={
                          isSession
                            ? objectReceived?.proposal?.request.user.lastName
                            : objectReceived?.request.user.lastName
                        }
                        className={styles.radius}
                      />
                    )}
                  </div>
                  <div className={styles.width}>
                    <div className={styles.fullName}>
                      {isSession
                        ? objectReceived?.proposal?.request.user.firstName
                        : objectReceived?.request.user.firstName}{' '}
                      {isSession
                        ? objectReceived?.proposal?.request.user.lastName.charAt(0)
                        : objectReceived?.request.user.lastName.charAt(0)}
                      .
                    </div>
                    <div className={styles.title}>Attendee</div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.padding}>
                  <StyledRating
                    name="color"
                    defaultValue={
                      objectReceived.reviews[0] &&
                      objectReceived.reviews[0].userId === objectReceived.proposal.ownerId
                        ? objectReceived.reviews[0].reviewRating
                        : objectReceived.reviews[1] &&
                          objectReceived.reviews[1].userId === objectReceived.proposal.ownerId
                        ? objectReceived.reviews[1].reviewRating
                        : 0
                    }
                    getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                    precision={1}
                    size={'medium'}
                    icon={<Grade fontSize="inherit" style={{ color: '#e54dc0' }} />}
                    emptyIcon={<Grade fontSize="inherit" style={{ color: '#F0F1F3' }} />}
                    readOnly
                  />
                  <div
                    className={
                      (objectReceived.reviews[0] &&
                        objectReceived.reviews[0].userId === objectReceived.proposal?.ownerId) ||
                      (objectReceived.reviews[1] &&
                        objectReceived.reviews[1].userId === objectReceived.proposal?.ownerId)
                        ? `${styles.sessionDetails}`
                        : `${styles.sessionDetails} ${styles.opacity}`
                    }
                  >
                    {objectReceived.reviews[0] &&
                    objectReceived.reviews[0].userId === objectReceived.proposal.ownerId
                      ? objectReceived.reviews[0].reviewSummary
                      : objectReceived.reviews[1] &&
                        objectReceived.reviews[1].userId === objectReceived.proposal.ownerId
                      ? objectReceived.reviews[1].reviewSummary
                      : 'Other party has not leave a review yet...'}
                  </div>
                </div>
                <div className={`${styles.row}`}>
                  <div className={styles.icon}>
                    {(
                      isSession
                        ? objectReceived?.proposal?.request.user.imageUrl
                        : objectReceived?.request.user.imageUrl
                    ) ? (
                      <img
                        src={
                          isSession
                            ? objectReceived?.proposal?.request.user.imageUrl
                            : objectReceived?.request.user.imageUrl
                        }
                        className={styles.attendeeImage}
                        alt="attendee"
                      />
                    ) : (
                      <NameInitials
                        firstName={
                          isSession
                            ? objectReceived?.proposal?.request.user.firstName
                            : objectReceived?.request.user.firstName
                        }
                        lastName={
                          isSession
                            ? objectReceived?.proposal?.request.user.lastName
                            : objectReceived?.request.user.lastName
                        }
                        className={styles.radius}
                      />
                    )}
                  </div>
                  <div className={styles.width}>
                    <div className={styles.fullName}>
                      {isSession
                        ? objectReceived?.proposal?.request.user.firstName
                        : objectReceived?.request.user.firstName}{' '}
                      {isSession
                        ? objectReceived?.proposal?.request.user.lastName.charAt(0)
                        : objectReceived?.request.user.lastName.charAt(0)}
                      .
                    </div>
                    <div className={styles.title}>Attendee</div>
                  </div>
                </div>
                <div className={`${styles.padding} ${styles.mTop}`}>
                  <StyledRating
                    name="color"
                    defaultValue={
                      objectReceived.reviews[0] &&
                      objectReceived.reviews[0].userId === objectReceived.proposal?.request.userId
                        ? objectReceived.reviews[0].reviewRating
                        : objectReceived.reviews[1] &&
                          objectReceived.reviews[1].userId ===
                            objectReceived.proposal?.request.userId
                        ? objectReceived.reviews[1].reviewRating
                        : 0
                    }
                    getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                    precision={1}
                    size={'medium'}
                    icon={<Grade fontSize="inherit" style={{ color: '#e54dc0' }} />}
                    emptyIcon={<Grade fontSize="inherit" style={{ color: '#7c7c7c' }} />}
                    readOnly
                  />
                  <div
                    className={
                      (objectReceived.reviews[0] &&
                        objectReceived.reviews[0].userId ===
                          objectReceived.proposal?.request.userId) ||
                      (objectReceived.reviews[1] &&
                        objectReceived.reviews[1].userId ===
                          objectReceived.proposal?.request.userId)
                        ? `${styles.sessionDetails}`
                        : `${styles.sessionDetails} ${styles.opacity}`
                    }
                  >
                    {objectReceived.reviews[0].userId === objectReceived.proposal?.request.userId
                      ? objectReceived.reviews[0].reviewSummary
                      : objectReceived.reviews[1] &&
                        objectReceived.reviews[1].userId === objectReceived.proposal?.request.userId
                      ? objectReceived.reviews[1].reviewSummary
                      : 'Other party has not leave a review yet...'}
                  </div>
                </div>
                <div className={`${styles.row}`}>
                  <div className={styles.icon}>
                    {(
                      isSession
                        ? objectReceived?.proposal?.owner.imageUrl
                        : objectReceived?.owner.imageUrl
                    ) ? (
                      <img
                        src={
                          isSession
                            ? objectReceived?.proposal?.owner.imageUrl
                            : objectReceived?.owner.imageUrl
                        }
                        className={styles.attendeeImage}
                        alt="attendee"
                      />
                    ) : (
                      <NameInitials
                        firstName={
                          isSession
                            ? objectReceived?.proposal?.owner.firstName
                            : objectReceived?.owner.firstName
                        }
                        lastName={
                          isSession
                            ? objectReceived?.proposal?.owner.lastName
                            : objectReceived?.owner.lastName
                        }
                        className={styles.radius}
                      />
                    )}
                  </div>
                  <div className={styles.width}>
                    <div className={styles.fullName}>
                      {isSession
                        ? objectReceived?.proposal?.owner.firstName
                        : objectReceived?.owner.firstName}{' '}
                      {isSession
                        ? objectReceived?.proposal?.owner.lastName.charAt(0)
                        : objectReceived?.owner.lastName.charAt(0)}
                      .
                    </div>
                    <div className={styles.title}>Mentor</div>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          <></>
        )}

        {isSession && !isAttendee && objectReceived.status !== 'Scheduled' ? (
          <></>
        ) : (
          <>
            {(isSession ? objectReceived?.proposal?.request : objectReceived?.request) ? (
              <div className={`${styles.elementSummary}`}>
                <div className={` ${styles.heading} ${styles.padding}`}>
                  <img className={styles.headingIcon} src={question} alt="question" />
                  <div className={styles.subHeading}>{t('requestDetails.request')}</div>
                </div>

                <div className={`${styles.courseTitle} ${styles.padding}`}>
                  {isSession
                    ? objectReceived?.proposal?.proposalDetail.category
                      ? objectReceived.proposal.proposalDetail.category.name
                      : objectReceived.proposal.proposalDetail.subCategory.name
                    : objectReceived?.proposalDetail.category
                    ? objectReceived.proposalDetail.category.name
                    : objectReceived.proposalDetail.subCategory.name}
                </div>
                <div className={`${styles.sessionDetails} ${styles.padding}`}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: isSession
                        ? objectReceived?.proposal?.request.description
                        : objectReceived?.request.description,
                    }}
                  ></div>
                  {}
                </div>
                <div
                  className={`${styles.row} ${styles.cursor}`}
                  onClick={() =>
                    setProfileInfo(
                      isSession
                        ? objectReceived?.proposal?.request.user.userId
                        : objectReceived?.request.user.userId,
                      false
                    )
                  }
                >
                  <div className={styles.icon}>
                    {(
                      isSession
                        ? objectReceived?.proposal?.request.user.imageUrl
                        : objectReceived?.request.user.imageUrl
                    ) ? (
                      <img
                        src={
                          isSession
                            ? objectReceived?.proposal?.request.user.imageUrl
                            : objectReceived?.request.user.imageUrl
                        }
                        className={styles.attendeeImage}
                        alt="attendee"
                      />
                    ) : (
                      <NameInitials
                        firstName={
                          isSession
                            ? objectReceived?.proposal?.request.user.firstName
                            : objectReceived?.request.user.firstName
                        }
                        lastName={
                          isSession
                            ? objectReceived?.proposal?.request.user.lastName
                            : objectReceived?.request.user.lastName
                        }
                        className={styles.radius}
                      />
                    )}
                  </div>
                  <div className={styles.width}>
                    <div className={styles.fullName}>
                      {isSession
                        ? objectReceived?.proposal?.request.user.firstName
                        : objectReceived?.request.user.firstName}{' '}
                      {isSession
                        ? objectReceived?.proposal?.request.user.lastName.charAt(0)
                        : objectReceived?.request.user.lastName.charAt(0)}
                      .
                    </div>
                    <div>
                      {objectReceived?.reviews?.length ? (
                        <>
                          {Array(Number(0))
                            .fill('')
                            .map((x, i) => {
                              return (
                                <img src={pinkStar} alt="rating" key={i} className={styles.star} />
                              )
                            })}
                          {Array(5 - Number(0))
                            .fill('')
                            .map((x, i) => {
                              return <img src={star} alt="rating" key={i} className={styles.star} />
                            })}
                        </>
                      ) : (
                        <>
                          {Array(5 - Number(0))
                            .fill('')
                            .map((x, i) => {
                              return <img src={star} alt="rating" key={i} className={styles.star} />
                            })}
                        </>
                      )}
                      {/* <img src={stars} /> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      <DialogWrapper
        onClose={() => {
          setGetPaid(false)
        }}
        open={getPaid}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <ElementsWrapper>
          <GetPaid
            availableFund={
              isSession
                ? Number(objectReceived.proposal?.proposalDetail.hourlyRate) *
                  Number(objectReceived.proposal?.proposalDetail.sessionTimeInHour)
                : Number(objectReceived.proposalDetail.hourlyRate) *
                  Number(objectReceived.proposalDetail.sessionTimeInHour)
            }
            proposalId={objectReceived.proposalId}
            proposal={objectReceived}
            onClosePopup={() => {
              setGetPaid(false)
              acceptProposal()
            }}
          />
        </ElementsWrapper>
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setEditDialog(false)
        }}
        open={editDialog}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <CreateProposal
          isEdit={true}
          createProposal={() => {
            setEditDialog(false)
            onClose()
          }}
          data={
            isSession
              ? { sessionId: objectReceived.sessionId, ...objectReceived.proposal }
              : objectReceived
          }
          isSession={isSession}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setOnClickCancel(false)
        }}
        open={onClickCancel}
        Title={DialogHeader}
      >
        <WithdrawResponse
          responseId={isSession ? objectReceived.sessionId : objectReceived?.proposalId}
          inProposal={!isSession}
          onSuccess={() => {
            setOnClickCancel(false)
            onClose()
          }}
          isSession={isSession}
          isAttendee={isAttendee}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setIsDisputeOpen(false)
        }}
        open={isDisputeOpen}
        Title={DialogHeader}
      >
        <WithdrawResponse
          responseId={isSession ? objectReceived.sessionId : objectReceived?.proposalId}
          onSuccess={() => {
            setOnClickCancel(false)
            onClose()
            if (teamId) {
              teamStats()
            }
          }}
          isSession={false}
          isAttendee={isAttendee}
          openDispute={isDisputeOpen}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setIsSessionFinished(false)
        }}
        open={isSessionFinished}
        Title={DialogHeader}
      >
        <FinishSession
          data={objectReceived}
          isAttendee={isAttendee}
          finishSession={() => {
            setIsSessionFinished(false)
            onClose()
            if (teamId) {
              teamStats()
            }
          }}
          teamId={teamId ? teamId : undefined}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setProfile(false)
        }}
        open={profile}
        Title={DialogHeader}
      >
        {profileDetails.isTeacher ? (
          <TeacherChatUserProfile isChat={false} userId={profileDetails.userId} />
        ) : (
          <StudentChatUserProfile isChat={false} userId={profileDetails.userId} />
        )}
      </DialogWrapper>
    </>
  )
}
