import React from 'react'
import logo from '../../assets/images/svg/logo.svg'
import { LoginWrapper } from '../../common/login-wrapper/LoginWrapper'
import { ForgetPasswordForm } from '../../components/forget-password-form/ForgetPasswordForm'
import { LoginBox } from '../../components/login-box/LoginBox'
import styles from '../login/Login.module.scss'

export const ForgetPassword: React.FC = () => {
  return (
    <LoginWrapper>
      <div className={styles.boxWrapper}>
        <div className={styles.header}>
          <img src={logo} alt="logo" />
        </div>
        <LoginBox>
          <ForgetPasswordForm />
        </LoginBox>
      </div>
    </LoginWrapper>
  )
}
