/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { NameInitials } from '../name-initials/NameInitials'
import styles from './EmailList.module.scss'
import deleteIcon from '../../assets/images/svg/delete-icon.svg'
import pinkStar from '../../assets/images/svg/pinkStar.svg'
import star from '../../assets/images/svg/star.svg'

export const EmailList: React.FC<{
  readonly each: any
  readonly removeUser: any
  readonly index: number
}> = ({ each, removeUser, index }) => {
  return (
    <div className={styles.members}>
      <div className={styles['membersList-profile']}>
        {each.imageUrl ? (
          <img src={each.imageUrl} alt="owner" className={styles.userImg} />
        ) : (
          <NameInitials
            firstName={each.firstName}
            lastName={each.lastName}
            className={styles.userImg}
          />
        )}
        <div className={styles['membersList-userDetails']}>
          <div className={styles['membersList-username']}>{each.firstName}</div>
          <div className={styles.feedbackContent}>
            <div className={styles.rating}>
              {Array(Number(0))
                .fill('')
                .map((x, i) => {
                  return <img src={pinkStar} alt="rating" key={i} className={styles.star} />
                })}
              {Array(5 - Number(0))
                .fill('')
                .map((x, i) => {
                  return <img src={star} alt="rating" key={i} className={styles.star} />
                })}
            </div>
          </div>
        </div>
      </div>
      <div className={styles['membersList-actions']}>
        <img
          src={deleteIcon}
          alt="delete icon"
          className={styles.delete_img}
          onClick={() => removeUser(index)}
        />
      </div>
    </div>
  )
}
