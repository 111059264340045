/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import chatConnect from '../../assets/images/svg/chat-connect.svg'
import grayChat from '../../assets/images/svg/chat-connect-gray.svg'
import grayTick from '../../assets/images/svg/gray_tick.svg'
import star from '../../assets/images/svg/star.svg'
import timeIcon from '../../assets/images/svg/time-icon.svg'
import { DialogWrapper } from '../../common/dialog-wrapper/DialogWrapper'
import { Button } from '../../common/button/Button'
import { ConnectPopup } from '../connect-popup/ConnectPopup'
import { DialogHeader } from '../../common/dialog-header/DialogHeader'
import styles from './RespondentCard.module.scss'
import { useHistory } from 'react-router'
import {
  TeacherChatUserProfile,
  StudentChatUserProfile,
} from '../chat-user-profile/ChatUserProfile'

export const RespondentCard: React.FC<{
  item: any
}> = ({ item }) => {
  const { push } = useHistory()
  const elem = Array.from({ length: 4 }, (_, index) => index + 1)
  const [openDialog, setopenDialog] = useState(false)
  const [response, setResponse] = useState<any>(item)
  const { t } = useTranslation()
  useEffect(() => {
    setResponse(item && item.responseId ? item : {})
  }, [item])

  const [profile, setProfile] = useState(false)
  const [profileDetails, setProfileDetails] = useState({
    isTeacher: false,
    userId: '',
  })

  const setProfileInfo = (userId: string, isTeacher: boolean) => {
    setProfileDetails({
      isTeacher: isTeacher,
      userId: userId,
    })
    setProfile(true)
  }

  return (
    <div className={styles['list-card']}>
      <div className={styles['list-card-header']}>
        <div className={styles['d-flex']}>
          <div className={styles['review-card__align-items']}>
            <img src={timeIcon} alt="time icon" className={styles['review-card__time-icon']} />
            <div className={styles['review-card__time-subject']}>
              {response.createdOn} {t('requestDetails.ago')}
            </div>
          </div>
        </div>
      </div>
      <div className={styles['list-card-title']}>
        ${response.hourlyRate}
        {t('requestDetails.perHour')}
      </div>
      <div className={styles['list-card-description']}>{response.description}</div>
      <div
        className={styles['review-card__profile']}
        onClick={() => setProfileInfo(response.user.userId, true)}
      >
        {response.user.imageUrl.length > 0 ? (
          <img src={response.user.imageUrl} alt="userimage" className={styles.userImage} />
        ) : (
          <div className={styles.icon}>
            <div className={styles.backgroundImg}>
              {response.user.firstName && response.user.lastName
                ? `${response.user.firstName.toUpperCase().charAt(0)}${response.user.lastName
                    .toUpperCase()
                    .charAt(0)}`
                : ''}
            </div>
          </div>
        )}
        <div className={styles['review-card__star-rating']}>
          <div className={styles['review-card__username']}>{response.user.firstName}</div>
          <div className={styles['review-card__rating']}>
            {elem.map((each) => {
              return (
                <img
                  src={star}
                  alt="star icons"
                  key={'star' + each}
                  className={styles['review-card__rating-icons']}
                />
              )
            })}
          </div>
        </div>
      </div>
      <div className={styles.btnWrapper}>
        <div className={styles.connect}>
          <Button
            text={response.isAcknowledged ? 'Connected' : 'Connect'}
            onClick={() => {
              setopenDialog(true)
            }}
            disabled={response.isAcknowledged}
            ButtonImg={() => {
              return (
                <img
                  src={response.isAcknowledged ? grayTick : chatConnect}
                  alt="chat connect"
                  className={styles['connect-img']}
                />
              )
            }}
            className={response.isAcknowledged ? styles.btn_connected : styles['btn-connect']}
          />
        </div>
        <div className={styles.connect}>
          {response.isAcknowledged ? (
            <Button
              text="Open Message"
              onClick={() => {
                push('/chat?type=learn')
              }}
              // disabled={response.isAcknowledged}
              ButtonImg={() => {
                return <img src={grayChat} alt="chat connect" className={styles['connect-img']} />
              }}
              className={styles.btn_responded}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <DialogWrapper
        onClose={() => {
          setopenDialog(false)
        }}
        open={openDialog}
        isFullScreen={false}
        Title={DialogHeader}
      >
        <ConnectPopup
          item={response}
          onClose={() => {
            setopenDialog(false)
          }}
          onSuccess={() => {
            setResponse((prev: any) => ({ ...prev, isAcknowledged: true }))
          }}
        />
      </DialogWrapper>
      <DialogWrapper
        onClose={() => {
          setProfile(false)
        }}
        open={profile}
        Title={DialogHeader}
      >
        {profileDetails.isTeacher ? (
          <TeacherChatUserProfile isChat={false} userId={profileDetails.userId} />
        ) : (
          <StudentChatUserProfile isChat={false} userId={profileDetails.userId} />
        )}
      </DialogWrapper>
    </div>
  )
}
