import * as yup from 'yup'
import { Patterns } from '../Constants'

export const firstNameSchema = yup.object().shape({
  value: yup
    .string()
    .matches(/^[A-Za-z ]*$/, 'First Name is not valid')
    .required('First Name is required')
    .max(40, 'Maximum allowed characters is 40')
    .test({
      name: 'trim',
      message: 'First Name is not valid',
      test: (value) => {
        let trimmedValue = value ? value?.trim() : ''
        return trimmedValue ? true : false
      },
    }),
})
export const teamNameSchema = yup.object().shape({
  value: yup
    .string()
    .matches(/^[A-Za-z ]*$/, 'Team Name is not valid')
    .required('Team name is required')
    .max(40, 'Maximum allowed characters is 40')
    .test({
      name: 'trim',
      message: 'Team Name is not valid',
      test: (value) => {
        let trimmedValue = value ? value?.trim() : ''
        return trimmedValue ? true : false
      },
    }),
})
export const teamTitleSchema = yup.object().shape({
  value: yup
    .string()
    .required('Team title is required')
    .test({
      name: 'trim',
      message: 'Team Title is not valid',
      test: (value) => {
        let trimmedValue = value ? value?.trim() : ''
        return trimmedValue ? true : false
      },
    }),
})
export const teamIdSchema = yup.object().shape({
  value: yup.string().required('Team Id is required'),
})
export const lastNameSchema = yup.object().shape({
  value: yup
    .string()
    .matches(/^[A-Za-z ]*$/, 'Last Name is not valid')
    .required('Last Name is required')
    .max(40, 'Maximum allowed characters is 40')
    .test({
      name: 'trim',
      message: 'Last Name is not valid',
      test: (value) => {
        let trimmedValue = value ? value?.trim() : ''
        return trimmedValue ? true : false
      },
    }),
})
export const phoneNumberSchema = yup.object().shape({
  value: yup
    .string()
    .required('Phone Number is required')
    .matches(/^[0-9]+$/, 'Please enter only number')
    .min(3, 'Invalid Phone Number')
    .max(15, 'Invalid Phone Number'),
})
export const countryCodeSchema = yup.object().shape({
  value: yup.object().shape({ codeId: yup.string().required('Please select Code') }),
})
export const countrySchema = yup.object().shape({
  value: yup.string().required('Please select Code'),
})
export const categorySchema = yup.object().shape({
  value: yup.string().required('Please select a Category/Sub Category'),
})
export const schemaEmail = yup.object().shape({
  value: yup.string().required('Email is required').email('Enter a valid email'),
})
export const schemaSkills = yup.object().shape({
  value: yup.array().of(yup.object().shape({ id: yup.string() })),
})
export const schemaAreaOfExpertise = yup.object().shape({
  value: yup.array().of(yup.object().shape({ id: yup.string() })),
})
export const costSchema = yup.object().shape({
  value: yup
    .number()
    .typeError('Please enter a number')
    .max(9999, 'Maximum amount is $9999')
    .min(1, 'Minimum amount should be $1')
    .required('Please enter hourly rate'),
})

export const cardNumber = yup.object().shape({
  value: yup
    .string()
    .required('*required')
    .max(4, 'Enter4 digits')
    .min(4, 'Enter4 digits')
    .matches(Patterns.digits, 'enter digits'),
})

export const cvc = yup.object().shape({
  value: yup
    .string()
    .required('*required')
    .max(3, 'Enter3 digits')
    .min(3, 'Enter3 digits')
    .matches(Patterns.digits, 'enter digits'),
})

export const querySchema = yup.object().shape({
  value: yup
    .string()
    .required('Question is required')
    .test({
      name: 'trim',
      message: 'Question is not valid',
      test: (value) => {
        let trimmedValue = value ? value?.trim() : ''
        return trimmedValue ? true : false
      },
    }),
})

export const queryCategorySchema = yup.object().shape({
  value: yup.string().required('Please enter a category'),
  // .test({
  //   name: 'trim',
  //   message: 'Enter valid category',
  //   test: (value) => {
  //     let trimmedValue = value ? value?.trim() : ''
  //     return trimmedValue ? true : false
  //   },
  // }),
})

export const schemaInterests = yup.object().shape({
  value: yup.array().of(yup.object().shape({ id: yup.string() })),
})
export const schemaCardId = yup.object().shape({
  value: yup.array().of(yup.object().shape({ last4: yup.string() })),
})

export const passwordSchema = yup.object().shape({
  value: yup
    .string()
    .required('Password is Required')
    .min(8, 'minimum Required Characters is 8')
    .matches(
      Patterns.password,
      'Password must contain at least one uppercase, one lowercase, one number and one special case character'
    ),
  // yup.object().shape({
  //   value: yup.string().matches(Patterns.password, 'Password is invalid').nullable(),
})
export const nameSchema = yup.object().shape({
  value: yup
    .string()
    .matches(Patterns.alphabets, 'Name is not valid')
    .required('Name is required')
    .test({
      name: 'trim',
      message: 'Name is not valid',
      test: (value) => {
        let trimmedValue = value ? value?.trim() : ''
        return trimmedValue ? true : false
      },
    }),
})

export const companyNameSchema = yup.object().shape({
  value: yup
    .string()
    .required('Company is required')
    .test({
      name: 'trim',
      message: 'Company name is not valid',
      test: (value) => {
        let trimmedValue = value ? value?.trim() : ''
        return trimmedValue ? true : false
      },
    }),
})

export const schemaLocation = yup.object().shape({
  value: yup.string().required('Company Location is required'),
})
export const loginPasswordSchema = yup
  .object()
  .shape({ value: yup.string().required('Password is required') })
export const passwordConfirmSchema = yup.object().shape({
  value: yup
    .string()
    .required('Password is Required')
    .min(8, 'minimum Required Characters is 8')
    .matches(
      Patterns.password,
      'Password must contain at least one uppercase, one lowercase, one number and one special case character'
    ),
  // .test({
  //   name: 'passwordMatcher',
  //   message: "Passwords don't match",
  //   test: (value) => {
  //     if (value === data.password.value) {
  //       return true
  //     } else {
  //       return false
  //     }
  //   },
  // }),
})
export const codeSchema = yup.object().shape({
  value: yup
    .string()
    .required('Code is required')
    .matches(Patterns.digits, 'Must be only digits')
    .min(6, 'Must be exactly 6 digits')
    .max(6, 'Must be exactly 6 digits')
    .typeError('Code can be a number only'),
})

export const categoryAndSoftwareSchema = yup.object().shape({
  value: yup.object().shape({ id: yup.string() }).required('Please select a category'),
})

export const accountNumberSchema = yup.object().shape({
  value: yup.string().required('Please enter account number'),
})

export const accountHolderNameSchema = yup.object().shape({
  value: yup.string().required('Please enter account holder name'),
})

export const accountRoutingNameSchema = yup.object().shape({
  value: yup.string().required('Please enter routing number'),
})
